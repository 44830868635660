import { Injectable, OnInit } from '@angular/core';
import { ApiUrls, BlobUrls, HttpParameters } from '../../../core/config';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { AlertService } from '../../core/alert/alert.service';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  Group,
  Member,
  GroupPost,
  Geoboundary,
  Like,
  Save,
  PermissionStatus,
  Group_Category,
  Category,
  Invitation,
  BlobData,
  GroupInvitation,
  Permission,
  AddGroupPermission,
  CreatePostResponse,
  GroupRequest,
  SavedLocation,
  User
} from '../../../core/models';
import { CommentService } from '../../general/comment/comment/comment.service';
import { StorageService } from '../../../services/storage.service';
import { Constants } from '../../../core/config';
import { ToasterService } from '../../../services/toaster.service';
import { Guid } from 'guid-typescript';
import { LocationRadiusLimit, pagination } from '../../../core/Constants';
import { UserService } from '../user/user.service';
import { SKIP_OPTION } from '@src/app/services/interceptor.service';
import { UtilitiesService } from '@src/app/services/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService implements OnInit {
  private readonly groupUrl = ApiUrls.GroupUrl;
  private readonly groupsUrl = ApiUrls.GroupsUrl;
  private readonly addGroupMemberUrl = ApiUrls.AddGroupsMemberUrl;
  private readonly groupsMemberUrl = ApiUrls.GroupsMemberUrl;
  private readonly groupssMemberUrl = ApiUrls.GroupssMemberUrl;
  private readonly groupPostUrl = ApiUrls.GroupPostUrl;
  private readonly groupsPostUrl = ApiUrls.GroupsPostUrl;
  private readonly groupPermUrl = ApiUrls.GroupPermissionUrl;
  readonly currentUserID: string = this.userService.getCurrentUserId();
  readonly coords: string = this.userService.get('coords');
  readonly radius: number = this.userService.get('radius');
  downloadPath: string = BlobUrls.Download + '/';
  public groupImage = new Subject<any>();
  size = pagination.size;
  page = pagination.page;

  postSuccessfulSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public postSuccessfulResponse = this.postSuccessfulSubject.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private commentService: CommentService,
    private storageService: StorageService,
    private utiliesService: UtilitiesService
  ) {}

  ngOnInit() {}

  public get(key: string): any {
    let currentUser = this.storageService.getCurrentUser();

    if (currentUser) {
      switch (key) {
        case 'id':
          return currentUser.id;
        case 'username':
          return currentUser.username;
        case 'coords':
          return currentUser.coordinates;
        case 'radius':
          return currentUser.radius || 100;
        case 'locationPref':
          return currentUser.locationPref || 'default-location';
        case 'default-location':
          return this.DefaultLocation(currentUser.savedLocations);
        default:
          alert('Incorrect user key: ' + key);
      }
    } else {
      return '';
    }
  }

  DefaultLocation(savedLocations: SavedLocation[]) {
    for (let location of savedLocations) {
      if (location.isDefault) {
        return location;
      }
    }
  }

  updateImage(imageData) {
    this.groupImage.next(imageData);
  }

  getImage(): Observable<any> {
    return this.groupImage.asObservable();
  }

  getUserPosts(userID): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.groupsUrl}/${userID}`);
  }

  getGroups(searchParam: string = '', page?: number, size?: number, filterItem?: string): Observable<Group[]> {
    const params = new HttpParams()
      .set('query', searchParam)
      .set('orderBy', filterItem)
      .set('page', page)
      .set('size', size);
    return this.http.get<Group[]>(`${this.groupsUrl}`, { params });
  }

  getGroupsOnlyMember(
    userId: string,
    searchParam: string = '',
    orderBy: string,
    page?: number,
    size?: number
  ): Observable<Group[]> {
    const params = new HttpParams()
      .set('query', searchParam)
      .set('orderBy', orderBy)
      .set('page', page)
      .set('size', size);
    return this.http.get<Group[]>(`${this.groupsUrl}/members-only/${userId}`, { params });
  }

  getCategory(searchParam: string): Observable<any[]> {
    const params = new HttpParams().set('query', searchParam);
    return this.http.get<any[]>(`${this.groupsUrl}/category/`, { params });
  }

  searchCategory(terms: Observable<string>): Observable<Category[]> {
    return terms.pipe(
      distinctUntilChanged(),
      filter((term) => term.length > 0),
      switchMap((term) => this.getCategory(term))
    );
  }

  getGroupCategory(groupId: string): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.groupsUrl}/group_category/${groupId}`);
  }

  addGroup_Category(group_category: Group_Category): Observable<Group_Category> {
    return this.http.post<Group_Category>(`${this.groupsUrl}/categoryAdd`, group_category);
  }

  editGroup_Category(group_category: Group_Category): Observable<Group_Category> {
    return this.http.patch<Group_Category>(`${this.groupsUrl}/categoryEdit`, group_category);
  }

  includeCategory(_category: Category): Observable<Category> {
    return this.http.post<Category>(`${this.groupsUrl}/categoryInclude`, _category);
  }

  getGroup(id, page?, size?): Observable<Group> {
    const params = new HttpParams().set('id', id).set('page', page).set('size', size);
    return this.http.get<Group>(`${this.groupUrl}`, { params });
  }

  getGroupByGroupHandle(groupHandle, page?, size?): Observable<Group> {
    const params = new HttpParams().set('groupHandle', groupHandle).set('page', page).set('size', size);
    return this.http.get<Group>(`${this.groupUrl}/group-handle`, { params });
  }

  getGroupHandle(id): Observable<Group> {
    return this.http.get<Group>(`${this.groupUrl}/handle/${id}`);
  }

  addGroup(_group: Group): Observable<any> {
    return this.http.post<Group>(`${this.groupUrl}`, _group);
  }

  editGroup(_group: Group): Observable<any> {
    return this.http.patch<Group>(`${this.groupUrl}`, _group);
    //return this.http.post<Invitation>(`${this.groupsMemberUrl}/response`, invite);
  }

  addMemberAsAdmin(_member: Member): Observable<Member> {
    return this.http.put<any>(`group-admin`, _member);
  }

  updateGroupProperty(groupId: string, key: string, value: string, blob: BlobData): Observable<any> {
    var dictionary = { [key]: value };
    let groupProperty = { map: dictionary, blobData: blob };
    return this.http.patch<any>(`update-property/${groupId}`, groupProperty);
  }

  updateGroupType(groupId: string, action: boolean): Observable<any> {
    return this.http.put<any>(`update-type/${groupId}`, action);
  }

  addGroupMember(_member: Member): Observable<Member> {
    return this.http.post<Member>(`${this.addGroupMemberUrl}`, _member);
  }

  inviteGroupMember(invite: GroupInvitation) {
    return this.http.post<string>(`${this.groupsMemberUrl}/invite`, invite);
  }

  inviteResponse(invite: GroupInvitation, action?: string): Observable<boolean> {
    if (!action) {
      action = null;
    }
    return this.http.patch<boolean>(`${this.groupsMemberUrl}/response/${action}`, invite);
    //return this.http.post<Invitation>(`${this.groupsMemberUrl}/response`, invite);
  }

  getGroupMemberNotification(): Observable<Member[]> {
    return this.http.get<Member[]>(`${this.groupsMemberUrl}`);
  }

  getAllUserMember(id, query: string = '', isAdmin?: boolean, page?: number, size?: number): Observable<Member[]> {
    const params = new HttpParams().set('isAdmin', isAdmin).set('query', query).set('page', page).set('size', size);
    return this.http.get<Member[]>(`${this.groupssMemberUrl}/user/${id}`, { params });
  }

  getUserMember(id, query: string = ''): Observable<User[]> {
    const params = new HttpParams().set('query', query);
    return this.http.get<User[]>(`${this.groupssMemberUrl}/${id}`, { params });
  }

  getGroupUsers(groupId: string, query?, page?, size?): Observable<User[]> {
    let params = new HttpParams().set('query', query).set('page', page).set('size', size);
    return this.http.get<User[]>(`${this.groupsUrl}/${this.coords}/${this.radius}/${groupId}`, { params });
  }

  //pending invites for groups
  getGroupPendingInvites(groupId: string, page?, size?): Observable<User[]> {
    let params = new HttpParams().set('page', page).set('size', size);
    return this.http.get<User[]>(`${this.groupsUrl}/pending/${this.coords}/${this.radius}/${groupId}`, { params });
  }

  cancelInvitation(groupId: string, invitedUserId: string): Observable<any> {
    return this.http.patch<any>(`${this.groupsMemberUrl}/cancel-invitation/${groupId}/${invitedUserId}`, null);
  }

  leaveGroup(groupId, userId) {
    return this.http.delete<any>(`remove/${groupId}/${userId}`);
  }

  deleteGroup(groupId) {
    return this.http.delete<boolean>(`delete/${groupId}`);
  }

  searchAllUserMember(groupID: string, terms: Observable<string>): Observable<Member[]> {
    return terms.pipe(
      distinctUntilChanged(),
      filter((term) => term.length > 0),
      switchMap((term) => this.getAllUserMember(groupID, term))
    );
  }

  getGroupType(id): Observable<boolean> {
    return this.http.get<boolean>(`${this.groupsUrl}/group-type/${id}`);
  }

  getGroupPermissions(id): Observable<Permission[]> {
    return this.http.get<Permission[]>(`${this.groupPermUrl}/${id}`);
  }

  getUserPermissionStatus(groupId, permissionName): Observable<boolean> {
    return this.http.get<boolean>(`user-permission-status/${groupId}/${permissionName}`);
  }

  getUserPermissionStatusList(groupId, permissionNames: any[]): Observable<any[]> {
    const params = new HttpParams({ fromObject: { permissionNames: permissionNames.map(String) } });
    return this.http.get<any[]>(`user-permission-status-list/${groupId}`, { params });
  }

  addPermission(addPermission: AddGroupPermission): Observable<any> {
    return this.http.post<any>(`permission-settings`, addPermission);
  }

  acceptGroupMember(member_accept: Member): Observable<Member> {
    return this.http.patch<Member>(`${this.groupsMemberUrl}`, member_accept);
  }

  disableGroupMember(member_disable: Member): Observable<Member> {
    return this.http.patch<Member>(`${this.groupsMemberUrl}/disable`, member_disable);
  }

  canSave(groupName: string, location: string, description: string, categoryId: string, blob: boolean): boolean {
    location = !location ? null : location.trim();
    description = !description ? null : description.trim();
    groupName = !groupName ? null : groupName.trim();

    if (!location || !description || !groupName || blob == false) {
      return false;
    }
    return true;
  }

  getGroupImage(groupImage: string): object {
    const emptyImage = './assets/images/group-placeholder.jpg';
    return { 'background-image': groupImage ? 'url(' + groupImage : 'url(' + emptyImage + ')' };
  }

  getGroupImageString(groupImage: string) {
    const emptyImage = './assets/images/group-placeholder.jpg';
    return groupImage ? 'url(' + groupImage : 'url(' + emptyImage + ')';
  }

  getUserGroupPosts(userID): Observable<GroupPost[]> {
    return this.http.get<GroupPost[]>(`${this.groupPostUrl}/${userID}`);
  }

  getGroupPosts(id, page?: number, size?: number): Observable<GroupPost[]> {
    let params = new HttpParams().set('page', page).set('size', size);
    return this.http.get<GroupPost[]>(`${this.groupsPostUrl}/${id}`, { params });
  }

  getGroupPost(id): Observable<GroupPost> {
    return this.http.get<GroupPost>(`${this.groupPostUrl}/${id}`);
  }

  getGroupBlob(id): Observable<BlobData> {
    return this.http.get<BlobData>(`${this.groupPostUrl}/blob/${id}`);
  }

  getGroupBlobBanner(id, blob_category: string): Observable<BlobData> {
    return this.http.get<BlobData>(`${this.groupPostUrl}/blob/${id}/${blob_category}`);
  }

  addGroupPost(_post: GroupPost): Observable<any> {
    return this.http.post<any>(`${this.groupPostUrl}`, _post);
  }

  editGroupPost(post_edited: GroupPost): Observable<Guid> {
    return this.http.patch<Guid>(`${this.groupPostUrl}`, post_edited);
  }

  editGroupBlob(post_edited: GroupPost): Observable<GroupPost> {
    return this.http.patch<GroupPost>(`${this.groupPostUrl}/blob/`, post_edited);
  }

  deletePost(postId: string, isDeleted: boolean): Observable<any> {
    const params = new HttpParams().set('isDeleted', isDeleted);
    return this.http.delete<any>(`${this.groupPostUrl}/${postId}`, { params });
  }

  private update_comment_state(postID: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.groupPostUrl}/${'toggle-comments'}/${postID}`);
  }
  show_comments(post: GroupPost) {
    this.commentService.getComments(post.id).subscribe((comments) => {
      post.groupComments = comments;
    });
  }

  private update_identity_state(postID: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.groupPostUrl}/${'toggle-identity'}/${postID}`);
  }

  toggle_identity(post: GroupPost) {
    this.update_identity_state(post.id).subscribe((new_state) => {
      //post.identityIsHidden = new_state;
    });
  }

  likePost(_like: Like): Observable<boolean> {
    return this.http.post<boolean>(`${this.groupPostUrl}/${'like'}`, _like, { context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)});
  }

  like_toggle_text(post: GroupPost): string {
    return 'Like';
  }

  private savePost(_save: Save): Observable<boolean> {
    return this.http.post<boolean>(`${this.groupPostUrl}/${'save'}`, _save);
  }

  save(post: GroupPost) {
    if (!post.saved) {
      post.saved = false;
    }
    let new_save = <Save>{ entityID: post.id, saved: post.saved, entityName: Constants.Group };
    this.savePost(new_save).subscribe((save_status) => {
      post.saved = save_status;
    });
  }

  save_toggle_text(post: GroupPost): string {
    return post.saved ? 'Unsave' : 'Save';
  }

  copyShareLink(post: GroupPost) {
    let link = this.utiliesService.getOrigin() + '/post/' + post.id;
    this.utiliesService.copyToClipboard(link).subscribe();
  }

  isAuthor(post: GroupPost): boolean {
    return post.author && post.author.id == this.currentUserID ? true : false;
  }

  canSaveGroupPost(body: string, hasBlob: boolean): boolean {
    body = !body ? null : body.trim();

    if (!body && !hasBlob) {
      return false;
    }

    return true;
  }

  canSaveGroupBlob(hasBlob: boolean): boolean {
    if (!hasBlob) {
      return false;
    }
    return true;
  }

  parseBoundary(boundary: Geoboundary): Geoboundary {
    if (boundary && boundary.pref == 'radius') {
      boundary.polygon = null;
    }

    if (boundary && boundary.pref == 'geofence') {
      let polygon = [];
      boundary.polygon.forEach((path) =>
        polygon.push({
          lat: typeof path.lat == 'function' ? path.lat() : path.lat,
          lng: typeof path.lng == 'function' ? path.lng() : path.lng,
        })
      );
      boundary.polygon = polygon;
      boundary.radius = null;
    }

    delete boundary['pref'];
    delete boundary['unit'];

    return boundary;
  }

  getFeaturedGroups() {
    return of();
  }

  isAvailable(input: string) {
    return this.http.get<boolean>(`${this.groupUrl}/${'isAvailable'}/${input}`);
  }

  getGroupRequest(groupID: string) {
    return this.http.get<GroupRequest[]>(`${this.groupsUrl}/${'request'}/${groupID}`);
  }

  requestToJoin(request: GroupRequest) {
    return this.http.post<boolean>(`${this.groupsUrl}/${'requestAdd'}`, request);
  }

  requestResponse(request: GroupRequest) {
    return this.http.post<boolean>(`${this.groupsUrl}/${'request-response'}`, request);
  }

  requestCountNotification(groupID: string) {
    return this.http.get<number>(`${this.groupsUrl}/${'request-count'}/${groupID}`);
  }

  suspendMember(member: Member) {
    return this.http.post<any>(`${this.groupsUrl}/${'suspend'}`, member);
  }

  getGroupSuggestionsByLocation(groupId, location, page, size): Observable<User[]> {
    let radius = LocationRadiusLimit.locationRadiusLimit;
    let params = HttpParameters.params({ radius, page, size });

    return this.http.get<User[]>(`${this.groupsUrl}/${'invitation-suggestions'}/${groupId}/${location}`, { params });
  }

  searchEntries(groupId, query: string = '', page, size, location: string = ''): Observable<User[]> {
    let params = HttpParameters.params({ location, page, size, query });
    return this.http.get<User[]>(`${this.groupsUrl}/get-users-by-keyword/${groupId}`, { params });
  }

  postCreated(value: boolean) {
    this.postSuccessfulSubject.next(value);
  }
}
