<div class="modal-header">
  <span *ngIf="infoIcon" class="mdi mdi-information-outline modal-icon"></span>
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">{{message}}</div>
<div class="modal-footer" *ngIf="showOption">
  <button type="button" class="btn btn-outline-primary save-btn" id="generic-modal-yes" (click)="onSave()">
    <span class="save-text">Yes</span>
  </button>
  <button type="button" class="btn btn-primary cancel-btn" id="generic-modal-no" (click)="onCancel()">
    <span class="cancel-text">No</span>
  </button>
</div>

<div class="modal-footer" *ngIf="!showOption">
  <button type="button" class="btn btn-primary cancel-btn" id="generic-modal-ok" (click)="onOk()">
    <span class="cancel-text">Ok</span>
  </button>
</div>