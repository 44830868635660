import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { DirectivesModule } from "@src/app/directives/directives.module";
import { PipesModule } from "@src/app/pipes/pipes.module";
import { HttpInterceptorService } from "@src/app/services/http-interceptor.service";
import { HttpProgressInterceptorService } from "@src/app/services/http-progress-interceptor.service";
import { InterceptorService } from "@src/app/services/interceptor.service";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { QuillModule } from "ngx-quill";
import { MdbFiveModule } from "../general/mdb-five/mdb-five.module";
import { SmartInputOutputModule } from "../general/smart-input-output/smart-input-output.module";
import { ContentPlaceholderComponent } from "./content-placeholder/content-placeholder.component";
import { EmptyPageComponent } from "./empty-page/empty-page.component";
import { EmptyStateComponent } from "./empty-state/empty-state.component";
import { FloatingInputComponent } from "./floating-input/floating-input.component";
import { LoadingIndicatorComponent } from "./loading-indicator/loading-indicator.component";
import { UserInteractionControlComponent } from "./user-interaction-control/user-interaction-control.component";
import { CChatDropdownComponent } from './c-chat-dropdown/c-chat-dropdown.component';

const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptorService, multi: true },
];

const declarations = [
  UserInteractionControlComponent,
  FloatingInputComponent,
  ContentPlaceholderComponent,
  EmptyPageComponent,
  EmptyStateComponent,
  LoadingIndicatorComponent,
  CChatDropdownComponent
];

const imports = [
  FormsModule,
  ReactiveFormsModule,
  MdbFiveModule,
  SmartInputOutputModule,
  DirectivesModule,
  PipesModule
];

@NgModule({
  declarations: [
    ...declarations,
    CChatDropdownComponent,
  ],
  providers: [...httpInterceptorProviders],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    PickerModule,
    QuillModule.forRoot(),
    RouterModule,
    ...imports
  ],
  exports: [
    ...declarations,
    ...imports
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule { }
