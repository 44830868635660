import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from "@angular/core";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { ToasterService } from "./toaster.service";
import { Guid } from 'guid-typescript';
import { isPlatformBrowser } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {

    private clearTextSubject = new BehaviorSubject<boolean>(false);
    public clearTextAction = this.clearTextSubject.asObservable();
    private renderer: Renderer2;

    constructor(private toasterService: ToasterService, private rendererFactory: RendererFactory2, @Inject(PLATFORM_ID) private platformId: Object) { 
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    getOrigin() {
        return isPlatformBrowser(this.platformId) ? (window.location.origin || (window.location.protocol + '//' + window.location.host)) : '';  // window.location.href.split(/[?#]/)[0]
    }

    copyLinkToClipboard(text: string) {
        let generatedLink = this.buildCopyUrl(text);
        generatedLink = this.removeQuotes(generatedLink);
        this.copyToClipboard(generatedLink).subscribe();
    }

    buildCopyUrl(text: string) {
        return this.getOrigin() + '/' + text;
    }

    public removeQuotes(data: string): any {
        return data.replace(/^"(.*)"$/, '$1');
    }

    public copyToClipboard(text: any): Observable<boolean> {
        let copied: boolean;

        if (navigator && navigator['clipboard']) {
            return from(navigator['clipboard'].writeText(text)
                .then(() => { copied = true; })
                .then(() => { return this.copyCompleted(copied); }));
        }

        else { return of(this.copyCompleted(this.fallbackCopy(text))); }
    }

    private fallbackCopy(data: string): boolean {
        if (isPlatformBrowser(this.platformId)) {
          const textArea = this.renderer.createElement('textarea');
          this.renderer.setProperty(textArea, 'value', data);
          this.renderer.appendChild(document.body, textArea);
    
          textArea.focus();
          textArea.select();
    
          let copied = false;
          
          try {
            copied = document.execCommand('copy');
          } catch (err) {
            console.error('Copy failed', err);
          }
    
          this.renderer.removeChild(document.body, textArea);
    
          return copied;
        }

        return false; // Default to false if not in a browser environment
    }

    private copyCompleted(copied: boolean): boolean {
        copied ? this.toasterService.success('Copied to clipboard') : this.toasterService.error('Unable to copy to clipboard');
        return copied;
    }

    public getRandomColorCode(): string {
        let randomHex = Math.floor(Math.random() * 16777215).toString(16);
        if (randomHex != 'ffffff') { return randomHex; }
        return this.getRandomColorCode();
    }

    GuidGenerator(): Guid {
        return Guid.create();
    }

    public carriageReturn(event): boolean {
        if (event.keyCode == 13 && !event.shiftKey) {
            return true;
        }
        return false;
    }

    clearText(clear: boolean) {
        this.clearTextSubject.next(clear);
    }

    debounce(func: Function, wait: number) {
        let timeout: any;

        return function (...args: any[]) {
            const context = this;

            const later = () => {
                timeout = null;
                func.apply(context, args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    showCharacterLimitToast() {
        this.toasterService.warning("Character Limit Exceeded", "", { timeOut: 10000 })
    }

}
