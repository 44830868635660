import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { FileUploadService } from './file-upload.service';
import { ToasterService } from '@src/app/services/toaster.service';
import { FileCategory, FileSizeRestrictionSetting } from '@src/app/core/Constants';
// import { NgxImageCompressService } from 'ngx-image-compress';  // "ngx-image-compress": "^13.1.16",
import { BlobData, BlobEmit, FileUploadResponse } from '@src/app/core/models/model';

export interface FileUploadOptions {
    multiple?: boolean;
    uploadCountLimit?: number;
    existingFileCount?: number;
    supportedTypes?: string[];
    maxSizePerFileInMB?: number;
    disableCompression?: boolean;
}

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [FileUploadService]
})
export class FileUploadComponent implements OnInit {

    @Input() parent_category: string;
    @Input() showUploadButton: boolean;
    @Input() showProgressIndicator: boolean;
    @ViewChild("input") input;
    uploadOptions: FileUploadOptions = { multiple: true, uploadCountLimit: 0, existingFileCount: 0, supportedTypes: ['image', 'video'], maxSizePerFileInMB: 0, disableCompression: false }
    index: number = 0;
    @Output() file_emitter = new EventEmitter<any>();
    @Output() cancel_emitter = new EventEmitter<any>();
    @Output() monitor_upload = new EventEmitter<boolean>();
    get supportedTypes() {
        return this.uploadOptions.supportedTypes.map(x => (x + '/*')).join(','); // image/*,video/*
    }

    // created to keep track of the auto generated guid for temporary container;
    guidHolder: string = "";
    fileResponse: FileUploadResponse;
    uploadComplete: boolean;
    uploadInProgress: boolean;        

    constructor(private fileUploadService: FileUploadService,
                private toasterService: ToasterService,
                // private imageCompress: NgxImageCompressService
          ) { }

    ngOnInit(): void { }   

    //getBuffer(file, reader, callback): void {

    //    // Set a callback funtion to fire after the file is fully loaded
    //    reader.onload = () => {

    //        let arrayBuffer = reader.result
    //        callback(arrayBuffer); // callback with the results
    //    }

    //    reader.readAsDataURL(file);
    //}


    launch_uploader(options: FileUploadOptions) {
        if (options.multiple !== null) { ((this.input.nativeElement) as HTMLInputElement).multiple = options.multiple }  // document.getElementById("input")['multiple'] = multiple;

        if (options.uploadCountLimit > 0) this.uploadOptions.uploadCountLimit = options.uploadCountLimit;

        if (options.existingFileCount > 0) { this.uploadOptions.existingFileCount = options.existingFileCount }

        if (options.disableCompression) this.uploadOptions.disableCompression = options.disableCompression;

        if (options.supportedTypes) {
            ((this.input.nativeElement) as HTMLInputElement).accept = options.supportedTypes.map(x => (x + '/*')).join(',');
        }

        if (this.fileLimitExceeded()) return;

        this.input.nativeElement.click();
    }

    fileLimitExceeded(newlyUploadedFileCount: number = 1) {
        if (this.uploadOptions.uploadCountLimit && (newlyUploadedFileCount + this.uploadOptions.existingFileCount) > this.uploadOptions.uploadCountLimit) {
            this.toasterService.error(`Upload Denied, Maximum file limit of ${this.uploadOptions.uploadCountLimit} can not be exceeded.`, 'Upload Denied');
            return true;
        }

        return false;
    }

    fileSizeExceeded(file: File): boolean {
        if (this.convertBytesToMegaBytes(file.size) > FileSizeRestrictionSetting.fileUploadSizeLimit) {
            this.toasterService.error(`${file.name} has exceeded the maximum file size of ${FileSizeRestrictionSetting.fileUploadSizeLimit}MB.`, 'Upload Denied', { timeOut: 10000 });
            return true;
        }

        return false;
    }


    convertBytesToMegaBytes(bytes): number {
        return (bytes / (1024 * 1024));
    }

    updateUploadProgress(inProgress: boolean) {
        this.uploadInProgress = inProgress;
        this.monitor_upload.emit(inProgress);
    }

    UploadFiles(): void {        
        this.updateUploadProgress(true);
        const i = this.index++;
        const file = this.input.nativeElement.files[i];
        const fileLength = this.input.nativeElement.files.length;

        if (this.fileLimitExceeded(fileLength)) {
            this.updateUploadProgress(false);
            return;
        }

        this.uploadComplete = i == (fileLength - 1);

        if (file) {

            if (this.fileSizeExceeded(file)) { 
                this.UploadFiles(); 
                this.updateUploadProgress(false);
                return;
            }

            if (this.allowedFileType(file)) {
                const file_category = file.type.match('image.*') ? FileCategory.Images : FileCategory.Videos;
                
                // if (!this.uploadOptions.disableCompression && file_category == FileCategory.Images) { 
                //     this.compressAndUploadImage(file, file_category); 
                // }

                // else {  
                    const file_name = file.name;
                    const input = new FormData();
                    input.append(file_category, file, file_name);
                    this.upload(input, file_category); 
                // }
            }

            else {
                this.toasterService.error(file.name + " : is an invalid file type, file not uploaded");
                this.UploadFiles();
            }            
        }

        else {
            //Reset file uploader when all uploads are completed
            this.index = 0;
            this.input.nativeElement.value = "";
            this.updateUploadProgress(false);
        }
    }

    upload(input: FormData, file_category: string) {
        this.fileUploadService.upload(input, this.parent_category, file_category)
            .subscribe((blobMetaData) => {
                let _blob: BlobData = blobMetaData;
                let _blobEmit: BlobEmit = <BlobEmit>{ blob: _blob, blobComplete: this.uploadComplete }
                this.file_emitter.emit(_blobEmit);
                this.UploadFiles();

                //let reader = new FileReader();
                ////let fileData = new Blob([file]);
                //this.getBuffer(file, reader, (result) => {

                //    let file_name = file.name;
                //    let extension = file_name.substring(_name.lastIndexOf('.') + 1);

                //    //result = result.replace("data:;base64,", "data:image/" + extension + ";base64,");

                //    if (file_category == "Images") {
                //        // Send this img to the resize function (and wait for callback)
                //        this.resize(result, 700, 350, extension, (resized_image, size_before, size_after) => {

                //            let _blob: BlobData = <BlobData>{ content: resized_image, name: file_name, blob_ID: guid, blob_category: file_category, isPrimary: false, newUpload: true, url: this.parent_category + "s/" + file_category + "/" + guid + "---" + file_name };
                //            this.file_emitter.emit(_blob);
                //            this.UploadFiles();
                //        });
                //    }

                //    else {
                //        // After the callback fires do:
                //        let _blob: BlobData = <BlobData>{ content: result, name: file_name, blob_ID: guid, blob_category: file_category, isPrimary: false, newUpload: true, url: this.parent_category + "s/" + file_category + "/" + guid + "---" + file_name };
                //        this.file_emitter.emit(_blob);
                //        this.UploadFiles();
                //    }
                //});
            },

            error => {
                this.toasterService.error("File upload error : " + JSON.stringify(error));
                this.UploadFiles();
            });
    }

    // resize(img_dataURL, MAX_WIDTH: number, MAX_HEIGHT: number, extension: string, callback) {

    //     // Create an img element and add the image file data to it
    //     var img = document.createElement("img");
    //     img.src = img_dataURL;

    //     // This will wait until the img is loaded before calling this function
    //     return img.onload = () => {

    //         // Get the images current width and height
    //         var width = img.width;
    //         var height = img.height;

    //         // Set the WxH to fit the Max values (but maintain proportions)
    //         if (width > MAX_WIDTH) {
    //             height *= MAX_WIDTH / width;
    //             width = MAX_WIDTH;
    //         }

    //         if (height > MAX_HEIGHT) {
    //             width *= MAX_HEIGHT / height;
    //             height = MAX_HEIGHT;
    //         }

    //         // create a canvas object
    //         var canvas = document.createElement("canvas");

    //         // Set the canvas to the new calculated dimensions
    //         canvas.width = width;
    //         canvas.height = height;
    //         var ctx = canvas.getContext("2d");

    //         ctx.drawImage(img, 0, 0, width, height);

    //         // Get this encoded as a jpeg
    //         var resized_dataUrl = canvas.toDataURL('image/' + extension);

    //         // callback with the results
    //         callback(resized_dataUrl, img.src.length, resized_dataUrl.length);
    //     };
    // }

    discardUploads(blobs: BlobData[]) {
        if (blobs && blobs.length > 0) {
            this.fileUploadService.discardUploads(blobs).subscribe();
        }
    }

    allowedFileType(file: any): boolean {
        let allowed = false;

        this.uploadOptions.supportedTypes.forEach(element => {
            if (file.type.match(`${element}.*`)) {
                allowed = true;
            }
        });

        return allowed;
    }

    // private compressAndUploadImage(file: File, fileCategory: string) {
    //     const reader = new FileReader();
    //     const input = new FormData();

    //     reader.onload = (event: any) => { 
    //         this.imageCompress
    //         .compressFile(event.target.result, -1, 50, 50) // 50% ratio, 50% quality
    //         .then(compressedImage => {
    //            input.append(fileCategory, this.dataURLtoFile(compressedImage, file.name), file.name);
    //            this.upload(input, fileCategory);
    //         });
    //     }

    //     reader.onerror = () => {
    //         input.append(fileCategory, file, file.name);
    //         this.upload(input, fileCategory);
    //     }

    //     reader.readAsDataURL(file);
    // }

    // private dataURLtoFile(dataurl: string, filename: string) {
    //     let arr = dataurl.split(','),
    //         mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[1]), 
    //         n = bstr.length, 
    //         u8arr = new Uint8Array(n);
            
    //     while(n--){
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
        
    //     return new File([u8arr], filename, {type:mime});
    // }

    @HostListener("window:focus", ["$event"])
        onFocus(event: FocusEvent): void {        
            if (this.uploadComplete != true) {             
                this.cancel_emitter.emit();       
            }
    }    
}


