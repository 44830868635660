<div class="modal-header" *ngIf="mode">
    <h5 class="modal-title">No results found</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>

<div class="empty-state" [ngClass]="{'empty-state-modal': mode}" *ngIf="!noSearchResult; else showNoSearchResult">
    <div class="px-4 empty-state-wrapper text-center">
        <h6 class="mb-1">No {{notInitial ? 'more ' : ''}} {{title}} found around your active locations.</h6>
        <p class="mb-3">Add more locations {{!isProd ? 'or explore' : ''}} to see {{notInitial ? 'more ' : ''}} {{title}}</p>
    </div>

    <div class="d-flex">
        <button class="btn btn-sm btn-outline-primary me-4" *ngIf="!isProd">
            <span class="mdi mdi-autorenew btn-icon"></span>
            <span class="btn-text">Explore</span>
        </button>
        <button (click)="goToLocation()"
            class="btn btn-sm btn-primary ">
            <span class="mdi mdi-map-marker-plus-outline btn-icon"></span>
            <span class="btn-text">Add Location</span>
        </button>
    </div>  
</div>
<ng-template #showNoSearchResult>
    <div class="empty-state" [ngClass]="{'empty-state-modal': mode}">
        <div class="px-4 empty-state-wrapper text-center">
            <h6 class="mb-1">{{title}} not found around your active locations.</h6>
        </div>
    </div>
</ng-template>
