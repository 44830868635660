import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '@src/app/core/config';
import { FeedbackGroup, SupportFAQ, TermsAndCondition, UserFeedback } from '@src/app/core/models/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private readonly AddFeedbackUrl = ApiUrls.AddFeedbackUrl;
  private readonly FeedbackGroupUrl = ApiUrls.FeedbackGroupUrl;
  private readonly SupportFAQUrl = ApiUrls.SupportFAQUrl;
  private readonly TermsAndConditionUrl = ApiUrls.TermsAndConditionUrl;

  constructor(private http: HttpClient) { }

  sendFeedback(payload): Observable<UserFeedback>{
    return this.http.post<UserFeedback>(this.AddFeedbackUrl, payload);
  }

  getFeedbackGroup(group: string): Observable<FeedbackGroup[]>{
    return this.http.get<FeedbackGroup[]>(`${this.FeedbackGroupUrl}/${group}`);
  }

  getLegal(): Observable<TermsAndCondition>{
    return this.http.get<TermsAndCondition>(`${this.TermsAndConditionUrl}`);
  }

  getFAQ(page: number, size: number): Observable<SupportFAQ[]>{
    const params = new HttpParams().set('page', page).set('size', size)
    return this.http.get<SupportFAQ[]>(`${this.SupportFAQUrl}`, {params});
  }
}
