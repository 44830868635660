import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DebounceTime, OnlineStatusTimer, SearchCharacterLimit, pagination } from '@src/app/core/Constants';
import { BlobData, Message, ResponseMessage, User, UserInputMessage } from '@src/app/core/models/model';
import { MessageFileUploadComponent } from '@src/app/modules/feature/message/message-file-upload/message-file-upload.component';
import { MessageService } from '@src/app/modules/feature/message/message/message.service';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { MessagingService } from '@src/app/services/messaging.service';
import { RestrictionsService } from '@src/app/services/restrictions.service';
import { SignalrService } from '@src/app/services/signalr.service';
import { StorageService } from '@src/app/services/storage.service';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MessageChatBoxComponent } from '../message-chat-box/message-chat-box.component';
import { UtilitiesService } from '@src/app/services/utilities.service';

@Component({
  selector: 'app-message-chat-popup',
  templateUrl: './message-chat-popup.component.html',
  styleUrls: ['./message-chat-popup.component.scss'],
})
export class MessageChatPopupComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  readonly currentUserID: string = this.userService.getCurrentUserId();
  searchRecepient = '';
  searchTerm = new FormControl();
  query: string = '';
  initialLoading: boolean = true;
  loading: boolean = false;
  dateAt = '';
  senderMessage = '';
  recepientMessage: UserInputMessage = {};
  readMore = false;
  recipientID: string = '';
  new_message: Message = { body: '' };
  recepient_message: Message = { body: '' };
  messageCollections = [];
  messageList: ResponseMessage[] = [];
  recepientMessages = [];
  recepientMessageThread = [];
  thread = { id: 'thread_id' };
  recepient = { id: 'recepient_id' };
  hourOfDay = { am: 'AM', pm: 'PM', pm_time: 12 };
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  isNewMessage: boolean = false;
  date = new Date();
  dateInfo = {
    day: this.date.getDate(),
    month: this.date.getMonth(),
    year: this.date.getFullYear(),
    hour: this.date.getHours(),
    mins: this.date.getMinutes(),
    sec: this.date.getSeconds(),
  };
  currentDate_info = { day: this.date.getDate(), month: this.date.getMonth(), year: this.date.getFullYear() };

  threadMessages = [];
  imageUrl = '';
  name = '';
  page = pagination.page;
  size = pagination.size;
  toggleMessageBox = false;
  newMessageBox = false;
  clickEvent: Subscription;
  timeoutDuration = 100;
  threadTracker = '';
  restrictedUsers: User[] = [];
  blockedUser: string = '';
  lockedField: boolean = false;
  userId = '';
  recipientUser: User;
  statusUpdateInterval: any;
  showCurrentlyTyping = false;
  searchCharacterLimit = SearchCharacterLimit.SearchTextbox;

  @Input() blobCollection: BlobData[] = [];
  @Output() closeMessagePopupEmitter = new EventEmitter<any>();

  @ViewChild(MessageChatBoxComponent) private messageChatBoxComponent: MessageChatBoxComponent;

  modalRef: MdbModalRef<MessageFileUploadComponent>;

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private storageService: StorageService,
    private messagingService: MessagingService,
    public signalrService: SignalrService,
    private restrictionService: RestrictionsService,
    private utilitiesService: UtilitiesService
  ) {
    this.removeExistingMessageBox();
    this.senderActionListener();
    this.searchTerm.valueChanges
      .pipe(
        debounceTime(DebounceTime.debounceTime),
        distinctUntilChanged(),
        tap((value) => {
          if (value.length > this.searchCharacterLimit){
            this.utilitiesService.showCharacterLimitToast();
            return;
          }else{
            this.fetchMessages(value);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.showCurrentlyTyping = false;
  }

  ngOnInit(): void {
    this.getMessages();
    this.lastViewNotification();
  }

  closeMessagePopup() {
    this.closeMessagePopupEmitter.emit();
  }

  fetchMessages(keyword: string) {
    if (keyword.length != 1 && this.query != keyword) {
      this.query = keyword;
      this.searchRecepient = this.query;
      // this.searchRecepient = this.query;
      this.getMessages();
    }
  }

  show(obj: Message, imageUrl: string, recepient: User) {
    this.threadTracker = obj.threadID;
    this.userId = recepient.id;
    this.removeNewMessageBox();
    this.clearMessageTextArea();
    this.loadMessages(obj.threadID);
    this.lockedTextField(obj.recipientID);
    this.imageUrl = imageUrl;
    this.name = recepient.name;
    this.recipientUser = recepient;
    this.storeData(recepient, obj.threadID);
    this.toggleMessageBox = true;
  }

  loadMessages(id: string) {
    this.messageService.loadMessages(id, this.page, this.size).subscribe((next) => {
      this.threadMessages = next;
      this.signalrService.chatWidgetMessages.next(this.threadMessages);
      this.eventTrackerToUpdateMessages();
      setTimeout(() => {
        this.messageChatBoxComponent.scrollToLastMessage();
      }, 1000);
    });
  }

  getMessages() {
    this.loading = true;
    this.showCurrentlyTyping = false;
    this.messageService.getMessageCollectionsAsync(this.searchRecepient).subscribe((res: any) => {
      this.loading = false;
      if (this.initialLoading) {
        this.initialLoading = false;
      }
      this.recepientMessages = [];
      const messageThread = res;
      this.messageCollections = messageThread;
      const messageList = [];
      for (let mes of this.messageCollections) {
        messageList.push(mes);
      }
      this.recepientMessages = messageList;
      this.triggerOnlineStatusUpdates();
      this.signalrService.chatWidgetMessageList.next(this.recepientMessages);
    });
  }

  triggerOnlineStatusUpdates() {
    this.updateOnlineStatus();
    if (!this.statusUpdateInterval) {
      this.statusUpdateInterval = setInterval(() => {
        this.updateOnlineStatus();
      }, OnlineStatusTimer.RefreshInterval);
    }
  }

  updateOnlineStatus() {
    if (this.recepientMessages.length > 0) {
      const users = this.recepientMessages.map((u) => u.user);
      this.userService.updateOnlineStatuses(users).subscribe((res) => {
        this.recepientMessages.forEach((u) => {
          const updatedUser = res.find((x) => x.id == u.user.id);
          if (updatedUser) u.user = updatedUser;
        });
      });
    }
  }

  //------set message to read------//
  eventTrackerToUpdateMessages() {
    this.messageService.eventTrackerToUpdateMessages(this.threadMessages, this.currentUserID);
  }

  //updates message last view to track user message activity
  lastViewNotification() {
    this.messageService.lastViewedMessageNotificationOn().subscribe();
  }

  //-------------------helper functions--------------------//
  storeData(recepient: User, threadId: string) {
    this.storageService.set(this.recepient.id, recepient.id);
    this.storageService.set(this.thread.id, threadId);
  }

  removeNewMessageBox() {
    this.messagingService.onclickEvent();
  }

  clearMessageTextArea() {
    this.messagingService.onclickEvent();
  }

  removeExistingMessageBox() {
    this.clickEvent = this.messagingService.getOnclick().subscribe(() => {
      this.toggleMessageBox = false;
    });
  }

  // getDateTime(date: Date) {
  //   const createdOn = new Date(date);

  //   this.dateInfo.year = createdOn.getFullYear();
  //   this.dateInfo.month = createdOn.getMonth();
  //   this.dateInfo.day = createdOn.getDate();
  //   this.dateInfo.mins = createdOn.getMinutes();
  //   this.dateInfo.sec = createdOn.getHours();

  //   if (this.currentDate_info.month != this.dateInfo.month) {
  //     this.dateAt = this.dateInfo.day + ' ' + this.getFormattedMonth(this.dateInfo.month) + ', ' + this.dateInfo.year;
  //     return this.dateAt;
  //   } else if (this.currentDate_info.day != this.dateInfo.day) {
  //     this.dateAt = this.dateInfo.day + ' ' + this.getFormattedMonth(this.dateInfo.month) + ', ' + this.dateInfo.year;
  //     return this.dateAt;
  //   } else {
  //     this.dateAt = this.dateInfo.hour + ':' + this.dateInfo.mins + ' ' + this.getHourOfDay(this.dateInfo.hour);
  //     return this.dateAt;
  //   }
  // }

  // getHourOfDay(hour: number): string {
  //   let hourOfDay = hour >= this.hourOfDay.pm_time ? this.hourOfDay.pm : this.hourOfDay.am;
  //   return hourOfDay;
  // }

  // getFormattedMonth(month: number): string {
  //   return this.months[month];
  // }

  getUserImage(userImage: string): object {
    return this.userService.getUserImage(userImage);
  }

  lockedTextField(userID: string): boolean {
    this.restrictionService.restrictedPartner(userID).subscribe((response) => {
      this.lockedField = response?.isBlocked;
      this.blockedUser = response?.name;
    });
    return this.lockedField;
  }

  senderActionListener(){
    this.signalrService.senderIsTyping$.subscribe(res=>{
      if(res && Object.keys(res).length > 0){
        if(res.threadID){
          let timer = 10000
          this.recepientMessage = res
          this.showCurrentlyTyping = true;
          setTimeout(() =>{
            this.showCurrentlyTyping = false;
          }, timer);
        }
      }
     })
   }
}
