import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {

  constructor(public modalRef: MdbModalRef<GenericModalComponent>, private router: Router) { }
  title: string | null = null;
  message: string | null = null;
  showOption: boolean = true;
  infoIcon: boolean;

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      this.modalRef.close()
    });
  }


  onCancel() {
    this.modalRef.close(false);
  }

  onSave() {
    this.modalRef.close(true);
  }

  onOk(){
    this.modalRef.close(true);
  }
}