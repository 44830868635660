import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlobUrls } from '@src/app/core/config';
import { BlobData } from '@src/app/core/models/model';
import { HeaderService } from '@src/app/services/header.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private uploadUrl = BlobUrls.Upload;

  constructor(private http: HttpClient, private header: HeaderService) { }



  upload(allFiles: any, parent_category: string, file_category: string) {
    return this.http.post<any>(`${this.uploadUrl}`, allFiles, { params: { parent_category, file_category } });
  }

  discardUploads(blobs: BlobData[]) {

    let delete_blobs: string[] = [];
    blobs.forEach(b => { delete_blobs.push(b.id); });

    return this.http.post<string>(`${this.uploadUrl}/${'discard'}`, delete_blobs);
  }

}
