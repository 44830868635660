<div class="new-chat-box" *ngIf="!toggleMessageBox">
  <div *ngIf="showSearch" class="new-chat-header">
    <p class="new-chat-title">
      <span class="mdi mdi-message-reply-outline"></span>
      New message
    </p>

    <button class="new-chat-close-btn" (click)="close()">
      <i class="fas fa-times"></i>
    </button>
  </div>

  <div class="new-chat-body">
    <!----------------------------Recepient-profile------------------------------------>
    <div class="new-chat-unit" *ngIf="showProfile">
      <div class="new-chat-unit-avatar">
        <div class="new-chat-unit-avatar-image" [appUserImage]="recepient"></div>
        <div class="new-chat-unit-avatar-status" [appOnlineStatus]="recepient" [dynamic]="true"></div>
      </div>
      <div class="new-chat-unit-body">
        <h5 class="new-chat-unit-username text-truncate">{{ recepient.name }}</h5>
        <p class="new-chat-unit-last-seen text-truncate">{{ lastSeen | timeAgo }}</p>
      </div>
      <button (click)="close()" class="new-chat-unit-close-btn">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!----------------------------Recepient-profile-end------------------------------------>

    <!---------------------------------Search-input-end----------------------------------------->
    <div class="new-chat-search-thread" *ngIf="showSearch || query != ''">
      <input
        #searchInput
        *ngIf="showSearch"
        type="text"
        placeholder="Type name or username of recipient"
        [formControl]="searchTerm"
        [maxlength]=searchCharacterLimit
        (keyup.enter)="searchUser($event.target.value)"
        class="new-chat-search-input"
      />

      <div class="new-chat-empty" *ngIf="query == '' && recepientUsers.length < 1">
        <p class="new-chat-empty-text">Add a recipient to start your first conversation.</p>
        <button (click)="focusSearchInput()" type="button" class="new-chat-empty-btn">Search Recipient</button>
      </div>

      <div *ngIf="query != ''" class="new-chat-search-thread-inner">
        <div
          *ngFor="let recipient of recepientUsers"
          class="new-chat-search-user-box"
          (click)="continueConversation(recipient.user)"
        >
          <div class="new-chat-search-user-avatar">
            <div class="new-chat-search-user-avatar-image" [appUserImage]="recipient.user"></div>
            <div class="new-chat-search-user-avatar-status" [appOnlineStatus]="recipient.user"></div>
          </div>
          <span class="new-chat-search-user-name text-truncate">{{ recipient.user.name }}</span>
        </div>
      </div>
    </div>

    <div class="" id="message" *ngIf="query == ''">
      <div class="card-body p-3" *ngIf="!showConversation"></div>
    </div>
  </div>
</div>

<app-message-chat-box
  *ngIf="toggleMessageBox"
  [threadMessages]="threadMessages"
  [isOpenToTheRight]="true"
  [name]="name"
  [imageUrl]="imageUrl"
  [threadTracker]="threadTracker"
  [isBlocked]="lockedField"
  [recipientUser]="recepient"
>
</app-message-chat-box>
