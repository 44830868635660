import { MainMasterService } from '@src/app/layouts/main-master/main-master.service';
import { Component, Input, OnInit } from "@angular/core";
import { ProfileTabs } from "@src/app/core/config";
import { Router } from '@angular/router';
import { UserService } from '../../feature/user/user.service';
import { environment } from '@src/environments/environment';

@Component({
  selector: "app-empty-state",
  templateUrl: "./empty-state.component.html",
  styleUrls: ["./empty-state.component.scss"],
})
export class EmptyStateComponent implements OnInit {
  @Input() title: string;
  @Input() noSearchResult: boolean;
  notInitial: boolean;
  mode: string;
  readonly currentUserID: string = this.userService.getCurrentUserId();
  readonly currentUsername: string = this.userService.getCurrentUserName();
  locationTab: string = ProfileTabs.Location.toLowerCase();

  isProd = environment.production;

  constructor(private userService: UserService, private mainMasterService: MainMasterService, private router: Router) { }

  ngOnInit(): void { }

  closeModal() {
    this.mainMasterService.updateCloseStatus(true);
  }

  goToLocation() {
    this.router.navigate(['/user/' + this.currentUsername], { queryParams: { tab: this.locationTab, modal: 'create' } })
  }
} 
