<!-- <div class="modal-header">
  <div class="modal-header-text fw-bold">Search Corchat</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div> -->

<div class="modal-body py-2">
  <div class="search-wrapper">
    <form [formGroup]="searchForm">
      <div
        class="d-flex justify-content-between flex-wrap align-items-center search-filter-wrapper"
      >
        <div class="py-2 flex-1 me-3">
          <span class="material-symbols-outlined search-res-icon">search</span>
          <span class="search-res">Search</span>
        </div>

        <div class="d-flex btns-wrapper">
          <mdb-form-control class="form-outline">
            <mdb-select
              class="select-form"
              formControlName="filterType"
              id="filterType"
            >
              <mdb-option
                class="mdb-option"
                *ngFor="let filterType of filterTypes"
                [value]="filterType.value"
                (click)="filterAction(filterType.value)"
                >{{ filterType.name }}</mdb-option
              >
            </mdb-select>
            <label mdbLabel for="filterType" class="form-label">Date</label>
            <span mdbDropdown #dropdown class="dropdown select-drp">
              <i
                aria-expanded="false"
                id="dropdownMenuButton"
                type="button"
                mdbDropdownToggle
                class="mdi mdi-chevron-down dropdown-toggle"
              >
              </i>
            </span>
          </mdb-form-control>
  
          <div class="shadow-0 filter-spacing dropdown" mdbDropdown #dropdownn>
            <button
              type="button"
              class="btn btn-outline-primary"
              aria-expanded="false"
              id="dropdownMenuButton"
              mdbDropdownToggle
            >
              <span class="location-btn-text">Location</span>
              <i class="mdi mdi-chevron-down me-n4 dropdown-toggle"></i>
            </button>
            <ul
              mdbDropdownMenu
              id="menu-dropdown"
              class="dropdown-menu position-absolute"
              aria-labelledby="dropdownMenuButton"
            >
              <li (click)="dropdownn.hide()" class="dropdown-item">
                <div
                  class="dropdown-list-item py-2"
                  role="button"
                  (click)="locationFilter()"
                >
                  <span>All</span>
                </div>
              </li>
              <li
                (click)="dropdownn.hide()"
                class="dropdown-item"
                *ngFor="let savedLocation of savedLocations"
              >
                <div
                  class="dropdown-list-item py-2"
                  role="button"
                  (click)="locationFilter(savedLocation)"
                >
                  <span class="mb-3">{{ savedLocation.address }}</span>
                </div>
              </li>
            </ul>
            <input type="hidden" [formControlName]="'location'" [value]="selectedLocation" />
          </div>
  
          <mdb-form-control
            class="filter-spacing radius-section"
            *ngIf="showRadius"
          >
            <mdb-select class="select-form" formControlName="radius" id="radius">
              <mdb-option
                class="mdb-option"
                *ngFor="let radiusOption of radiusOptions"
                [value]="radiusOption.value"
                (click)="radiusFilter(radiusOption.value)"
                >{{ radiusOption.name }}</mdb-option
              >
            </mdb-select>
            <label mdbLabel for="radius" class="form-label">Radius</label>
            <span mdbDropdown #dropdown class="dropdown select-drp">
              <i
                aria-expanded="false"
                id="dropdownMenuButton"
                type="button"
                mdbDropdownToggle
                class="mdi mdi-chevron-down dropdown-toggle"
              >
              </i>
            </span>
          </mdb-form-control>
        </div>
      </div>
  
      <div class="py-2 m-0">
        <div *ngIf="selectedAddress" class="tag-stack overflow-hidden w-100">
          <label class="d-inline-flex overflow-hidden w-100">
            <span class="d-inline-block text-truncate tag-stack-text">{{
              selectedAddress
            }}</span>
          </label>
          <i (click)="removeAddress()" class="fas fa-times tag-stack-icon"></i>
        </div>
      </div>
  
      <div class="search py-2">
        <input
            #searchInput
            type="search"
            class="w-100 form-control rounded-connect"
            placeholder="Enter search here ..."
            aria-label="Search"
            aria-describedby="search-addon"
            [maxlength]=searchCharacterLimit
            (keyup.enter)="characterSearchLimit()"
            formControlName="query"
            id="search-query-input"
            autocomplete="off"
        />
        <button
          (click)="characterSearchLimit()"
          class="btn btn-primary search-btn"
          type="submit"
          id="search-query-btn"
        >
          <span class="material-symbols-outlined search-btn-icon">search</span>
          <span class="search-btn-text">Search</span>
        </button>
      </div>
    </form>
  </div>
</div>
