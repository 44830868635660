import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Color } from '../core/Constants';
import { User } from '../core/models';
import { UserService } from '../modules/feature/user/user.service';

@Directive({
  selector: '[appUserImage]',
})
export class UserImageDirective {
  private user = {} as User;
  private userImage: string;
  private get element(): HTMLElement {
    return this.el.nativeElement as HTMLElement;
  }

  @Input('styleDeclaration') public styleDeclaration = <CSSStyleDeclaration>{};

  @Input() set appUserImage(user: any) {
    this.initialize(user);
  }

  @Input() public clickable = true;
  @HostListener('click', ['$event'])
  navigateTo($event: any) {
    if (this.clickable && this.user?.username) {
      this.userService.navigateToProfilePage(this.user.username);
    }
  }

  constructor(private el: ElementRef, private userService: UserService) {}

  initialize(user: User) {
    this.user = user;
    const imageUrl = user?.imageURL;

    if (!imageUrl) {
      this.element.style.backgroundImage = ``;
      this.element.style.backgroundColor = this.styleDeclaration.backgroundColor || Color.primary;
      this.element.textContent = `${this.formattedName(user)}`;
      this.element.style.textAlign = this.styleDeclaration.textAlign || 'center';
      this.element.style.display = this.styleDeclaration.display || 'flex';
      this.element.style.justifyContent = this.styleDeclaration.justifyContent || 'center';
      this.element.style.alignItems = this.styleDeclaration.alignItems || 'center';
      this.element.style.fontSize = this.styleDeclaration.fontSize || 'small';
      this.element.style.color = this.styleDeclaration.color || 'white';
    } else {
      this.element.textContent = ``;
      this.userImage = this.userService.getUserImageString(imageUrl);
      this.element.style.backgroundImage = `${this.userImage}`;
    }
  }

  formattedName(user: User): string {
    if (!user || !user.name) {
      return user?.username ? user.username.split(' ')[0][0].toLocaleUpperCase() : '';
    }
  
    const userNames = user.name.split(' ');
  
    if (userNames.length > 1) {
      const [firstName, lastName] = this.searchAndFormatName(userNames);
      return `${firstName[0]}${lastName[0]}`.toLocaleUpperCase();
    }
  
    if (userNames.length === 1) {
      const [singleName] = userNames;
      return singleName[0].toLocaleUpperCase();
    }
  
    return ''; // Handles unexpected cases
  }

  searchAndFormatName(names: string[]): string[] {
    let userNames = [];
    for (let i = 0; i < names.length; i++) {
      if (names[i] != '') {
        userNames.push(names[i]);
      }
    }
    return userNames;
  }
}
