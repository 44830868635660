import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ApiUrls } from '../core/config';

export interface SpeedTest {
  internetSpeed: InternetSpeed;
  lastChecked: Date;
};

export interface InternetSpeed {
  value: string;
  unit: string;
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(private http: HttpClient) { }

  getCurrentInternetSpeed(){
    // const url = this.utilitiesService.getOrigin();
    return this.http.get<number>(`${ApiUrls.Network}/internet-speed`)
  }

  getCachedInternetSpeed() {
    // const cachedSpeed = this.storageService.get(NetworkOptions.speedValue);
    // if (cachedSpeed) {
    //   const speed = JSON.parse(cachedSpeed) as SpeedTest;
    //   const expiration = 1000 * 60 * 15; // speed test expiration 
    //   let lastChecked = Math.abs(new Date().valueOf() - new Date(speed.lastChecked).valueOf());
    //   if (lastChecked < expiration) { 
    //     return speed.internetSpeed.value;
    //   }
    // }
    return ''; // no cached value detected
  }
}
