import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { LoginService } from '../public/login/login.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppResolver implements Resolve<any> {

  constructor(private authService: AuthService, private loginService: LoginService, private router: Router) {
    
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
        this.router.navigate(['/home']);
    } else {
        this.loginService.gotoExternalLogin();
    }

    return;
  }
}
