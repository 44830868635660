import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Router } from '@angular/router';
import { BlobUrls } from '@src/app/core/config';
import { BlobData } from '@src/app/core/models/model';

@Component({
  selector: 'app-video-player-modal',
  templateUrl: './video-player-modal.component.html',
  styleUrls: ['./video-player-modal.component.scss']
})
export class VideoPlayerModalComponent implements OnInit, AfterViewInit {
  blob: BlobData;
  constructor(public modalRef: MdbModalRef<VideoPlayerModalComponent>, public router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.router.events.subscribe(() => {

      this.modalRef.close()

    });

  }

  getURL(blob: BlobData) {
    return encodeURI(BlobUrls.Download + '/' + blob.url);
  }

}