import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NotificationsService } from '../notifications.service';
import { NotificationTemplate } from './Template';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { filter, finalize } from 'rxjs/operators';
import { MainMasterService } from '@src/app/layouts/main-master/main-master.service';
import {
  connectionActions,
  pagination,
  connectionStatus,
  parentCategory,
  connectionType,  
  Types
} from '@src/app/core/Constants';
import { NotificationMessage, User } from '@src/app/core/models/model';
import { ComplaintComponent } from '@src/app/modules/core/complain/complaint.component';
import { SignalrService } from '@src/app/services/signalr.service';
import { UserService } from '../../user/user.service';
import { Subscription } from 'rxjs';

export interface NotificationTemplate {
  message: string;
  link: string;
  actionType: boolean;
  createdOn: Date;
  notificationId: string;
  isRead: boolean;
}
@Component({
  selector: 'app-notifiations',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  styleDeclaration = <CSSStyleDeclaration>{
    paddingTop: '7px',
    fontSize: 'large',
  };

  modalRef: MdbModalRef<ComplaintComponent> | null = null;

  notificationConnection = connectionActions;
  loading = true;
  placeholderText = 'No new notification available.';

  showAllNotifications: boolean = true;
  notificationStatus: string;
  private subscription: Subscription = new Subscription();

  characterLimit = 80

  page = pagination.page;
  size = pagination.size;
  readonly currentUserID: string = this.userService.getCurrentUserId();
  notifications: NotificationMessage[] = [];
  customTemplate: {
    link: string;
    message: string;
    actionType: boolean;
    createdOn: Date;
    notificationId: string;
    isRead: boolean;
  };

  public connectionStatus: string;
  isAttending: boolean = false;

  setNotificationStatus = {
    allNotifications: 'all',
    unreadNotifications: 'unread'
  }

  types = {
    likeNotification: 'LikeNotification',
    likeGroupNotification: 'LikeGroupNotification',
    replyNotification: 'ReplyNotification',
    replyGroupNotification: 'ReplyGroupNotification',
    commentNotification: 'CommentNotification',
    commentGroupNotification: 'CommentGroupNotification',
    liveStreamNotification: 'LiveStreamNotification',
    eventNotification: 'EventNotification',
    eventStatusNotification: 'EventStatusNotification',
    groupInvitationNotification: 'GroupInvitationNotification',
    sharedNotification: 'sharedNotification',
    sharedGroupNotification: 'sharedGroupNotification',
    trendNotification: 'TrendNotification',
    connectionNotification: 'ConnectionNotification',
    pinnedUserNotification: 'PinnedUserNotification',
    profileRequestNotification: 'ProfileRequestNotification',
    mentionedGroupNotification: 'MentionedGroupNotification',
    mentionedPostNotification: 'MentionedPostNotification',
    privacyGroupNotification: 'PrivacyGroupNotification',
  };

  eventActionType = {
    cancelled_event: 'cancelled-event',
    reactivated_event: 'reactivated-event',
    deleted_event: 'deleted-event',
    rescheduled_event: 'rescheduled-event',
  };

  ConnectionActionTypes = {
    ignored: "Ignored",
    connected: "connected",
  };

  query: string = '';

  mentionNameRegex: string = '<a href rel="$3">$2</a>';

  constructor(
    private modalService: MdbModalService,
    private notificationService: NotificationsService,
    private signalrService: SignalrService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private mainMasterService: MainMasterService
  ) {
    this.signalrService.retrieveNotification().subscribe((msg: NotificationMessage) => {
      if (msg?.id) {
        this.notifications = [...new Set([msg, ...this.notifications])];
        this.cdr.detectChanges();
      }
    });

    this.signalrService.interactionUpdate.subscribe((res) => {
      if (res && this.notifications.length > 0) {
        const index = this.notifications.findIndex(
          (x) => x.localNotificationdata.localNotificationId == res.notificationId
        );
        if (res.relationship === connectionType.connected) {
          this.notifications[index].localNotificationdata.connectionStatus = connectionType.connected;
        } else {
          this.notifications[index].localNotificationdata.connectionStatus = connectionStatus.ignored;
        }
      }
    });
  }

  ngOnInit() {
    this.getNotifications();
    this.onScroll();
  }

  toggleBetweenNotifications() {
    this.loading = true;
    this.showAllNotifications = !this.showAllNotifications;
    this.page = 0;
    this.notifications = [];
    if (this.showAllNotifications) {
      this.notificationStatus = this.setNotificationStatus.allNotifications
    } else {
      this.notificationStatus = this.setNotificationStatus.unreadNotifications
    }
    this.getNotifications();
    this.cdr.detectChanges();
  }

  getNotifications() {
    this.subscription.add(
      this.notificationService
        .getNotifications(this.page, this.size, this.notificationStatus, this.query)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe((response) => {
          this.notifications = [...new Set([...this.notifications, ...response])];
          this.updateNotificationLastViewedOn();
        })
    );
  }

  updateNotificationLastViewedOn() {
    this.notificationService.updateNotificationLastViewedOn(Types.ViewPageNotification).subscribe();
  }

  connect(notification: NotificationMessage) {
    let partner_id = notification.localNotificationdata.id;
    if (notification.notificationType != this.types.profileRequestNotification) {
      this.subscription.add(
        this.userService.pin(partner_id, connectionActions.connect).subscribe((status) => {
          notification.localNotificationdata.connectionStatus = this.ConnectionActionTypes.connected;
        })
      );
    } else {
      this.subscription.add(
        this.userService.profileRequest(partner_id, connectionActions.connect).subscribe((status) => {
          notification.localNotificationdata.connectionStatus = this.ConnectionActionTypes.connected;
        })
      );
    }

    this.cdr.detectChanges();
  }

  ignore(notification: NotificationMessage) {
    let partner_id = notification.localNotificationdata.id;
    if (notification.notificationType != this.types.profileRequestNotification) {
      this.userService.pin(partner_id, connectionActions.disconnect).subscribe((status) => {
        if (status) {
          status.response.relationship = this.ConnectionActionTypes.ignored;
        }
        notification.localNotificationdata.connectionStatus = status?.response?.relationship;
      });
      notification.localNotificationdata.connectionStatus = this.ConnectionActionTypes.ignored;
    } else {
      this.subscription.add(
        this.userService.profileRequest(partner_id, connectionActions.ignore).subscribe((status) => {
          // if (status?.response?.relationship == '') {
          //   status.response.relationship = 'Ignored';
          // }
          if (status) { status.response.relationship = this.ConnectionActionTypes.ignored };
          notification.localNotificationdata.connectionStatus = status?.response?.relationship;
        })
      );
    }

    this.cdr.detectChanges();
  }

  viewNotification(notificationId: string) {
    this.subscription.add(
      this.notificationService.viewNotification(notificationId).subscribe((response) => {
        if (response) {
          const notification = this.notifications.find((c) => c.id == notificationId);
          let index = this.notifications.indexOf(notification);
          this.notifications[index].isRead = true;
          this.cdr.detectChanges();
        }
      })
    );
  }

  message(user: User) {
    this.userService.messageUser(user);
  }

  public openModal(notificationId) {
    this.modalRef = this.modalService.open(ComplaintComponent, {
      modalClass: 'modal-dialog-centered',
      data: {
        subject_id: notificationId,
        category: 'notification',
      },
    });
  }

  parentCategory(category: parentCategory): string {
    switch (category) {
      case parentCategory.isLikeOnPost: {
        return 'likes your post';
      }
      case parentCategory.isLikeOnComment: {
        return 'likes your comment';
      }
      case parentCategory.isLikeOnReply: {
        return 'likes your reply';
      }
      default: {
        break;
      }
    }
  }

  onScroll() {
    this.subscription.add(
      this.mainMasterService
        .getScrollPage()
        .pipe(filter((data) => data === true))
        .subscribe((x) => {
          this.page++;
          this.getNotifications();
        })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
