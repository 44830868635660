import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Token, Session } from '../core/models';
import { HttpClient, HttpContext } from '@angular/common/http';
import { shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { SKIP_OPTION } from './interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private freshToken$?: Observable<Token>;
  
  constructor(private http: HttpClient, private storageService: StorageService) { }

  new_access_token(refreshToken: string) {
    const headers = { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Headers': 'Content-Type',
      'refreshToken': refreshToken
    };

    if (!environment.production) headers['client_id'] = environment.clientID // needed for localhost / debugging only

    // return this.isAuthenticated() ? of(this.getCachedToken()) : this.getFreshToken(headers);

    if (this.isAuthenticated()) {
      this.freshToken$ = null;
      return of(this.getCachedToken());
    } 

    return this.getFreshToken(headers)
  }

  private getFreshToken(headers: any) {
    if (!this.freshToken$) {
      this.freshToken$ = this.http.get<Token>(`${environment.authApiUrl}user/renew-token`, { headers, context: new HttpContext().set(SKIP_OPTION.ALL, true) })
          .pipe(
            tap(response => this.updateToken(response)),
            shareReplay(1)
          );
    };

    return this.freshToken$;
  }

  getCachedToken() {
    const session: Session = this.storageService.getCurrentSession();
    return session?.token;
  }

  //================================= Update token =============================================

  updateToken(token: Token) {
    if (token?.access_token) {
      const session: Session = this.storageService.getCurrentSession();
      token.refresh_token = !token.refresh_token ? session.token.refresh_token : token.refresh_token; //preserve existing refresh token if new RT is null
      session.token = token;
      this.storageService.updateSession(session);
    }
  }

  //================================= Validate token =============================================

  public isAuthenticated(): boolean {
    let session: Session = this.storageService.getCurrentSession();
    if (!session || JSON.stringify(session) == '{}') { return false; }
    const expiresAt = session.token.expires || '{}';
    return new Date().getTime() < new Date(expiresAt).getTime();
  }

  public isLoggedIn(): boolean {
    let session = this.storageService.getCurrentSession();
    return session && session.id ? true : false;
  }

  //public parseJwt(access_token) {
  //  var base64Url = access_token.split('.')[1];
  //  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //  return JSON.parse(window.atob(base64));
  //};
}
