import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from '../../notification/notifications.service';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Types, pagination, connectionStatus, notificationType, invitationTypes, SearchCharacterLimit, DebounceTime } from '@src/app/core/Constants';
import { InvitationSummary, InvitationsTab, NotificationMessage } from '@src/app/core/models/model';
import { SignalrService } from '@src/app/services/signalr.service';
import { UtilitiesService } from '@src/app/services/utilities.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-pending-request',
  templateUrl: './pending-request.component.html',
  styleUrls: ['./pending-request.component.scss'],
})
export class PendingRequestComponent implements OnInit, AfterViewInit {
  // @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;

  @ViewChild('tabs') tabs: MdbTabsComponent;
  groupRequest: InvitationSummary = {};
  eventRequest: InvitationSummary = {};
  profileRequests: InvitationSummary = {};
  groupLimit: number;
  tab: string;
  currentTab: string;
  tabIndex: number = 0;
  invitationsTab = InvitationsTab;
  placeholderText = 'No pending request available.';
  emptyConnections = 'No pending connection request.';
  emptyEvents = 'No pending event invitation.';
  emptyGroups = 'No pending group invitation.';

  invitationsSections = {
    connections: 'connections',
    events: 'events',
    groups: 'groups',
  };
  query: string;
  
  searchTerm = new FormControl();

  notifications: NotificationMessage[] = [];
  eventRequests: NotificationMessage[] = [];
  groupRequests: NotificationMessage[] = [];
  allProfileNotifications: NotificationMessage[] = [];
  types = Types;
  page = pagination.page;
  size = pagination.size;
  loading: boolean = false;
  loadingConnections: boolean = false;
  loadingEvents: boolean = false;
  loadingGroups: boolean = false;
  connectionStatus = connectionStatus;
  searchCharacterLimit = SearchCharacterLimit.SearchTextbox;

  constructor(
    private notificationService: NotificationsService,
    private signalrService: SignalrService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService
  ) {
    this.signalrService.retrieveNotification().subscribe((msg: NotificationMessage) => {
      if (msg?.id && msg.notificationType == notificationType.group) {
        this.groupRequest.records = [...new Set([msg, ...this.groupRequest?.records])];
        this.cdr.detectChanges();
      }
      if (msg?.id && msg.notificationType == notificationType.event) {
        this.eventRequest.records = [...new Set([msg, ...this.eventRequest?.records])];
        this.cdr.detectChanges();
      }
    });

    this.tab = this.activatedRoute.snapshot.queryParams['tab'];

    this.initializeSearchTerm();
  }

  ngOnInit() {
    this.getInvitationsNotifications(3);
    this.updateNotificationLastViewedOn();
  }

  ngAfterViewInit(): void {
    //  this.loading = true;
    this.renderActiveTab(this.tab);
    this.cdr.detectChanges();
  }

  initializeSearchTerm() {
    this.searchTerm = new FormControl();

    this.searchTerm.valueChanges
      .pipe(
        debounceTime(DebounceTime.debounceTime),
        distinctUntilChanged(),
        tap((value) => {
          if (value.length > this.searchCharacterLimit){
            this.utilitiesService.showCharacterLimitToast();
            return;
          }else{
            this.searchPendingRequest(value);
          }
        })
      )
      .subscribe();
  }

  searchPendingRequest(keyword: string) {
    if (keyword.length != 1 && this.query != keyword) {
      this.loading = true;
      this.query = keyword;
      this.search_invitation();
    }
  }

  updateNotificationLastViewedOn() {
    this.notificationService.updateNotificationLastViewedOn(Types.RequestNotification).subscribe();
  }


  getInvitationsNotifications(limit: number, query?: string) {
    this.loading = true;
    this.notificationService.getInvitationSummary(limit, query).subscribe((response) => {
      this.loading = false;
      if (response) {
        if (this.groupRequest?.records?.length > 0) {
          this.groupRequest = null;
          this.groupRequest = response[invitationTypes.groupInvitations];
        } else {
          this.groupRequest = response[invitationTypes.groupInvitations];
        }

        if (this.eventRequest?.records?.length > 0) {
          this.eventRequest = null;
          this.eventRequest = response[invitationTypes.eventInvitations];
        } else {
          this.eventRequest = response[invitationTypes.eventInvitations];
        }
        if (this.profileRequests?.records?.length > 0) {
          this.profileRequests = null;
          this.profileRequests = response[invitationTypes.ProfileRequestInvitations];
        } else {
          this.profileRequests = response[invitationTypes.ProfileRequestInvitations];
        }
      }
    });
  }

  getProfileRequestNotifications(invitationType: string, pageNumber: number, size: number, query: string = '') {
    this.loadingConnections = true;
    this.notificationService.getInvitationByType(invitationType, pageNumber, size, query).subscribe(
      (response) => {
        this.loadingConnections = false;
        this.notifications = response;
      },
      (error) => {
        this.loadingConnections = false;
      }
    );
  }

  getEventNotifications(invitationType: string, pageNumber: number, size: number, query: string = '') {
    this.loadingEvents = true;
    this.notificationService.getInvitationByType(invitationType, pageNumber, size, query).subscribe(
      (response) => {
        this.loadingEvents = false;
        this.eventRequests = response;
      },
      (error) => {
        this.loadingEvents = false;
      }
    );
  }

  getGroupNotifications(invitationType: string, pageNumber: number, size: number, query?: string) {
    this.loadingGroups = true;
    this.notificationService.getInvitationByType(invitationType, pageNumber, size, query).subscribe(
      (response) => {
        this.loadingGroups = false;
        this.groupRequests = response;
      },
      (error) => {
        this.loadingGroups = false;
      }
    );
  }

  searchCharcaterLimit() {
    if (this.query.length > this.searchCharacterLimit){
      this.utilitiesService.showCharacterLimitToast();
      return;
    } else {
      this.search_invitation();
    }
  }

  search_invitation() {
    if (this.tabIndex == 1) {
      this.notificationService.eventSubject.next(this.query);
    } else if (this.tabIndex == 2) {
      this.notificationService.groupSubject.next(this.query);
    } else {
      this.getInvitationsNotifications(3, this.query);
    }
  }

  viewNotification(notificationId: string) {
    this.notificationService.viewNotification(notificationId).subscribe((response) => {
      if (response) {
        //this.cdr.detectChanges();
      }
    });
  }

  navigateUser(locationTab: number) {
    this.tabs.setActiveTab(locationTab);
  }

  onGetActiveTab(tabs) {
    if (this.tabs) {
      this.myMethodChangingQueryParams(tabs.index);
    }
  }

  public myMethodChangingQueryParams(tab: number) {
    switch (tab) {
      case this.invitationsTab.All: {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: undefined,
        });
        this.getInvitationsNotifications(3);
        break;
      }

      case this.invitationsTab.Connections: {
        this.multiQueryParams(this.invitationsSections.connections);
        this.getProfileRequestNotifications(notificationType.profileRequest, this.page, this.size);
        break;
      }

      case this.invitationsTab.Events: {
        this.multiQueryParams(this.invitationsSections.events);
        this.getEventNotifications(notificationType.event, this.page, this.size);
        break; 
      }

      case this.invitationsTab.Groups: {
        this.multiQueryParams(this.invitationsSections.groups);
        this.getGroupNotifications(notificationType.group, this.page, this.size);
        break;
      }

      default:
        break;
    }
  }

  multiQueryParams(tab: string) {
    const queryParams: Params = { tab: tab };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  renderActiveTab(key: string) {
    let index = 0;
    switch (key) {
      case this.invitationsSections.connections: {
        index = this.invitationsTab.Connections;
        break;
      }
      case this.invitationsSections.events: {
        index = this.invitationsTab.Events;
        break;
      }
      case this.invitationsSections.groups: {
        index = this.invitationsTab.Groups;
        break;
      }
      default: {
        break;
      }
    }
    this.tabs.setActiveTab(index);
  }
}
