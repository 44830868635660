import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalTracks } from "@src/app/core/config";
import { Geoboundary, Geotag, BlobData, GeoTagMessageData, Post, MessageData, NowPlaying, Group } from "@src/app/core/models/model";
import { GeotagMessageService } from "@src/app/services/messages/geotag-message.service";
import { PostMessageService } from "@src/app/services/messages/post-message.service";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
  selector: 'app-modal-post',
  templateUrl: './modal-post.component.html',
  styleUrls: ['./modal-post.component.scss']
})
export class ModalPostComponent implements OnInit, OnDestroy {

  data = <MessageData>{
    title: '',
    message: '',
  };

  sharedEventStyling: boolean;
  modifiedBlobName: string;
  boundary: Geoboundary = {};
  geotag: Geotag = {};
  postEditGeotag: Geotag = {};
  postEditGeoBoundary: Geoboundary = {};
  //action: Subject<any> = new Subject();
  event: Event;
  inProgress = { geotag: false, boundary: false };
  geotagView: boolean = false;
  geotagComment: boolean = false;
  geoboundaryView: boolean = false;

  modalState: string;
  postModalView: boolean = false;
  disableGeotag: boolean;
  disableGeoboundary: boolean;
  postEditModalView: boolean;
  postReportModalView: boolean;
  blobDetailView: boolean;
  postEmbedModalView: boolean;
  sharedEventModalView: boolean = true;
  currentView: string;
  autoplay: boolean;
  commentId: string;
  replyToggle: boolean;
  // post modal peculiar
  textInputType: string;
  postFiles: BlobData[];
  postIdForEdit: string;
  subject_id: string;
  trackModals: string;
  modalResponse: GeoTagMessageData = {
    geotag: {},
    boundary: {},
    text: "",
    modalReturnState: "",
    postData: <Post>{}
  }
  geotagModal: Geotag;
  boundaryModal: Geoboundary;
  boundaryView: boolean = false;
  sharedEntity: Post | Event | Group;
  sharedEntityType: string;
  popPost: Post;
  postId: string;
  blobIndex: number;
  popState: string;
  nowPlaying: NowPlaying;
  isIdentityHidden: boolean;
  isUploadingFile: boolean;
  popToggle: boolean = true;
  triggerPost: boolean; // pass as true into this modal from calling component to display user pix, name and address altogether
  coordinates: string = '';
  $unsubscribe = new Subject<void>();
  postForEmbed: string;

  @Output() close_modal = new EventEmitter<any>();

  //styles
  modalContent: string = "modal-content";
  //modalContentPop: string = "modal-content p-0";
  modalBody: string = "modal-body";
  showClose: boolean = true;
  //modalBodyPop: string = "modal-body m-0 p-0"

  constructor(public modalRef: MdbModalRef<ModalPostComponent>,
              private geotagMessageService: GeotagMessageService,
              private postMessageService: PostMessageService, 
              private router: Router) {

    this.modalContent = "modal-content";
    this.modalBody = "modal-body"
    this.postMessageService.getPost().subscribe(post => {
      if (post.data.text == ModalTracks.PostComplete || post.data.text == ModalTracks.PostBlobComplete) {
        this.modalResponse.text = post.data.postData.id;
        this.modalResponse.modalReturnState = post.data.text;
        this.modalResponse.postData = post.data.postData
        const closeMessage = this.modalResponse;    
        this.modalRef.close(closeMessage);    
      }
    });

    this.geotagMessageService.getGeotagSignal()
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(signal => {        
        switch (signal.data.text) {
          case ModalTracks.PostGeoTag:
            this.trackModals = signal.data.text
            this.geotag = signal.data.geotag;
            this.boundary = signal.data.boundary;
            this.postModalView = false;
            this.geotagView = true;
            break;
          case ModalTracks.PostGeoBoundary:
            this.trackModals = signal.data.text
            this.geotag = signal.data.geotag;
            this.boundary = signal.data.boundary;
            this.postModalView = false;
            this.geoboundaryView = true;
            break;
          case ModalTracks.PostEditGeoTag:
            this.trackModals = signal.data.text
            this.geotag = signal.data.geotag;
            this.boundary = signal.data.boundary;
            this.postEditModalView = false;
            this.geotagView = true;
            break;
          case ModalTracks.PostEditBoundary:
            this.trackModals = signal.data.text
            this.geotag = signal.data.geotag;
            this.boundary = signal.data.boundary;
            this.postEditModalView = false;
            this.geoboundaryView = true;
            break;
          case ModalTracks.CloseBlobModal:
            this.modalRef.close();
            break;
        }
      });
  }

  ngOnInit(): void {   
   
    switch (this.modalState) {
      case ModalTracks.GeoTagModal:
        if (this.boundaryView) { this.geoboundaryView = true; }
        else { this.geotagView = true; }
        break;
      case ModalTracks.PostFormModal:
        this.postModalView = true;
        break;
      case ModalTracks.PostEditFormModal:
        this.trackModals = ModalTracks.PostEditFormModal;
        this.postEditModalView = true;
        break;
      case ModalTracks.PostReportFormModal:
        this.postReportModalView = true;
        break;
      case ModalTracks.PostEmbedModal:
        this.postEmbedModalView = true;
        break;
      case ModalTracks.FilesModal:
        this.postModalView = true;
        break;
      case ModalTracks.SharedEntityModal:
        this.postModalView = true;
        break;
      case ModalTracks.ViewGeoTagModal:
        this.geotagView = true;
        this.geotagComment = true;
        this.trackModals = ModalTracks.ViewGeoTagModal
        break;
      case ModalTracks.LocationGeoTagModal:
        this.geotagView = true;
        this.trackModals = ModalTracks.LocationGeoTagModal
        break;
      case ModalTracks.BlobDetailView:
        this.popToggle = false;
        this.modalContent = "modal-content overflow-hidden p-0";
        this.modalBody = "modal-body m-0 p-0";
        this.showClose = false;
        this.blobDetailView = true;
        break;
    }
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      this.modalRef.close()
    });
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  closeModal() {    
    this.modalRef.close(this.modalResponse);
  }

  // Disabling this because it is unnecessary and leads to poor user experience
  // openGenericModal() {
  //   (this.data.title = 'Unsaved Changes Warning.'),
  //     (this.data.message = 'Are you sure you want to exit post operation ?'),
  //     (this.data.showOption = true);

  //   this.genericModalService.open(this.data).subscribe((res) => {
  //     if (res) {
  //       this.hideModal();
  //     }
  //   });
  // }

  hideModal() {    
    this.modalResponse.modalReturnState = "closeModal";
    this.modalRef.close(this.modalResponse);
  }

  // Geo -modal specific
  render(child: string): boolean {
    if (this.hasGeo()) {
      switch (child) {
        case 'geotag':
          return this.inProgress.geotag;
        case 'boundary':
          return this.inProgress.boundary;
      }
    }
    return false;
  }

  hasGeo(): boolean { return JSON.stringify(this.geotag) != '{}'; }

  closePopup() { this.modalRef.close(); }

  addGeotag(_geotag: Geotag) {    
    // if (_geotag == null && this.trackModals == ModalTracks.LocationGeoTagModal) {
    //   this.geotagView = false;
    //   this.modalRef.close(_geotag);
    //   return;
    // }    

    if (_geotag == null && this.trackModals != ModalTracks.LocationGeoTagModal) {
      this.geotagView = false;
      this.postModalView = true;
      return;
    }

    switch (this.trackModals) {
      case ModalTracks.PostGeoTag:
        this.geotagView = false;
        this.modalResponse.geotag = _geotag;
        this.geotagMessageService.sendGeotagSignal(this.modalResponse);
        this.postModalView = true;
        break;
      case ModalTracks.PostEditGeoTag:
        this.postEditGeotag = _geotag;
        this.geotagView = false;
        this.postEditModalView = true;
        break;
      case ModalTracks.ViewGeoTagModal:
        this.geotagView = false;
        this.modalRef.close();
        break;
      case ModalTracks.LocationGeoTagModal:
        this.geotagView = false;
        this.modalRef.close(_geotag);
        break;
      default:
        this.modalResponse.geotag = _geotag;
        this.geotagMessageService.sendGeotagSignal(this.modalResponse);
        this.geotagView = false;
        this.postModalView = true;
        break;
    }
  }

  updateGeotag(_geotag: Geotag) {    
    this.geotag = _geotag;
  }

  addBoundary(_boundary: Geoboundary) {   
    if (_boundary == null ) {
      this.geoboundaryView = false;
      if (this.trackModals === ModalTracks.PostEditBoundary) {
        this.postEditModalView = true;
      } else {
        this.postModalView = true;
      }
      return;
    } 

    this.boundaryModal = _boundary;
    
    switch (this.trackModals) {
      case ModalTracks.PostGeoBoundary:
        this.geotagView = false;
        this.geoboundaryView = false;
        this.modalResponse.geotag = this.geotag;
        this.modalResponse.boundary = _boundary;
        this.geotagMessageService.sendGeotagSignal(this.modalResponse);
        this.postModalView = true;
        break;
      case ModalTracks.PostEditGeoTag:
        this.postEditGeotag = this.geotag;
        this.postEditGeoBoundary = _boundary;
        this.boundaryModal = _boundary;
        this.geotagView = false;
        this.geoboundaryView = false;
        this.postEditModalView = true;
        break;
      case ModalTracks.PostEditBoundary:
        this.postEditGeotag = this.geotag;
        this.postEditGeoBoundary = _boundary;
        this.boundaryModal = _boundary;
        this.geotagView = false;
        this.geoboundaryView = false;
        this.postEditModalView = true;
        break;
      case ModalTracks.ViewGeoTagModal:
        this.geotagView = false;
        this.geoboundaryView = false;
        this.modalRef.close();
        break;
      case ModalTracks.LocationGeoTagModal:
        this.geotagView = false;
        this.geoboundaryView = false;
        this.modalRef.close();
        break;
      default:
        this.modalResponse.geotag = this.geotag;
        this.modalResponse.boundary = _boundary;
        this.geotagMessageService.sendGeotagSignal(this.modalResponse);
        this.geotagView = false;
        this.geoboundaryView = false;
        this.postModalView = true;
        break;
    }
  }

  showBoundary() {
    this.geoboundaryView = true;
    this.geotagView = false;
  }

  showGeotag() {
    this.geotagView = true;
    this.geoboundaryView = false;
  }

  bubbleClose() {
    this.close_modal.emit();
  }
}
