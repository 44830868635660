import { Injectable } from '@angular/core';
import { Observable, Subject} from 'rxjs';
import { GeoTagMessageData } from '../../core/models/model';

@Injectable({
  providedIn: 'root'
})
export class GeotagMessageService {

  private subject = new Subject<any>();

  sendGeotagSignal(message: GeoTagMessageData){
    this.subject.next({data: message});
  }

  getGeotagSignal(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage()
  {
    this.subject.next();
  }

  constructor() { }
}
