<section class="invitation-snippets">
  <div
    class="invitation-snippets-row shadow-card"
    *ngFor="let notification of notifications; let even = even; let odd = odd;" trackById
    [ngClass]="{ 'list-odd': odd, 'list-even': even }"
  >
    <ng-container>
      <div class="invitation-user-image-box">
        <div
          (click)="viewNotification(notification.id)"
          [routerLink]="['/user/', notification.user.username]"
          class="invitation-user-image"
          [styleDeclaration]="styleDeclaration"
          [appUserImage]="notification.user"
        ></div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <span
            class="invite me-3"
            (click)="viewNotification(notification.id)"
            [routerLink]="['/user/', notification.user.username]"
            role="button"
          >
            <span class="text-lowercase invite-sender"> {{ notification.user.username }} </span>
            <span class="invite-text"> requested to connect with you and view your profile </span>
          </span>

          <div class="">
            <div
              *ngIf="
                notification?.localNotificationdata?.connectionStatus != connectionStatus.ignored &&
                notification?.localNotificationdata?.connectionStatus != connectionStatus.connected
              "
              class="d-flex mt-1 gap-2"
            >
              <button class="btn btn-sm btn-outline-primary" 
              (click)="ignore(notification)" 
              [disabled]="notification.isDisabled"
              [id]="'connectionReject' + notification.id">
                <span class="mdi mdi-close-circle-outline ms-n2 me-2"></span>
                <span class="me-n1">Ignore</span>
              </button>
              <button class="btn btn-sm btn-primary" 
              (click)="connect(notification)" 
              [disabled]="notification.isDisabled"
              [id]="'connectionAccept' + notification.id">
                <span class="mdi mdi-account-plus-outline ms-n1 me-2"></span>
                <span class="me-n1">Accept</span>
              </button>
            </div>

            <div *ngIf="notification.isIgnored">
              <div mdbDropdown #dropdown class="dropdown">
                <button
                  class="btn btn-sm btn-outline-primary"
                  aria-expanded="false"
                  id="dropdownMenuButton"
                  [disabled]="notification.isDisabled"
                  mdbDropdownToggle
                >
                  <span class="mdi mdi-close-circle-outline ms-n2 me-2"></span>
                  <span class="me-2">Ignored</span>
                  <i class="mdi mdi-chevron-down me-n3 dropdown-toggle"></i>
                </button>
                <ul mdbDropdownMenu class="dropdown-menu dropdown-list" aria-labelledby="dropdownMenuButton">
                  <li (click)="dropdown.hide()">
                    <a role="button" class="dropdown-item" (click)="connect(notification)">
                      <span class="mdi mdi-account-multiple-outline dropdown-list-icon"></span>
                      <span class="dropdown-list-text"> Accept</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="notification.isAccepted">
              <div class="position-relative">
                <button class="btn btn-sm btn-outline-primary" disabled>
                  <span class="">Connected</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
