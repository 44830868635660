<div
  #videoPlayerContainer
  class="video-player-container"
  [ngClass]="{ fullscreen: isFullScreen, 'not-native-fullscreen': !isNativeFullScreen && isFullScreen }"
  (mousemove)="onContainerMouseMove($event)"
  (touchmove)="onContainerMouseMove($event)"
  (mouseup)="onContainerMouseUp($event)"
  (touchend)="onContainerMouseUp($event)"
  (click)="handleVideoClick($event)"
  [id]="id"
>
  <div class="video-player-spinner-overlay" *ngIf="isBuffering || !isLoaded">
    <span class="video-player-spinner-ball"></span>
  </div>

  <button class="video-player-play-pause" *ngIf="isLoaded" [ngClass]="{'show-controls': isControlsVisible}" (click)="togglePlayPause($event)">
    <svg class="video-player-play-pause-icon" *ngIf="isPlaying" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 19c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2v10c0 1.1.9 2 2 2zm6-12v10c0 1.1.9 2 2 2s2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2z"/></svg>
    <svg class="video-player-play-pause-icon" *ngIf="!isPlaying" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18a1 1 0 0 0 0-1.69L9.54 5.98A.998.998 0 0 0 8 6.82z"/></svg>
  </button>

  <div class="video-player-wrapper" (click)="onControlsWrapperClick($event)"
    [ngClass]="{'show-progressbar': isProgressbarVisible}">
    <div
      #videoTimeline
      (click)="onTimelineClick($event)"
      (mousemove)="onTimelineMouseMove($event)"
      (touchmove)="onTimelineMouseMove($event)"
      (mousedown)="onTimelineMouseDown($event)"
      (touchstart)="onTimelineMouseDown($event)"
    >
      <div class="video-player-progress-area">
        <span
          class="video-player-progress-timeline-slice"
          [ngStyle]="{ left: progressBarLeftOffset + 'px' }"
          [ngClass]="{ 'd-block': isSeeking }"
          >{{ progressTime }}</span
        >
        <div
          class="video-player-progress-bar"
          [ngStyle]="{ width: progressBarWidth + '%' }"
          [ngClass]="{ 'visible-pointer': isSeeking }"
        ></div>
      </div>
    </div>
    
    <div class="video-player-controls" [ngClass]="{'px-2': videoPlayerContainer.offsetWidth <= 380}">
      <div class="video-player-timer" *ngIf="controls">
        <div class="video-player-timer-full" *ngIf="videoPlayerContainer.offsetWidth > 380">
          <span class="video-player-current-time">{{ currentTime }}</span>
          <span class="video-player-time-separator"> / </span>
          <span class="video-player-duration">{{ duration }}</span>
        </div>
        <span class="video-player-remaining-time" *ngIf="videoPlayerContainer.offsetWidth <= 380">{{ remainingTime }}</span>
      </div>
      
      <div class="video-player-options" *ngIf="controls">
        <div class="video-player-volume-container" *ngIf="controls">
          <input
            #volumeSlider
            class="video-player-volume-range"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value="0.5"
            (input)="onVolumeSliderInput($event)"
            *ngIf="videoPlayerContainer.offsetWidth > 375"
          />
          <span (click)="toggleVolume()" class="material-symbols-outlined video-player-volume-icon">{{ config?.muted ? 'volume_off' : 'volume_up' }}</span>
        </div>
        <!-- <div class="video-player-playback-content" *ngIf="(controls || speedControls) && (videoPlayerContainer.offsetWidth > 290)">
          <button class="video-player-playback-speed-btn" (click)="toggleSpeedOptions()">
            <span class="material-symbols-rounded">slow_motion_video</span>
          </button>
          <ul class="video-player-playback-speed-options" [ngClass]="{ show: isVideoSpeedOptionsOpen }">
            <li
              *ngFor="let speedOption of speedOptionsList"
              (click)="setSpeed(speedOption.speed)"
              [ngClass]="{ active: speedOption.active }"
            >
              {{ speedOption.tag }}
            </li>
          </ul>
        </div> -->
        <button class="video-player-pic-in-pic" (click)="onPipBtnClick()" *ngIf="(controls || pipControls) && (videoPlayerContainer.offsetWidth > 275)">
          <span class="material-icons">picture_in_picture_alt</span>
        </button>
        <button class="video-player-fullscreen" (click)="toggleFullScreen()">
          <i class="fa-solid" [ngClass]="{ 'fa-expand': !isFullScreen, 'fa-compress': isFullScreen}"></i>
        </button>
      </div>
    </div>
  </div>

  <video
    #mainVideo
    class="video-player-native-video"
    [ngClass]="{'cover': view === 'cover', 'contain': view === 'contain'}"
    (loadeddata)="resetCounter()"
    (loadedmetadata)="onVideoLoadedMetadata()"
    (timeupdate)="onTimeUpdate($event)"
    (play)="onPlay($event)"
    (pause)="onPause()"
    (ended)="onEnded($event)"
    playsinline
    [muted]="config?.muted"
    [autoplay]="autoplay"
    [loop]="loop"
    (waiting)="onVideoWaiting()"
    (canplay)="onVideoCanPlay()"
  >
    <source [src]="src" type="video/mp4" />
    Your browser does not support the video tag.
  </video>

</div>
