<div class="notification-container h-100">
  <div class="header-notification h-100 d-flex flex-column">
    <div class="header-notification-left">
      <div>
        <span class="mdi mdi-bell-outline"></span>
        <span class="notif-text">Notifications</span>
      </div>
      <div class="form-check form-switch d-flex">
        <input mdbCheckbox class="form-check-input" type="checkbox" id="active" (click)="toggleBetweenNotifications()" />
        <label class="form-check-label unread-label" for="active">
          <span class="">Unread Only</span>
        </label>
      </div>
    </div>

    <!-- <div class="row search-wrapper">
            <div class="col-12 d-flex flex-column position-relative">
                <div class="input-group has-search">
                     <input type="search" class="form-control" placeholder="Search" aria-label="Search"
                     (input)="search_notification()" [(ngModel)]="query" 
                     aria-describedby="search-addon"/>             
                </div>
                <span class="fa fa-search form-control-search align-self-end" aria-hidden="true"></span>   
            </div>        
        </div> -->

    <div class="content-area flex-1"
      [ngClass]="{'d-flex justify-content-center align-items-center' : loading || (!notifications || !notifications[0])}">
      <div class="loading-indicator" *ngIf="loading; else loaded">
        <app-loading-indicator></app-loading-indicator>
      </div>

      <ng-template #loaded>
        <app-content-placeholder *ngIf="!notifications || !notifications[0]" [placeholderText]="placeholderText">
        </app-content-placeholder>
        <section class="notifications-snippets" *ngFor="let notification of notifications;" trackById>
          <div [ngSwitch]="notification.notificationType">
            <div class="notifications-snippets-row" *ngSwitchCase="types.sharedNotification">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div class="notifications-snippets-user text-lowercase"
                  [routerLink]="['/user/', notification.user.username]">
                  <span class="user-name text-lowercase d-inline">{{ notification.user.username }}</span>
                  <span class="d-inline"> shared your post &nbsp; </span>
                  <a class="d-inline"><span class="d-inline-block text-truncate highlight-body">
                      <a class="d-inline-flex" [routerLink]="['/post/', notification.localNotificationdata.id]">
                        <app-smart-output
                          [body]="notification.localNotificationdata.body | notificationLimit : characterLimit"
                          [displayMode]="'inline'">
                        </app-smart-output>
                      </a> </span></a>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.commentNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/post/', notification.localNotificationdata.id]" class="notifications-snippets-user">
                  <span class="user-name text-lowercase d-inline">{{ notification.user.username }}</span>
                  <span class="d-inline"> commented on your post &nbsp; </span>
                  <a class="d-inline"><span class="highlight-body">
                      <app-smart-output [body]="
                          notification.localNotificationdata.body | truncateHtml | notificationLimit : characterLimit
                        " [displayMode]="'inline'">
                      </app-smart-output>
                    </span></a>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }} </span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.replyNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/post/', notification.localNotificationdata.id]" class="notifications-snippets-user">
                  <span class="text-lowercase d-inline">{{ notification.user.username }}</span>
                  <span class="d-inline"> replied to your comment </span>
                  <a class="d-inline"><span class="highlight-body">
                      <app-smart-output [body]="
                          notification.localNotificationdata.body | truncateHtml | notificationLimit : characterLimit
                        " [displayMode]="'inline'">
                      </app-smart-output>
                    </span></a>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }} </span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.likeNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>
              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/post/', notification.localNotificationdata.id]" class="notifications-snippets-user">
                  <span class="text-lowercase d-inline">{{ notification.user.username }}</span>
                  <span class="me-1 d-inline"> {{ parentCategory(notification.localNotificationdata.parentCategory)
                    }}</span>
                  <a class="d-inline"><span class="highlight-body">
                      <app-smart-output [body]="
                          notification.localNotificationdata.body | truncateHtml | notificationLimit : characterLimit
                        " [displayMode]="'inline'">
                      </app-smart-output>
                    </span></a>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }} </span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row overflow-hidden"
              [ngClass]="{'read': notification.isRead, 'not-read': !notification.isRead}"
              *ngSwitchCase="types.mentionedPostNotification">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main overflow-hidden flex-1"
                (click)="viewNotification(notification.id)">
                <div
                  class="notifications-snippets-user text-lowercase d-flex overflow-hidden w-100 align-items-baseline"
                  [routerLink]="['/user/', notification.user.username]">
                  <span class="user-name text-lowercase d-inline w-auto flex-none">{{ notification.user.username }}
                    &nbsp;</span>
                  <span class="d-inline w-auto flex-none"> mentioned you in a post &nbsp; </span>
                  <a class="d-inline flex-1 text-truncate mention-body-wrapper"><span class="highlight-body"
                      [routerLink]="['/post/', notification.localNotificationdata.id]">
                      <app-smart-output [body]="notification.localNotificationdata.body" [displayMode]="'inline'">
                      </app-smart-output>
                    </span></a>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.connectionNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)" class="notifications-snippets-user">
                  <span class="text-lowercase d-inline">{{ notification.user.username }}</span>
                  <span class="d-inline"> connected with you. </span>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }} </span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.pinnedUserNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div class="pin-req-wrapper" (click)="viewNotification(notification.id)">
                  <div class="pin-req-received">
                    <span class="pin-req-user text-lowercase">{{ notification.user.username }}</span>
                    <span class="pin-text"> pinned you.</span>
                    <span class="notifications-send-btn button-float">
                      <div
                        *ngIf="!notification?.localNotificationdata?.connectionStatus"
                        class="pin-req-btns">
                        <button [id]="'connect' + notification.id" class="btn btn-primary btn-sm gap-2" 
                          (click)="connect(notification)">
                          <span class="mdi mdi-account-plus-outline pin-tag"></span>
                          <span class="connect-text">Connect</span>
                        </button>

                        <button [id]="'reject' + notification.id" class="btn btn-outline-primary btn-sm gap-2" 
                          (click)="ignore(notification)">
                          <span class="mdi mdi-close-circle-outline ignore-flag"></span>
                          <span class="ignore-text">Ignore</span>
                        </button>                          
                      </div>

                      <button class="btn btn-outline-primary gap-2" [id]="'connected' + notification.id"
                      *ngIf="notification?.localNotificationdata?.connectionStatus == ConnectionActionTypes.connected">
                        <span class="mdi mdi-account-multiple-outline connected-icon"></span>
                        <span class="connected-text">Connected</span>
                      </button>

                      <button class="btn btn-sm btn-outline-primary"
                      *ngIf="notification?.localNotificationdata?.connectionStatus == ConnectionActionTypes.ignored">
                        <span class="mdi mdi-close-circle-outline me-2 ignore-flag"></span>
                        <span class="ignore-text">Ignored</span>
                      </button>
                    </span>
                  </div>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
              <span *ngIf="!notification.isRead" class="dot d-sm-block d-md-none"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.eventStatusNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/event/', notification.localNotificationdata.id]" class="event-invite-wrapper">
                  <div class="notifications-snippets-user event-invite-received">
                    <span class="event-sender text-lowercase">{{ notification.user.username }}</span>
                    <span [ngSwitch]="notification.localNotificationdata.statusType">
                      <span *ngSwitchCase="eventActionType.cancelled_event" class="invite-text">
                        {{ notification.localNotificationdata.status ? 'cancelled' : 'reactivated' }} &nbsp;</span>
                      <span *ngSwitchCase="eventActionType.reactivated_event" class="invite-text">
                        {{ notification.localNotificationdata.status ? 'cancelled' : 'reactivated' }} &nbsp;</span>
                      <span *ngSwitchCase="eventActionType.deleted_event" class="invite-text"> deleted &nbsp;</span>
                      <span *ngSwitchCase="eventActionType.rescheduled_event" class="invite-text">
                        rescheduled &nbsp;</span>
                    </span>
                    <a class="d-inline-flex"><span class="d-inline-block text-truncate highlight-body invite-body">
                        {{ notification.localNotificationdata.body + ' event' }}</span></a>
                  </div>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.replyGroupNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)" class="notifications-snippets-user">
                  <span class="text-lowercase d-inline"> {{ notification.user.username }} </span>
                  <span class="d-inline"> replied to your comment </span>
                  <a class="d-inline">
                    <span class="highlight-body">
                      <app-smart-output
                        [body]="notification.localNotificationdata.body | notificationLimit : characterLimit"
                        [displayMode]="'inline'">
                      </app-smart-output>
                    </span>
                  </a>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
              <span *ngIf="!notification.isRead" class="dot d-sm-block d-md-none"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.profileRequestNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div class="pin-req-wrapper" (click)="viewNotification(notification.id)">
                  <div class="pin-req-received">
                    <span class="pin-req-user text-lowercase">{{ notification.user.username }}</span>
                    <span class="pin-text"> requested to connect with you and view your profile.</span>
                    <span class="notifications-send-btn button-float">
                      <div
                        *ngIf="!notification?.localNotificationdata?.connectionStatus"
                        class="pin-req-btns">
                        <button [id]="'connect' + notification.id" class="btn btn-primary btn-sm gap-2" 
                          (click)="connect(notification)">
                          <span class="mdi mdi-account-plus-outline pin-tag"></span>
                          <span class="connect-text">Connect</span>
                        </button>

                        <button [id]="'reject' + notification.id" class="btn btn-outline-primary btn-sm gap-2" 
                          (click)="ignore(notification)">
                          <span class="mdi mdi-close-circle-outline ignore-flag"></span>
                          <span class="ignore-text">Ignore</span>
                        </button>                          
                      </div>

                      <button class="btn btn-outline-primary gap-2" [id]="'connected' + notification.id"
                      *ngIf="notification?.localNotificationdata?.connectionStatus == ConnectionActionTypes.connected">
                        <span class="mdi mdi-account-multiple-outline connected-icon"></span>
                        <span class="connected-text">Connected</span>
                      </button>

                      <button class="btn btn-sm btn-outline-primary"
                      *ngIf="notification?.localNotificationdata?.connectionStatus == ConnectionActionTypes.ignored">
                        <span class="mdi mdi-close-circle-outline me-2 ignore-flag"></span>
                        <span class="ignore-text">Ignored</span>
                      </button>
                    </span>
                  </div>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
              <span *ngIf="!notification.isRead" class="dot d-sm-block d-md-none"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.groupInvitationNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/group', notification.localNotificationdata.handle]" class="group-invite-wrapper">
                  <div class="notifications-snippets-user group-invite-received">
                    <span class="group-invite-sender text-lowercase">{{ notification.user.username }}</span>
                    <span class="invite-text"> invited you to join </span>
                    <a class="d-inline-flex"><span class="d-inline-block highlight-body invite-body">
                        {{ notification.localNotificationdata.body + ' group' }}</span></a>
                  </div>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.eventNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/event/', notification.localNotificationdata.id]" class="event-invite-wrapper">
                  <div class="notifications-snippets-user event-invite-received">
                    <span class="event-sender text-lowercase">{{ notification.user.username }}</span>
                    <span class="invite-text"> invited you to attend </span>
                    <a class="d-inline-flex"><span class="d-inline-block highlight-body invite-body">
                        {{ notification.localNotificationdata.body + ' event' }}</span></a>
                  </div>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row overflow-hidden" *ngSwitchCase="types.mentionedGroupNotification"
              [ngClass]="{'read': notification.isRead, 'not-read': !notification.isRead}">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main overflow-hidden flex-1"
                (click)="viewNotification(notification.id)">
                <div
                  class="notifications-snippets-user text-lowercase d-flex overflow-hidden w-100 align-items-baseline"
                  [routerLink]="['/user/', notification.user.username]">
                  <span class="user-name text-lowercase d-inline w-auto flex-none">{{ notification.user.username
                    }}&nbsp; </span>
                  <span class="d-inline w-auto flex-none"> mentioned you in </span>
                  <a class="d-inline flex-1 text-truncate mention-body-wrapper"><span
                      class="d-inline-block text-truncate highlight-body">
                      <p class="d-inline-flex" [routerLink]="['/group/', notification.localNotificationdata.handle]">
                        <app-smart-output [body]="notification.localNotificationdata.body" [displayMode]="'inline'">
                        </app-smart-output>
                        <span>&nbsp; group post &nbsp;</span>
                      </p>
                    </span></a>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }}</span>
              </div>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.commentGroupNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/group', notification.localNotificationdata.handle]"
                  class="notifications-snippets-user">
                  <span class="text-lowercase d-inline">{{ notification.user.username }}</span>
                  <span class="d-inline"> commented on your post in </span>
                  <a class="d-inline-flex"><span class="d-inline-block text-truncate highlight-body">
                      <app-smart-output
                        [body]="notification.localNotificationdata.handle | notificationLimit : characterLimit"
                        [displayMode]="'inline'">
                      </app-smart-output>
                    </span></a>
                  <span> group &nbsp; </span>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }} </span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.likeGroupNotification">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/post/', notification.localNotificationdata.id]" class="notifications-snippets-user">
                  <span class="text-lowercase d-inline">{{ notification.user.username }}</span>
                  <span class="d-inline"> {{ parentCategory(notification.localNotificationdata.parentCategory) }}
                  </span>
                  <a class="d-inline"><span class="d-inline-block text-truncate highlight-body">
                      <app-smart-output
                        [body]="notification.localNotificationdata.body | notificationLimit : characterLimit"
                        [displayMode]="'inline'">
                      </app-smart-output>
                    </span></a>
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }} </span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <div class="notifications-snippets-row" *ngSwitchCase="types.privacyGroupNotification"
              [ngClass]="!notification.isRead ? 'not-read' : 'read'">
              <div class="notifications-user-image-box">
                <span (click)="viewNotification(notification.id)" [routerLink]="['/user/', notification.user.username]"
                  class="notifications-user-image" [styleDeclaration]="styleDeclaration"
                  [appUserImage]="notification.user"></span>
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <div (click)="viewNotification(notification.id)"
                  [routerLink]="['/group', notification.localNotificationdata.handle]"
                  class="notifications-snippets-user">
                  <span class="text-lowercase d-inline">{{ notification.user.username }}</span>
                  <span [ngSwitch]="notification.localNotificationdata.status">

                    <span *ngSwitchCase="true" class="d-inline">
                      changed the privacy status of
                      <a class="d-inline"><span class="d-inline-block text-truncate highlight-body">
                          <app-smart-output
                            [body]="notification.localNotificationdata.body | notificationLimit : characterLimit"
                            [displayMode]="'inline'">
                          </app-smart-output>
                        </span></a>
                      group from public to private
                    </span>

                    <span *ngSwitchCase="false" class="d-inline">
                      changed the privacy status of
                      <a class="d-inline"><span class="d-inline-block text-truncate highlight-body">
                          <app-smart-output
                            [body]="notification.localNotificationdata.body | notificationLimit : characterLimit"
                            [displayMode]="'inline'">
                          </app-smart-output>
                        </span></a>
                      group from private to public
                    </span>
                  </span>
                  <!-- <span class="d-inline"> changed group </span>
                  <a class="d-inline"><span class="d-inline-block text-truncate highlight-body">
                      <app-smart-output
                        [body]="notification.localNotificationdata.body | notificationLimit : characterLimit"
                        [displayMode]="'inline'">
                      </app-smart-output>
                    </span></a> -->
                  <!-- <span> privacy status to  </span> -->
                </div>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }} </span>
              </div>
              <span *ngIf="!notification.isRead" class="dot"></span>
            </div>

            <!-- <div class="notifications-snippets-row" *ngSwitchCase="types.trendNotification">
              <div class="notifications-user-image-box">
                <img
                  src="../../../assets/images/user-chat-img-1.png"
                  alt="User Image"
                  class="notifications-user-image"
                />
              </div>

              <div class="notifications-snippets-main" (click)="viewNotification(notification.id)">
                <p class="notifications-snippets-user">
                  <a [routerLink]="['/hastag', '']"
                    ><strong>{{ notification.localNotificationdata.body | notificationLimit }}</strong></a
                  ><span [className]="!notification.isRead ? 'notifications-snippets-body' : 'text-muted'">
                    &nbsp; is currently trending. &nbsp;</span
                  >
                </p>
                <span class="notifications-snippets-body"> {{ notification.createdOn | timeAgo }} </span>
              </div>
            </div> -->
          </div>
        </section>
      </ng-template>
    </div>
  </div>
</div>