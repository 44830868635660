import { EventEmitter, Injectable, Output } from "@angular/core";
import { connectionStatus } from "@src/app/core/Constants";
import { PendingRequestModel, NotificationMessage } from "@src/app/core/models/model";

@Injectable({
  providedIn: 'root'
})
export class PendingRequestService {

  @Output() connectionEvent = new EventEmitter<PendingRequestModel>();
  notification: NotificationMessage[] = []
  status = connectionStatus;

  snycResponse(res: PendingRequestModel, profileRequests: NotificationMessage[]): NotificationMessage[] {
    //  if(res.type == invitationTypes.ProfileRequestInvitations){
    profileRequests.forEach(function (value) {
      if (value.localNotificationdata.localNotificationId == res.id) {
        if (res.connectionStatus == "Ignored") {
          value.isIgnored = res.response
          value.localNotificationdata.connectionStatus = "Ignored"
        }
        if (res.connectionStatus == "Connected") {
          value.isAccepted = res.response
          value.localNotificationdata.connectionStatus = "Connected"
        }
      }
      return profileRequests;
    });
    return profileRequests;
    //  }
  }
}