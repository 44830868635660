//import { NavBarBrand, SideNav, NavBar, UxRootConfig } from '@seech/ux'; 
//import { CoreRootConfig } from '@seech/core';
import { CoreRootConfig } from './core/models'
// import { environment } from './../environments/environment';

/*const navBarBrand: NavBarBrand = {
    url: '/home',
    image: 'assets/images/header_logo.png'
};

const sideNav: SideNav[] = [
    { url: '/home', icon: 'building', name: 'Home' }, 
    { url: '/user/1', icon: 'user', name: 'Users' }, 
    { url: '/notifications', icon: 'bell', name: 'Notifications' },
    { url: '/forms', icon: 'poll', name: 'Forms' },
];

const navBar: NavBar[] = [
    { url: '/user/messages', icon: 'envelope', name: 'Messages' }, 
    { url: '/user/notifications', icon: 'bell', name: 'Notifications' }, 
    {
      url: '/user', 
      image: 'https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg',
      icon: 'user', 
      name: 'John Doe',
      dropdownitems: [
        { url: '/user', icon: 'user', name: 'My account' },
        { url: '/logout', icon: 'user', name: 'Logout' }
      ]
    }
];*/

export const CORE_ROOT_CONFIG: CoreRootConfig = {
    //environment,
    analyticsConfig: {
        google: '',
        tealium: ''
    },
    sessionIdleConfig: {
        interval: 0, // 28 * 60 * 1000, 
        countdown: 0 // 2 * 60 * 1000
    },
};

/*export const UX_ROOT_CONFIG: UxRootConfig = {
    navBarBrand,
    navBar,
    sideNav,
    showSearch: true,
};*/
