import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { User } from '../../core/models';
import { Router, ActivatedRoute } from '@angular/router';
import { DiagnosticService } from '../../services/diagnostic.service';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from '@src/app/services/toaster.service';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { GeolocationService } from '@src/app/modules/general/geolocation/geolocation/geolocation.service';
import { UtilitiesService } from '@src/app/services/utilities.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [UserService],
})
export class SignupComponent implements OnInit {
  auth_web_url: string = environment.authWebURL;
  user: User = <User>{ coordinates: '', address: '' };
  signup_token: string;
  returnUrl: string;
  loading = false;

  // location: SavedLocation = {};
  // username = { message: '', available: true, min_length: 5, isShort: false };

  constructor(
    private router: Router, private route: ActivatedRoute, private userService: UserService, 
    private diagnostic: DiagnosticService, private geolocationService: GeolocationService,
    private toasterService: ToasterService, private utilitiesService: UtilitiesService, @Inject(PLATFORM_ID) private platformId: Object) {

    this.signup_token = this.route.snapshot.queryParams['token'];
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    if (!this.signup_token) {
      const redirectUrl = this.utilitiesService.getOrigin() + (this.returnUrl ? '&returnUrl=' + this.returnUrl : '') + '&action=signup';
      const fullAuthWebUrl = this.auth_web_url + '/login?redirect=' + encodeURIComponent(redirectUrl);
  
      if (isPlatformBrowser(this.platformId)) {
        window.location.href = fullAuthWebUrl;
      } else {
        // Handle server-side logic for redirects if needed.
        // You might set HTTP headers or do nothing.
      }
    }
  }
  
  ngOnInit() {
    this.getUserCoordinate();
  }

  getUserCoordinate() {
    this.geolocationService.GetCurrentPosition().subscribe((coordinate) => {
      this.geolocationService.GetGeocode(coordinate, 'coordinates', true).subscribe((result) => {
        this.user.coordinates = coordinate;
        this.user.address = result?.address;
        // this.location.coordinates = result?.coordinates;
        // this.location.address = result?.address;
        // this.location.type = result?.location_type;
        // this.user.savedLocations.push(this.location);
        this.signup();
      });

    });
  }

  signup() {
    if (this.user.coordinates) { // && this.user.savedLocations[0]
      if (this.signup_token) {
        this.loading = true;
        this.userService.signup(this.signup_token, this.user)
          .subscribe(
            data => {
              this.router.navigate(['/login'], { queryParams: { 'isSignup': true }, queryParamsHandling: "merge" });
            },
            (error: HttpErrorResponse) => {
              this.diagnostic.error(error);
              this.loading = false;
            });
      }

      else { this.toasterService.error("Token error, unable to complete registration."); }
    }

    else { this.toasterService.error("Unable to obtain a location, registration failed."); }
  }
}
