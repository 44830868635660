<mdb-lightbox #lightbox (lightboxClosed)="lightboxClosed()" [hidden]="modalOnly" class="lightbox">
  <div class="row">
    <div class="col-lg-4" *ngFor="let blob of activeBlobs | slice:0:getPreviewLimit();" trackById>
      <img
        *ngIf="isSelected(blob); else notSelected"
        mdbLightboxItem
        #mdbLightboxItem="mdbLightboxItem"
        [attr.src]="getURL(blob)"
        [img]="getURL(blob)"
        alt="{{blob.altText}}" 
        [id]="blob.id"
        class="w-100"
        [ngStyle]="{'opacity': (i === (getPreviewLimit() - 1)) && (activeBlobs.length > getPreviewLimit()) ? '0.5' : ''}"
      />
      <ng-template #notSelected>
          <img
            mdbLightboxItem
            [attr.src]="getURL(blob)"
            [img]="getURL(blob)"
            alt="{{blob.altText}}" 
            [id]="blob.id"
            class="w-100"
          />
      </ng-template>
    </div>
  </div>
</mdb-lightbox>