import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@src/app/core/models/model';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { UserService } from '../user.service';

@Component({
  selector: 'app-blocked-user',
  templateUrl: './blocked-user.component.html',
  styleUrls: ['./blocked-user.component.scss']
})
export class BlockedUserComponent implements OnInit {
  user = <User>{ blobs: [] };
  action: string;

  styleDeclaration = <CSSStyleDeclaration>{
    textAlign: 'center',
    paddingTop: '7px',
    fontSize: 'x-large',
    color: 'white',
  }
  constructor(public modalRef: MdbModalRef<BlockedUserComponent>, private cdr: ChangeDetectorRef,private router: Router, private userService: UserService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();

    this.router.events.subscribe(() => {
      this.modalRef.close()
    });
  }

  unblockAndConnect(user: User, action: any) {
    this.userService.unblockAndPin(user.id, action).subscribe((status) => {
      user.pinStatus = status?.response;
      this.user = user;
      this.modalRef.close();
    });
  }

}
