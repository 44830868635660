import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentUserImageDirective } from './current-user-image.directive';
import { GroupImageDirective } from './group-image.directive';
import { MentionRouteDirective } from './mention-route.directive';
import { NgForTrackByIdDirective } from './ng-for-track-by-id.directive';
import { OnlineStatusDirective } from './online-status.directive';
import { TextareaAutoSizeDirective } from './textarea-auto-size.directive';
import { UserImageDirective } from './user-image.directive';
import { AnalyticsDirective } from './analytics.directive';

const directives = [
  OnlineStatusDirective,
  CurrentUserImageDirective,
  UserImageDirective,
  NgForTrackByIdDirective,
  TextareaAutoSizeDirective,
  MentionRouteDirective,
  GroupImageDirective,
  AnalyticsDirective,
];

@NgModule({
  declarations: [...directives],
  imports: [
    CommonModule
  ],
  exports: [...directives]
})
export class DirectivesModule { }
