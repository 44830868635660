import { query } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ApiUrls, HttpParameters } from '../core/config';
import { LocationRadiusLimit } from '../core/Constants';
import { Pagination, User } from '../core/models';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  private readonly suggestionUrl = ApiUrls.SuggestionUrl;

  constructor(private http: HttpClient) { }

  getPinSuggestions(pagination: Pagination) {
    const { page, size } = pagination;
    return this.http.get<User[]>('suggestion/pins', { params: { page, size } });
  }

  getConnectionsByLocations(location, page, size): Observable<User[]> {
    let radius = LocationRadiusLimit.locationRadiusLimit;
    let params = HttpParameters.params({ radius, page, size });

    return this.http.get<User[]>(`${this.suggestionUrl}/${'location-connections'}/${location}`, { params });
  }



}
