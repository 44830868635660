<div class="empty-page vh-100">
    <div class="empty-page-wrapper text-center">
        <span class="mdi mdi-database-search mdi-48px"></span>
        <h6 class="mb-3">{{entityName | titlecase}} is deleted or restricted.</h6>
    </div>

    <div class="d-flex align-items-center gap-2">
        <button [routerLink]="['/home']" class="btn btn-sm btn-outline-primary">
            <span class="mdi mdi-home-outline btn-icon"></span>
            <span class="btn-text">Back to Home</span>
        </button>
        <button *ngIf="listButton" [routerLink]="['/'+entityName+'/list']" class="btn btn-sm btn-primary ">
            <span class="mdi mdi-view-list-outline btn-icon"></span>
            <span class="btn-text">Proceed to {{entityName}} listing</span>
        </button>
    </div>
</div>