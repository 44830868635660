import { InjectionToken } from '@angular/core';
import { Pagination } from './models';

export const injectorToken = new InjectionToken<string>('');

export const Color = {
  primary: '#0091EA',
  secondary: '#0091EA',
  tertiary: '#0091EA',
};

export const connectionType = {
  pinned: 'pinned',
  connected: 'connected',
  pinning: 'pinning',
  disconnected: 'disconnect',
};

export const userUrl = {
  profile: 'profile',
  banner: 'banner',
};

export const SignalRMethodCall = {
  NewMessage: 'NewMessage',
  UpdateThreadMessages: 'UpdateThreadMessages',
};

export const Views = {
  media: 'media',
  location: 'location',
};

export const Methods = {
  SendMessage: 'sendMessage',
  ReceiveMessageThread: 'receiveMessageThread',
};


export const notificationType = {
  group: 'GroupInvitationNotification',
  event: 'EventNotification',
  profileRequest: 'ProfileRequestNotification',
};

export const invitationTypes = {
  groupInvitations: 'groupInvitations',
  eventInvitations: 'eventInvitations',
  ProfileRequestInvitations: 'profileRequestInvitations',
};

export const InvitationTitle = {
  inviteAttendees: 'Invite Attendees',
  inviteMembers: 'Invite Members',
};

export const InvitationType = {
  event: 'Event',
  group: 'Group',
};

export const invitationStatus = {
  accepted: 'accepted',
  declined: 'declined',
};

export const connectionStatus = {
  ignored: 'Ignored',
  connected: 'Connected',
};

export const pinPrompt = {
  unpin: 'UNPIN',
  disconnect: 'DISCONNECT',
  connect: 'CONNECT',
  pin: 'PIN',
};

export const restrictionTypes = {
  mute: 'mute',
  unMute: 'unmute',
  block: 'block',
  unBlock: 'unblock',
};

export const connectionActions = {
  connect: 'Connect',
  disconnect: 'Disconnect',
  ignore: 'Ignore',
  pin: 'Pin',
  unpin: 'Unpin'
};

export const actionTypes = {
  edit: 'edit',
  delete: 'delete',
  comment: 'comment',
  identity: 'identity',
  renew: 'renew',
  complaint: 'complaint',
  embed: 'embed',
  link: 'link',
  mute: 'mute',
  block: 'block',
  unmute: 'unmute',
  unblock: 'unblock',
  save: 'save',
};

export const actionTypePostOptions = {
  savedPost: 'saved',
  detailPost: 'detail',
  postPopup: 'postPopup',
};

export const actionTypeProfileImage = {
  UpdateImage: 'update',
  ResetImage: 'reset',
  BannerImage: 'banner',
  ProfileImage: 'profile',
};

export const pagination = <Pagination>{
  page: 0,
  size: 50,
};

export const eventLimit: number = 5;
export const savedLocationLimit: number = 10;
export const featuredOrganizersLimit: number = 5;
export const featuredAttendeesLimit: number = 5;

export const groupProperties = {
  description: 'description',
  rules: 'rules',
  profileImage: 'profile',
  bannerImage: 'banner',
  invites: 'invites',
  share: 'share',
  messagings: 'messagings',
  comments: 'comments',
  settings: 'settings',
  announcements: 'announcements',
  posts: 'posts',
  image: 'image',
  events: 'events',
  hasRequest: 'request',
  groupEventInvitation: 'groupEventInvitation',
};

export const privacyOptions = {
  private: 1,
  public: 2,
  hybrid: 3,
};

export enum UserConnectionTab {
  All = 0,
  Connected = 1,
  Pinned = 2,
  Pinning = 3,
}



export enum parentCategory {
  isLikeOnPost = 1,
  isLikeOnComment,
  isLikeOnReply,
}

export const featureFlags = 'featured-flags';

export const featureFlagsName = {
  featuredEvent: 'featured-event',
  featuredGroup: 'featured-group',
  trends: 'trends',
};

export const ScreenSize = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export const EntityType = {
  Post: 'post',
  Event: 'event',
  Group: 'group',
  Profile: 'Profile',
};

export const RestrictionSettings = {
  EventFileUploadCountLimit: 10,
  GroupPostFileUploadCountLimit: 10,
  MessageFileUploadCountLimit: 10,
  AccoutFileUploadCountLimit: 10,
  GroupEntityFileUploadCountLimit: 10,
  PostFileUploadCountLimit: 10,
};

export const SearchCharacterLimit = {
  SearchTextbox: 50,
  InputField: 50,
  TextArea: 100,
  GroupHandle: 21,
  Username: 15,
  Alias: 20
}

export const OnlineStatusTimer = {
  RefreshInterval: 300000,
};

export const FileCategory = { Images: "Images", Videos: "Videos" };

export const FileSizeRestrictionSetting = {
  fileUploadSizeLimit: 50,
};

export const DebounceTime = {
  debounceTime: 1500,
};

export const LocationRadiusLimit = {
  locationRadiusLimit: 100,
};

export const SocialTag = {
  Mention: { Name: 'mention', Trigger: '@', Size: 5 },
  HashTag: { Name: 'hashtag', Trigger: '#', Size: 5 },
};

export const mentionType = {
  Post: 'post',
  Comment: 'comment',
  Reply: 'reply',
  GroupPost: 'group-post',
  GroupComment: 'group-comment',
  GroupReply: 'group-reply',
};

export const GroupRequestAction = {
  Request: 'request',
  Decline: 'decline',
};

export const MessageUploadOptions = {
  fromMessagePage: 'fromMessagePage',
  fromMessageWidget: 'fromMessageWidget',
  fromNewMessageWidget: 'fromNewMessageWidget',
};

export const CharacterLimit = {
  listingSearch: 20
}

export const EventStringValues = {
  attendListModalTitle: 'Attendees',
};

export const Types = {
  likeNotification: 'LikeNotification',
  likeGroupNotification: 'LikeGroupNotification',
  replyNotification: 'ReplyNotification',
  replyGroupNotification: 'ReplyGroupNotification',
  commentNotification: 'CommentNotification',
  commentGroupNotification: 'CommentGroupNotification',
  liveStreamNotification: 'LiveStreamNotification',
  eventNotification: 'EventNotification',
  groupInvitationNotification: 'GroupInvitationNotification',
  postNotification: 'PostNotification',
  postGroupNotification: 'PostGroupNotification',
  trendNotification: 'TrendNotification',
  connectionNotification: 'ConnectionNotification',
  pinnedUserNotification: 'PinnedUserNotification',
  profileRequestNotification: 'ProfileRequestNotification',
  mentionedGroupNotification: 'MentionedGroupNotification',
  mentionedPostNotification: 'MentionedPostNotification',
  ViewPageNotification: "ViewPageNotification",
  RequestNotification: "RequestNotification"
};

export const postFormatsContainerWidth = {
  xs: 336,
  modal: {
    xs: 278,
    sm: 317,
    md: 366,
    lg: 403,
    xl: 455,
  },
};

export const messageDeleteOptions = {
  delete: 'delete', 
  retract: 'retract',
  fromwidget: 'widget',
  fromMain: 'main'
}

export const profileOption = {
  username: 'username', 
  alias: 'alias',
  note: 'note',
}

export enum FilterType {
  NoFilter,
  PastHour,
  Past24Hours,
  PastWeek,
  PastMonth,
  Custom,
  Location,
}

export enum radiusOptions {
  OneMile,
  FiveMiles,
  TwentyMiles,
  FiftyMiles,
  HundredMiles,
}

export const textValidatorLength = {
  minimumNameLength: 2,
  minimumLength: 5,
  maximumLength: 15,
  verficationCodeLength: 6,
};

export const ReactiveFormCustomValidator = {
  minimumNameLength: textValidatorLength.minimumNameLength,
  minimumLength: textValidatorLength.minimumLength,
  maximumLength: textValidatorLength.maximumLength,
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneRegex: '[0-9+]*'
};

