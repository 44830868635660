import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmojiControlComponent } from './emoji-control/emoji-control.component';
import { SmartInputComponent } from './smart-input/smart-input.component';
import { SmartOutputComponent } from './smart-output/smart-output.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { QuillModule } from 'ngx-quill';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@src/app/pipes/pipes.module';
import { DirectivesModule } from '@src/app/directives/directives.module';

const declarations = [
  EmojiControlComponent,
  SmartOutputComponent,
  SmartInputComponent,
];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PickerModule,
    QuillModule.forRoot(),
    PipesModule,
    DirectivesModule
  ],
  exports: [...declarations]
})
export class SmartInputOutputModule { }
