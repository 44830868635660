<div class="text-container d-flex align-items-center justify-content-between pb-2">
    <div>        
        <span class="d-flex align-items-center justify-content-center" [routerLink]="['/user/', user.username]" [queryParams]="{tab: locationTab}">
            <span class="material-symbols-outlined icon-size icon-font">
              map
            </span>
            <span class="heading-title" role="button">Locations</span><span class="heading-number"
                role="button">({{savedLocationCount}})</span>
        </span>
    </div>
    <div class="d-flex align-items-center justify-content-center">
        <span class="material-symbols-outlined text-black refresh mx-2 loc-icon-branding icon-size"  (click)="reloadLocations()">
             autorenew 
        </span>
        <span class="material-symbols-outlined loc-icon-branding icon-size" (click)="createLocation()">
            add_location_alt
        </span>
    </div>
</div>
<div class="location-icon-container">
    <div class="locations-item"
        *ngFor="let savedLocation of savedLocations; first as isFirst; last as isLast; let even = even; let odd = odd"
        [ngClass]="{'list-odd': odd, 'list-even': even, 'first-class': isFirst, 'last-class': isLast}">
        <span [ngClass]="{'first-tag': isFirst, 'sub-tag': !isFirst}" *ngIf="savedLocation.isDefault"
            class="material-icons-outlined map-tag">person_pin_circle</span>
        <span *ngIf="!savedLocation.isDefault" class="mdi mdi-map-marker-outline map-tag alt-map"></span>
        <div class="locations w-100">
            <p class="locations-address">{{savedLocation.address}}</p>
            <p class="locations-desc">{{savedLocation.description}}</p>
            <div class="locations-mode-toggler">
                <div class="switch first">
                    <div class="form-check form-switch">
                        <input mdbCheckbox class="form-check-input" type="checkbox" id="default"
                            [checked]="savedLocation.isDefault == true" (click)="SetDefault(savedLocation)"
                            [disabled]="savedLocation.isDefault == true" />
                        <label class="form-check-label default-label" for="default">
                            <span class="default">Default</span>
                        </label>
                    </div>
                </div>

                <div class="switch">
                    <div class="form-check form-switch">
                        <input mdbCheckbox class="form-check-input" type="checkbox" id="active"
                            [checked]="savedLocation.isActive == true" (click)="SetActive(savedLocation)" />
                        <label class="form-check-label active-label" for="active">
                            <span class="active">Active</span>
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>