import { StorageService } from './services/storage.service';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FeatureflagService } from './services/featureflag.service';
import { featureFlags } from './core/Constants';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MainMasterService } from '@src/app/layouts/main-master/main-master.service';
import { AnalyticsService } from './services/analytics.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private featureFlagService: FeatureflagService,
    private storageService: StorageService,
    private router: Router,
    private mainMasterService: MainMasterService,
    private analyticsService: AnalyticsService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

    this.analyticsService.start();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        if (isPlatformBrowser(this.platformId)) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    });

    this.getFeatureFlags();
  }

  ngOnInit() {
    if (this.checkIsIOS()) {
      this.renderer.addClass(document.documentElement, 'ios-zoom-fix');
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((x: NavigationEnd) => {
      this.mainMasterService.updateCloseStatus(true);
    });
    
    // if (isPlatformBrowser(this.platformId)) {
    //   this.loadScripts();
    // }
  }

  // private loadScripts() {
  //   this.loadScript('../assets/scripts/jquery-1.10.2.min.js');
  //   this.loadScript('../assets/scripts/jquery.signalR-2.2.2.min.js');
  // }

  // private loadScript(url: string) {
  //   const s = this.renderer.createElement('script');
  //   s.type = 'application/javascript';
  //   s.src = url;
  //   s.async = true;
  //   s.defer = true;
  //   this.renderer.appendChild(document.head, s);
  // }

  checkIsIOS(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }

    return false; // Default to false if not in a browser environment
  }

  getFeatureFlags() {
    this.featureFlagService.getFeatureFlags().subscribe((response) => {
      this.storageService.set(featureFlags, response);
    });
  }
}
