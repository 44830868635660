import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Constants } from '@src/app/core/config';
import { DebounceTime, OnlineStatusTimer, pagination, SearchCharacterLimit } from '@src/app/core/Constants';
import { BlobData, Message, RecepientUser, ResponseMessage, User } from '@src/app/core/models/model';
import { MessageFileUploadComponent } from '@src/app/modules/feature/message/message-file-upload/message-file-upload.component';
import { MessageService } from '@src/app/modules/feature/message/message/message.service';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { FileUploadComponent } from '@src/app/modules/general/media/file-upload/file-upload.component';
import { MessagingService } from '@src/app/services/messaging.service';
import { RestrictionsService } from '@src/app/services/restrictions.service';
import { SignalrService } from '@src/app/services/signalr.service';
import { StorageService } from '@src/app/services/storage.service';
import { UtilitiesService } from '@src/app/services/utilities.service';
import { Guid } from 'guid-typescript';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-new-message-box',
  templateUrl: './new-message-box.component.html',
  styleUrls: ['./new-message-box.component.scss'],
})
export class NewMessageBoxComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  threadMessages: Message[] = [];
  currentUserID: string = this.userService.getCurrentUserId();
  filteredOptions: Observable<any[]>;
  query: string = '';
  searchTerm = new FormControl();
  recepients: any[] = [];
  recepientUsers: RecepientUser[] = [];
  recepient: any = {};
  showProfile = false;
  showConversation = false;
  recepientstatus = false;
  multiImage: boolean = false;
  user: User;
  message: Message = {};
  messagesBody = '';
  threadId: string = '';
  isEmojiPickerVisible: boolean;
  defaultClass = true;
  showSearch = true;
  recepientMessages: ResponseMessage[] = [];
  searchRecepient = '';
  messageCollections = [];
  userRecepient: any = {};
  mess: Message;
  threadMessageId = '';
  threadTracker = '';
  thread = { id: 'thread_id' };
  _recepient = { id: 'recepient_id', respId: 'messageRecepient_id' };
  blobs = [];
  readonly category: string = Constants.Post;
  subject$ = new Subject<void>();
  hasblob = false;
  imageUrl = '';
  name = '';
  toggleMessageBox = false;
  blockedUser: string = '';
  lockedField: boolean = false;
  statusUpdateInterval: any;
  @Input() blobCollection: BlobData[] = [];
  page = pagination.page;
  size = pagination.size;
  searchCharacterLimit = SearchCharacterLimit.SearchTextbox;


  @ViewChild(FileUploadComponent)
  private fileUploadComponent: FileUploadComponent;

  modalRef: MdbModalRef<MessageFileUploadComponent>;

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private restrictionService: RestrictionsService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private messagingService: MessagingService,
    public signalrService: SignalrService,
    private _ngZone: NgZone,
    private modalService: MdbModalService,
    private utilitiesService: UtilitiesService
  ) {
    this.searchTerm.valueChanges
      .pipe(
        debounceTime(DebounceTime.debounceTime),
        distinctUntilChanged(),
        tap((value) => {
          if (value.length > this.searchCharacterLimit){
            this.utilitiesService.showCharacterLimitToast();
            return;
          }else{
            this.searchUser(value);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }

  ngOnInit(): void {
    this.user = this.storageService.getCurrentUser();
    this.getMessages();
  }

  searchUser(keyword: string) {
    if (keyword.length != 1 && this.query != keyword) {
      this.query = keyword;
      this.searchEntries();
    }
  }

  focusSearchInput() {
    this.searchInput.nativeElement.focus();
  }

  //----------load new or existing conversations-------------//
  continueConversation(user: User) {
    this.threadMessages = [];
    this.showProfile = true;
    this.showSearch = false;
    this.showConversation = true;
    this.recepientstatus = true;
    this.defaultClass = false;
    this.query = '';
    this.recepient = user;
    this.imageUrl = user.imageURL;
    this.name = user.name;
    this.toggleMessageBox = true;
    this.getMessages();
    this.lockedTextField(user.id);
    this.loadRecepientConversation(this.recepient.id);
  }

  loadRecepientConversation(recepientId: string) {
    this.messageService.loadRecepientConversation(recepientId).subscribe((res) => {
      this.threadMessages = res;
      if (this.threadMessages.length > 0) {
        this.threadId = this.threadMessages[0].threadID;
        this.threadTracker = this.threadId;
        this.loadMessages(this.threadId);
        this.storeData(this.recepient); //used to track and persist message recepients
      } else {
        let participants: string[] = []
        participants.push(this.currentUserID)
        participants.push(this.recepient.id)
        this.messageService.start_thread(participants).subscribe((res: Guid) => {
          this.threadId = res.toString();
          this.loadMessages(this.threadId);
          this.storeData(this.recepient);
        });
      }
    });
  }

  loadMessages(threadId: string) {
    this.messageService.loadMessages(threadId, this.page, this.size).subscribe((next) => {
      this.threadMessages = next;
      this.signalrService.chatWidgetMessages.next(this.threadMessages);
      this.eventTrackerToUpdateMessages();
      setTimeout(() => {
        // this.messageChatBoxComponent.scrollToLastMessage();
      }, 1000);
    });
  }

  eventTrackerToUpdateMessages() {
    this.messageService.eventTrackerToUpdateMessages(this.threadMessages, this.currentUserID);
  }

  //--------get logged in user message list--------//
  getMessages() {
    this.messageService.getMessageCollectionsAsync(this.searchRecepient).subscribe((res) => {
      this.recepientMessages = [];
      this.recepientMessages = res;
    });
  }

  //-----------------helper functions---------------//

  storeData(recepient: User) {
    this.storageService.set(this._recepient.id, recepient.id);
    this.storageService.set(this.thread.id, this.threadId);
  }

  close() {
    this.messagingService.onclickEvent();
  }

  searchEntries() {
    this.messageService.searchEntries(this.query).subscribe((res) => {
      if (res) {
        this.recepientUsers = res.filter((a) => a.user.id != this.currentUserID);
        this.triggerOnlineStatusUpdates();
      }
    });
  }

  isShiftEnter(event): boolean {
    return this.utilitiesService.carriageReturn(event);
  }

  lockedTextField(userID: string): boolean {
    this.restrictionService.restrictedPartner(userID).subscribe((response) => {
      this.lockedField = response?.isBlocked;
      this.blockedUser = response?.name;
    });
    return this.lockedField;
  }

  triggerOnlineStatusUpdates() {
    this.updateOnlineStatus();
    if (!this.statusUpdateInterval) {
      this.statusUpdateInterval = setInterval(() => {
        this.updateOnlineStatus();
      }, OnlineStatusTimer.RefreshInterval);
    }
  }

  updateOnlineStatus() {
    let users = this.recepientUsers.map((user) => user.user);
    if (users.length > 0) {
      this.userService.updateOnlineStatuses(users).subscribe((res) => {
        this.recepientUsers.forEach((u) => {
          const updatedUser = res.find((x) => x.id == u.user.id);
          if (updatedUser) u.user = updatedUser;
        });
      });
    }
  }
}
