<div class="chat-popup" [ngClass]="{ 'move-to-the-left': toggleMessageBox }">
  <div class="chat-popup-header">
    <h2 class="chat-popup-title">Messages</h2>

    <button (click)="closeMessagePopup()" class="chat-popup-close">
      <i class="fa fa-chevron-down fs-6 chat-popup-close-icon"></i>
    </button>
  </div>
  <div class="input-group">
    <div class="form-group has-search w-100">
      <span class="fa fa-search form-control-feedback" *ngIf="query == ''"></span>
      <input
        type="text"
        class="form-control w-100"
        name="search"
        [formControl]="searchTerm"
        [maxlength]=searchCharacterLimit
        (keyup.enter)="fetchMessages($event.target.value)"
        placeholder="Search Messages ..."
        autocomplete="off"
      />
    </div>
  </div>

  <div class="flex-1 w-100 d-flex align-items-center justify-content-center" *ngIf="loading && initialLoading">
    <p>Loading...</p>
  </div>
  <div class="chat-popup-inner" *ngIf="!loading">
    <div class="chat-unit-wrapper" role="button" *ngFor="let msg of signalrService.chatWigdetList$ | async">
      <div
        class="chat-unit"
        (click)="show(msg.message, msg.user.imageURL, msg.user)"
        *ngIf="msg.message.senderID != currentUserID && !msg.message.isRead"
      >
        <div class="chat-unit-avatar">
          <div class="chat-unit-avatar-image" [appUserImage]="msg.user"></div>
          <div class="chat-unit-avatar-status" [appOnlineStatus]="msg.user"></div>
        </div>
        <div class="chat-unit-body">
          <h5 class="chat-unit-username text-truncate">{{ msg.user.name }}</h5>
          <p class="chat-unit-last-msg text-truncate" *ngIf="!showCurrentlyTyping">
            <span *ngIf="msg.hasBlob" class="mdi mdi-camera chat-unit-last-msg-hasblob"></span>{{
            msg.message.body.trim() }}
          </p>
          <ng-container *ngIf="showCurrentlyTyping">
            <p class="chat-unit-last-msg text-truncate" *ngIf="msg.message.threadID == recepientMessage.threadID">
              <span class="p-1 message-currently-typing"> <strong>is typing...</strong></span>
            </p>
            <p class="chat-unit-last-msg text-truncate" *ngIf="msg.message.threadID != recepientMessage.threadID">
              <span *ngIf="msg.hasBlob" class="mdi mdi-camera chat-unit-last-msg-hasblob"></span>{{
                msg.message.body.trim() }}            
            </p>
          </ng-container>
        </div>
        <!-- <span class="chat-unit-last-msg-date">{{ getDateTime(msg.message.createdOn) }}</span> -->
        <span class="chat-unit-last-msg-date" *ngIf="!showCurrentlyTyping">{{ msg.message.createdOn | date : 'shortDate' }}, {{ msg.message.createdOn
          | date : 'shortTime' }}</span>
      </div>
      <div
        class="chat-unit"
        (click)="show(msg.message, msg.user.imageURL, msg.user)"
        *ngIf="msg.message.senderID == currentUserID || msg.message.isRead"
      >
        <div class="chat-unit-avatar">
          <div class="chat-unit-avatar-image" [appUserImage]="msg.user"></div>
          <div class="chat-unit-avatar-status" [appOnlineStatus]="msg.user"></div>
        </div>
        <div class="chat-unit-body">
          <h5 class="chat-unit-username text-truncate">{{ msg.user.name }}</h5>
          <p class="chat-unit-last-msg text-truncate" *ngIf="!showCurrentlyTyping">
            <span *ngIf="msg.hasBlob" class="mdi mdi-camera chat-unit-last-msg-hasblob"></span>{{
            msg.message.body.trim() }}
          </p>
          <ng-container *ngIf="showCurrentlyTyping">
            <p class="chat-unit-last-msg text-truncate" *ngIf="msg.message.threadID == recepientMessage.threadID">
              <span class="p-1 message-currently-typing"> <strong>is typing...</strong></span>
            </p>
            <p class="chat-unit-last-msg text-truncate" *ngIf="msg.message.threadID != recepientMessage.threadID">
              <span *ngIf="msg.hasBlob" class="mdi mdi-camera chat-unit-last-msg-hasblob"></span>{{
                msg.message.body.trim() }}            
            </p>
          </ng-container>
        </div>
        <!-- <span class="chat-unit-last-msg-date">{{ getDateTime(msg.message.createdOn) }}</span> -->
        <span class="chat-unit-last-msg-date" *ngIf="!showCurrentlyTyping">{{ msg.message.createdOn | date : 'shortDate' }}, {{ msg.message.createdOn
          | date : 'shortTime' }}</span>
      </div>
    </div>

    <div class="chat-popup-empty" *ngIf="recepientMessages.length < 1">
      <p class="chat-popup-empty-text">You have no conversations</p>
    </div>
  </div>
</div>

<app-message-chat-box 
  *ngIf="toggleMessageBox" 
  [threadMessages]="threadMessages" 
  [name]="name" 
  [imageUrl]="imageUrl"
  [threadTracker]="threadTracker" 
  [isBlocked]="lockedField" 
  [showCurrentlyTyping]="showCurrentlyTyping"
  [recipientUser]="recipientUser">
</app-message-chat-box>