import { Guid } from "guid-typescript";
import { parentCategory } from "../Constants";

export interface Pagination {
    page: number;
    size: number;
}

export interface SubscribeToUserListingUpdates {
    users?: User[];
    pageNumber?: number;
}


export interface Filter {
    location?: string;
    min_radius?: number;
    max_radius?: number;
    min_time?: Date;
    max_time?: Date;
}

export interface buttonList {
    title: string;
    icon: string;
}

//export interface Geography {
//    geography: { wellKnownText: string; coordinateSystemId: number; };
//}

export interface buttonList {
    title: string;
    icon: string;
}

export interface Geolocation extends Geotag {
    location?: string;
}

export interface SharedEntity
{
    entityInput?: string[];
    entityUrl?: string;
    entityId?: string;
}

export interface Geotag {
    coordinates?: string;
    address?: string;
    type?: string; // Last, Current, Default, Saved
    description?: string;
}

export interface SavedLocation extends Geotag {
    id?: string;
    userID?: string;
    description?: string;
    isActive?: boolean;
    isDefault?: boolean;
    CreatedOn?: Date;
    isNew?: boolean;
    isDeleted?: boolean;
    isEditing?: boolean;
    hasFocus?: boolean;
}

export interface Notification {
    readonly id?: string;
    userID?: string;
    body?: string;
    isRead?: boolean;
    readonly createdOn?: Date;
}

export interface Message extends ButtonHandler {
    id?: string;
    threadID?: string;
    senderID?: string;
    recipientID?: string;
    body?: string;
    isRead?: boolean;
    blocked?: boolean;
    isRetracted?: boolean;
    senderDeleted?: boolean;
    recipientDeleted?: boolean;
    createdOn?: Date;
    blobs?: BlobData[];
}

export interface UserInputMessage {
    recepientID?: string;
    senderID?: string;
    threadID?: string;
    recepientName?: string;
    imageUrl?: string;
}

export interface MessageBlock {
    timeStamp: Date;
    messages: Message[];
    creator: string;
}

export interface MessageCollection {
    id?: string;
    name?: string;
    lastMessage?: Message;
    status?: string;
}

export interface MessageResponse {
    id?: string;
    recepientName?: string;
    imageUrl?: string;
    lastMessage?: Message;
}

export interface ResponseMessage {
    message?: Message;
    user?: User;
    onlineSettingPreference?: boolean
    hasBlob?: boolean;
}

export interface RecepientUser {
    user?: User;
    savedLocation?: SavedLocation;
}

export interface Thread {
    id?: string; // TODO : Not db generated
    participants?: string[];
    messages?: Message[];
    most_recent?: Date;
    unread_count?: number;
    isLoaded?: boolean;
    new_message?: Message;
    //message_count: number;
    //readonly createdOn?: Date;
}

export interface CallData {
    call_initiator?: string;
    call_receiver?: string;
    recipientID?: string;
    target_type?: string;
    data_type?: string;
    value?: any;
}

export interface Token {
    access_token?: string;
    token_type?: string;
    expires_in?: number;
    refresh_token?: string;
    issued?: Date;
    expires?: Date;
}

export interface Grant {
    username?: string;
    password?: string;
    grant_type?: string;
}

export interface InvitationResponse {
    eventId: string;
    intendedAction: string;
}
export interface LocalNotificationdata {
    localNotificationId: string;
    id: string;
    body: string;
    connectionStatus: string;
    invitation?: Invitation;
    parentCategory?: parentCategory;
    status?: boolean;
    statusType?: string;
    notificationId?: string;
}
export interface NotificationMessage extends ButtonHandler {
    id?: string;
    isRead?: boolean;
    createdOn?: Date;
    notificationType?: string;
    user?: any;
    defaultLocation?: string;
    localNotificationdata?: LocalNotificationdata
    isAccepted?: boolean;
    isIgnored?: boolean;
    isDisabled?: boolean;
}

export interface ButtonHandler{
    isDisabled?: boolean;
    isHidden?: boolean;
    isUnsuccessful?: boolean;
}

export interface InvitationSummary {
    records?: NotificationMessage[],
    count?: number
}

export interface UserConnectionStatus {
    records?: User[],
    count?: number
}

export interface UserConnectionSummary {
    pinned: UserConnectionStatus,
    pinning: UserConnectionStatus,
    connected: UserConnectionStatus
}

export interface UserEventSummary {
    ongoing?: EntityWithCount<Event>,
    upcoming?: EntityWithCount<Event>,
    past?: EntityWithCount<Event>
}

//export interface Credential {
//    username?: string;
//    password?: string;
//    coordinates?: string;
//    email?: string;
//    phone?: string;
//}

export interface MessageData {
    title: string,
    message: string,
    showOption: boolean,
    infoIcon?: boolean,
}

export interface Session {
    readonly id?: string;
    userID?: string;
    token?: Token;
    isConnected?: boolean;
    updatedOn?: Date;
    userAgent?: string;
    lastLogin?: Date;
    lastSeen?: Date;
    lastDetectedIP?: string;
    loginStatus?: string;
}

export interface Author {
    readonly id?: string;
    username?: string;
    imageURL?: string;
    name?: string;
}

export interface Setting {
    locationPref?: string;
    radius?: number;
    boundary?: string[];
    language?: string;
    isDisabled?: boolean;
    isDeleted?: boolean;
}

export interface Restriction {
    readonly id?: string;
    userID?: string;
    restrictedID?: string;
    blockedOn?: Date;
    mutedOn?: Date;
    isMuted?: boolean;
    isBlocked?: boolean;
    isRestricted?: boolean;
    name?: string;
}
export interface PinstatusResponse {
    statusCode?: number;
    response?: PinStatus;
}
export interface PinStatus {
    relationship?: string;
    action?: string;
}
export interface User extends Author, Setting {
    authId?: string;
    name?: string;
    alias?: string;
    coordinates?: string;
    address?: string;
    email?: string;
    phone?: string;
    dob?: Date;
    gender?: string;
    note?: string;
    roles?: string;
    lastSeen?: Date;
    blobs?: BlobData[];
    savedLocations?: SavedLocation[];
    imageURL?: string;
    bannerURL?: string;
    // notifications?: Notification[];
    threads?: Thread[];
    messages?: Message[];
    sessions?: Session[];
    settings?: Settings[];
    subject_Setting?: boolean;
    pinStatus?: PinStatus;
    members?: Member;
    onlineStatus?: number;
    restriction?: Restriction;
    undiscoverable?: boolean;
    isPrivate?: boolean;
    isInvitedEvent?: boolean;
    onlineUserSettings?: boolean;
    hideOnlineStatus?: boolean;
    checkbox?: boolean;
    canMessage?: boolean; 
    responseCode?: number;
    responseMessage?: string;
    pinnedCount?: number;
    pinningCount?: number;
    connectedCount?: number;
}
export interface UserThumbnail {
    id?: Guid;
    imageURL?: string;
    name?: string;
}
export interface UserImage {
    "background-image": string;
}

export interface Reply {
    readonly id?: string;
    body?: string;
    author?: Author;
    commentID?: string;
    body_backup?: string;
    liked?: boolean;
    totalLikes?: number;
    readonly postedOn?: Date;
    editedOn?: Date;
    isEditing?: boolean;
    showEmojiPicker?: boolean;
    likeInProgress?: boolean;
    showMore?: boolean;
    isDeleted?: boolean;
}

export interface ReplyGroup {
    readonly id?: string;
    body?: string;
    author?: Author;
    commentID?: string;
    body_backup?: string;
    liked?: boolean;
    totalLikes?: number;
    readonly postedOn?: Date;
    editedOn?: Date;
    isEditing?: boolean;
    showEmojiPicker?: boolean;
    likeInProgress?: boolean;
    showMore?: boolean;
    isNew?: boolean;
    isDeleted?: boolean;
}

export interface GroupReplyLike {
    readonly id?: string;
    replyID?: string;
    userID?: string;
    createdOn?: Date;
    liked?: boolean;
}

export interface Comment {
    readonly id?: string;
    body?: string;
    author?: Author;
    postID?: string;
    replies?: Reply[];
    body_backup?: string;
    liked?: boolean;
    totalLikes?: number;
    totalReplies?: number;
    readonly postedOn?: Date;
    editedOn?: Date;
    isEditing?: boolean;
    isReplying?: boolean;
    showEmojiPicker?: boolean;
    showReplyEmojiPicker?: boolean;
    likeInProgress?: boolean;
    showMore?: boolean;
    replyToggle?: boolean;
    isDeleted?: boolean;
}

export interface CommentGroup {
    readonly id?: string;
    body?: string;
    author?: Author;
    postID?: string;
    groupReplies?: ReplyGroup[];
    liked?: boolean;
    totalLikes?: number;
    totalReplies?: number;
    isEditing?: boolean;
    isReplying?: boolean;
    body_backup?: string;
    readonly postedOn?: Date;
    editedOn?: Date;
    toggleReply?: boolean;
    likeInProgress?: boolean;
    showMore?: boolean;
    isNew?: boolean;
    isDeleted?: boolean;
}

export interface Member {
    id?: string;
    userID?: string;
    groupId?: string;
    createdOn?: Date;
    isDeleted?: boolean;
    isAdmin?: boolean;
    isSuspended?: boolean;
    suspendUntil?: Date;
    suspendedBy?: string;
    user?: User;
    group?: Group;
}

export interface FeatureFlag {
    name?: string;
    isEnabled?: boolean;
}

export interface PostOperation {
    actionType?: string;
    post?: Post;
}

export interface BlobData {
    id?: string;
    parentId?: string;
    tempContainer?: string;
    blobCategory?: string;
    url?: string;
    originalName?: string;
    modifiedName?: string;
    extension?: string;
    createdOn?: Date;
    createdBy?: string;
    title?: string;
    altText?: string;
    caption?: string;
    isDeleted?: boolean
    newUpload?: boolean,
    coverImg?: string;
    isPrimary?: boolean;
    autoplay?: boolean;
}

export interface ImageUpload {
    fileBlob?: BlobData;
    imageType?: string;
    intendedAction?: string;
}
export interface Post {
    readonly id?: string;
    body?: string;
    blobs?: BlobData[];
    geotag?: Geotag;
    author?: Author;
    commentIsEnabled?: boolean;
    identityIsHidden?: boolean;
    isBlocked?: boolean;
    isMuted?: boolean;
    comments?: Comment[];
    hashTags?;
    readonly postedOn?: Date;
    repostedOn?: Date;
    editedOn?: Date;
    isDeleted?: boolean;
    liked?: boolean;
    saved?: boolean;
    featuredLikers?: UserThumbnail[];
    totalComments?: number;
    totalLikes?: number;
    totalSaves?: number;
    totalShares?: number;
    sharedEntityId?: string;
    sharedEntityType?: string;
    sharedEntity: null | Post | Event;
    current_action: string;
    canRenew?: boolean;
    hasBoundary?: boolean;
    hasBlob?: boolean;
    //likes?: Like[];
    likeFactor?: boolean;
    //radius?: number;
    //geofence?: string;
    boundary?: Geoboundary;
    showMore: boolean;
    onlineSettingPreference?: boolean;
    likeInProgress?: boolean;
    suppressComments?: boolean;
}

export interface Group {
    readonly id?: string;
    groupName?: string;
    featured?: boolean;
    location?: string;
    description?: string;
    address?: string;
    blobBanner?: BlobData[];
    blobImage?: BlobData[];
    geotag?: Geotag;
    author?: Author;
    createdBy?: string;
    hashTags?;
    mentions?;
    handle?: string;
    createdOn?: Date;
    editedOn?: Date;
    isDeleted?: boolean;
    hasBoundary?: boolean;
    category?: Category[];
    urlHold?: string;
    groupActivity?: GroupActivity;
    isMember?: boolean;
    isAdmin?: boolean;
    isPrivate?: boolean;
    boundary?: Geoboundary;
    totalMembers?: number;
    totalAdmins?: number;
    rules?: string;
    coordinates?: string;
    profileImageUrl?: string;
    bannerImageUrl?: string;
    hasRequest?: boolean;
    isSuspended?: boolean;
    suspendedUntil?: string;
    onlyMembersTotal?: number;
    isHidden?: boolean;
}

export interface GroupActivity {
    membersRangeCount?: number;
    eventCount?: number;
    postCount?: number;
}

export interface GroupPost {

    readonly id?: string;
    body?: string;
    groupId?: string;
    blobs?: BlobData[];
    author?: Author;
    groupComments?: CommentGroup[];
    hashTags?;
    mentions?;
    readonly postedOn?: Date;
    repostedOn?: Date;
    editedOn?: Date;
    isDeleted?: boolean;
    liked?: boolean;
    saved?: boolean;
    total_comments?: number;
    total_likes?: number;
    total_saves?: number;
    total_shares?: number;
    sharedPostID?: string;
    likeInProgress?: boolean;
    showMore?: boolean;
}

export interface GroupMember {
    readonly id?: string;
    userId: string;
    groupId: string;
    createdOn: Date;
    isDeleted?: boolean;
    isAdmin?: boolean;
    isSuperAdmin?: boolean;
}

export interface Invitation {
    readonly id?: string;
    userId: string;
    activityId: string;
    invitedOn: Date;
    respondedOn?: Date;
    invitedBy?: boolean;
    accepted?: boolean;
    isAdmin?: boolean;
    activityType: number;
}

export interface GroupInvitation {
    readonly id?: string;
    userId: string;
    groupId: string;
    invitedOn?: Date;
    respondedOn?: Date;
    invitedBy?: boolean;
    accepted?: boolean;
}

export interface EventInvitation {
    readonly id?: string;
    userId: string;
    eventId: string;
    invitedOn?: Date;
    respondedOn?: Date;
    invitedBy?: boolean;
    accepted?: boolean;
}
export interface Permission {
    readonly id?: string;
    groupId?: string;
    permissionName?: string;
    fieldName?: string;
    isSelected?: boolean;
    createdOn?: Date;
    permissionFieldType?: number;
}
export interface PermissionStatus {
    settingsName: string;
    settingsStatus: boolean;
    loginUserStatus: string;
}

export interface PermissionResponse {
    settings: boolean;
    user: string;
}

export interface GroupPermissionTypes {
    name?: string;
    createdOn?: Date;
}

export interface Geoboundary {
    pref?: string;
    unit?: string;
    radius?: number;
    polygon?: any[]; //[{ lat: number, lng: number }];
    //geofence?: string;
}

export interface Like {
    readonly id?: string;
    postID: string;
    userID?: string;
    createdOn?: Date;
    liked?: boolean;
    pictureUrl: string;
    post: Post;
}

export interface CommentLike {
    readonly id?: string;
    commentID: string;
    userID: string;
    createdOn?: Date;
    liked?: boolean;
}

export interface ReplyLike {
    readonly id?: string;
    replyID: string;
    userID: string;
    createdOn?: Date;
    liked?: boolean;
}

export interface Save {
    readonly id?: string;
    //postID: string;
    entityID: string;
    //userID: string;
    entityName: string;
    createdBy?: string;
    createdOn?: Date;
    isDeleted?: boolean;
    saved?: boolean;
}

export interface Share {
    readonly id?: string;
    postID: string;
    userID: string;
    sharedTo?: string;
    createdOn?: Date;
}

export interface Complaint {
    readonly id?: string;
    category?: string;
    subjectID?: string;
    violationID?: string;
    details?: string;
    createdOn?: Date;
    isAnonymous?: boolean;
}

export interface Violation {
    readonly id?: string;
    description?: string;
    value?: string;
    groupName?: string;
    deprecated: boolean;
    readonly createdOn?: Date;
}

export interface Tag {
    readonly id?: string;
    postID: string;
    hashTagID?: string;
    createdOn?: Date;
}

export interface SearchParam {
    query?: string;
    category?: string;
    location?: string;
    radius?: number;
    queryTab?: number;
    filterType?: number;
    search?: string;
}

export interface SearchResult {
    posts?: EntityWithCount<Post>;
    events?: EntityWithCount<Event>;
    users?: EntityWithCount<User>;
    groups?: EntityWithCount<Group>;
}

export interface EntityWithCount<T> {
    records?: T[];
    count?: number
}

export interface Search {
    param?: SearchParam;
    result?: SearchResult;
}

export interface Diagnostic {
    id: string;
    status: number;
    userID?: string;
    caseID?: string;
    previousURL?: string;
    currentURL?: string;
    userMessage?: string;
    message?: string;
    fullMessage?: string;
    level?: string;
    time?: Date;
    diagnosticCode?: string;
    requestOrigin?: string;
}

export interface Alert {
    type: AlertType;
    message: string;
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}

export interface Event {
    //readonly id?: string;
    id?: string;
    name?: string;
    address?: string;
    description?: string;
    externalLink?: string;
    bannerImageUrl?: string;
    geotag?: Geotag;
    hasBoundary?: boolean;
    createdBy?: string;
    categories?: Category[];
    entity_Tags?: Entity_Tag[];
    //groupId?: string;
    isCancelled?: boolean;
    identityIsHidden?: boolean;
    isDeleted?: boolean;
    isAttending?: boolean;
    eventDate?: Date;
    endDate?: Date;
    readonly createdOn?: Date;
    cancelledOn?: Date;
    editedOn?: Date;
    attendees?: Attendee[];
    featuredAttendees?: Attendee[];
    featuredOrganizers?: Attendee[];
    totalAttendees?: number,
    totalOrganizer?: number,
    boundary?: Geoboundary;
    blobs?: BlobData[];
    inviteesId?: string[];
    coordinates?: string;
    saved?: boolean;
    entityHandleInfo?: EntityHandleInfo;
    isExpired?: boolean;
    isPrivate?: boolean;
    isOrganizer?: boolean;
    isHidden?: boolean;
}
export interface EntityHandleInfo {
    entityType: string;
    id: string;
    handle: string;
    isSuspended?: boolean;
    isMember?: boolean;
}

/*export interface Geography{
    coordinateSystemId?: number;
    wellKnownText?: string; 
}*/


export interface Attendee {
    readonly id?: string;
    eventID?: string;
    userID?: string;
    readonly createdOn?: Date;
    editedOn?: Date;
    user?: User;
    event?: Event;
    rsvpOn?: Date;
    isOrganizer?: boolean;
}

export interface Category {
    readonly id?: string;
    name?: string;
    readonly createdOn?: Date;
    colorCode?: string;
    isVerified?: boolean;
    parentId?: string;
    description?: string;
    entity_Tags?: Entity_Tag[];
    show: boolean;
}

export interface Entity_Tag {
    id?: string;
    tagId?: string;
    readonly createdOn?: Date;
    appId?: string;
    entityId?: string;
    parentName?: string;
    isDeleted?: boolean;
    category: Category;
}

export interface Mention {
    id: string;
    username: string;
    name: string;
    imageURL: string;
    category: string;
}

export interface Hashtag {
    readonly id?: string;
    name?: string;
    readonly createdOn?: Date;
}

export interface Trend {
    name?: string;
    count?: number;
    location?: string;
}

export interface AddGroupPermission {
    groupFieldType?: number;
    permissionName?: string;
    groupId?: string;
    isSelected?: boolean;
}

export interface Group_Category {
    readonly id?: string;
    categoryId?: string;
    groupId?: string;
    readonly CreatedOn?: Date;
}

export interface MemberRole {
    role: string;
}

export interface GroupBlobMessage {
    groupId: string;
    blobCategory: string;
    blobs: BlobData[];
}

export interface GeoTagMessageData {
    geotag: Geotag,
    boundary: Geoboundary,
    text: string,
    modalReturnState: string,
    postData: Post
}

export interface BlobEmit {
    blob: BlobData,
    blobComplete: boolean
}

export interface FileUploadResponse {
    id: string;
    contentType: string;
    uploadPath: string;
    fileExtension: string;
    originalName: string;
    modifiedName: string;
    container: string;
    parentId: string;
    imageGuid: string;
}

export interface MdbFileFormat {
    url: string,
    alt: string,
    category: string,
    postId?: string,
    modifiedName?: string,
    id?: string
}

export interface MdbBasicImage {
    img: string,
    thumb: string,
    description: string
}

export interface Connections {
    id: string;
    sessionID: string;
    userID: string;
    isConnected: boolean;
    lastUpdated: Date;
}

/*export interface Settings 
{
    id?: string;
    userID: string;
    settingsName: string;
    connections: boolean;
    pinned: boolean;
    everyone: boolean;
    createdOn: Date;
    lastUpdated: Date;
}*/


//======================================== session models
export interface CoreUser {
    id?: string;
    globalId?: string; // same as auth ID (all instances need to be renamed to globalId)
    username?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    sessions?: CoreSession[]
}

export interface CoreSession {
    readonly id?: string;
    userID?: string;
    token?: Token;
    isConnected?: boolean;
    updatedOn?: Date;
    userAgent?: string;
    lastLogin?: Date;
    lastSeen?: Date;
    lastDetectedIP?: string;
    loginStatus?: string;
}

export interface SessionIdleConfig {
    interval: number;
    countdown: number;
}
export interface DialogData {
    title?: string,
    message: string,
    confirmText?: string,
    declineText?: string,
    cancelText?: string,
}

export interface CoreEnvironment {
    production: boolean;
    authWebUrl: string;
    authApiUrl: string;
    baseApiUrl: string;
    clientID: string;
}

export interface AnalyticsConfig {
    google: string;
    tealium: string;
}

export interface Settings {
    readonly id?: string;
    userID: string;
    settingsName: string;
    selection?: string;
    createdOn: Date;
    modifiedOn: Date;
    selectedValue?: string;
}

export interface NotificationSettings {
    readonly id?: string;
    userId?: string;
    fieldName: string;
    settingsName: string;
    emailEnabled?: boolean;
    smsEnabled?: boolean;
    pushEnabled?: boolean;
    selectedValue?: boolean;
    selectedKey?: string;
    createdOn?: Date;
    modifiedOn?: Date;
}

export enum TabEnum {
    All = 0,
    Posts = 1,
    Users = 2,
    Events = 3,
    Groups = 4
}

export enum InvitationsTab {
    All = 0,
    Connections = 1,
    Events = 2,
    Groups = 3
}

export enum GroupsTab {
    About = 0,
    Post = 1,
    Gallery = 2,
    Members = 3,
    Events = 4
}


export enum ProfileTabsEnum {
    Posts = 0,
    Gallery = 1,
    Locations = 2,
    Events = 3,
    Groups = 4
}

export enum SavedTabsEnum {
    Posts = 0,
    Events = 1,
}

export enum OnlineStateEnum {
    Online = 1,
    Idle,
    Offline
}

export enum permissionFieldType {
    admin = 1,
    member,
    unrestricted,
    restricted,
    all
}

export enum GroupType {
    private = 1,
    public
}
export interface GroupCommentLike {
    readonly id?: string;
    readonly commentId?: string;
    readonly userId?: string;
    createdOn?: Date;
    liked?: boolean
}

export interface GroupPostLike {
    readonly id?: string;
    readonly postID?: string;
    readonly userID?: string;
    createdOn?: Date;
    liked?: boolean;
}

export interface CreatePostResponse {
    readonly id?: string;
    isDeleted?: boolean;
}

export interface TogglePostComment {
    post: Post
    show: boolean
}

export interface BroadcastMessage {
    recipientID: string[];
    body?: string;
    secondaryBody?: string;
    createdOn?: Date;
}

export interface GroupRequest {
    readonly id?: string;
    readonly userId?: string;
    readonly groupId?: string;
    requestedOn?: Date;
    requestedBy?: string;
    respondedOn?: Date;
    respondedBy?: string;
    accepted?: boolean;
    deletedOn?: Date;
    isDeleted?: boolean;
    action?: string;
    user?: User
}

export interface Mentions {
    readonly id?: string;
    entityID?: string;
    entityType?: string;
    userID?: string;
    createdOn?: Date;
    DeletedOn?: Date;
    isDeleted?: boolean;
}

export interface SavedEntity {
    readonly id?: string;
    createdBy?: string;
    entityID?: string;
    createdOn?: Date;
    entityName?: string;
    entityIsDeleted?: boolean;
    post?: Post;
    event?: Event;
}

export interface PendingRequestModel {
    type?: string;
    id?: string;
    response?: boolean;
    connectionStatus?: string;
}

export interface SortOption {
  label: string;
  value: string;
  id?: string;
}

export interface InteractionUpdate {
    recepientId?: string;
    notificationId?: string;
    relationship?: string;
    action?: string;
}

export interface IGenericObject {
    [key: string]: any;
}

export interface NowPlaying {
    blobId?: string;
    config: {
        currentTime?: number;
        muted?: boolean;
        volume?: number; 
    };
}

export interface VideoConfig {
    currentTime?: number,
    muted?: boolean,
    volume?: number,
}

export interface ValueSet {
    value: string;
    description: string;
    selected?: boolean;
}

export interface OptionSet {
    key: string;
    description: string,
    hint?: string,
    options: ValueSet[],
    selectedValues: ValueSet[]
}

export interface SettingCategory {
    key: string,
    description: string,
    hint?: string,
    values?: OptionSet[]
}

export interface DropdownData {
    optionValues: any[];
    defaultValue: string;
  }

  export interface FeedbackGroup {
    id: string
    createdOn: string
    groupName: string
    description: string
    value: string
    deletedOn: string
    isDeleted: boolean
    deactivatedOn: string
    isDeactivated: boolean
    appFeatures: AppFeature[]
    feedbackCategories: FeedbackCategory[]
  }

export interface TermsAndCondition {
  id: string
  createdOn: string
  publishDate: string
  body: string
  createdBy: string
  modifiedOn: string
  modifiedBy: string
  deletedOn: string
  revisionNumber: number
  isDeleted: boolean
  deactivatedOn: string
  isDeactivated: boolean
}


  export interface SupportFAQ {
    id: string
    createdOn: string
    createdBy: string
    modifiedOn: string
    modifiedBy: string
    deletedOn: string
    question: string
    answer: string
    isDeleted: boolean
    deactivatedOn: string
    isDeactivated: boolean
  }

  export interface UserFeedback {
    categories: string[]
    features: string[]
    allowFollowUp: boolean
    id: string
    createdOn: string
    userId: string
    userFeedback: string
    phoneNumber: string
    emailAddress: string
    deletedOn: string
    isDeleted: boolean
    deactivatedOn: string
    isDeactivated: boolean
    appFeatures: AppFeature[]
    feedbackCategories: FeedbackCategory[]
  }
  
  export interface AppFeature {
    id: string
    createdOn: string
    appFeatureLookupId: string
    deletedOn: string
    isDeleted: boolean
    deactivatedOn: string
    isDeactivated: boolean
    feedbackId: string
    appFeatureLookup: AppFeatureLookup
  }
  
  export interface AppFeatureLookup {
    id: string
    createdOn: string
    groupName: string
    description: string
    value: string
    deletedOn: string
    isDeleted: boolean
    deactivatedOn: string
    isDeactivated: boolean
    appFeatures: any[]
    feedbackCategories: any[]
  }
  
  export interface FeedbackCategory {
    id: string
    createdOn: string
    feedbackCategoryLookupId: string
    deletedOn: string
    isDeleted: boolean
    deactivatedOn: string
    isDeactivated: boolean
    feedbackId: string
    feedbackCategoryLookup: FeedbackCategoryLookup
  }
  
  export interface FeedbackCategoryLookup {
    id: string
    createdOn: string
    groupName: string
    description: string
    value: string
    deletedOn: string
    isDeleted: boolean
    deactivatedOn: string
    isDeactivated: boolean
    appFeatures: any[]
    feedbackCategories: any[]
  }
