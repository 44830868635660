import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SocialTag } from '../core/Constants';
import { Hashtag, Mention } from '../core/models';

@Injectable({
  providedIn: 'root'
})
export class SocialTagsService {

  constructor(private http: HttpClient) { }

  buildMentionMarkup(username: string): string {
    return `<span class="mention" data-value="<a routerlink=&quot;${username}&quot; class=&quot;mention-anchor-pointer&quot; social-tag=&quot;mention&quot;>${username}</a>" data-denotation-char="@">﻿<span contenteditable="false"><span class="ql-mention-denotation-char">@</span><a routerlink="${username}" class="mention-anchor-pointer" social-tag="mention">${username}</a></span>﻿</span>` + `<span>&nbsp;</span>`;
  }

  getMatchingMentions(queryText: string, coordinates: string) {
    return this.http
      .get(`socialtag/mentions?location=${coordinates}&size=${SocialTag.Mention.Size}&query=${queryText}`)
      .pipe(
        map((res: Mention[]) =>
          res.map((x) => ({
            id: `@${x.username}`,
            name: x.name,
            value: `${x.username}`,
            link: `${x.username}`,
            imageUrl: x.imageURL,
            type: SocialTag.Mention.Name,
            target: x.category,
          }))
        )
      );
  }

  getMatchingHashTags(queryText: string) {
    return this.http.get(`socialtag/hashtags?size=${SocialTag.HashTag.Size}&query=${queryText}`).pipe(
      map((res: Hashtag[]) =>
        res.map((x) => ({
          id: `#${x.name}`,
          name: x.name,
          value: x.name,
          link: `${x.name}`,
          type: SocialTag.HashTag.Name,
        }))
      )
    );
  }

  // static customMentions(username: string, id: string) {
  //   var element = document.createElement("p");
  //   var anchor = document.createElement("a");
  //   anchor.setAttribute('class', 'social-tag mention');
  //   anchor.setAttribute('data-mention', `@${username}`);
  //   anchor.setAttribute('data-user-id', `${id}`);
  //   anchor.setAttribute('href', `${id}`);
  //   anchor.setAttribute('social-tag', `mention`);
  //   anchor.text = `@${username}`;
  //   element.appendChild(anchor);

  //   return element.innerHTML;
  // }
}
