import { Directive, OnDestroy, Renderer2 } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';

@Directive({
  selector: '[appAnalytics]'
})
export class AnalyticsDirective {

  private unlistener: () => void;
  private listeners = [];
  private splitId: any[];
  private iteratableInput: number = 4;
  private nonIteratableInput: number = 3;

  constructor(private renderer: Renderer2, private analyticsService: AnalyticsService) { }

  ngOnInit() {    
    this.unlistener = this.renderer.listen("document", "click", (evt) => {             
        var target = evt.target || evt.srcElement || evt.currentTarget;                
        var idAttr = target.attributes.id;
        
        if (idAttr) {
          var value = idAttr.nodeValue;                 
          // handle event level navigation
          this.splitId = value.split('-'); 

          if (this.splitId.length == this.nonIteratableInput) {
            this.analyticsService.trackEvent(this.splitId[0], this.splitId[1], this.splitId[2]); 
            this.analyticsService.trackPage(evt.delegateTarget.location.href); 
          }

          else if (this.splitId.length == this.iteratableInput) {
            this.analyticsService.trackEvent(this.splitId[0], this.splitId[1], this.splitId[2], this.splitId[3]); 
            this.analyticsService.trackPage(evt.delegateTarget.location.href); 
          }
        }                           
      }            
    );     

    this.listeners.push(this.unlistener);
  }

  ngOnDestroy() {
    this.listeners.forEach(listener => this.unlistener());  // handles memory leak   
  }
}
