import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Types } from '@src/app/core/Constants';
import { HttpParameters, ApiUrls, IDictionary } from '@src/app/core/config';
import { NotificationMessage, InvitationSummary } from '@src/app/core/models/model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) { }

  public groupSubject = new BehaviorSubject<string>('');
  public groupSearchResponse = this.groupSubject.asObservable();
  public eventSubject = new BehaviorSubject<string>('');
  public eventSearchResponse = this.eventSubject.asObservable();
  public resetCount = new Subject();

  getNotifications(page: number, size: number, filterBy: string, searchQuery: string = ''): Observable<NotificationMessage[]> {
    let params = HttpParameters.params({ page, size, filterBy, searchQuery });
    return this.http.get<NotificationMessage[]>(`${ApiUrls.Notification}`, { params });
  }

  getNotificationCount(): Observable<number> {
    return this.http.get<number>(`${ApiUrls.Notification}/Count`);
  }

  getNotificationRequestCount(): Observable<number> {
    return this.http.get<number>(`${ApiUrls.Notification}/RequestCount`);
  }

  getInvitationSummary(limit: number, query: string = ''): Observable<IDictionary<InvitationSummary>> {
    let params = HttpParameters.params({ query });
    return this.http.get<IDictionary<InvitationSummary>>(`${ApiUrls.Notification}/InvitationSummary/${limit}`, {
      params,
    });
  }

  getInvitationByType(
    invitationType: string,
    page: number,
    size: number,
    query: string = ''
  ): Observable<NotificationMessage[]> {
    let params = HttpParameters.params({ page, size, query });
    return this.http.get<NotificationMessage[]>(`${ApiUrls.Notification}/Invitation/${invitationType}`, { params });
  }

  viewNotification(NotificationId: string) {
    return this.http.patch<any>(`${ApiUrls.Notification}/view/${NotificationId}`, null);
  }

  updateNotificationLastViewedOn(notificationAction: string) {
    let params = HttpParameters.params({ notificationAction });
    return this.http.patch<boolean>(`${ApiUrls.Notification}/view`, null, { params }).pipe(tap(() => this.resetCount.next(notificationAction)));
  }

  resetNotificationCount() {
    return this.resetCount.asObservable();
  }
}
