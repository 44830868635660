<div class="floating-form-field" [ngClass]="marginBottomClass || 'mb-3'">
  <ng-container *ngIf="type !== 'textarea' && type !== 'date'">
    <input
      #inputRef
      mdbInput
      [id]="formInputName"
      [type]="type"
      [formControl]="control"
      class="floating-form-field-input"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (input)="onInputChange($event)"
      (click)="onClickEvent($event)"
      [maxlength]=inputMaxlength
      [readonly]="readOnly"
      [ngClass]="{ 'not-empty': hasValue, 'is-label-suspended': isLabelSuspended }"
    />
    <label *ngIf="label" for="{{ formInputName }}" class="floating-form-field-label" [attr.for]="formInputName">{{
      label
    }}</label>
  </ng-container>

  <ng-container *ngIf="type === 'textarea'">
    <div class="floating-form-field-textarea-wrapper" (click)="imitateFocusOnInput()" (blur)="onBlur()"></div>
    <textarea
      #textarea
      mdbInput
      [id]="formInputName"
      type="text"
      [formControl]="control"
      class="floating-form-field-textarea"
      [minRows]="1"
      [maxRows]="4"
      (focus)="onFocus()"
      (input)="onInputChange($event)"
      (blur)="onBlur()"
      [ngClass]="{ 'not-empty': hasValue, 'is-label-suspended': isLabelSuspended }"
    ></textarea>
    <label *ngIf="label" for="{{ formInputName }}" class="floating-form-field-area-label" [attr.for]="formInputName">{{
      label
    }}</label>
  </ng-container>

  <ng-container *ngIf="type === 'date'">
    <input
      mdbInput
      [id]="formInputName"
      [mdbDatepicker]="datepicker"
      type="text"
      class="floating-form-field-input"
      [ngClass]="{ 'not-empty': hasValue, 'is-label-suspended': isLabelSuspended }"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [formControl]="control"
      [required]="required"
      (click)="datepicker.open()"
    />
    <label *ngIf="label" class="floating-form-field-label">{{ label }}</label>
    <span class="floating-form-field-input-icon calendar-date-icon">
      <mdb-datepicker-toggle [mdbDatepicker]="datepicker"></mdb-datepicker-toggle>
    </span>

    <mdb-datepicker [minDate]="minDate" [maxDate]="maxDate" [inline]="true" #datepicker [format]="'mm/dd/yyyy'">
    </mdb-datepicker>
  </ng-container>

  <ng-content></ng-content>
</div>
