import { LimitPipe } from './limit.pipe';
import { Pipe, PipeTransform, Renderer2, RendererFactory2 } from '@angular/core';
import { StripTagsPipe } from './strip-tags.pipe';

@Pipe({
  name: 'notificationLimit'
})
export class NotificationLimitPipe extends LimitPipe {

  private renderer: Renderer2;
  
  constructor(private rendererFactory: RendererFactory2, protected stripTags: StripTagsPipe) {
    super(stripTags);
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  transform(value: string, limit: number = 20, completeWords: boolean = false, ellipsis = '...') {
    // if (completeWords) {
    //   limit = value.substr(0, limit).lastIndexOf(' ');
    // }
    // return value != undefined && value.length > limit ? value.substr(0, limit) + ellipsis : value;
    return this.truncateHTMLText(value, limit)
  }

  truncateHTMLText(body: string, n: number): string {
    // Create a temporary div element to parse the HTML string
    const tempDiv = this.renderer.createElement('div') as HTMLDivElement;
    tempDiv.innerHTML = body; // Traverse the DOM tree and truncate the text nodes
    let truncatedChars = 0;
    let lastSpaceIndex = -1;
    const traverseDOM = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent!;
        if (truncatedChars + text.length > n) {
          // Truncate the text after the last space index and stop traversing further
          if (lastSpaceIndex > -1) {
            node.textContent = text.substring(0, lastSpaceIndex) + '...';
            truncatedChars = lastSpaceIndex + 3;
          } else {
            node.textContent = text.substring(0, n) + '...';
            truncatedChars = n + 3;
          }
          return;
        } else {
          // Keep traversing the next nodes
          truncatedChars += text.length;
          lastSpaceIndex = text.lastIndexOf(' ', n - truncatedChars);
        }
      } else {
        // Traverse the child nodes recursively
        for (const childNode of Array.from(node.childNodes)) {
          traverseDOM(childNode);
          if (truncatedChars >= n) return;
        }
      }
    };
    traverseDOM(tempDiv); // Remove any remaining nodes after the truncation point
    const allNodes = tempDiv.querySelectorAll('*');
    for (const node of Array.from(allNodes)) {
      if (truncatedChars >= n) {
        node.remove();
      } else if (node.nodeType === Node.TEXT_NODE) {
        truncatedChars += node.textContent!.length;
      }
    } // Return the truncated HTML string
    return tempDiv.innerHTML;
  }

}
