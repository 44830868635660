import { Pipe, PipeTransform, Renderer2, RendererFactory2 } from '@angular/core';

@Pipe({
  name: 'truncateHtml'
})
export class TruncateHtmlPipe implements PipeTransform {

  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }
  
  transform(html: string) {
    if(!html) return '';
    const container = this.renderer.createElement('div');
    container.innerHTML = html;
    const root = container.getElementsByTagName('p') as HTMLCollectionOf<HTMLParagraphElement>;
    return root?.item(0)?.innerHTML ? root.item(0).innerHTML : html;
  }
}
