import { Component, OnInit } from '@angular/core';
import { Geotag, User } from 'src/app/core/models';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-address-modal',
  templateUrl: './user-address-modal.component.html',
  styleUrls: ['./user-address-modal.component.scss']
})
export class UserAddressModalComponent implements OnInit {

  data: Geotag;
  text: User;

  constructor(public modalRef: MdbModalRef<UserAddressModalComponent>, private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      this.modalRef.close()
    });
  }

}
