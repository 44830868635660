import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '@src/app/core/config';
import { Complaint, Violation } from '@src/app/core/models/model';

@Injectable({
  providedIn: 'root'
})
export class ComplaintService {

  private complaintUrl = ApiUrls.Complaint;

  constructor(private http: HttpClient) { }

  submit(complaint: Complaint): Observable<Complaint> {
    return this.http.post<Complaint>(`${this.complaintUrl}`, complaint);
  }

  getViolations(): Observable<Violation[]> {
    return this.http.get<Violation[]>(`${this.complaintUrl}/violations`);
  }
}
