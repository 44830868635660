import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-smart-output',
  templateUrl: './smart-output.component.html',
  styleUrls: ['./smart-output.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartOutputComponent implements OnInit, OnChanges {
  constructor(private sanitizer: DomSanitizer, public elementRef: ElementRef) {}

  processedBody: SafeHtml;

  @Input() body: string;
  @Input() isPaddingTop: boolean;
  @Input() contentFontSize: string = 'normal';
  @Input() displayMode: string;
  @Input() isDeleted: string;

  ngOnInit(): void {  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.body && changes.body.currentValue !== changes.body.previousValue) {
      this.processedBody = this.sanitizeBody(this.body);
    }
  }
  

  @HostBinding('ngStyle')
  get hostStyle() {
    return {
      'display': this.displayMode === 'inline' ? 'inline' : ''
    };
  }

   private sanitizeBody(body: string): SafeHtml {
    const pattern = /((?:(?:https?|ftp):\/\/)|(?:www\.))[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?/gi;
    const paragraphs = body.split("<p>");
    const replacedParagraphs = paragraphs.map((paragraph) => {
      const replacedParagraph = paragraph.replace(pattern, (url) => {
        const displayedUrl = url.startsWith("http") ? url : `http://${url}`;
        return `<a href="${displayedUrl}" target="_blank">${url}</a>`;
      });

      return replacedParagraph;
    });

    const replacedBody = replacedParagraphs.join("<p>");

    return this.sanitizer.bypassSecurityTrustHtml(replacedBody);
  }
}
