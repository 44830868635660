<div class="modal-header">
    <div class="modal-title" id="exampleModalLabel">
        <span class="mdi mdi-message-alert-outline report-icon"></span>
        <span class="report-text">Report</span>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <div class="body-header"> Report this post </div>
    <form [formGroup]="complaintForm">
        <div class="body-content">
            <div>
                <div *ngFor="let violation of violations">
                    <div class="form-check first-item">
                        <input type="radio" class="form-check-input chk-box" id="{{violation.id}}" [value]="violation"
                            formControlName="violation" name="violation">
                        <label class="form-check-label content-text" for="{{violation.id}}">{{violation.description}}</label>
                    </div>
                </div>
            </div>
            <div *ngIf="showDetails" class="md-form input-group input-group-sm mb-3 report-mid-textbox">
                <!-- <input type="text" class="form-control form-outline i-field" formControlName="details" aria-label="Sizing example input" aria-describedby="inputGroupMaterial-sizing-sm"> -->
                <textarea mdbInput class="form-control form-outline i-field" [(ngModel)]="count"
                    maxlength="{{maxlength}}" formControlName="details" aria-label="Sizing example input"
                    aria-describedby="inputGroupMaterial-sizing-sm" rows="2"></textarea>
                <!-- <span *ngIf="count?.length" class="count-text" >{{count.length}} / {{maxlength}}</span> -->
                <span *ngIf="count?.length" class="count-text">{{maxlength - count.length | count: 'character'}}
                    remaining</span>
            </div>
        </div>

        <div class="modal-footer">
            <div class="form-check left-side">
                <input mdbCheckbox class="form-check-input" type="checkbox" value="" formControlName="isAnonymous"
                    [checked]="true" />
                <label class="form-check-label report-anon" for="flexCheckChecked">
                    Report Anonymously
                </label>
            </div>
            <button (click)="submit()" [disabled]="isEnabled()" class="btn btn-primary send-report-btn" id="btn-post">
                <span class="mdi mdi-message-alert-outline send-report-icon"></span>
                <span class="report-text"> Report </span>
            </button>
        </div>
    </form>
</div>