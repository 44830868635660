import { Directive, ElementRef, Input } from '@angular/core';
import { OnlineStatusTimer } from '../core/Constants';
import { OnlineStateEnum, User } from '../core/models/model';
import { UserService } from '../modules/feature/user/user.service';

@Directive({
  selector: '[appOnlineStatus]'
})
export class OnlineStatusDirective {
  
  @Input() public dynamic: boolean = false;

  @Input() set appOnlineStatus(user: User) {
    if(user.hideOnlineStatus == true) { this.hideStatus() } 
    else{
      if(this.dynamic) { this.getSingleOnlineStatus(user.id); }
      else { this.updateColorCoding(user.onlineStatus); }
    }
  }

  constructor(private el: ElementRef, private userService: UserService) { }

  updateColorCoding(onlineStatus: number) {
    (this.el.nativeElement as HTMLElement).style.display = 'block';
    (this.el.nativeElement as HTMLElement).style.border = '2px solid white';

    switch (onlineStatus) {
      case OnlineStateEnum.Online:
        (this.el.nativeElement as HTMLElement).style.backgroundColor = '#10bd10'; // green
        break;
      case OnlineStateEnum.Idle:
        (this.el.nativeElement as HTMLElement).style.backgroundColor = '#fecc34'; // amber
        break;
      case OnlineStateEnum.Offline:
        (this.el.nativeElement as HTMLElement).style.backgroundColor = 'gray';
        break;
    }
  }

  hideStatus() {
    (this.el.nativeElement as HTMLElement).style.display = 'none';
  }

  getSingleOnlineStatus(id) {
    this.updateOnlineStatus(id);
    setInterval(() => {
      this.updateOnlineStatus(id);
    }, OnlineStatusTimer.RefreshInterval);
  }

  updateOnlineStatus(id: string) {
    if (id) {
      this.userService.getUserOnlineState(id).subscribe(result => {
        this.updateColorCoding(result);
      });
    }
  }
}
