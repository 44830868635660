import { Injectable, Inject, Renderer2, RendererFactory2, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { CoreRootConfig } from '../../app/core/models';
import { CoreConfigService } from './core-config.service';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  trackingEnabled: boolean;
  tealium_script_src = '';
  googleTrackingId = '';
  private renderer: Renderer2;

  constructor(
    @Inject(CoreConfigService) config: CoreRootConfig, 
    private router: Router, 
    private rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: Object) {
      
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.googleTrackingId = config?.analyticsConfig?.google || environment.googleAnalyticsId;
    this.tealium_script_src = config?.analyticsConfig?.tealium || ''; // '//assets/tealium-script-location/javascript/tealiumTagLoad.js';
    this.trackingEnabled = isPlatformBrowser(this.platformId) && (!!this.googleTrackingId || !!this.tealium_script_src);
  }

  public start() {
    if (this.trackingEnabled) {
      this.gaSetConfig();
      this.tealiumSetConfig();
    }
  }

  public trackPage(urlAfterRedirects: string) {    
    if (this.trackingEnabled) {
      this.gaTrackPage(urlAfterRedirects);
      this.tealiumView(urlAfterRedirects);
    }
  }

  public trackEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    // eventCategory = e.g Event, Group, Post (top level categories)
    // eventAction = e.g post / create, edit, delete 
    // eventLabel = e.g dropdown label / description
    // eventValue = e.g dropdown value / ID    

    if (this.trackingEnabled) {
      this.gaTrackEvent(eventCategory, eventAction, eventLabel, eventValue);
      this.tealiumLink({ eventCategory, eventAction, eventLabel, eventValue });
    }
  }

  getScreenClass(): string {
    // Default value when running server-side rendering
    let defaultScreenClass = 'desktop';
  
    // Only access `window` when it's available (i.e., on the client)
    if (isPlatformBrowser(this.platformId)) {
      const width = window.innerWidth;
      if (width < 768) {
        return 'mobile';
      } else if (width < 992) {
        return 'tablet';
      } else {
        return 'desktop';
      }
    }
    
    return defaultScreenClass;
  }

  // ============ google analytics ======================
  private gaSetConfig() {
    if (!this.googleTrackingId) return;

    // Load gtag.js code snippet
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'src', `https://www.googletagmanager.com/gtag/js?id=${this.googleTrackingId}`);
    this.renderer.setAttribute(script, 'async', 'true');
    const head = document.head;
    this.renderer.appendChild(head, script);

    // Initialize gtag.js with tracking ID
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).gtag = function() { (<any>window).dataLayer.push(arguments); };
    (<any>window).gtag('js', new Date());
    (<any>window).gtag('config', this.googleTrackingId);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.trackPage(event.urlAfterRedirects);
      }
    });
  }

  private gaTrackEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    this.gaSendEvent('buttonClick', { eventCategory, eventLabel, eventAction, eventValue });
  }

  private gaTrackPage(url: string) {
    // this.gaSendEvent('page_view', { 'pagePath': url, 'screen_class': this.getScreenClass() });
    // this.gaSendEvent('page_view', { 'page_path': url, 'screen_class': this.getScreenClass() });
    this.gaSendEvent('pageView', { 'pagePath': url, 'screen_class': this.getScreenClass() });
  }

  private gaSendEvent(eventName: string, eventData: {}) {
    if (!this.googleTrackingId) return;
    // (<any>window).gtag('event', eventName, eventData); // Track event using gtag.js
    (<any>window).dataLayer.push({ event: eventName, ... eventData }); // Push event to data layer
  }

  // ============== tealium ==============================
  private tealiumSetConfig() {
    (<any>window).utag_cfg_ovrd = { noview : true };
    (<any>window).utag_data = {};
    this.tealium_script_src = this.tealium_script_src;
  }

  // Generic script loader with callback
  private tealiumGetScript( src : string, callback : Function ) {
    let d = document;
    let o = { callback: callback || function() {} };
    let s, t;

    if ( typeof src == "undefined" ) return;

    s = d.createElement("script");s.language="javascript";s.type="text/javascript";s.async=1;s.charset="utf-8";s.src=src;
    if ( typeof o.callback == "function" ) {
      if ( d.addEventListener ) {
        s.addEventListener("load",function(){o.callback()},false);
      } else {
        // old IE support
        s.onreadystatechange=function(){if(this.readyState=="complete"||this.readyState=="loaded"){this.onreadystatechange=null;o.callback()}};
      }
    }
    t = d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s, t);
  }

  // Data layer is optional set of key/value pairs
  private tealiumTrack(tealium_event: string, data? : any) {
    if ( this.tealium_script_src === '' ) {
      // console.log("Tealium config not set.");
      return;
    }
    
    if ( (<any>window).utag === undefined ) {
      this.tealiumGetScript( this.tealium_script_src, function() {
        (<any>window).utag.track( tealium_event, data );
      });
    } 
    
    else {
      (<any>window).utag.track( tealium_event, data );
    }
  }

  private tealiumView(data? : any) {
    if (this.tealium_script_src) this.tealiumTrack("view", data);
  }

  private tealiumLink(data? : any) {
    if (this.tealium_script_src) this.tealiumTrack("link", data);
  }
}
