<div [ngClass]="modalContent" class="modal-content modal-post-content" *ngIf="popToggle" id="create-post-modal">
    <button type="button" class="btn-close p-0" aria-label="Close" (click)="hideModal()"
        *ngIf="!geotagView && !geoboundaryView"></button>
    <div class="modal-body" [ngClass]="{'p-0': sharedPost}">
        <app-embed *ngIf="postEmbedModalView" [postId]="postForEmbed"></app-embed>
        <app-complaint *ngIf="postReportModalView" [subject_id]="subject_id"></app-complaint>
        <app-post-upload [hidden]="!postModalView" [triggerPost]="triggerPost" [getInputType]="textInputType"
            [sharedEntity]="sharedEntity" [sharedEventStyling]="sharedEventStyling" [isUploadingFile]="isUploadingFile"
            [isIdentityHidden]="isIdentityHidden" [sharedEntityType]="sharedEntityType" [ModalBlobs]="postFiles"
            [geotagModal]="geotagModal" [boundaryModal]="boundaryModal" [coordinates]="coordinates">
        </app-post-upload>
        <!-- <app-event-item *ngIf="sharedEventModalView" [event]="event"></app-event-item> -->
        <app-post-edit *ngIf="postEditModalView" [postId]="postIdForEdit" [geotagModal]="postEditGeotag"
            [geoBoundaryModal]="postEditGeoBoundary" [modalTrack]="trackModals" [coordinates]="coordinates">
        </app-post-edit>
        <app-geotag (update_emitter)="updateGeotag($event)" (geotag_emitter)="addGeotag($event)"
            (showBoundary_emitter)="showBoundary()" *ngIf="geotagView" [geotag]="geotag" [boundary]="boundary"
            [componentTracker]="trackModals" [textInputType]="textInputType" [mode]="'add'"
            (close_emitter)="closePopup()"></app-geotag>
        <app-geoboundary (boundary_emitter)="addBoundary($event)" (showGeotag_emitter)="showGeotag()"
            *ngIf="geoboundaryView" [geotag]="geotag" [boundary]="boundary" (close_emitter)="closePopup()">
        </app-geoboundary>
        <app-post-popups [popState]="popState" [popPost]="popPost" [postId]="postId" [blobIndex]="blobIndex" [nowPlaying]="nowPlaying"
            [currentView]="currentView" [modifiedBlobName]="modifiedBlobName" [autoplay]="autoplay"
            [commentId]="commentId" [replyToggle]="replyToggle" (close_emit)="bubbleClose();" *ngIf="blobDetailView">
        </app-post-popups>
        <!-- <app-post-listing [popState]="popState" [mode]="BlobMode" [popPost]="popPost" [toggleView]="toggleView" [postId]="postId" [blobIndex]="blobIndex" [modifiedName]="modifiedBlobName" [autoplay]="autoplay" [commentId]="commentId" [replyToggle]="replyToggle" *ngIf="blobDetailView"></app-post-listing>         -->
    </div>
</div>
  
  
  
  <div [ngClass]="modalContent" *ngIf="!popToggle">
    <div class="modal-body p-0">
        <app-embed *ngIf="postEmbedModalView"></app-embed>
        <app-post-edit *ngIf="postEditModalView" [postId]="postIdForEdit" [geotagModal]="postEditGeotag"
            [geoBoundaryModal]="postEditGeoBoundary" [modalTrack]="trackModals" [coordinates]="coordinates">
        </app-post-edit>
        <app-complaint *ngIf="postReportModalView" [subject_id]="subject_id"></app-complaint>
        <app-post-upload [hidden]="!postModalView" [triggerPost]="triggerPost" [getInputType]="textInputType"
            [sharedEntity]="sharedEntity" [sharedEventStyling]="sharedEventStyling"
            [isIdentityHidden]="isIdentityHidden" [sharedEntityType]="sharedEntityType" [ModalBlobs]="postFiles"
            [geotagModal]="geotagModal" [boundaryModal]="boundaryModal" [coordinates]="coordinates"></app-post-upload>
        <app-geotag (update_emitter)="updateGeotag($event)" (geotag_emitter)="addGeotag($event)" *ngIf="geotagView"
            [geotag]="geotag" [boundary]="boundary" [componentTracker]="trackModals" [mode]="'add'"
            (close_emitter)="closePopup()"></app-geotag>
        <app-geoboundary (boundary_emitter)="addBoundary($event)" *ngIf="geoboundaryView" [geotag]="geotag"
            [boundary]="boundary" (close_emitter)="closePopup()"></app-geoboundary>
        <app-post-popups [popState]="popState" [popPost]="popPost" [postId]="postId" [blobIndex]="blobIndex" [nowPlaying]="nowPlaying"
            [currentView]="currentView" [modifiedBlobName]="modifiedBlobName" [autoplay]="autoplay"
            [commentId]="commentId" [replyToggle]="replyToggle" (close_modal)="hideModal()" *ngIf="blobDetailView">
        </app-post-popups>
  
        <!-- <app-post-listing [popState]="popState" [mode]="BlobMode" [popPost]="popPost" [postId]="postId" [blobIndex]="blobIndex" [toggleView]="toggleView" [modifiedName]="modifiedBlobName" [autoplay]="autoplay" [commentId]="commentId" [replyToggle]="replyToggle" (close_modal)="hideModal()" *ngIf="blobDetailView"></app-post-listing>     -->
    </div>
  </div>
  
  
  