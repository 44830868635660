import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { User, Session, Geotag, Setting } from '../core/models';


@Injectable({
    providedIn: 'root'
})
export class StorageService {

    readonly key = { currentSession: 'current-session', currentUser: 'current-user', currentPosition: 'current-position', currentGeo: 'current-geo' };

    private dataSource = new Subject<any>();
    data = this.dataSource.asObservable();

    constructor() { }

    public set(key: string, _value: any, propagate?: boolean) {  //propagate sets the reactive flag!

        let value = JSON.stringify(_value);
        localStorage.setItem(key, value);

        if (propagate == true) { this.dataSource.next({ key, value }); }
    }

    public get(key: string): string {
        return localStorage.getItem(key);
    }

    public initSession(user: User) {
        this.updateSession(user.sessions[0]);
        delete user.sessions;
        this.set(this.key.currentUser, user); // set in local storage
    }

    public getCurrentSession(): Session { //  use this to get current session id
        return JSON.parse(this.get(this.key.currentSession)) as Session || {};
    }

    public updateSession(session: Session) {
        session.token['expires_at'] = session.token.expires_in * 1000 + new Date().getTime();
        this.set(this.key.currentSession, session, false); //session key set here -- session Id is sitting here!      
    }

    public endSession() {
        // this.delete(this.key.currentSession);
        // this.delete(this.key.currentUser);
        this.reset();
    }

    public setCurrentUser(user: User) {
        this.set(this.key.currentUser, user, true);
    }

    public getCurrentUser(): User {
        return JSON.parse(this.get(this.key.currentUser)) as User || {};
    }

    public setCurrentPosition(currentPosition: string) {
        return this.set(this.key.currentPosition, currentPosition);
    }

    public getCurrentPosition() {
        return this.get(this.key.currentPosition) || '';
    }

    public setCurrentGeo(geo: Geotag) {
        this.setCurrentPosition(geo.coordinates);
        return this.set(this.key.currentGeo, geo, true);
    }

    public getCurrentGeo(): Geotag {
        return JSON.parse(this.get(this.key.currentGeo)) as Geotag;
    }



    //public removeCurrentUser() {
    //    this.delete(this.key.currentUser);
    //}

    public delete(key: string) {
        localStorage.removeItem(key);
    }

    public reset(exclusions?: string[]) {

        if (exclusions && exclusions[0]) {
            const excluded = exclusions.map(exclusion => ({ key: exclusion, value: localStorage.getItem(exclusion) }));
            localStorage.clear();
            excluded.forEach(x => localStorage.setItem(x.key, x.value));
        } else {
            localStorage.clear();
        }
    }

    public updateUserSettings(setting: Setting) {
        let user: User = this.getCurrentUser();
        user = Object.assign({}, user, setting);
        this.setCurrentUser(user);
    }

    public updateUser(newUser: User) {
        let user: User = this.getCurrentUser();
        user = Object.assign({}, user, newUser);
        this.setCurrentUser(user);
    }

    public hasStorage(type: string) {

        //if (typeof (Storage) !== "undefined") {
        //    // Code for localStorage/sessionStorage.
        //} else {
        //    // Sorry! No Web Storage support..
        //}

        try {
            var storage = window[type],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch (e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    }

    //if(hasStorage('localStorage')) {
    //    // Yippee! We can use localStorage
    //}
    //else {
    //    // Too bad, no localStorage
    //}


}
