import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpContextToken } from '@angular/common/http';
import { Router } from '@angular/router';
import { HeaderService } from './header.service';
import { AuthService } from './auth.service';
import { DiagnosticService } from './diagnostic.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToasterService } from './toaster.service';
import { SessionService } from './session.service';
import { Token } from '../core/models';


export interface SkipSettings {
  all?: boolean;
  progress?: boolean;
  headers?: boolean;
  url?: boolean;
  request?: boolean;
  response?: boolean;
  authentication?: boolean;
}

export const SKIP_OPTION = { 
  ALL: new HttpContextToken(() => false), 
  PROGRESS: new HttpContextToken(() => false), 
  HEADERS: new HttpContextToken(() => false), 
  URL: new HttpContextToken(() => false), 
  REQUEST: new HttpContextToken(() => false), 
  RESPONSE: new HttpContextToken(() => false), 
  AUTHENTICATION: new HttpContextToken(() => false), 
};

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private router: Router, private header: HeaderService, private sessionService: SessionService,
    private authService: AuthService, private diagnostic: DiagnosticService, private toastservice: ToasterService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skip = this.getSkipSettings(request);

    if (this.authService.isAuthenticated() || skip.authentication) {
      return this.completeRequest(request, next, skip);
    }

    else {
      return this.handle401(request, next, skip);
    }
  }

  getSkipSettings(req: HttpRequest<any>): SkipSettings {
    let skip = {} as SkipSettings;

    if (req.url.startsWith('http') || req.context.get(SKIP_OPTION.ALL)) { // skip all if external request or skip all is requested
      Object.keys(SKIP_OPTION).forEach(key => {
        skip[key.toLowerCase()] = true;
      })
    }

    else {
      Object.keys(SKIP_OPTION).forEach(key => {
        skip[key.toLowerCase()] = req.context.get(SKIP_OPTION[key]);
      })
    }

    return skip;
  }

  private completeRequest(request: HttpRequest<any>, next: HttpHandler, skip: SkipSettings) {

    // const started = Date.now();
    // let outcome: string;
    if (!skip.request) {
      request = request.clone({
        headers: skip.headers ? request.headers : this.header.setAdditionalHeaders(request),
        url: skip.url ? request.url : (environment.baseApiUrl + request.url),
      });
    }

    return next.handle(request).pipe(
      // map((event: HttpEvent<any>) => 
      //    outcome = event instanceof HttpResponse ? 'succeeded' : '',
      // ),
      tap((event: HttpEvent<any>) => this.sessionService.updateSessionStates()),
      catchError((error: any) => {
        if (!skip.response && error instanceof HttpErrorResponse) {
          return this.handleError(error, request, next, skip);
        }
        else { return throwError(error); }
      }),
      // finalize(() => {
      //     const elapsed = Date.now() - started;
      //     const msg = `${request.method} "${request.urlWithParams}" ${outcome} in ${elapsed} ms.`;
      // })
    );
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler, skip: SkipSettings): Observable<any> {

    switch (error.status) {
      case 401:
        return this.handle401(request, next, skip);
      case 403:
        this.toastservice.warning(error.message);
        break;
      case 409:
      case 404:
        return throwError(error);
      default:
        if (error.status >= 300 && error.status < 400) return throwError(error);
        else { this.diagnostic.displayMessage(this.diagnostic.processError(error)); } // TODO : only in dev mode
    }

    return throwError("Unknown server error has occurred"); // Unknown error / Do not retry automatically
  };

  private handle401(request: HttpRequest<any>, next: HttpHandler, skip: SkipSettings) {

    const refreshToken = this.header.refreshToken;

    if (refreshToken) {
      return of(request).pipe(
              mergeMap(() => this.authService.new_access_token(refreshToken)),
              mergeMap((token: Token) => this.completeRequest(request, next, skip)),
              catchError(err => {
                this.goToLogin();
                return of(request); // throwError(err);
              })
      );
    }

    else { return of(request); }
  }

  private goToLogin() {
    // this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    this.sessionService.gotoExternalLogin({ returnUrl: this.router.url })
  }
}
