import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTags',
})
export class StripTagsPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/html');
      const elements = doc.querySelectorAll('p');
      let text = '';
      for (let i = 0; i < elements.length; i++) {
        if (i > 0) {
          text += ' '; // Add a space between elements
        }
        text += elements[i].textContent;
      }
      return text;
    } else {
      return '';
    }
    // if (!value) return;
    // return value.replace(/(<([^>]+)>)/gi, '');
  }
}
