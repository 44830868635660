import { Component, Input, OnInit, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-emoji-control',
  templateUrl: './emoji-control.component.html',
  styleUrls: ['./emoji-control.component.scss']
})
export class EmojiControlComponent implements OnInit {
  @Output() addEmoji = new EventEmitter<any>();
  @Output() closeEmoji = new EventEmitter();
  @Input() emojiStyle: {};

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.closeEmoji.emit();
      event.stopPropagation();
    }
  }

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  selectEmoji(emoji) {
    this.addEmoji.emit(emoji);
  }
}
