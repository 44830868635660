import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { StorageService } from '@src/app/services/storage.service';
import { ToasterService } from '@src/app/services/toaster.service';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { SupportService } from '../support.service';
import { Diagnostic, FeedbackGroup, UserFeedback } from '@src/app/core/models/model';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { ReactiveFormCustomValidator } from '@src/app/core/Constants';

export const FeedbackGroups = {
  appFeatures: 'app-features',
  feedbackCategory: 'feedback-category',
}

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {
  feedbackForm!: FormGroup;
  saving = false;
  userId!: string;
  appFeatures: FeedbackGroup[] = [];
  feedBackCategories: FeedbackGroup[] = [];

  constructor(public modalRef: MdbModalRef<FeedbackModalComponent>,
              private storageService: StorageService,
              private toasterService: ToasterService,
              private supportService: SupportService,
              private diagnostic: DiagnosticService,
              public fb: FormBuilder) { }

  ngOnInit(): void {
    this.getAppFeatures();
    this.getFeedbackCategories();
    this.initForm();
    this.userId = this.storageService.getCurrentUser().id;
  }

  initForm(){
    this.feedbackForm = this.fb.group({
      categories: [[]],
      features: [''],
      userFeedback: ['', Validators.required],
      isAnonymous: [true, Validators.required],
      allowFollowUp: [false, Validators.required],
      userId: [''],
      emailAddress: ['', [Validators.pattern(ReactiveFormCustomValidator.emailRegex)]],
      phoneNumber: ['', [
        Validators.minLength(ReactiveFormCustomValidator.minimumLength),
        Validators.maxLength(ReactiveFormCustomValidator.maximumLength),
        Validators.pattern(ReactiveFormCustomValidator.phoneRegex)]]
    }, { validators: [this.ensureContactFilled()]});

  }


  getAppFeatures(){
    this.supportService.getFeedbackGroup(FeedbackGroups.appFeatures)
    .subscribe((res: FeedbackGroup[]) => {
      this.appFeatures = res;
    });
  }

  getFeedbackCategories(){
    this.supportService.getFeedbackGroup(FeedbackGroups.feedbackCategory)
    .subscribe((res: FeedbackGroup[]) => {
      this.feedBackCategories = res;
    });
  }

  hideModal(){
    this.modalRef.close();
  }

  setClientId(){
    this.feedbackForm.patchValue({
      userId: !this.feedbackForm.get('isAnonymous').value ? this.userId : ''
    });
  }

  collectData(){
    const allowFollowUp = this.feedbackForm.controls['allowFollowUp'].value;
    if(!allowFollowUp){
      this.feedbackForm.patchValue({
        emailAddress: '',
        phoneNumber: ''
      });
    }
  }

  ensureContactFilled = (): ValidatorFn => {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const allowFollowUp = formGroup.get('allowFollowUp').value;
      const email = formGroup.get('emailAddress').value;
      const phone = formGroup.get('phoneNumber').value;
      if(allowFollowUp && !email && !phone){
        return {invalid: true};
      }
      return null;
    }
  }

  sendFeedback(){
    this.saving = true;
    const payload = this.feedbackForm.value;
    this.supportService.sendFeedback(payload).subscribe((feedback: UserFeedback) => {
      this.saving = false;
      this.modalRef.close();
      this.toasterService.success('Feedback submission successful');
    },
    (error) => {
      this.toasterService.error('Feedback submission failed');
      this.saving = false;
      this.diagnostic.displayMessage(<Diagnostic>error);
    })
  }

}
