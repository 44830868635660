import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '@src/app/core/config';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Restriction, Post, User } from '../core/models';

@Injectable({
  providedIn: 'root'
})
export class RestrictionsService {
  id: Guid;
  private userUrl = ApiUrls.User;
  private usersUrl = ApiUrls.Users;
  private profile = new Subject<any>();
  public muteSubject = new Subject<boolean>();
  public blockSubject = new BehaviorSubject<boolean>(false);
  public muteResponse = this.muteSubject.asObservable();
  public blockResponse = this.blockSubject.asObservable();
  constructor(private http: HttpClient) { }

    //=============================================== Restriction ===============================================================

    restriction(restrictedUserId: string, restrictionType: string): Observable<Restriction> {
      return this.http.post<Restriction>(`${this.userUrl}/restriction/${restrictionType}/${restrictedUserId}`, null);
    }
    mute_user(post: Post, restrictionType: string): boolean {
      this.restriction(post.author.id, restrictionType).subscribe((res) => {
        post.isMuted = res.isMuted;
        this.muteSubject.next(res.isMuted);
      });
      return post.isMuted;
    }
  
    mute_text(post: Post): string {
      const actionText = post.isMuted ? 'Unmute' : 'Mute';
      return `${actionText} ${post.author?.username ? ('@' + post.author?.username) : 'Creator'}`;
    }
  
    block_user(post: Post, restrictionType: string): boolean {
      this.restriction(post.author.id, restrictionType).subscribe((res) => {
        post.isBlocked = res.isBlocked;
        this.blockSubject.next(res.isBlocked);
      });
      return post.isBlocked;
    }
  
    block_text(post: Post): string {
      const actionText = post.isBlocked ? 'Unblock' : 'Block';
      return `${actionText} ${post.author?.username ? ('@' + post.author?.username) : 'Creator'}`;
    }
  
    restrictedUsers(restrictionType?: string): Observable<User[]> {
      if (!restrictionType) { restrictionType = null; }
      return this.http.get<User[]>(`${this.userUrl}/restriction/${restrictionType}`);
    }
  
    restrictedPartner(partnerId: string): Observable<Restriction> {
      return this.http.get<Restriction>(`${this.userUrl}/restricted-partner/${partnerId}`);
    }  

  getBlockageSummary(partnerID: string) {
    return this.http.get<any>(`${ApiUrls.Messages}/blockage-summary/${partnerID}`);
  }

}
