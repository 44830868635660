<section class="invitation-snippets">
  <app-loading-indicator *ngIf="loading"></app-loading-indicator>
  <div
    class="invitation-snippets-row shadow-card"
    *ngFor="let notification of groupRequests; let even = even; let odd = odd;" trackById
    [ngClass]="{ 'list-odd': odd, 'list-even': even }"
  >
    <div class="invitation-user-image-box">
      <div
        (click)="viewNotification(notification.id)"
        [routerLink]="['/user/', notification.user.username]"
        class="invitation-user-image"
        [styleDeclaration]="styleDeclaration"
        [appUserImage]="notification.user"
      ></div>
    </div>

    <div class="w-100" (click)="viewNotification(notification.id)">
      <div class="d-flex text-btn-wrapper justify-content-between align-items-center">
        <span class="invite" [routerLink]="['/group', notification.localNotificationdata.handle]" role="button">
          <span class="invite-sender"> {{ notification.user.username }} </span>
          <span class="invite-text text-lowercase"> invited you to join </span>
          <a class="d-inline-flex"
            ><span class="invite-body d-inline-block text-truncate">
              {{ notification.localNotificationdata.body + ' group' }}
            </span></a
          >
        </span>

        <span class="">
          <div *ngIf="notification.localNotificationdata.invitation.accepted == null" class="d-flex mt-1 gap-2">
            <button
              [id]="'decline' + notification.id"
              [disabled]="notification.isDisabled"
              class="btn btn-sm btn-outline-primary"
              (click)="decline(notification)">
              <span class="mdi mdi-close-circle-outline ms-n2 me-2"></span>
              <span class="me-n1">Ignore</span>
            </button>
            <button 
            [id]="'accept' + notification.id" 
            [disabled]="notification.isDisabled"
            class="btn btn-sm btn-primary" 
            (click)="accept(notification)">
              <span class="mdi mdi-account-multiple-plus-outline ms-n1 me-2"></span>
              <span class="me-n1">Join Group</span>
            </button>
          </div>
          <div *ngIf="notification.localNotificationdata.invitation.accepted == false">
            <div class="dropdown" mdbDropdown #dropdown>
              <button
                class="btn btn-sm btn-outline-primary"
                [disabled]="notification.isDisabled"
                aria-expanded="false"
                id="dropdownMenuButton"
                mdbDropdownToggle
              >
                <span class="mdi mdi-close-circle-outline ms-n2 me-2"></span>
                <span class="me-1">Ignored</span>
                <i class="mdi mdi-chevron-down me-n3 dropdown-toggle"></i>
              </button>
              <ul mdbDropdownMenu class="dropdown-menu dropdown-list" aria-labelledby="dropdownMenuButton">
                <li (click)="dropdown.hide()">
                  <a role="button" class="dropdown-item" (click)="accept(notification)">
                    <span class="mdi mdi-account-multiple-plus-outline dropdown-list-icon"></span>
                    <span class="dropdown-list-text"> Join Group</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="notification.localNotificationdata.invitation.accepted == true">
            <div class="position-relative">
              <button class="btn btn-sm btn-outline-primary" disabled>
                <span class="">Accepted</span>
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</section>

