<!-- <div *ngIf="blobs.length === 0; else showImages">
  <app-content-placeholder [placeholderText]="placeholderText">
  </app-content-placeholder>
  hi
</div> -->

<section class="gallery">
  <div class="gallery-item" *ngFor="let blob of blobs">
    <a class="d-block w-100 h-100" (click)="blobSelected(blob)" [ngStyle]="{'pointer-events': showCarouselBtns ? 'none' : 'auto' }">
      <img *ngIf="blob.blobCategory == 'Images'" [data-mdb-img]="getURL(blob)" [attr.src]="getURL(blob)"
        class="d-block carousel-image" alt="{{blob.altText}}" />

      <div class="video-wrapper" *ngIf="blob.blobCategory == 'Videos'">
        
        <div class="carousel-video overflow-hidden">
          <app-video-player [loop]="true" [autoplay]="true" [src]="getURL(blob)" (play)="onPlay($event.target)" (ended)="onEnded($event.target)" [id]="blob.id" [controls]="false" [view]="'cover'"></app-video-player>
        </div>
        
        <!-- <video class="carousel-video" [id]="blob.id" (play)="onPlay($event.target)"
          (ended)="onEnded($event.target)" controls muted playsinline autoplay loop>
          <source [attr.src]="getURL(blob)" type="video/mp4" class="d-block">
          Your browser does not support the video tag.
        </video> -->
        <!-- <video width="100%" height="100%" [id]="blob.id" (click)="showVideo(blob)"
        (play)="onPlay($event.target)" (ended)="onEnded($event.target)" controls playsinline [muted]="muted" 
        class="flex-item position-relative group-video" autoplay="blob.autoplay"
        [ngStyle]="{'opacity': (i === (previewLimit - 1)) && (blobs.length > previewLimit) ? '0.5' : ''}">
        <source [attr.src]="getURL(blob)" type="video/mp4" class="d-block w-100" />
        Your browser does not support the video tag.
        </video> -->
        <!-- <span class="mdi mdi-play-speed play-btn" controls></span> -->
      </div>
    </a>
  </div>
  <div *ngIf="showCarouselBtns">
    <button class="carousel-control-prev" type="button" tabindex="0" data-mdb-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </button>
    <button class="carousel-control-next" type="button" tabindex="0" data-mdb-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </button>
  </div>
</section>

<app-lightbox [blobs]="blobs" *ngIf="enableLightbox" [selectedItem]="selectedImage" [modalOnly]="true"></app-lightbox>