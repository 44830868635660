import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SKIP_OPTION } from './interceptor.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class HttpProgressInterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(private loaderService: LoaderService) { }

  removeRequest(req: HttpRequest<any>) {
    if (req.context.get(SKIP_OPTION.ALL) !== true && req.context.get(SKIP_OPTION.PROGRESS) !== true) {
      const i = this.requests.indexOf(req);
      if (i >= 0) { this.requests.splice(i, 1); }

      if (this.requests.length === 0) this.loaderService.isLoading.next(false);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.context.get(SKIP_OPTION.ALL) !== true && req.context.get(SKIP_OPTION.PROGRESS) !== true) {
      this.requests.push(req);
      if (this.requests.length === 1) this.loaderService.isLoading.next(true);
    }

    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },
          err => {
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });

      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
