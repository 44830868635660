import { Component, OnInit, EventEmitter, Output, Input, ViewChild, OnDestroy, AfterViewInit, RendererFactory2, Renderer2 } from '@angular/core';
import { SocialTag } from '@src/app/core/Constants';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { UtilitiesService } from '@src/app/services/utilities.service';
import {  IGenericObject } from '@src/app/core/models/model';

import { EmojiControlService } from '@src/app/services/emoji-control.service';
import Quill from 'quill';
import 'quill-mention';
import { SocialTagsService } from '@src/app/services/social-tags.service';

@Component({
  selector: 'app-smart-input',
  templateUrl: './smart-input.component.html',
  styleUrls: ['./smart-input.component.scss'],
})
export class SmartInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() placeHolder: string = '';
  @Input() coordinates: string = '';
  @Input() body: string = '';
  @Input() isDisabled: boolean = false;
  @Input() emoji: Observable<any>;
  @Input() isFloatingEmojiBox: boolean = false;
  @Input() elementId: string = '';  

  @Input() height: string = '60px';
  @Input() minHeight: string = '60px';
  @Input() maxHeight: string;
  @Input() offsetTop: number = 2;
  @Input() offsetLeft: number = 0;
  @Input() mentionDenotationChars: string[] = ['@', '#'];
  @Input() isolateCharacter: boolean = false;
  @Input() fixMentionsToQuill: boolean = false;
  @Input() showDenotationChar: boolean = true;
  @Input() blotName: string = 'mention';
  @Input() defaultMenuOrientation: string = 'bottom'; //Options are 'bottom' and 'top'
  @Input() dataAttributes: string[] = ['id', 'value', 'category'];
  @Input() selectKeys: number[] = [13, 32, 9];
  @Input() positioningStrategy: string = 'absolute'; // Options are 'normal' and 'fixed'. When 'fixed', the menu will be appended to the body and use fixed positioning. Use this if the menu is clipped by a parent element that's using `overflow:hidden
  @Input() spaceAfterInsert: boolean = true; //Whether or not insert 1 space after mention block in text
  @Input() mentionListClass: string = 'ql-mention-list'; //Style class to be used for the mention list (may be null)
  @Input() mentionContainerClass: string = 'ql-mention-list-container'; //Style class to be used for the mention list container (may be null)
  @Input() listItemClass: string = 'ql-mention-list-item'; //Style class to be used for list items (may be null)
  @Input() readOnly: boolean = false;
  @Input() newLineOnEnter: boolean = false;
  @Input() linkTarget: string = 'href';
  @Input() emojiPickerVisible: boolean;
  @Input() emojiPickerDisabled: boolean;
  @Input() hideEmojiTrigger: boolean;
  @Input() isCustomEmojiBoxPosition: boolean = false;
  @Input() customEmojiBoxStyle: {} = {};
  @Input() minChars: number = 0;
  @Input() setAutofocusOnReply: boolean = false;

  @Output() valueChanged = new EventEmitter<string>();
  @Output() loaded = new EventEmitter<any>();
  @Output() sendRequest = new EventEmitter<any>();
  mentionResults$: Observable<any>;
  mentionSubject = new Subject<string>();
  mentionSubscription;
  hashtagResults$: Observable<any>;
  hashTagSubject = new Subject<string>();
  hashTagSubscription;
  emojiStyle: IGenericObject = {};
  isComponentLoaded = false;

  modules = {
    toolbar: false,
    mention: {
      allowedChars: /^[a-zA-Z0-9\-sÅÄÖåäö]*$/,
      minChars: this.minChars,
      mentionDenotationChars: this.mentionDenotationChars,
      isolateCharacter: this.isolateCharacter,
      offsetLeft: this.offsetLeft,
      offsetTop: this.offsetTop,
      fixMentionsToQuill: this.fixMentionsToQuill,
      showDenotationChar: this.showDenotationChar,
      defaultMenuOrientation: this.defaultMenuOrientation,
      blotName: this.blotName,
      selectKeys: this.selectKeys,
      positioningStrategy: this.positioningStrategy,
      spaceAfterInsert: this.spaceAfterInsert,
      mentionListClass: this.mentionListClass,
      mentionContainerClass: this.mentionContainerClass,
      listItemClass: this.listItemClass,
      linkTarget: this.linkTarget,
      source: async (searchTerm: string, renderList: Function, trigger: string) => {
        let matches: any;
        searchTerm = searchTerm || trigger;

        switch (trigger) {
          case SocialTag.Mention.Trigger: //'@'
            if (!this.mentionSubscription) {
              this.mentionSubscription = this.mentionResults$.subscribe((res) => {
                matches = res;
                renderList(res);
              });
            }
            this.mentionSubject.next(searchTerm);
            break;

          case SocialTag.HashTag.Trigger: //'#'
            if (!this.hashTagSubscription) {
              this.hashTagSubscription = this.hashtagResults$.subscribe((res) => {
                matches = res;
                renderList(res);
              });
            }
            this.hashTagSubject.next(searchTerm);
            break;
        }

        renderList(matches);
      },

      renderItem: (item: any) => this.customItemRenderer(item),

      onSelect(item: { value: string; denotationChar: string, target: string | null}, insertItem: Function) {
        const newItem = { value: '', denotationChar: '', target: ''};

        // the renderer property did not work in this context. javascript DOM rendering
       //  const container =  this.renderer.createElement('div');;
        const container =  document.createElement('div');
        container.innerHTML = item.value;
        const root = container.getRootNode() as HTMLParagraphElement;

        if (root) {
          const anchors = root.getElementsByTagName('a') as HTMLCollectionOf<HTMLAnchorElement>;

          if (anchors?.length) {
            Array.from(anchors).forEach((a) => {
              const href = a.getAttribute('href');
              if (href) {
                a.setAttribute('routerLink', href);
                a.classList.add('mention-anchor-pointer');
                switch (item?.denotationChar) {
                  case SocialTag.Mention.Trigger: //'@'
                    a.setAttribute('social-tag', SocialTag.Mention.Name);
                    a.setAttribute('category', item.target);
                    break;

                  case SocialTag.HashTag.Trigger: //'#'
                    a.setAttribute('social-tag', SocialTag.HashTag.Name);
                    break;
                }
                a.removeAttribute('href');
                a.removeAttribute('target');
                item.value = container.innerHTML;
              }
            });
          }
        }
        newItem.value = item.value;
        newItem.denotationChar = item.denotationChar;
        insertItem(newItem);
      },
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: { index: any }) => {
            if (range?.index <= 0) {
              return;
            }
            if (!this.newLineOnEnter) {
              this.sendRequest.emit();
              return;
            }

            this.editorComponent.quillEditor.insertText(range.index, '\n');
          },
        },

        /* Space and Tabs are being commented out because there is no need to clear out empty spaces or tabs in a user's
         comment or post. The user can write the post as he wishes. The no breaking space being added was also causing
        word break issues */
        // space: {
        //   key: 32,
        //   handler: (range) => {
        //     if (range?.index > 0) {
        //       this.editorComponent.quillEditor.insertText(range.index, '\u00A0');
        //       return
        //     }
        //     return;
        //   }
        // },
        // tab: {
        //   key: 9,
        //   handler: (range) => {
        //     if (range?.index > 0) {
        //       this.editorComponent.quillEditor.insertText(range.index, '\u0009');
        //       return
        //     }
        //     return;
        //   }
        // },
      },
    }
  };

  @ViewChild('editor', { static: false }) editorComponent: any;
  private emojiSubscription: Subscription;
  lastCursorIndex: number;
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2, 
    private socialTagService: SocialTagsService, 
    private utilitiesService: UtilitiesService, 
    private emojiControlService: EmojiControlService) {

      this.renderer = this.rendererFactory.createRenderer(null, null);

      this.mentionResults$ = this.mentionSubject.pipe(
        distinctUntilChanged(),
        mergeMap((searchText) => this.socialTagService.getMatchingMentions(searchText, this.coordinates))
      );

      this.hashtagResults$ = this.hashTagSubject.pipe(
        distinctUntilChanged(),
        mergeMap((searchText) => this.socialTagService.getMatchingHashTags(searchText))
      );
  }

  ngOnInit(): void {
    this.utilitiesService.clearTextAction.subscribe((res) => {
      if (res && this.editorComponent) {
        this.clearText();
      }
    });

    if (this.emoji) {
      this.emojiSubscription = this.emoji.subscribe((event) => this.addEmoji(event));
    }
  }

  ngAfterViewInit(): void {
    this.isComponentLoaded = true;

    if(this.setAutofocusOnReply) {
      this.setFocus()
    }
  }

  ngOnDestroy() {
    if (this.emoji) {
      this.emojiSubscription.unsubscribe();
    }
  }

  isObjectTruthyAndNotEmpty(obj: IGenericObject) {
    return obj && Object.keys(obj).length > 0;
  }

  onEditorChanged(event: { html: any; range: { index: number } }) {
    if (event?.html === null) {
      this.lastCursorIndex = 0;
    }

    if (event?.range?.index) {
      this.lastCursorIndex = event?.range?.index; // this gives last cursor position
    }
  }

  // onEditorCreated(editor) {
  //   editor.clipboard.addMatcher('IMG', (node, delta) => {
  //     const Delta = Quill.Quill.import('delta')
  //     return new Delta().insert('')
  //   })
  //   editor.clipboard.addMatcher('PICTURE', (node, delta) => {
  //     const Delta = Quill.Quill.import('delta')
  //     return new Delta().insert('')
  //   })
  // }

  onEditorCreated(quill: Quill) {

    quill.clipboard.addMatcher('IMG', (node, delta) => {
      const Delta = Quill.import('delta')
      return new Delta().insert('')
    });

    quill.clipboard.addMatcher('PICTURE', (node, delta) => {
      const Delta = Quill.import('delta')
      return new Delta().insert('')
    });

    setTimeout(() => {
      this.loaded.emit();
    }, 500);
  }

  onContentChanged = (event: { html: string }) => {
    const data = this.getTrimmedValue(event?.html);
    this.valueChanged.emit(data);
  };

  addEmoji(event: { emoji: { native: string | any[] } }) {
    if (this.lastCursorIndex !== undefined) {
      this.editorComponent.quillEditor?.insertText(this.lastCursorIndex, event.emoji.native); // insert text after the index
      this.lastCursorIndex += event.emoji.native.length;
    } else {
      this.body = this.appendToBody(this.body, event.emoji.native);
    }
  }

  // Handle Emoji Toggle and Position
  toggleEmoji(event: MouseEvent) {
    this.emojiPickerVisible = !this.emojiPickerVisible;
    const {
      spaceRemainderToTheBottom,
      buttonTop,
      buttonLeft,
      emojiBoxWidth,
      emojiBoxHeight,
      viewportWidth
    } = this.emojiControlService.getEmojiButtonCoordinates(event);

    let top = '';
    let bottom = '';
    let left = '';

    if (spaceRemainderToTheBottom > (emojiBoxHeight + 20)) {
      if (this.isFloatingEmojiBox) {
        top = 'unset';
        bottom = `${spaceRemainderToTheBottom - (emojiBoxHeight + 20)}px`;
      } else {
        top = '30px';
        bottom = 'unset';
      }
    } else {
      if (this.isFloatingEmojiBox) {
        top = `${buttonTop - (emojiBoxHeight + 20)}px`;
        bottom = 'unset';
      } else {
        top = 'unset';
        bottom = '30px';
      }
    }

    if (this.isFloatingEmojiBox) {
      if (viewportWidth < 400) {
        left = '15px'
      } else {
        left = `${(buttonLeft + 20) - emojiBoxWidth}px`;
      }
    } else {
      left = 'unset';
    }

    if (this.emojiPickerVisible) {
      this.emojiStyle = {
        position: this.isFloatingEmojiBox ? 'fixed' : 'absolute',
        top: top,
        bottom: bottom,
        left: left,
        right: this.isFloatingEmojiBox ? 'unset' : '-6px'
      }
    } else {
      this.emojiStyle = {};
    }
  }

  appendToBody(body: string, appendItem: any): string | null {
    const container = this.renderer.createElement('div');
    container.innerHTML = body;
    const root = container.getRootNode() as HTMLParagraphElement;

    if (root) {
      const appendNode = this.renderer.createText(appendItem);
      root.appendChild(appendNode);
      return root.textContent;
    }
  }

  onBlur(event: { editor: { root: { dataset: { placeholder: string } } } }) {
    event.editor.root.dataset.placeholder = this.placeHolder;
  }

  onFocus(event: { editor: { root: { dataset: { placeholder: string } } } }) {
    event.editor.root.dataset.placeholder = '';
  }

  setFocus() {
    this.editorComponent?.quillEditor?.focus();
  }

  clearText() {
    this.valueChanged.emit('');
    this.body = '';
    this.editorComponent.html = '';
    this.editorComponent.quillEditor.root.innerHTML = '';
  }

  getTrimmedValue(content: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const isEmptyOrOnlyBrTag = (node: Node): boolean => {
      if (node.nodeName === 'P') {
        if (node.childNodes.length === 0 || (node.childNodes.length === 1 && node.firstChild.nodeName === 'BR')) {
          return true;
        }
        if ((node as HTMLElement).textContent.trim() === '') {
          return true;
        }
      }
      return false;
    };

    const hasActualContent = (node: Node): boolean => {
      if (!node) return false;

      let actualContent = false;
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE && child.textContent.trim() !== '') {
          actualContent = true;
        } else if (child.nodeType === Node.ELEMENT_NODE && child.nodeName !== 'BR' && child.nodeName !== 'P') {
          actualContent = true;
        }
      });
      return actualContent;
    };

    let contentStarted = false;

    const nodesToRemove: Node[] = [];

    for (let i = 0; i < doc.body.childNodes.length; i++) {
      const child = doc.body.childNodes[i];
      if (hasActualContent(child)) {
        contentStarted = true;
      }
      if (isEmptyOrOnlyBrTag(child) && !contentStarted) {
        nodesToRemove.push(child);
      }
    }

    contentStarted = false;
    for (let i = doc.body.childNodes.length - 1; i >= 0; i--) {
      const child = doc.body.childNodes[i];
      if (hasActualContent(child)) {
        contentStarted = true;
      }
      if (isEmptyOrOnlyBrTag(child) && !contentStarted) {
        nodesToRemove.push(child);
      }
    }

    nodesToRemove.forEach((node) => {
      if (node.parentNode) {
        node.parentNode.removeChild(node);
      }
    });

    const trimmedValue = doc.body.innerHTML.trim() || '';
    return trimmedValue == 'null' ? '' : trimmedValue;
  }

  customItemRenderer(item: { type: any; id: any; name: any, value: string, category: string | null }) {
    let element: string;

    switch (item.type) {
      case 'mention':
        element = `<span class='mention-item' id='mention-list-item-id-${item.id}'>${item.name} <span class='mention-item-username'>${item.id}</span></span>`;
        break;
      case 'hashtag':
        element = `<span class='mention-item' id='mention-list-item-id-${item.id}'>${item.name}</span>`;
        break;
    }

    return element;
  }
}