import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { NgModule } from "@angular/core";
import { AppResolver } from "../resolvers/app.resolver";

const public_routes: Routes = [

    {
        path: 'login',
        component: LoginComponent,
    },

    {
        path: 'signup',
        component: SignupComponent,
    },

    // {
    //     path: 'embed/post/:id',
    //     component: PostDetailComponent,
    // },

    // { path: '', redirectTo: '/login?action=resolve', pathMatch: 'full' }, // default empty route

    { path: '', redirectTo: '/login',  resolve: { action: AppResolver }, pathMatch: 'full' }, // redirect happens in the AppResolver
];

const routes: Routes = [
    { path: '', data: { title: 'Public Views' }, children: public_routes },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PublicRoutingModule { }
