import { Pipe, PipeTransform } from '@angular/core';
import { StripTagsPipe } from './strip-tags.pipe';

@Pipe({
  name: 'limit',
})
export class LimitPipe implements PipeTransform {
  /**
   *
   */
  constructor(protected stripTags: StripTagsPipe) {}

  truncateHtmlContent(htmlString: string, limit: number, ellipsis: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
  
    interface CharCount {
      count: number;
      html: string;
    }
  
    function traverse(node: Node, charCount: CharCount): void {
      if (charCount.count >= limit) return;
    
      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = limit - charCount.count;
        const textContent = (node as Text).textContent.slice(0, remainingChars);
        charCount.count += textContent.length;
        charCount.html += textContent;
        if (charCount.count >= limit) {
          charCount.html += ellipsis;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const elementNode = node as Element;
        const tagName = elementNode.tagName.toLowerCase();
    
        if (tagName !== "body") {
          const attributes = Array.from(elementNode.attributes)
            .map((attr) => {
              const attrValue =
                attr.name === "data-value"
                  ? encodeURIComponent(attr.value)
                  : attr.value;
              return `${attr.name}="${attrValue}"`;
            })
            .join(" ");
          const openingTag = `<${tagName}${attributes ? " " : ""}${attributes}>`;
          const closingTag = `</${tagName}>`;
    
          if (tagName === "br") {
            charCount.html += openingTag;
          } else {
            charCount.html += openingTag;
            Array.from(elementNode.childNodes).forEach((child) =>
              traverse(child, charCount)
            );
            charCount.html += closingTag;
          }
        } else {
          Array.from(elementNode.childNodes).forEach((child) =>
            traverse(child, charCount)
          );
        }
      }
    }
  
    const charCount: CharCount = { count: 0, html: "" };
    traverse(doc.body, charCount);
    return charCount.html;
  }


  transform(value: string, limit: number = 0, completeWords: boolean = true, ellipsis = ' ...') {
    const rawValue = this.stripTags.transform(value);

    if (limit > 0 && completeWords) {
      limit = rawValue.substr(0, limit).lastIndexOf(' ');
    } else if (limit === 0 && completeWords) {
      limit = rawValue.length;
    }

    const returnedValue = rawValue && rawValue.length > limit ? this.truncateHtmlContent(value, limit, ellipsis): value;
    return returnedValue;
  }
}
