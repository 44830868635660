import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '@src/app/core/config';
import { Hashtag, Trend } from '@src/app/core/models/model';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class TrendService {

  private readonly trendUrl = ApiUrls.TrendUrl;
  readonly currentUserID: string = this.userService.getCurrentUserId();
  readonly coords: string = this.userService.get('coords');
  readonly radius: number = this.userService.get('radius');

  constructor(private http: HttpClient, private userService: UserService) { }

  getHashtags(): Observable<Hashtag[]> {
    return this.http.get<Hashtag[]>(`${this.trendUrl}/hashtags`);
  }

  getTrends(searchEntry: string = ''): Observable<Trend[]> {
    const params = new HttpParams().set('query', searchEntry)
    return this.http.get<Trend[]>(`${this.trendUrl}/${this.coords}`, { params });
  }

  gettrends() {
    return of()
  }

  search(terms: Observable<string>): Observable<Trend[]> {
    return terms.pipe(
      distinctUntilChanged(),
      filter(term => term.length > 0),
      switchMap(term => this.getTrends(term))
    );
  }
}
