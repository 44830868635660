import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApiUrls } from '../core/config';
import { StorageService } from './storage.service';
import { Diagnostic } from '../core/models';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ToasterService } from './toaster.service';
import { AlertService } from '../modules/core/alert/alert.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticService {

    private diagnosticsUrl = ApiUrls.DiagnosticsUrl;
    private readonly userID: string = this.getID();

    constructor(private http: HttpClient, private storageService: StorageService, private alertService: AlertService,
        private toastservice: ToasterService, @Inject(PLATFORM_ID) private platformId: Object) { }

    private getID(): string {
        let user = this.storageService.getCurrentUser();
        return user && user.id ? user.id : "";
    }

    public handleError(error: any): Observable<any> {

        return throwError(this.processError(error));
    }

    public processError(error: HttpErrorResponse) {

        return this.isDiagnostic(error.message) ? JSON.parse(error.message) : `${error.status} - ${error.statusText || ''} ${error.message}`;
    }

    private isDiagnostic(data: any): boolean {

        if (!data) { return false; }

        try {
            let diagnostic = JSON.parse(data) as Diagnostic;
            return diagnostic.id || diagnostic.level || diagnostic.diagnosticCode ? true : false;
        }
        catch (e) {
            //if (e instanceof SyntaxError) { 
            return false;
            //}
        }
    }

    displayMessage(data: any, origin?: string) {

        let user_message = data.userMessage || data || "Unknown Error";
        let code_origin = data.diagnosticCode || origin || null;

        let message = code_origin ? JSON.stringify(user_message).concat(" : Code - ", JSON.stringify(code_origin)) : JSON.stringify(user_message);
        this.toastservice.error(message);

        if (typeof data === "object") {
            if (data.level == "Error") {
                this.error(data, origin);
            }
        }

        // Unknown Diagnostic message Level
        else if (typeof data === "string") {
            this.debug(data, origin);
        }
    }

    //critical, error, warn, debug, info, log

    error(message: any, origin?: string) {

        let messageType = typeof message;
        let error = <Diagnostic>{};

        // Error originating directly from component
        if (messageType === "string") {
            error = <Diagnostic>{ id: null, message, level: "Error" };
        }

        // Error originating from server side / Data passed from "displayMessage()"
        else if (messageType === "object") {
            error = <Diagnostic>{ id: message.id };
        }

        error.requestOrigin = origin || null;
        error.userID = this.userID;
        
        if (isPlatformBrowser(this.platformId)) {
            error.previousURL = document.referrer || '';
            error.currentURL = window.location.pathname;
        }

        //add more details here.....

        this.transmitData(error).subscribe();
    }

    debug(input: string, origin?: string) {

        let error = <Diagnostic>{};

        error = <Diagnostic>{ id: null, userMessage: input, level: "Debug" };

        error.requestOrigin = origin || null;
        error.userID = this.userID;

        if (isPlatformBrowser(this.platformId)) {
            error.previousURL = document.referrer || '';
            error.currentURL = window.location.pathname;
        }
        
        //add more details here.....

        this.transmitData(error).subscribe();
    }

    transmitData(data: Diagnostic) {

        return of();  //disabling client side error log

        //   if (data.id == null) {
        //       return this.http.post<Diagnostic>(this.diagnosticsUrl, data);
        //   }

        //   else {
        //       return this.http.patch<Diagnostic>(`${this.diagnosticsUrl}/${data.id}`, data);
        //   }
    }
}
