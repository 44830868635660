<div>
    <h4 class="title mt-n5"><span class="mdi mdi-xml me-2"></span> Embed</h4>
    <hr>
    <p>Copy and paste this code in your blog or website</p>
    <div>
        <textarea rows="4" cols="60" class="text-center" #embedFrame>
            <iframe src="{{postUrl}}" frameborder="0" height="649" width="504" allowfullscreen="" title="Embedded post"></iframe>
        </textarea>
    </div>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div class="form-check">
        <input mdbCheckbox class="form-check-input" type="checkbox" id="comments" [(ngModel)]="includeComment"
            (change)="updateUrl()" />
        <label class="form-check-label active-label" for="active">
            <span class="comments">Include comments</span>
        </label>
    </div>

    <button (click)="copyLink()" type="button" class="btn text-capitalize text-white">
        <span class="mdi mdi-content-copy"></span> Copy
    </button>
</div>