import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
// import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-saved-locations-modal',
  templateUrl: './saved-locations-modal.component.html',
  styleUrls: ['./saved-locations-modal.component.scss']
})
export class SavedLocationsModalComponent implements OnInit, AfterViewInit {
  @Output() close = new EventEmitter<any>();

  isComponentLoaded: boolean = false;

  constructor(
    // public modalRef: MdbModalRef<SavedLocationsModalComponent>
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isComponentLoaded = true;
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target) && this.isComponentLoaded) {
      this.close.emit();
    }
  }

  closeWidget(event: any) { 
    // this.modalRef.close();
    event.preventDefault();
    this.close.emit();
  }
}
