import { Directive, ElementRef, Input } from '@angular/core';
import { Color } from '../core/Constants';
import { User, UserImage } from '../core/models';
import { UserService } from '../modules/feature/user/user.service';
import { StorageService } from '../services/storage.service';

@Directive({
  selector: '[appCurrentUserImage]',
})
export class CurrentUserImageDirective {
  @Input('styleDeclaration') public styleDeclaration = <CSSStyleDeclaration>{};
  currentUserImage: string;

  constructor(private el: ElementRef, private userService: UserService, private storageService: StorageService) {
    this.currentUserImage = this.userService.getCurrentUserImageString();
    if (!this.currentUserImage) {
      let user = this.storageService.getCurrentUser();
      (this.el.nativeElement as HTMLElement).style.backgroundColor =
        this.styleDeclaration.backgroundColor || Color.primary;
      (this.el.nativeElement as HTMLElement).textContent = `${this.formattedName(user)}`;
      (this.el.nativeElement as HTMLElement).style.textAlign = this.styleDeclaration.textAlign || `center`;
      (this.el.nativeElement as HTMLElement).style.color = this.styleDeclaration.color || `white`;
      (this.el.nativeElement as HTMLElement).style.display = this.styleDeclaration.display || `flex`;
      (this.el.nativeElement as HTMLElement).style.justifyContent = this.styleDeclaration.justifyContent || `center`;
      (this.el.nativeElement as HTMLElement).style.alignItems = this.styleDeclaration.alignItems || `center`;
      (this.el.nativeElement as HTMLElement).style.fontSize = this.styleDeclaration.fontSize || `small`;
    } else {
      (this.el.nativeElement as HTMLElement).style.backgroundImage = this.currentUserImage;
    }
  }

  formattedName(user: User): string {
    if (!user) {
      return '';
    } else if (user.name) {
      const userNames = user.name.split(' ');
      const names = this.searchAndFormatName(userNames);
      let fn = names[0][0];
      let ln = names[1][0];
      return (fn + ln).toLocaleUpperCase();
    }

    return user?.username ? user.username.split(' ')[0][0].toLocaleUpperCase() : '';
  }

  searchAndFormatName(names: string[]): string[] {
    let userNames = [];
    for (let i = 0; i < names.length; i++) {
      if (names[i] != '') {
        userNames.push(names[i]);
      }
    }
    return userNames;
  }
}
