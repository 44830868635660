import { Component } from '@angular/core';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MessageData, Token, User } from '../../core/models';
import { DiagnosticService } from '../../services/diagnostic.service';
import { ToasterService } from '@src/app/services/toaster.service';
import { GeolocationService } from '@src/app/modules/general/geolocation/geolocation/geolocation.service';
import { GenericModalService } from '@src/app/modules/core/generic-modal-dialog/generic-modal/generic-modal.service';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { SessionService } from '@src/app/services/session.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [LoginService],
})
export class LoginComponent {
    loading = false;
    returnUrl: string;
    accessToken: string;
    isSignup: boolean;
    clientId: string;

    accountInfo = <MessageData>{
        title: '',
        message: ''
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loginService: LoginService,
        private authService: AuthService,
        private toastr: ToasterService,
        private geolocationService: GeolocationService,
        private diagnostic: DiagnosticService,
        private userService: UserService,
        private sessionService: SessionService,
        private genericModalService: GenericModalService) {

        this.execute();
    }

    execute() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
        this.accessToken = this.route.snapshot.queryParams['token'];
        this.isSignup = this.route.snapshot.queryParams['isSignup'] === 'true';
        const action = String(this.route.snapshot.queryParams['action']).toLowerCase()

        switch (action) {
            // case 'resolve': {
            //     if (this.authService.isAuthenticated()) {
            //         this.router.navigate(['/home']);
            //     } else {
            //         this.gotoExternalLogin();
            //     }

            //     break;
            // }
            case 'logout': {
                this.loginService.logout();
                this.gotoExternalLogin({ action: 'logout' });
                break;
            }

            default: {
                this.loading = true;
                this.geolocationService.UpdateCoordinates();
                if (this.accessToken) { // new login
                    let token = <Token>{ token_type: 'bearer', access_token: this.accessToken };
                    this.login(token);
                }

                else if (this.authService.isAuthenticated()) { // old auto-login (local)

                    //TODO - this will still be fixed and updated
                    if (this.returnUrl.includes('returnUrl')) {
                        this.router.navigate(['/home']);
                    } else {
                        this.router.navigate([this.returnUrl || '/home']);
                    }
                }
                else {
                    this.gotoExternalLogin();
                }
            }
        }
    }

    login(token: Token) {
        this.loginService.login(token, this.geolocationService.GetCachedPosition())
            .subscribe(
                res => {
                    if (res.status == 200) {
                        const user = res.body as User;

                        if (user?.responseCode == 202) {
                            this.reactivateAccount(user);
                            return;
                        }

                        if (user?.responseCode == 205) {
                          this.restoreDeletedAccount(user);
                          return;
                        }

                        if (user?.responseCode === 204) { // Unregistered user
                            this.router.navigate(['/signup'], { queryParams: { 'token': this.accessToken, 'returnUrl': this.returnUrl } });
                            return;
                        }
                         
                        if (this.isSignup) {
                            this.router.navigate([this.returnUrl || '/home'], { queryParams: { 'isSignup': this.isSignup } });
                        }
                        else {
                            this.router.navigate([this.returnUrl || '/home']);
                        }
                    }
                },
                error => {

                    this.loading = false;
                   
                    if (error.status === 401) { // Unauthorized login / expired token
                        this.gotoExternalLogin();
                    }

                    else {
                        this.diagnostic.error(this.diagnostic.handleError(error));
                        this.toastr.error('An unknown login error has occurred');
                    }
                });
    }

    gotoExternalLogin(parameters: {} = { returnUrl: this.returnUrl }) {
        this.loginService.gotoExternalLogin(parameters);
    }

    reactivateAccount(user: User) {
        this.accountInfo.title = 'Reactivate Account';
        this.accountInfo.message = 'This action will reactivate your account. Please confirm or decline.';
        this.accountInfo.showOption = true;
        this.accountInfo.infoIcon = true;

        this.genericModalService.open(this.accountInfo).subscribe((result) => {
            if (result == true) {
                this.reactivateUser(false, user);
            } else {
                this.router.navigate(['/login'], { queryParams: { action: 'logout' } });
            }
        });
    }

    reactivateUser(isDeactivated: boolean, user: User) {
        this.userService.activate(isDeactivated, user?.sessions[0]?.token)
            .subscribe(
                response => {
                    this.successfulReactivateInfo(user);
                  // this.router.navigate(['/login'], { queryParams: { action: 'logout' } });
                },
                error => {
                    if (error.status == 404) { alert(error.error.message); }
                    else { alert('An unknown error has occurred, please try again later.'); }
                });
    }

    successfulReactivateInfo(user: User) {
        this.accountInfo.title = 'Account re-activaated';
        this.accountInfo.message = 'Your account is now re-activated.';
        this.accountInfo.showOption = false;
        this.accountInfo.infoIcon = false;
        this.accountInfo.infoIcon = false;
        this.genericModalService.open(this.accountInfo).subscribe((response) => { 
            if(response){
            this.sessionService.initialize(user);
            this.router.navigate([this.returnUrl || '/home']);
         }});
    }

    restoreDeletedAccount(user: User){
        this.accountInfo.title = 'Deleted Account';
        this.accountInfo.message = 'This action will restore your deleted account. Please confirm or decline.';
        this.accountInfo.showOption = true;
        this.accountInfo.infoIcon = true;

        this.genericModalService.open(this.accountInfo).subscribe((result) => {
            if (result == true) {
                this.restoreDeletedUser(false, user);
            } else {
                this.router.navigate(['/login'], { queryParams: { action: 'logout' } });
            }
        });
    }

    restoreDeletedUser(isDeleted: boolean, user: User) {
        this.userService.restore(isDeleted, user?.sessions[0]?.token)
            .subscribe(
                response => {
                    this.successfulRestoredInfo(user);
                },
                error => {
                    if (error.status == 404) { alert(error.error.message); }
                    else { alert('An unknown error has occurred, please try again later.'); }
                });
    }

  successfulRestoredInfo(user: User) {
        this.accountInfo.title = 'Account restored';
        this.accountInfo.message = 'Your account is now restored.';
        this.accountInfo.showOption = false;
        this.accountInfo.infoIcon = false;
        this.genericModalService.open(this.accountInfo).subscribe((response) => { 
            if(response){
            this.sessionService.initialize(user);
            this.router.navigate([this.returnUrl || '/home']);
         }});
        return;
    }

}

