import { Injectable } from '@angular/core';
import { MessageData } from 'src/app/core/models';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GenericModalComponent } from './generic-modal.component';

@Injectable({
  providedIn: 'root'
})

export class GenericModalService {

  modalRef: MdbModalRef<GenericModalComponent> | null = null;

  constructor(private modalService: MdbModalService,) { }

  public open(options: MessageData): Observable<any> {
    this.modalRef = this.modalService.open(GenericModalComponent, {
      modalClass: 'modal-dialog-centered modal-sm',
      data: {
        title: options.title,
        message: options.message,
        showOption: options.showOption,
        infoIcon: options.infoIcon
      }
    });

    // return this.modalRef.close()

    return this.modalRef.onClose.pipe(take(1), map(res => {
      options.showOption = true
      return res
    }));
  }

}
