<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
    </div>

    <div class="modal-body">
        <div class="body-content">
            <span class="wrapper-text">You are currently blocking <span class="username">{{user?.name}}</span>,
                Unblock and Continue</span>
        </div>
        <div class="align-self-center d-flex">
            <!-- <button class="btn btn-outline-primary cancel-btn" (click)="modalRef.close()">
                <span>Cancel</span>
            </button> -->
            <button class="btn btn-primary">
                <span class="" (click)="unblockAndConnect(user, action)">Unblock & Continue</span>
            </button>
        </div>
    </div>
</div>