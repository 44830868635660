import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { IndividualConfig, ToastService } from 'ng-uikit-pro-standard';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastr: ToastService) { }

  //functions can be extended when necessary
  success(message?: string, title?: string, toastConfig?: {}) {
    if (!environment.production) {
      this.toastr.success(message, toastConfig)
    }
  }

  warning(message?: string, title?: string, toastConfig?: {}) {
    if (!environment.production) {
      this.toastr.warning(message, title, toastConfig)
    }
  }

  error(message?: string, title?: string, toastConfig?: {}) {
    if (!environment.production) {
      this.toastr.warning(message, title, toastConfig)
    }
  }


  info(message?: string, title?: string, toastConfig?: {}) {
    if (!environment.production) {
      this.toastr.info(message, title, toastConfig)
    }
  }
}
