// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: 'https://dev-api-corchat.azurewebsites.net/api/',
  centralsocket: 'https://dev-api-corchat.azurewebsites.net/centralsocket',
  authApiUrl: 'https://dev-api-auth-seech.azurewebsites.net/api/',
  authWebURL: 'https://dev-auth.seech.com',
  accountWebURL: 'https://dev-account.seech.com',
  clientID: '78b28b63-353c-e811-82da-34e6ad5c2703',
  googleAPIKey: "AIzaSyBXozDBQk-HzM3s9bzeTc5XfvVGaYLNvnA",
  googleAnalyticsId: 'G-533GZT6SR2',
};
  /*
* In development mode, to ignore zone related error stack frames such as
* `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
* import the following file, but please comment it out in production mode
* because it will have performance impact when throw error
*/
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
