import { FeatureflagService } from './../services/featureflag.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { featureFlags } from '../core/Constants';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private authService: AuthService, private featureflagService: FeatureflagService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (route.data?.flag) {
      const active = this.featureflagService.isActive(route.data?.flag);
      if (!active) {
        this.router.navigate(['/home']);
        return false;
      }
    }

    if (route.data?.anonymous === true) return true;

    if (this.authService.isLoggedIn()) {

      // let origin = window.location.origin || (window.location.protocol + '//' + window.location.host);
      // let current_url = origin + state.url;
      // let referrer = window.location.href;

      // if (referrer == current_url) { return true; }
      //TODO: troubleshoot to ensure it works
      // this.router.navigate(['/authenticate'], { queryParams: { returnUrl: state.url }, skipLocationChange: true });
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.canActivate(route, state);
  }
}
