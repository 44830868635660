import { Component, OnInit, OnDestroy } from '@angular/core';
import { connectionType, pagination } from '@src/app/core/Constants';
import { Pagination, User } from '@src/app/core/models/model';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { SignalrService } from '@src/app/services/signalr.service';
import { SuggestionService } from '@src/app/services/suggestion.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MainMasterService } from '../main-master.service';

@Component({
  selector: 'app-widget-pinned-users',
  templateUrl: './widget-pinned-users.component.html',
  styleUrls: ['./widget-pinned-users.component.scss']
})
export class WidgetPinnedUsersComponent implements OnInit, OnDestroy {

  loading = true;
  users: User[] = [];
  unsubscribe$ = new Subject<void>();

  styleDeclaration = <CSSStyleDeclaration>{
    paddingTop: '5px',
    fontSize: 'medium',
  }

  constructor(private userService: UserService, private suggestionService: SuggestionService, private mainMasterService: MainMasterService, private signalrService: SignalrService) {
    this.mainMasterService.updateSync()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getPinSuggestions();
      });
      this.signalrService.interactionUpdate.subscribe(res=>{
        if(res && this.users.length > 0){
          const index = this.users.findIndex(x => x.id == res.recepientId);
          this.dismiss(res.recepientId)
        }
      })
  }

  ngOnInit(): void {
    this.getPinSuggestions();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPinSuggestions() {
    const pag = <Pagination>{ page: pagination.page, size: pagination.size }
    this.suggestionService.getPinSuggestions(pag).subscribe(res => {
      this.users = res;
      this.loading = false;
    });
  }

  pin(user: User) {
    user['pinInProgress'] = true;

    this.userService.pin(user.id, user.pinStatus?.action)
      .pipe(finalize(() => user['pinInProgress'] = false))
      .subscribe((status) => {
        const index = this.users.findIndex(x => x.id == user.id);
        this.users.splice(index, 1);
      });
  }

  dismiss(userId) {
    const index = this.users.findIndex(user => user.id == userId)
    const dismissed = this.users.splice(index, 1)
  }
}
