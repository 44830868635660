import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  connectionActions,
  connectionStatus,
  connectionType,
  invitationTypes,
  pagination,
  Types,
} from '@src/app/core/Constants';
import { NotificationMessage, PendingRequestModel } from '@src/app/core/models/model';
import { SignalrService } from '@src/app/services/signalr.service';
import { NotificationsService } from '../../notification/notifications.service';
import { UserService } from '../../user/user.service';
import { PendingRequestService } from '../pending-requests.service';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
})
export class ConnectionComponent implements OnInit {
  @Input() notifications: NotificationMessage[] = [];
  @Input() profileSummary?: boolean;

  query: string = '';
  page = pagination.page;
  size = pagination.size;
  types = Types;
  styleDeclaration = <CSSStyleDeclaration>{
    textAlign: 'center',
    paddingTop: '3px',
    fontSize: 'x-large',
    color: 'white',
  };
  connectionLimit: 0;
  connectionStatus = connectionStatus;
  loading: boolean = false;

  constructor(
    private notificationService: NotificationsService,
    private userService: UserService,
    private signalrService: SignalrService,
    private cdr: ChangeDetectorRef,
    private pendingRequestService: PendingRequestService
  ) {
    this.signalrService.retrieveNotification().subscribe((msg: NotificationMessage) => {
      if (msg?.id) {
        this.notifications = [...new Set([msg, ...this.notifications])];
        this.cdr.detectChanges();
      }
    });
    this.signalrService.interactionUpdate.subscribe((res) => {
      if (res && this.notifications.length > 0) {
        const index = this.notifications.findIndex(
          (x) => x.localNotificationdata?.localNotificationId == res.notificationId
        );
        if (res.relationship.toLowerCase() === connectionType.connected) {
          this.notifications[index].localNotificationdata.connectionStatus = this.connectionStatus.connected;
          this.notifications[index].isAccepted = true;
          this.notifications[index].isIgnored = false;
        }else{
          this.notifications[index].localNotificationdata.connectionStatus = this.connectionStatus.connected;
          this.notifications[index].isAccepted = false;
          this.notifications[index].isIgnored = true;
        }
      }
    });
  }

  ngOnInit(): void {
    // if(this.profileSummary){
    //   this.getProfileRequestNotifications(this.page, this.size);
    // }
  }

  getProfileRequestNotifications(invitationType: string, pageNumber: number, size: number, query: string = '') {
    this.loading = true;
    this.notificationService.getInvitationByType(invitationType, pageNumber, size, query).subscribe(
      (response) => {
        this.loading = true;
        if (response.length > 0) {
          if (this.notifications.length > 0 && query) {
            this.notifications = [];
            this.notifications = [...new Set([...response, ...this.notifications])];
          } else {
            this.notifications = response;
          }
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  connect(notification: NotificationMessage) {
    notification.isDisabled = true;
    let partner_id = notification.localNotificationdata.id;
    this.userService.profileRequest(partner_id, connectionActions.connect, notification.localNotificationdata.notificationId).subscribe((status) => {
      notification.localNotificationdata.connectionStatus = this.connectionStatus.connected;
      notification.isAccepted = true;
      notification.isIgnored = false;
      var pendingRequest: PendingRequestModel = {
        id: notification.localNotificationdata.localNotificationId,
        response: notification.isAccepted,
        connectionStatus: this.connectionStatus.connected,
        type: invitationTypes.ProfileRequestInvitations,
      };
      this.notifications = this.pendingRequestService.snycResponse(pendingRequest, this.notifications);
      notification.isDisabled = false;
    }, error => {
      notification.isDisabled = false;
    });
    this.cdr.detectChanges();
  }

  ignore(notification: NotificationMessage) {
    notification.isDisabled = true;
    let partner_id = notification.localNotificationdata.id;
    this.userService.profileRequest(partner_id, connectionActions.ignore, notification.localNotificationdata.notificationId).subscribe((status) => {
      if (status?.response?.relationship == '') {
        status.response.relationship = this.connectionStatus.ignored;
      }
      notification.localNotificationdata.connectionStatus = status?.response?.relationship;
      notification.isIgnored = true;
      notification.isAccepted = false;
      var pendingRequest: PendingRequestModel = {
        id: notification.localNotificationdata.localNotificationId,
        response: notification.isIgnored,
        connectionStatus: this.connectionStatus.ignored,
        type: invitationTypes.ProfileRequestInvitations,
      };
      this.notifications = this.pendingRequestService.snycResponse(pendingRequest, this.notifications);
      notification.isDisabled = false;
    },error => {
      notification.isDisabled = false;
    });
    this.cdr.detectChanges();
  }

  viewNotification(notificationId: string) {
    this.notificationService.viewNotification(notificationId).subscribe((response) => {
      if (response) {
        const notification = this.notifications.find((c) => c.id == notificationId);
        let index = this.notifications.indexOf(notification);
        this.notifications[index].isRead = true;
        this.cdr.detectChanges();
      }
    });
  }
}
