import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@src/app/core/config';
import { MessageUploadOptions, RestrictionSettings } from '@src/app/core/Constants';
import { BlobData, BlobEmit, IGenericObject, Message } from '@src/app/core/models/model';
import { BlobCarouselComponent } from '@src/app/modules/general/media/blob-carousel/blob-carousel.component';
import { FileUploadComponent } from '@src/app/modules/general/media/file-upload/file-upload.component';
import { EmojiControlService } from '@src/app/services/emoji-control.service';
import { MessagingService } from '@src/app/services/messaging.service';
import { ToasterService } from '@src/app/services/toaster.service';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-message-file-upload',
  templateUrl: './message-file-upload.component.html',
  styleUrls: ['./message-file-upload.component.scss'],
})
export class MessageFileUploadComponent implements OnInit {
  postFiles: BlobData[];
  threadId: string;
  userMessage: Message = {};
  recepientId: string;
  messageBody: string = '';
  isEmojiPickerVisible: boolean;
  isFloatingEmojiBox: boolean = true;
  multiImage: boolean = true;
  showFullVisibleMediaWithOverlay: boolean = true;
  showFullVisibleMediaInNonMultiImageLightBox: boolean = true;
  typeOption: string;
  emojiStyle: IGenericObject = {};
  readonly category: string = Constants.Post;

  @ViewChild(FileUploadComponent, { static: false })
  private fileUploadComponent: FileUploadComponent;

  @ViewChild(BlobCarouselComponent, { static: false })
  private blobCarouselComponent: BlobCarouselComponent;

  constructor(
    public modalRef: MdbModalRef<MessageFileUploadComponent>,
    public messagingService: MessagingService,
    private router: Router,
    private toasterService: ToasterService,
    private emojiControlService: EmojiControlService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      this.modalRef.close();
    });
  }

  upload() {
    const existingFileCount = this.blobCarouselComponent.blobs.filter((x) => x.isDeleted != true).length;
    this.fileUploadComponent.launch_uploader({
      multiple: true,
      uploadCountLimit: RestrictionSettings.PostFileUploadCountLimit,
      existingFileCount,
    });
  }

  addFile(file: BlobEmit) {
    this.blobCarouselComponent.blobs.unshift(file.blob);
  }

  hasBlob() {
    return this.blobCarouselComponent?.blobs?.length > 0;
  }

  sendMessage() {
    let blobs = this.blobCarouselComponent.getNewBlobs();
    this.userMessage.blobs = blobs;
    this.userMessage.body = this.messageBody;
    switch (this.typeOption) {
      case MessageUploadOptions.fromMessagePage: {
        this.messagingService.messageEvent.emit(this.userMessage);
        break;
      }
      case MessageUploadOptions.fromMessageWidget: {
        this.messagingService.messageWidgetEvent.emit(this.userMessage);
        break;
      }
      case MessageUploadOptions.fromNewMessageWidget: {
        this.messagingService.newMessageWidgetEvent.emit(this.userMessage);
        break;
      }
    }
    this.modalRef.close();
  }

  addEmoji(event) {
    this.messageBody = `${this.messageBody}${event.emoji.native}`;
  }

  // Handle Emoji Toggle and Position
  toggleEmoji(event: MouseEvent) {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
    const {
      spaceRemainderToTheBottom,
      buttonTop,
      buttonLeft,
      emojiBoxWidth,
      emojiBoxHeight,
      viewportWidth
    } = this.emojiControlService.getEmojiButtonCoordinates(event);

    let top = '';
    let bottom = '';
    let left = '';

    if(spaceRemainderToTheBottom > (emojiBoxHeight + 20)) {
      if(this.isFloatingEmojiBox) {
        top = 'unset'; 
        bottom = `${spaceRemainderToTheBottom - (emojiBoxHeight + 20)}px`;
      } else {
        top = '30px';
        bottom = 'unset';
      }
    } else {
      if(this.isFloatingEmojiBox) {
        top =  `${buttonTop - (emojiBoxHeight + 20)}px`;
        bottom = 'unset';
      } else {
        top = 'unset';
        bottom = '30px';
      }
    }

    if(this.isFloatingEmojiBox) {
      if(viewportWidth < 400) {
        left = '15px'
      } else {
        left = `${(buttonLeft + 20) - emojiBoxWidth}px`;
      }
    } else {
      left = 'unset';
    }

    if(this.isEmojiPickerVisible) {
      this.emojiStyle = {
        position: this.isFloatingEmojiBox ? 'fixed' : 'absolute',
        top: top,
        bottom: bottom,
        left: left,
        right: this.isFloatingEmojiBox ? 'unset' : '-6px'
      }
    } else {
      this.emojiStyle = {};
    }
  }
}
