import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UtilitiesService } from '@src/app/services/utilities.service';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { PostService } from '../../feature/post/post.service';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss']
})
export class EmbedComponent implements OnInit {
  @Input() postId: string;
  postUrl: string;
  includeComment: boolean = false;

  @ViewChild('embedFrame') embedFrame: ElementRef;

  constructor(public modalRef: MdbModalRef<EmbedComponent>, private utilitiesService: UtilitiesService, private postService: PostService) { }

  ngOnInit(): void {
    this.getPostUrl();
  }

  getPostUrl() {
    this.postUrl = this.postService.returnEmbedLink(this.postId, this.includeComment);
  }

  copyLink() {
    const text = this.utilitiesService.removeQuotes(JSON.stringify(this.embedFrame.nativeElement.value));
    this.utilitiesService.copyToClipboard(text).subscribe(success => {
      if (success) this.modalRef.close()
    });
  }

  updateUrl() {
    this.getPostUrl();
  }



}
