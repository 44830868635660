import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { StorageService } from './storage.service';
import { SignalrService } from './signalr.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { User, SessionIdleConfig, DialogData } from '../core/models';
import { CORE_ROOT_CONFIG } from './../../app/app.config'
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';
import { concatMap, filter, map, retry } from 'rxjs/operators';
import { ApiUrls } from '../core/config';
import { GenericModalService } from '../modules/core/generic-modal-dialog/generic-modal/generic-modal.service';
import { environment } from '@src/environments/environment';
import { NetworkService, SpeedTest } from './network.service';
import { UtilitiesService } from './utilities.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private sessionUrl = ApiUrls.Session;
  private get user() { return this.storageService.getCurrentUser(); }
  private get session() { return this.getCurrentSession(); }

  //-------------------------------------storage
  sessionIdleConfig: SessionIdleConfig = {
    interval: CORE_ROOT_CONFIG.sessionIdleConfig.interval,
    countdown: CORE_ROOT_CONFIG.sessionIdleConfig.countdown
  };
  
  worker: Worker = new Worker('../../assets/scripts//worker.js');
  countdown: number;
  logoutTrigger: any;
  private renderer: Renderer2;

  private keepaliveRequestQueue = new Subject<Observable<any>>();
  private speedtestRequestQueue = new Subject<Observable<any>>();

  constructor(private storageService: StorageService,
    private signalrService: SignalrService,
    private modalService: GenericModalService,
    private networkService: NetworkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private utilitiesService: UtilitiesService,
    private rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.keepaliveRequestQueue.pipe(concatMap(requestObservable => requestObservable)).subscribe();

    // this.speedtestRequestQueue.pipe(concatMap(requestObservable => requestObservable))
    //     .subscribe((data) => {
    //         const currentSpeed = JSON.stringify({ internetSpeed: data, lastChecked: new Date() } as SpeedTest);
    //         this.storageService.set(NetworkOptions.speedValue, currentSpeed);
    //     });

    //this.sessionIdleConfig = this.config.sessionIdleConfig || { } as SessionIdleConfig;
    //this.countdown = this.sessionIdleConfig.countdown;
    //setTimeout(this.interval = setInterval(this.update_location, this.location_interval), this.location_interval);

    if (isPlatformBrowser(this.platformId)) {
      this.renderer.listen('window', 'storage', (event) => {
        if (!this.isValidLogin()) {
          const dialog = this.modalService.open({
            title: 'Session Canceled.',
            message: 'You have been logged out because of a login or logout activity from another browser tab.',
            showOption: false
          });
          dialog.subscribe(() => this.gotoExternalLogin());
        }
      });
    }
  }

  public initialize(user: User) {
    this.storageService.initSession(user); // user data and connection Id is in here
    this.initActivityTimer();
  }

  public connect() {
    this.signalrService.start();
  }

  public end() {
    this.storageService.endSession();
    this.signalrService.stop();
  }

  public reset() {
    this.storageService.reset();
    this.signalrService.stop();
  }

  getCurrentSession() {
    return this.storageService.getCurrentSession();
  }
  
  getCurrentPosition() {
    return this.storageService.getCurrentPosition();
  }

  public isValidLogin(): boolean { // Check if session is still valid
    if (!this.session?.token || (this.user?.id != this.session?.userID)) {
      return false;
    }

    return true;
  }

  gotoExternalLogin(parameters = {}) {
    this.storageService.reset();

    if (!environment.production) parameters['client_id'] = environment.clientID; // needed for localhost / debugging only

    const params = parameters ? '&' + Object.keys(parameters)
                                            .map(x => x)
                                            .map(key => `${key}=${parameters[key]}`)
                                            .join('&') : '';
    
    const origin = this.utilitiesService.getOrigin();
    const redirectUrl = `${origin}/login${params}`;
    window.location.href = `${environment.authWebURL}/login?redirect=${redirectUrl}`;


    //window.open(environment.authWebURL + '/login', '_blank').focus();

    //let loginBtn = document.createElement("a");
    //loginBtn.target = "_blank";
    //loginBtn.href = environment.authWebURL + "/login";
    //loginBtn.click();
  }

  //=============================== Activity Timer ==================================

  private initActivityTimer() {
    if (this.sessionIdleConfig.countdown && this.sessionIdleConfig.interval) {

      this.worker.onmessage = (event: any) => {
        switch (event.data.action) {
          case 'countdown':
            this.startWarning();
            break;
        }
      };

      // observe the active route and refresh the activity timer any time it changes
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          this.refreshActivityTimer();
          while (route.firstChild) { route = route.firstChild; }
          return route;
        })).subscribe();

      this.refreshActivityTimer();
    }
  }

  private startWarning() {
    const interval = 1000;
    this.countdown = this.sessionIdleConfig.countdown;

    this.logoutTrigger = setInterval(() => {

      if (this.countdown > 0) {
        this.countdown -= interval;
        this.updateMessage();
      }

      else {
        this.resetTimers();
        this.end();
      }
    }, interval);

    this.showWarningDialog();
  }

  refreshActivityTimer() {
    this.resetTimers();
    this.postWorkerMessage({ action: 'refresh', value: this.sessionIdleConfig.interval });
    // todo
    // http call to keeplive and update ; lastseen on the user object to current time (UTC), 
    // also update the LastUpdated of the connection object
    // also update lastseen on session

    //note - the session Id is persisted between UI and api
    // pass the session Id in the keep alive method

    this.keepCurrentSessionAlive();
  }

  private resetTimers() {
    if (this.logoutTrigger) {
      clearInterval(this.logoutTrigger);
    }
  }

  postWorkerMessage(data: any) {
    this.worker.postMessage(data);
  }

  private updateMessage() {
    // this.dialogSvc.updateMessage(this.getMessage());
  }

  private showWarningDialog() {
    const data: DialogData = {
      title: 'SESSION TIMEOUT WARNING',
      message: this.getMessage(),
      confirmText: 'CONTINUE',
      cancelText: 'LOGOUT',
    };

    // this.dialogSvc.open(data).subscribe(
    //   (proceed: boolean) => { proceed ? this.refreshActivityTimer() : this.end() },
    // );
  }

  private getMessage(): string {
    const minutes = Math.floor((this.countdown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((this.countdown % (1000 * 60)) / 1000);

    return `Idle session warning. Logging out in ${minutes > 0 ? (minutes + ' minute') : ''} ${seconds} seconds`;
  }

  keepCurrentSessionAlive() {
    const session = this.storageService.getCurrentSession();
    const { id, lastSeen } = session;
    const throttle = 1000 * 60 * 5; // minimum update interval (5 minutes)

    if (id && Math.abs(new Date().valueOf() - new Date(lastSeen).valueOf()) > throttle) {
      session.lastSeen = new Date();
      this.storageService.updateSession(session);
      const request = this.http.get<any>(`${this.sessionUrl}/${id}`).pipe(retry(2));
      this.keepaliveRequestQueue.next(request);
    }
  }

  updateInternetSpeed() {
    const cachedSpeed = this.networkService.getCachedInternetSpeed();
    let lastChecked: number;
    const throttle = 1000 * 60 * 15; // minimum update interval (15 minutes)

    if (cachedSpeed) {
      const speed = JSON.parse(cachedSpeed) as SpeedTest;
      lastChecked = Math.abs(new Date().valueOf() - new Date(speed.lastChecked).valueOf());
    }

    if(!cachedSpeed || (lastChecked > throttle)) {
      const request = this.networkService.getCurrentInternetSpeed();
      this.speedtestRequestQueue.next(request);
    }
  }
  
  updateSessionStates() {
    this.keepCurrentSessionAlive();
   // this.updateInternetSpeed();
  }
}  
