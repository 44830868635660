import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';
import { ToasterService } from '@src/app/services/toaster.service';
import { ApiUrls, Constants, ShapeOptions } from '@src/app/core/config';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { Geotag, SavedLocation } from '@src/app/core/models/model';
import { StorageService } from '@src/app/services/storage.service';

declare var google;

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {

    //private diagnosticsUrl = ApiUrls.DiagnosticsUrl;
    key: string = "key=" + Constants.Google_API_Key;
    updatingLocation: boolean = false;
    shape_options = ShapeOptions;

    constructor(private http: HttpClient, private diagnostic: DiagnosticService, private toast: ToasterService, private storageService: StorageService) { }

    set_map_url(coords: string) {
        let map_url: string = ApiUrls.Google_maps_API_URL + "staticmap?center=" + coords + "&markers=color:red%7C" + coords + "&zoom=17&size=800x600&key=" + Constants.Google_API_Key;
        return map_url;
    }

    GetSavedLocations(): SavedLocation[] {

        let user = this.storageService.getCurrentUser();

        if (user) {
            if (user.savedLocations && user.savedLocations.length > 0) {
                return user.savedLocations;
            }

            else { this.diagnostic.displayMessage('No saved location(s) found.') }
        }

        else { this.diagnostic.displayMessage('Current user not found.') }
    }

    GetDefaultLocation(): SavedLocation {

        let savedLocations = this.GetSavedLocations();

        if (savedLocations && savedLocations.length > 0) {
            for (let location of savedLocations) {
                if (location.isDefault) {
                    return location;
                }
            }

            if (!environment.production) this.toast.error('DEBUG => default location not set')
        }

        else { if (!environment.production) this.toast.error('DEBUG => no saved location found in storage') }

        return {}; 
    }

    GetCachedPosition(): string {
        this.UpdateComplete();
        return this.storageService.getCurrentPosition();
    }

    CachedCurrentGeo(): Geotag {
        return this.storageService.getCurrentGeo();
    }

    GetCurrentPosition(): Observable<string> {
        return Observable.create(observer => {
            if (navigator.geolocation) {
                this.updatingLocation = true;

                let options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                };

                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        observer.next(this.formatCoords(position));
                        observer.complete();
                    },
                    (error) => {
                        let cached_current_position = this.GetCachedPosition();
                        if (cached_current_position) {
                            observer.next(cached_current_position);
                            observer.complete();
                        }

                        else { this.fallbackCurrentPosition(observer); }
                    });
            }

            else { this.fallbackCurrentPosition(observer); } // alert("Geolocation is not supported by this browser.")
        });
    }

    private fallbackCurrentPosition(observer: any) {
        this.fallback().subscribe((coordinates) => {
            observer.next(coordinates);
            observer.complete();
        });
    }

    private fallback(): Observable<string> {

        let geolocateURL: string = ApiUrls.Google_geolocate_API_URL;

        return this.http.post(`${geolocateURL}?${this.key}`, {}).pipe(
            map((position) => { return this.formatCoords(position, true); }),
            catchError((err) => { return this.CurrentLocationError(err); })
        );
    }

    private formatCoords(position: any, fallback?: boolean) {
        let coords = fallback ? (position.location.lat + ',' + position.location.lng) : (position.coords.latitude + ',' + position.coords.longitude);
        this.storageService.setCurrentPosition(coords);
        this.UpdateComplete();
        return coords;
    }

    private CurrentLocationError(error): string {
        if (!environment.production) this.toast.error('DEBUG => Error while retrieving location : ' + error.message + ' - ' + error.code + ' - ' + this.ShowError(error));
        this.UpdateComplete();
        return '';
    }

    private ShowError(error): string {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                return "Geolocation request denied by user."
            case error.POSITION_UNAVAILABLE:
                return "Location information is unavailable."
            case error.TIMEOUT:
                return "The request to get location timed out."
            case error.UNKNOWN_ERROR:
                return "Unknown error while trying to obtain current location."
        }
    }

    UpdateCoordinates() {
        this.GetCurrentPosition().subscribe();
    }

    private UpdateComplete() {
        this.updatingLocation = false;
    }

    ConvertDMSToDD(degrees, minutes, seconds, direction): string {

        let dd = degrees + minutes / 60 + seconds / (60 * 60);

        if (direction == "S" || direction == "W") {
            dd = dd * -1;
        } // Don't do anything for N or E

        return dd;
    }

    convertCoords(value: string, coord_type: string) {

        let decimal_degrees = '';

        if (coord_type == 'dms') {

            let parts = value.split(/[^\d\w]+/);
            let lat = this.ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
            let lng = this.ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);

            return decimal_degrees = lat + ',' + lng;
        }

        //if (coord_type == 'ddm') {
        //    return decimal_degrees = '';
        //}
    }

    processLocation(value: string) {

        let coords_dd_patt = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/; //47.64896,-122.34811
        let coords_dms_patt = /^[NS]([0-8][0-9](\.[0-5]\d){2}|90(\.00){2})\040[EW]((0\d\d|1[0-7]\d)(\.[0-5]\d){2}|180(\.00){2})$/; //36°57'9"N 110°4'21"W | N36°57'9" W110°4'21"
        //let coords_ddm_patt = /e/; //N47°38.938 W122°20.887 | 47°38.938N 122°20.887W

        if (coords_dd_patt.test(value)) {
            return { location: value, type: 'coordinates' };
        }

        else if (coords_dms_patt.test(value)) {
            return { location: this.convertCoords(value, 'dms'), type: 'coordinates' };
        }

        //else if (coords_ddm_patt.test(value)) {
        //    return { location: this.convertCoords(value, 'ddm'), type: 'coordinates' };
        //}

        else { return { location: value, type: 'address' }; }
    }

    GetGeocode(value: string, location_type: string, isCurrent?: boolean): Observable<any> {

        if (location_type == 'unknown') {
            let result = this.processLocation(value);
            value = result.location;
            location_type = result.type;
        }

        let geocodeURL: string = ApiUrls.Google_maps_API_URL + "geocode/json";

        if (location_type == 'coordinates') {
            var queryString = "latlng=" + value.replace(/"| /g, '');
        }

        else if (location_type == 'address') {
            var queryString = "address=" + value.replace(/ /g, '+');
        }

        return this.http.get(`${geocodeURL}?${queryString}&${this.key}`).pipe(
            map((res) => this.ExtractGeodata(res, location_type, value, isCurrent)),
            catchError(err => this.diagnostic.handleError(err))
        );
    }

    private ExtractGeodata(geodata: any, location_type: string, value: string, isCurrent?: boolean) {

        let location_set = { coordinates: '', address: '', location_type: '' };

        if (geodata.status == 'OK') {

            for (let geo of geodata.results) {

                if (geo.geometry.location && geo.formatted_address) {
                    location_set.address = geo.formatted_address;
                    location_set.coordinates = geo.geometry.location.lat + ',' + geo.geometry.location.lng;
                    location_set.location_type = location_type;
                    break;
                }
            }
        }

        else if (geodata.status == 'ZERO_RESULTS') {

            if (location_type == 'coordinates') {
                location_set.coordinates = value;
                location_set.address = 'UNKNOWN LOCATION';
                location_set.location_type = location_type;
            }

            else if (location_type == 'address') {
                this.toast.warning('Location not found or invalid, please try a different location');
            }

            else { this.toast.warning('Location not found') }
        }

        else { this.toast.warning(geodata.status); }

        if (isCurrent) {
            let geo = JSON.parse(JSON.stringify(location_set));
            delete geo['location_type'];
            this.storageService.setCurrentGeo(geo);
        }

        return location_set;
    }

    createLatLng(coordinates: string) {
        let coords = coordinates.split(",");
        return { lat: parseFloat(coords[0]), lng: parseFloat(coords[1]) };
    }

    containsLocation(position, boundary_image, google): boolean {
        let hasLocation = google.maps.geometry.poly.containsLocation(position, boundary_image);
        boundary_image.setOptions(hasLocation ? this.shape_options.Success : this.shape_options.Error);
        return hasLocation;
    }

    boundIsValid(coordinates: string, polygon): boolean {
        let coords = coordinates.split(",");
        let position = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]))
        let boundary_image = new google.maps.Polygon({ paths: polygon });

        return this.containsLocation(position, boundary_image, google);
    }
}
