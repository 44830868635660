import { NgForOf } from '@angular/common';
import { Directive, Host } from '@angular/core';

interface Item {
  id: string;
}

@Directive({
  selector: '[trackById]',
})
export class NgForTrackByIdDirective<T extends Item> {
  constructor(@Host() private ngFor: NgForOf<T>) {
    this.ngFor.ngForTrackBy = (index: number, item: T) => item.id;
  }
}
