import { HttpParams } from '@angular/common/http';
import { environment } from "../../environments/environment";


const { baseApiUrl, authApiUrl, googleAPIKey } = environment;


export interface IBlobUrls {
    readonly Upload: string;
    readonly Download: string;
}

export const BlobUrls: IBlobUrls = {
    Upload: "upload",
    //Upload: baseApiUrl + "upload",
    Download: baseApiUrl + "download",
};


export interface IConstants {
    readonly Google_API_Key: string;
    readonly Post: string;
    readonly Group: string;
    readonly User: string;
    readonly Event: string;
    readonly Message: string;
    readonly BlobImage: string;
    readonly BlobBanner: string;
}

export const Constants: IConstants = {
    Google_API_Key: googleAPIKey,
    Post: "Post",
    Group: "group",
    User: "User",
    Event: "Event",
    Message: "Message",
    BlobImage: "BlobImage",
    BlobBanner: "BlobBanner"
};


export interface IApiUrls {
    readonly Google_maps_API_URL: string;
    readonly Google_geolocate_API_URL: string;

    readonly SettingsUrl: string;
    readonly Session: string;
    readonly Signup: string;
    readonly Login: string;
    readonly User: string;
    readonly UserInteraction: string;
    readonly Users: string;
    readonly Messages: string;
    readonly Notification: string;
    readonly PostUrl: string;
    readonly PostsUrl: string;
    readonly SuggestionUrl: string;
    readonly PeopleUrl: string;
    readonly GroupPostUrl: string
    readonly GroupsPostUrl: string;
    readonly GroupUrl: string;
    readonly GroupsUrl: string;
    readonly GroupsMemberUrl: string;
    readonly AddGroupsMemberUrl: string;
    readonly GroupssMemberUrl: string;
    readonly GroupPermissionUrl: string;
    readonly CommentUrl: string;
    readonly CommentsUrl: string;
    readonly ReplyUrl: string;
    readonly RepliesUrl: string;
    readonly CommentGroupUrl: string;
    readonly CommentsGroupUrl: string;
    readonly ReplyGroupUrl: string;
    readonly RepliesGroupUrl: string;
    readonly DiagnosticsUrl: string;
    readonly Search: string;
    readonly Complaint: string;
    readonly EventUrl: string;
    readonly TrendUrl: string;
    readonly SavedLocationUrl: string;
    readonly GalleryUrl: string;
    readonly FeatureFlagUrl: string; 
    readonly Network: string;
    readonly AddFeedbackUrl: string;
    readonly FeedbackGroupUrl: string;
    readonly SupportFAQUrl: string;
    readonly TermsAndConditionUrl: string;
}

export const ApiUrls: IApiUrls = {
    Google_maps_API_URL: "https://maps.googleapis.com/maps/api/",
    Google_geolocate_API_URL: "https://www.googleapis.com/geolocation/v1/geolocate",

    SettingsUrl: "Settings",
    Session: "session",
    Signup: "account/signup",
    Login: "account/login",
    User: "user",
    UserInteraction: "user/interaction",
    Users: "users",
    Messages: "messages",
    Notification: "notification",
    PostUrl: "post",
    PostsUrl: "posts",
    SuggestionUrl: "suggestion",
    PeopleUrl: "people",
    GroupPostUrl: "groupPost",
    GroupsPostUrl: "groupPostMany",
    GroupUrl: "group",
    GroupsUrl: "groups",
    AddGroupsMemberUrl: "addgroupMember",
    GroupsMemberUrl: "groupMember",
    GroupssMemberUrl: "groupMembers",
    GroupPermissionUrl: "groupPerm",
    CommentUrl: "comment",
    CommentsUrl: "comments",
    ReplyUrl: "reply",
    RepliesUrl: "replies",
    CommentGroupUrl: "groupcomment",
    CommentsGroupUrl: "groupcomments",
    ReplyGroupUrl: "groupreply",
    RepliesGroupUrl: "groupreplies",
    Network: "Global/network",
    AddFeedbackUrl: "Support/feedback",
    FeedbackGroupUrl: "Support/lookup",
    SupportFAQUrl: "Support/faqs",
    TermsAndConditionUrl: "Support/terms-and-conditions",

    DiagnosticsUrl: "diagnostic",
    Search: "search",
    Complaint: "complaint",
    EventUrl: "event",
    TrendUrl: "trend",
    SavedLocationUrl: "savedlocation",
    GalleryUrl: "gallery",
    FeatureFlagUrl: "FeatureFlag"
};

export interface IShapeOptions {
    readonly Normal: {};
    readonly Success: {};
    readonly Error: {};
}

export const ShapeOptions: IShapeOptions = {
    Normal: {
        strokeColor: 'blue',
        fillColor: 'blue',
        fillOpacity: 0.2,
        strokeWeight: 2,
        clickable: false,
        editable: false,
        zIndex: 1,
        geodesic: true
    },
    Success: { strokeColor: 'green', fillColor: 'green', },
    Error: { strokeColor: '#FF0000', fillColor: '#FF0000', }
};

export const CONFIGURATION = { Constants, BlobUrls, ApiUrls, ShapeOptions }

export interface IPermissionSettings {
    readonly settings: string;
    readonly comments: string;
    readonly messagings: string;
    readonly invites: string;
    readonly posts: string;
}

export const PermissionSettings: IPermissionSettings = {
    settings: 'settings',
    comments: 'comments',
    messagings: 'messagings',
    invites: 'invites',
    posts: 'posts'
};

export interface IRole {
    readonly superAdmin: string;
    readonly admin: string;
    readonly member: string;
    readonly nonMember: string;
};

export const Role: IRole = {
    superAdmin: 'isSuperAdmin',
    admin: 'isAdmin',
    member: 'isMember',
    nonMember: 'isNonMember'
};

export interface ISettings {
    readonly Messaging: string;
    readonly Invitation: string;
};

export const Settings: ISettings = {
    Messaging: 'messaging',
    Invitation: 'invitation'
};

export interface IModalTracks {
    readonly GeoTagModal: string;
    readonly TextArea: string;
    readonly PostGeoTag: string;
    readonly PostGeoBoundary: string;
    readonly PostBlobComplete: string;
    readonly PostFormModal: string;
    readonly PostEditFormModal: string;
    readonly PostComplete: string;
    readonly FilesModal: string;
    readonly Modal: string;
    readonly CloseBlobModal: string;
    readonly SharedEntityModal: string;
    readonly PostReportFormModal: string;
    readonly PostEmbedModal: string;
    readonly PostEditGeoTag: string;
    readonly PostEditBoundary: string;
    readonly ViewGeoTagModal: string;
    readonly LocationGeoTagModal: string;
    readonly BlobDetailView: string;
    readonly LocationUpdate: string;
    readonly LocationCreate: string;
}


export const ModalTracks: IModalTracks = {
    GeoTagModal: "geoTagModal",
    TextArea: "textarea",
    PostGeoTag: "postGeoTag",
    PostGeoBoundary: "postGeoBoundary",
    PostBlobComplete: "postBlobComplete",
    PostFormModal: "postFormModal",
    PostEditFormModal: "postEditFormModal",
    PostComplete: "postComplete",
    FilesModal: "filesModal",
    Modal: "modal",
    CloseBlobModal: "CloseBlobModal",
    SharedEntityModal: "sharedEntityModal",
    PostReportFormModal: "postReportFormModal",
    PostEmbedModal: "postEmbedModal",
    PostEditGeoTag: "postEditGeoTag",
    PostEditBoundary: "postEditBoundary",
    ViewGeoTagModal: "viewGeoTagModal",
    LocationGeoTagModal: "locationGeoTagModal",
    BlobDetailView: "blobDetailView",
    LocationUpdate: "locationUpdate",
    LocationCreate: "locationCreate"
};

export interface IViewSettings {
    readonly GroupGeotag: string;
    readonly GroupGeoBoundary: string;
    readonly GroupBlobEdit: string;
    readonly EventGeotag: string;
    readonly EventGeoBoundary: string;
}

export const ViewSettings: IViewSettings = {
    GroupGeotag: 'groupGeotag',
    GroupGeoBoundary: 'groupGeoboundary',
    GroupBlobEdit: 'groupBlobEdit',
    EventGeotag: 'eventGeotag',
    EventGeoBoundary: 'eventGeoboundary'
};

export interface INotificationSettings {
    readonly EmailEnabled: string;
    readonly SmsEnabled: string;
    readonly PushEnabled: string;
}

export const NotificationSettingsConfig: INotificationSettings = {
    EmailEnabled: 'emailEnabled',
    SmsEnabled: 'smsEnabled',
    PushEnabled: 'pushEnabled'
}

export const SettingsType = {
    UserSettings: 'userSettings',
    NotificationSettings: 'notificationSettings'

}
interface IStringKeyDictionary<T> {
    [index: string]: T;
}

interface INumberKeyDictionary<T> {
    [index: number]: T;
}

export interface IProfileTabs {
    readonly Post: string;
    readonly Gallery: string;
    readonly Location: string;
    readonly Events: string;
    readonly Groups: string;
}

export const ProfileTabs: IProfileTabs = {
    Post: 'Posts',
    Gallery: 'Gallery',
    Location: 'Locations',
    Events: 'Events',
    Groups: 'Groups'
};

export const BANNER_PLACEHOLDER = './assets/images/banner-placeholder.png';
export const EVENT_PLACEHOLDER = './assets/images/event-placeholder.jpg';
export const GROUP_PLACEHOLDER = './assets/images/group-placeholder.jpg';

declare global {
    interface Array<T> {
        last(): T;
    }
}

if (!Array.prototype.last) {
    Array.prototype.last = function () {
        if (!this.length) {
            return undefined;
        }
        return this[this.length - 1];
    };
}

export type IDictionary<T> = IStringKeyDictionary<T> | INumberKeyDictionary<T>;
export class HttpParameters {

    static params(dict: IDictionary<Number | string>): HttpParams {
        let params = new HttpParams();
        for (const key of Object.keys(dict)) {
            params = params.append(key, dict[key]);
        }
        return params;
    }
}


