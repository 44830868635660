import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '@src/app/core/config';
import { User } from '@src/app/core/models/index';

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  private readonly peopleUrl = ApiUrls.PeopleUrl;
  constructor(private http: HttpClient) {}

  getPeople(query?, page?, size?) {
    const params = new HttpParams().set('query', query).set('page', page).set('size', size);

    return this.http.get<User[]>(`${this.peopleUrl}`, { params });
  }
}
