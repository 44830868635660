import { Injectable, EventEmitter } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Post, Message, CallData, NotificationMessage, InteractionUpdate } from '../core/models';
import { HeaderService } from './header.service';
import { AuthService } from './auth.service';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToasterService } from './toaster.service';
import { UserService } from '../modules/feature/user/user.service';


@Injectable({
    providedIn: 'root'
})


export class SignalrService {

    private get session() { return this.header.session; }
    private proxy: any;
    private connection: any;
    private state = { connecting: 0, connected: 1, reconnecting: 2, disconnected: 4 };
    public post = new EventEmitter<Post>();
    public message = new EventEmitter<Message>();
    public notifications = new BehaviorSubject<NotificationMessage>({});
    public messageNotification = new BehaviorSubject<Message>({});
    public interactionUpdate = new BehaviorSubject<InteractionUpdate>({});
    public postThread = new EventEmitter<string>();
    public callData = new EventEmitter<CallData>();
    public messageThreadSource = new BehaviorSubject<any[]>([])
    messageThread$ = this.messageThreadSource.asObservable();

    public messageSource = new BehaviorSubject<any[]>([])
    message$ = this.messageSource.asObservable();

    public chatWidgetMessages = new BehaviorSubject<any[]>([])
    chatMessages$ = this.chatWidgetMessages.asObservable();

    public chatWidgetMessageList = new BehaviorSubject<any[]>([])
    chatWigdetList$ = this.chatWidgetMessageList.asObservable();

    public currentlyTyping = new BehaviorSubject<any>({})
    senderIsTyping$ = this.currentlyTyping.asObservable();

    users$ = new BehaviorSubject([]);
    readonly coords: string = this.userService.get('coords');
    messageList: any[] = [];
    messages: any[] = [];
    messg: any = {};
    messageReceived = new EventEmitter<any>();
    messageThreadUpdateList = new EventEmitter<any>();
    messageId = ""


    constructor(
        private header: HeaderService,
        private authService: AuthService,
        private toastservice: ToasterService,
        private userService: UserService) { }

    // method to hit from client
    public Teleport(param: any) {
        if (this.authService.isAuthenticated()) {
            if (this.isDisconnected()) {
                this.connection.start().done(() => { this.Teleport(param); })
            }

            else {
                this.proxy.invoke(param.method, param.channel, JSON.stringify(param.data))
                    .fail(error => {
                        if (!environment.production) {
                            console.warn('DEBUG: Signalr => server method invocation failed : ' + param.method + ' - ' + JSON.stringify(error));
                        }

                        this.handleError(error).subscribe(
                            () => { if (error.data.status == 401) { this.Teleport(param); } }, // handle server side 401
                        );
                    });
            }
        }

        else { // handle client side 401
            this.handle401().subscribe(
                () => this.Teleport(param),
                () => {
                    if (!environment.production) console.warn('DEBUG: Signalr => token renewal error on server method invocation');
                }
            );
        }
    }

    public Transmit(channel: string, data: any) {
        this.Teleport({ method: 'Transmit', channel: channel, data: data });
    }

    public retrieveMessage(): Observable<Message> {
        return this.message.asObservable();
    }

    public retrivePostThread(): Observable<string> {
        return this.postThread.asObservable();
    }

    public retrieveNotification(): Observable<NotificationMessage> {
        return this.notifications.asObservable();
    }

    public retreiveMessages(): Observable<Message> {
        return this.messageNotification.asObservable()
    }

    private setupConnection() {
        this.connection = new signalR.HubConnectionBuilder().
            withUrl(`${environment.centralsocket}`, {
                accessTokenFactory: () => {
                    return this.header.get_signalr_qs()['Access-Token']
                } // If 2036 characters or greater, 404 responses is issued, configure API to increase querystring
            }).
            withAutomaticReconnect(). // Restart on disconnect after 5secs [5000]
            configureLogging(signalR.LogLevel.Information).build();
        this.connection.onclose(async () => {
            await this.startConnection();
        });
        this.registerOnServerEvents();
        //  this.receiveMessage()
    }

    private async startConnection() {
        if (this.authService.isAuthenticated()) {
            try {
                await this.connection.start();
            } catch (error) {
                console.log(`error: ${error}`);
                this.handleError(error).subscribe(
                    () => { this.startConnection(); },
                    error => {
                        if (!environment.production) {
                            console.warn('DEBUG: SignalR unable to connect => ' + JSON.stringify(error));
                        }
                    }
                );
            }
        }

        else { // handle client side 401
            this.handle401().subscribe(
                () => this.startConnection(),
                () => {
                    if (!environment.production) {
                        console.warn('DEBUG: signalR token renewal error (unable to connect)');
                    }
                }
            );
        }
    }


    public start() {
        if (this.isInSession()) {
            this.setupConnection();
            this.startConnection();
        }
    }

    private restart() {
        if (this.isInSession()) {

            if (this.authService.isAuthenticated()) {
                this.connection.start()
                    .done(() => console.log('Realtime connection established'))
                    .fail((error: any) => {
                        this.handleError(error).subscribe(
                            () => { this.restart(); },
                            error => {
                                if (!environment.production) {
                                    console.warn('DEBUG: SignalR reconnection failed => ' + JSON.stringify(error));
                                }
                            }
                        );
                    });
            }

            else { // handle client side 401
                this.handle401().subscribe(
                    () => this.restart(),
                    () => {
                        if (!environment.production) {
                            console.warn('DEBUG: Signalr => token renewal error on reconnect');
                        }
                    }
                );
            }
        }
    }

    public stop() {
        if (this.connection) {
            this.connection.stop();
        }
    }

    private registerOnServerEvents() {
        this.connection.on("push", (channel: string, event: any) => {
            switch (channel) {
                case 'post':
                    this.post.next(event);
                    break;
                case 'message':
                    //used to track duplicate messages
                    if (event.id != this.messageId) {
                        this.messageId = event.id
                        this.message.emit(event);
                        if(event.isRetracted === null)
                        { this.messageNotification.next(event) }
                    }
                    break;
                case 'call':
                    this.callData.next(event);
                    break;

                case 'notifications':
                    this.notifications.next(event);
                    break;

                case 'postThread':
                    this.postThread.emit(event);
                    break;

                case 'interaction':
                    this.interactionUpdate.next(event)
                    break

                case 'userInputAction':
                    this.currentlyTyping.next(event)
                    break

                default:
                    // default code here
                    break;
            }
        });

        this.connection.on('showMessage', (type: string, msg: string) => {
            if (!environment.production) {
                switch (type) {
                    case 'info':
                        this.toastservice.info('Realtime Connection Established');
                        break;
                    case 'warning':
                    case 'error':
                        console.warn('DEBUG: SignalR => ' + JSON.stringify(msg));
                        break;
                }
            }
        });
    }

    private isInSession(): boolean { return this.session && this.session.token ? true : false; } // check this to avoid reconnection attempt after logout

    private isDisconnected(): boolean {
        return (this.connection && this.connection.state === this.state.disconnected) ? true : false;
    }

    private handleError(error): Observable<any> {
        if (error.source === 'HubException') {

            console.error('SignalR Hub Exception => ' + error.message + ' : ' + error.data.status + ' - ' + error.data.userMessage);

            switch (error.data.status) {
                case 401:
                    return this.handle401();
                case 403:
                case 500:
                    return throwError(error);
                default:
                    setTimeout(() => { return of('retry'); }, 5000); // Trigger retry after 5 seconds
            }
        }

        else {
            console.error('Unknown signalr error : ' + JSON.stringify(error));
            return throwError(error);
        }
    }

    private handle401() {

        const refreshToken = this.header.refreshToken;

        if (refreshToken) {
            return this.authService.new_access_token(refreshToken).pipe(
                map(success => {
                    this.connection.qs = this.header.get_signalr_qs();
                    return success; //retry
                }),
                catchError(refreshTokenError => {
                    if (!environment.production) this.toastservice.warning('DEBUG: SignalR => login or session restart required');

                    // this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                    throw refreshTokenError;
                })
            );
        }
    }
}  
