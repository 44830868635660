<div class="mobile-widget-location-wrapper">
    <div class="mobile-widget-location-header">
        <button class="mobile-widget-location-close-btn" role="button" (click)="closeWidget($event)">
        <span class="mdi mdi-arrow-left text-white fs-4"></span>
        </button>
    </div>
    <div class="mobile-widget-location-body">
        <div class="mobile-widget-location-body-inner">
            <app-saved-locations-card></app-saved-locations-card>
        </div>
    </div>
</div>