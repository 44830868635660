import { HttpParams, HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiUrls, HttpParameters } from "@src/app/core/config";
import { Post, Geoboundary, Like, User, Save, SavedEntity } from "@src/app/core/models/model";
import { ToasterService } from "@src/app/services/toaster.service";
import { UtilitiesService } from "@src/app/services/utilities.service";
import { Subject, Observable } from "rxjs";
import { AlertService } from "../../core/alert/alert.service";
import { CommentService } from "../../general/comment/comment/comment.service";
import { UserService } from "../user/user.service";
import { SKIP_OPTION } from "@src/app/services/interceptor.service";


@Injectable({
  providedIn: 'root'
})
export class PostService {
  private readonly postUrl = ApiUrls.PostUrl;
  private readonly postsUrl = ApiUrls.PostsUrl;
  readonly currentUserID: string = this.userService.getCurrentUserId();
  readonly coords: string = this.userService.get('coords');
  readonly radius: number = this.userService.get('radius');
  private savedPost = new Subject<any>();
  private showComments: Subject<Post> = new Subject();
  private closeModal: Subject<boolean> = new Subject();

  constructor(private utilitiesService: UtilitiesService, private http: HttpClient, private userService: UserService,
    private commentService: CommentService, private alert: AlertService, private toasterService: ToasterService) { }

  savedPostEvent() {
    this.savedPost.next()
  }

  savedPostCallBack(): Observable<any> {
    return this.savedPost.asObservable()
  }

  updateShowComments(post: Post) {
    this.showComments.next(post)
  }

  getShowComments(): Observable<Post> {
    return this.showComments.asObservable();
  }

  updateCloseModal(status: boolean) {
    this.closeModal.next(status)
  }

  getCloseModal(): Observable<boolean> {
    return this.closeModal.asObservable();
  }

  getUserPosts(userID, page, size): Observable<Post[]> {
    let params = HttpParameters.params({ page, size });
    return this.http.get<Post[]>(`${this.postsUrl}/${userID}`, { params });
  }

  getPosts(page, size): Observable<Post[]> {
    let params = HttpParameters.params({ page, size });
    return this.http.get<Post[]>(`${this.postsUrl}/${this.coords}/${this.radius}`, { params });
  }

  getPost(id: string, isMinimal?): Observable<Post> {
    if(!isMinimal){ isMinimal = false }
    let params = HttpParameters.params({ isMinimal });
    return this.http.get<Post>(`${this.postUrl}/${id}`, { params });
  }

  addPost(_post: Post): Observable<Post> {
    return this.http.post<Post>(`${this.postUrl}`, _post);
  }

  editPost(post_edited: Post): Observable<Post> {
    return this.http.patch<Post>(`${this.postUrl}`, post_edited);
  }

  deletePost(postId: string, isDeleted: boolean) {
    const params = new HttpParams().set('isDeleted', isDeleted);
    return this.http.delete<boolean>(`${this.postUrl}/${postId}`, { params });
  }

  parseBoundary(boundary: Geoboundary): Geoboundary {

    if (boundary && boundary.pref == 'radius') {
      boundary.polygon = null;
    }

    if (boundary && boundary.pref == 'geofence') {
      let polygon = [];
      boundary.polygon.forEach((path) => polygon.push({ lat: typeof path.lat == 'function' ? path.lat() : path.lat, lng: typeof path.lng == 'function' ? path.lng() : path.lng }));
      boundary.polygon = polygon;
      boundary.radius = null;
    }

    delete boundary['pref'];
    delete boundary['unit'];

    return boundary;
  }

  //updateBoundary(post: Post, boundary: Geoboundary) {

  //    post.hasBoundary = false;
  //    post.radius = null;
  //    post.geofence = '';

  //    if (boundary && boundary.pref) {
  //        if (boundary.pref == 'radius') {
  //            post.radius = boundary.radius;
  //        }

  //        else {
  //            boundary.polygon.push(boundary.polygon[0]); //close the polygon ring
  //            boundary.polygon.forEach((path) => { post.geofence += path.lng() + ' ' + path.lat() + ','; });
  //            post.geofence = post.geofence.slice(0, -1);
  //        }
  //    }
  //}

  private update_comment_state(postID: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.postUrl}/${'toggle-comments'}/${postID}`);
  }

  toggle_comments(post: Post) {
    this.update_comment_state(post.id)
      .subscribe(new_state => {
        post.commentIsEnabled = new_state;
        if (post.commentIsEnabled) { this.show_comments(post); }
      });
  }

  show_comments(post: Post,) {
    this.commentService.getComments(post.id)
      .subscribe(comments => { post.comments = comments; });
  }

  comment_toggle_text(post: Post): string {
    return (post.commentIsEnabled ? "Disable" : "Enable") + " Comments";
  }

  private update_identity_state(postID: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.postUrl}/${'toggle-identity'}/${postID}`);
  }

  toggle_identity(post: Post) {
    this.update_identity_state(post.id)
      .subscribe(new_state => { post.identityIsHidden = new_state; });
  }

  identity_toggle_text(post: Post) {
    return post.identityIsHidden ? 'Show ID' : 'Hide ID';
  }

  likePost(_like: Like): Observable<boolean> {
    return this.http.post<boolean>(`${this.postUrl}/${'like'}`, _like, { context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)});
  }

  getLikeUsersList(PostID, page, size, query: string = ''): Observable<User[]> {
    let params = HttpParameters.params({ page, size, query });
    return this.http.get<User[]>(`${this.postUrl}/likers/${PostID}`, { params });
  }

  savePost(_save: Save): Observable<boolean> {
    return this.http.post<boolean>(`${this.postUrl}/${'save'}`, _save);
  }

  save_toggle_text(post: Post): string {
    return post.saved ? "Unsave" : "Save";
  }

  getUserSavedPosts(page, size, query: string = ''): Observable<SavedEntity[]> {
    let params = HttpParameters.params({ page, size, query });
    return this.http.get<SavedEntity[]>(`${this.postUrl}/${'retrieve-saved-post'}`, { params });
  }

  deleteSavedPost(savedEntity: SavedEntity) {
    return this.http.patch<any>(`${this.postUrl}/${'delete-saved-post'}`, savedEntity);
  }

  renewPost(postID: string): Observable<Date> {
    return this.http.get<Date>(`${this.postUrl}/${'renew'}/${postID}`);
  }

  returnShareLink(postId: string, comments?: boolean): string {
    return this.utilitiesService.buildCopyUrl('post/' + postId + '?comments=' + comments);
  }

  returnEmbedLink(postId: string, comments?: boolean): string {
    return this.utilitiesService.buildCopyUrl('embed/post/' + postId + '?comments=' + comments);
  }

  copyShareLink(post: Post) {
    this.utilitiesService.copyLinkToClipboard('post/' + post.id);
  }

  feedback(post: Post) {
    post['feedbackInProgress'] = true;
  }

  isAuthor(post: Post): boolean {
    return post.author && post.author.id == this.currentUserID ? true : false;
  }

  canSave(body: string, hasBlob: boolean, canSaveGeo: boolean, canSaveBoundary: boolean, hasSharedEntity: boolean): boolean {

    body = !body ? null : body.trim();

    if (canSaveGeo && canSaveBoundary && (body || hasBlob || hasSharedEntity)) {
      return true;
    }

    return false;
  }

  postThreads(postIds: string[]): Observable<Post[]> {
    return this.http.post<Post[]>(`${this.postUrl}/postThread`, postIds);
  }

}
