<div class="modal-content modal-post-content p-3" id="feedback-modal">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <h6 class="fw-bold">Feedback</h6>
        <button type="button" class="btn-close p-0" aria-label="Close" (click)="hideModal()">
        </button>
    </div>

    <form [formGroup]="feedbackForm" novalidate>
        <div class="row">
            <div class="col-12 mb-3">
                <mdb-form-control>
                    <mdb-select [multiple]="true" formControlName="features">
                      <mdb-select-all-option>Select all</mdb-select-all-option>
                      <mdb-option *ngFor="let option of appFeatures" [value]="option.id">{{
                        option.description
                      }}</mdb-option>
                    </mdb-select>
                    <label mdbLabel class="form-label">Select app feature(s)</label>
                </mdb-form-control>
            </div>
            <div class="col-12 mb-3">
                <mdb-form-control>
                    <mdb-select [multiple]="true" formControlName="categories">
                      <mdb-select-all-option>Select all</mdb-select-all-option>
                      <mdb-option *ngFor="let option of feedBackCategories" [value]="option.id">{{
                        option.description
                      }}</mdb-option>
                    </mdb-select>
                    <label mdbLabel class="form-label">Select category</label>
                </mdb-form-control>
            </div>
            <div class="col-12 mb-3">
                <div class="form-group">
                    <app-floating-input
                    formInputName="userFeedback"
                    label="Feedback"
                    type="textarea"
                    [control]="feedbackForm.controls['userFeedback']">
                    </app-floating-input>
               </div>
            </div>
            <div class="col-6 mb-3">
                <div class="switch first">
                    <div class="form-check form-switch">
                        <input mdbCheckbox class="form-check-input" 
                            type="checkbox" id="isAnonymous"                           
                            formControlName="isAnonymous"
                            (change)="setClientId()"/>
                        <label class="form-check-label default-label" for="isAnonymous">
                            <span class="default">Anonymous</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="switch first">
                    <div class="form-check form-switch">
                        <input mdbCheckbox class="form-check-input" 
                            type="checkbox" id="allowFollowUp"                           
                            formControlName="allowFollowUp"
                            (change)="collectData()"/>
                        <label class="form-check-label default-label" for="allowFollowUp">
                            <span class="default">Allow follow up</span>
                        </label>
                    </div>
                    <ng-container *ngIf="feedbackForm.controls['allowFollowUp'].value">
                        <p class="text-info text-switch-info">
                            *email address or phone number is required
                        </p>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="feedbackForm.controls['allowFollowUp'].value">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <app-floating-input
                        formInputName="emailAddress"
                        label="Email"
                        type="text"
                        [control]="feedbackForm.controls['emailAddress']">
                        </app-floating-input>
                   </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <app-floating-input
                        formInputName="phoneNumber"
                        label="Phone"
                        type="text"
                        [control]="feedbackForm.controls['phoneNumber']">
                        </app-floating-input>
                   </div>
                </div>
            </ng-container>

            <div class="col-12">
                <button [disabled]="feedbackForm.invalid || saving"
                    (click)="sendFeedback()"
                    class="btn d-flex align-items-center btn-link btn-post-send ms-auto" id="btn-post">
                    <span class="btn-post-edit-icon">
                        <span class="mdi mdi-send text-white"></span>
                    </span>
                    <span id="post-creation-post" class="btn-post-edit-text text-capitalize text-white">Submit</span>
              </button>
            </div>
        </div>
    </form>
</div>
