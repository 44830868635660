import { Pipe, PipeTransform } from '@angular/core';
import { TimeAgoPipe } from './time-ago.pipe';
import { TimeAheadPipe } from './time-ahead.pipe';
import * as moment from 'moment';


@Pipe({
  name: 'timeDiff'
})
export class TimeDiffPipe implements PipeTransform {

  constructor(private timeAgo: TimeAgoPipe, private timeAhead: TimeAheadPipe) {

  }

  transform(value: any, args?: string) {
    return this.getTimeDiff(value, args)
  }

  getTimeDiff(value: any, direction: string) {
    switch (direction) {
      case 'ago':
        return this.getTimeAgo(value);

      case 'ahead':
        return this.getTimeAhead(value);

      default:
        return moment(new Date()).diff(value) > 0 ?
          this.getTimeAgo(value) :
          this.getTimeAhead(value);
    }
  }

  getTimeAgo(value) {
    return this.timeAgo.transform(value);
  }

  getTimeAhead(value) {
    return this.timeAhead.transform(value);
  }

}
