import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SignalrService } from '@src/app/services/signalr.service';
import { NotificationMessage, Diagnostic, GroupInvitation, Invitation } from 'src/app/core/models';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { pagination, notificationType, invitationStatus } from '@src/app/core/Constants';
import { GroupService } from '../../groups/group.service';
import { NotificationsService } from '../../notification/notifications.service';

@Component({
  selector: 'group-request',
  templateUrl: './group-request.component.html',
  styleUrls: ['./group-request.component.scss'],
})
export class GroupRequestComponent implements OnInit {
  styleDeclaration = <CSSStyleDeclaration>{
    textAlign: 'center',
    paddingTop: '3px',
    fontSize: 'x-large',
    color: 'white',
  };

  @Input() groupRequests: NotificationMessage[];
  @Input() groupSummary?: boolean;

  query: string;
  page = pagination.page;
  size = pagination.size;
  loading: boolean = false;
  disableButton: boolean = false;

  constructor(
    private signalrService: SignalrService,
    private notificationService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private groupService: GroupService,
    private diagnostic: DiagnosticService
  ) {
    this.signalrService.retrieveNotification().subscribe((msg: NotificationMessage) => {
      if (msg?.id && msg?.notificationType == notificationType.group) {
        this.groupRequests = [...new Set([msg, ...this.groupRequests])];
        this.cdr.detectChanges();
      }
    });

    this.search_groups();
  }

  ngOnInit(): void {}

  search_groups() {
    this.notificationService.groupSearchResponse.subscribe((query) => {
      if (query) {
        this.getInvitationsNotifications(notificationType.group, this.page, this.size, query);
      }
    });
  }

  getInvitationsNotifications(invitationType: string, pageNumber: number, size: number, query?: string) {
    this.loading = true;
    this.notificationService.getInvitationByType(invitationType, pageNumber, size, query).subscribe(
      (response) => {
        this.loading = false;
        if (response.length > 0) {
          if (this.groupRequests?.length > 0 && query) {
            this.groupRequests = [];
            this.groupRequests = [...new Set([...response, ...this.groupRequests])];
          } else {
            this.groupRequests = response;
          }
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  viewNotification(notificationId: string) {
    this.notificationService.viewNotification(notificationId).subscribe((response) => {
      if (response) {
        //this.cdr.detectChanges();
      }
    });
  }

  accept(notification: NotificationMessage, i) {
    notification.isDisabled = true;
    let invitation = this.invitation(notification.localNotificationdata.invitation);
    this.groupService.inviteResponse(invitation, invitationStatus.accepted).subscribe(
      (resp) => {
        this.groupRequests.find((c) => c.localNotificationdata.id == notification.localNotificationdata.id).localNotificationdata.invitation.accepted = resp;
        notification.isDisabled = false;
        this.cdr.detectChanges();
      },
      (error) => {
        notification.isDisabled = false;
        this.diagnostic.displayMessage(<Diagnostic>error);
      }
    );
    return notification;
  }

  decline(notification: NotificationMessage, i) {
    notification.isDisabled = true;
    let invitation = this.invitation(notification.localNotificationdata.invitation);
    this.groupService.inviteResponse(invitation, invitationStatus.declined).subscribe(
      (resp) => {
        this.groupRequests.find((c) => c.localNotificationdata.id == notification.localNotificationdata.id).localNotificationdata.invitation.accepted = resp;
        notification.isDisabled = false;
        this.cdr.detectChanges();
      },
      (error) => {
        notification.isDisabled = false;
        this.diagnostic.displayMessage(<Diagnostic>error);
      }
    );
    return notification;
  }

  invitation(invitation: Invitation): GroupInvitation {
    let invite = <GroupInvitation>{
      id: invitation.id,
      groupId: invitation.activityId,
      userId: invitation.userId,
      invitedOn: invitation.invitedOn,
      invitedBy: invitation.invitedBy,
      isAdmin: invitation.isAdmin,
      accepted: invitation.accepted,
      respondedOn: invitation.respondedOn,
    };
    return invite;
  }

}
