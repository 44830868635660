import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { ApiUrls, HttpParameters } from '@src/app/core/config';
import {
  Thread,
  MessageCollection,
  ResponseMessage,
  RecepientUser,
  BroadcastMessage,
  Message,
  Diagnostic,
  User,
  UserInputMessage
} from '@src/app/core/models/model';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { SignalrService } from '@src/app/services/signalr.service';
import { messageDeleteOptions } from '@src/app/core/Constants';
import { UserService } from '../../user/user.service';
import { StorageService } from '@src/app/services/storage.service';
import { SKIP_OPTION } from '@src/app/services/interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  readonly currentUser = this.storageService.getCurrentUser();
  id: Guid;
  deleteOptions = messageDeleteOptions;
  userInputMessage:  UserInputMessage = {};

  constructor(private http: HttpClient, private diagnostic: DiagnosticService, private signalrService: SignalrService, 
              private storageService: StorageService,) {}

  loadThreads(recipientID?: string) {
    let messageURL = ApiUrls.Messages + (recipientID ? '/' + recipientID : '');
    return this.http.get<Thread[]>(`${messageURL}`);
  }

  loadMessages(threadID: string, page?: number, size?: number) {
    this.id = Guid.parse(threadID);
    let params = HttpParameters.params({ page, size });
    return this.http.get<Message[]>(`${ApiUrls.Messages}/get-message-thread/${this.id}`, { params });
  }

  loadRecepientConversation(recepientId: string) {
    this.id = Guid.parse(recepientId);
    return this.http.get<Message[]>(`${ApiUrls.Messages}/recepient-messages/${this.id}`);
  }

  getMessageCollectionsAsync(queryString: string, skipProgressBar = false) {
    return this.http.get<ResponseMessage[]>(`${ApiUrls.Messages}/${'existing-threads?query='}${queryString}`,
    {context: new HttpContext().set(SKIP_OPTION.PROGRESS, skipProgressBar)});
  }

  search(terms: Observable<string>): Observable<RecepientUser[]> {
    return terms.pipe(
      distinctUntilChanged(),
      filter((term) => term.length > 0),
      switchMap((term) => this.searchEntries(term))
    );
  }

  searchEntries(queryString: string) {
    return this.http.get<RecepientUser[]>(`${ApiUrls.Messages}${'/search?query='}${queryString}`);
  }

  start_thread(participants: string[]): Observable<any> {
    return this.http.post(`${ApiUrls.Messages}/${'new'}`, participants);
  }

  send(message: Message) {
    return this.http.post<Message>(`${ApiUrls.Messages}`, message, {context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)});
  }

  sendBroadCast(message: BroadcastMessage) {
    return this.http.post<Message>(`${ApiUrls.Messages}/broadcast`, message);
  }

  markMessagesAsRead(messages: any[]) {
    return this.http.put<Message>(`${ApiUrls.Messages}/${'mark-as-read'}`, messages, {context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)});
  }


  messageNotificationCount() {
    return this.http.get<any>(`${ApiUrls.Messages}/${'count'}`);
  }

  lastViewedMessageNotificationOn() {
    return this.http.patch<boolean>(`${ApiUrls.Messages}/${'view'}`, null);
  }

  updateMessage(message: Message){
    return this.http.patch<boolean>(`${ApiUrls.Messages}/${'update'}`, message);
  }

  clearMessages(threadID: string){
    return this.http.delete<boolean>(`${ApiUrls.Messages}/${'delete'}/${threadID}`);
  }

  eventTrackerToUpdateMessages(threadMessages, currentUserID) {
    let messages = threadMessages.filter((mes) => mes.recipientID === currentUserID && mes.isRead === false)
    if (messages.length > 0) {
      this.markMessagesAsRead(messages).pipe(
      map(response =>{
        //update message count in main master component
        for(let mess of messages){
          mess.isRead = true;
          this.signalrService.messageNotification.next(mess)
        }
      })
     )
    }
  }

  //---------user typing function-----------//
  userInput(userMessage: UserInputMessage){
    return this.http.post<any>(`${ApiUrls.Messages}/${'userInputAction'}`, userMessage, 
    {context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)})
  }

  senderInputAction(recepientId, threadId){
    this.userInputMessage.imageUrl = this.currentUser.imageURL
    this.userInputMessage.recepientID = recepientId
    this.userInputMessage.senderID = this.currentUser.id
    this.userInputMessage.threadID = threadId
    this.userInputMessage.recepientName = this.currentUser.name

    this.userInput(this.userInputMessage)
    .subscribe({
      next:(data)=>{},
      error: (error)=> { this.diagnostic.displayMessage(<Diagnostic>error); }
    })
   }

  //------------message delete/undo handler functions-------------//
  deleteMessage(message: Message,  threadMessages: Message[], recepientMessages: ResponseMessage[], currentUserID: string, option: string, i: number, page: string){
    switch (option) {
      case this.deleteOptions.delete:
        if(message.senderID === currentUserID){ message.senderDeleted = true, threadMessages[i].senderDeleted = true  }
        else if(message.recipientID === currentUserID){ message.recipientDeleted = true, threadMessages[i].recipientDeleted = true  }
        this.updateRecepientListAfterDelete(message, recepientMessages, threadMessages)
        break;
      case this.deleteOptions.retract:
        message.isRetracted = true;  
        threadMessages[i].isRetracted = true;
        this.updateRecepientListAfterDelete(message, recepientMessages, threadMessages)
        break;
    }
    if(page == this.deleteOptions.fromwidget){ this.signalrService.chatWidgetMessages.next(threadMessages); }
    else if (page == this.deleteOptions.fromMain){ this.signalrService.messageSource.next(threadMessages); }
    
    this.updateMessage(message).subscribe({
      next: (data)=>{},
      error: (error)=>{  this.diagnostic.displayMessage(<Diagnostic>error); }
    })
  }

  updateRecepientListAfterDelete(mess: Message, recepientMessages: ResponseMessage[],  threadMessages: Message[]){
    for (let i = 0; i < recepientMessages.length; i++) {
      //loop through recepient list
      if (recepientMessages[i].message.id === mess.id) {
        if(threadMessages.length > 1){
          let mess = this.getPreviousMessage(threadMessages)
          recepientMessages[i].message = mess;
          recepientMessages[i].hasBlob = mess.blobs.length > 0 ? true : false;
        }
        else if(threadMessages.length === 1){
          const index = recepientMessages.indexOf(recepientMessages[i]);
          if (index !== -1) recepientMessages.splice(index, 1);
        }
        this.signalrService.messageThreadSource.next(recepientMessages);
        this.signalrService.chatWidgetMessageList.next(recepientMessages);
      }
    }
  }

  updateRecepientListAfterUndo(mess: Message, recepientMessages: ResponseMessage[], userMessageThread: Message[], user: User){
    for (let i = 0; i < recepientMessages.length; i++) {
      if (recepientMessages[i].message.threadID === mess.threadID) {
        let lastMessage = userMessageThread[userMessageThread.length - 1]
        if(lastMessage.id === mess.id){
            if(userMessageThread.length > 1){
              recepientMessages[i].message = mess;
              recepientMessages[i].hasBlob = mess.blobs.length > 0 ? true : false;
            }
            else if(userMessageThread.length === 1){
              let responseBlock = <ResponseMessage>{ message: mess, user: user, hasBlob: mess.blobs.length > 0 ? true : false };
              recepientMessages.unshift(responseBlock);
              this.signalrService.messageThreadSource.next(recepientMessages);
            }
            this.signalrService.messageThreadSource.next(recepientMessages);
            this.signalrService.chatWidgetMessageList.next(recepientMessages);
        }
      }
    }
  }

  getPreviousMessage(threadMessages: Message[]): Message{
    let userThreadCount = threadMessages.length - 1
    for (let i = 1; i < userThreadCount; i++){
      let mess = threadMessages[userThreadCount - i]
      if(!mess.isRetracted){
        return mess
      }
    }
  }
}
