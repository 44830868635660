import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilitiesService } from '@src/app/services/utilities.service';

@Component({
  selector: 'app-floating-input',
  templateUrl: './floating-input.component.html',
  styleUrls: ['./floating-input.component.scss'],
})
export class FloatingInputComponent implements AfterViewInit {
  @ViewChild('textarea') textarea: ElementRef;
  hasValue: boolean = false;
  selector: string = 'input';
  @Input() type: string = 'text';
  @Input() marginBottomClass: string = 'mb-3';
  @Input() autocomplete: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() inputMaxlength: number;
  // @Input() textareaMaxlength: number;
  @Input() control: FormControl;
  @Input() minDate: Date;
  @Input() maxDate: Date;  
  @Input() isLabelSuspended: boolean = false;
  @Input() formInputName: string;
  @Input() readOnly: boolean = false;
  @Input() hasIcon: boolean = false;
  @Input() required: boolean = true;

  @Output() dateValidityCheck = new EventEmitter<boolean>();
  @Output() onInput = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();

  constructor(private el: ElementRef, private renderer: Renderer2, private utilitiesService: UtilitiesService) {}

  ngOnInit() {
    this.control.valueChanges.subscribe((value) => {
      if (value) {
        this.hasValue = true;
      } else {
        this.hasValue = false;
      }
    });

    if (this.type === 'text') {
      this.selector = 'input';
    } else if (this.type === 'textarea') {
      this.selector = 'textarea';
    } else {
      this.selector = 'input';
    }
  }

  ngAfterViewInit() {
    this.checkInputValue();
  }

  onInputChange(event: any) {
    this.onInput.emit(event.target.value);
    this.characterLimitValidation(event.target.value);
  }

  characterLimitValidation(characterLength) {
    //input field
    if(this.inputMaxlength) {
      if(characterLength.length > this.inputMaxlength){
        this.utilitiesService.showCharacterLimitToast();
        return;
      } else this.checkInputValue();
    }
    
    //textarea
    // if(this.textareaMaxlength && characterLength.length > this.textareaMaxlength) {
    //   this.utilitiesService.showCharacterLimitToast();
    //   return;
    // }
  }

  onClickEvent(event: any) {
    this.onClick.emit();
  }

  onFocus() {
    this.renderer.addClass(this.el.nativeElement.querySelector(this.selector), 'focused');
  }

  imitateFocusOnInput() {
    this.renderer.addClass(this.el.nativeElement.querySelector(this.selector), 'focused');
    this.textarea.nativeElement.focus();
  }

  onBlur() {
    this.renderer.removeClass(this.el.nativeElement.querySelector(this.selector), 'focused');
    this.checkInputValue();
  }

  checkInputValue() {
    const input = this.el.nativeElement.querySelector(this.selector);
    let isValid = true;

    if (input.value) {
      this.renderer.addClass(input, 'not-empty');

      const selectedDate = new Date(input.value);
      const minDate = new Date(this.minDate?.getFullYear(), this.minDate?.getMonth(), this.minDate?.getDate() + 1);

      if (selectedDate < minDate || selectedDate >= this.maxDate) {
        isValid = false;
      }

      this.dateValidityCheck.emit(isValid);
    } else {
      this.renderer.removeClass(input, 'not-empty');
    }
  }
}
