import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  HostListener,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { NowPlaying, VideoConfig } from '@src/app/core/models/model';

interface HTMLVideoElementWithPIP extends HTMLVideoElement {
  requestPictureInPicture(): Promise<any>;
}

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})

export class VideoPlayerComponent implements AfterViewInit, OnChanges {
  @ViewChild('mainVideo') mainVideo: ElementRef<HTMLVideoElementWithPIP>;
  @ViewChild('videoPlayerContainer') videoPlayerContainer: ElementRef;
  @ViewChild('videoTimeline') videoTimeline: ElementRef;
  @ViewChild('volumeSlider') volumeSlider: ElementRef;

  @Input() id: string = '';
  @Input() src: string = '';
  @Input() autoplay: boolean = true;
  @Input() loop: boolean = false;
  @Input() controls: boolean = true;
  @Input() view: 'contain' | 'cover' = 'contain';

  @Input() playbackControls: boolean = true;
  @Input() pipControls: boolean = false;

  @Output() play = new EventEmitter<any>();
  @Output() ended = new EventEmitter<any>();
  @Output() clicked = new EventEmitter<any>();
  @Output() currentlyPlayingId = new EventEmitter<any>();

  private _nowPlaying: NowPlaying = {
    blobId: '',
    config: {
      currentTime: 0,
      muted: true,
      volume: 0.5,
    }
  };
  get nowPlaying(): NowPlaying { return this._nowPlaying; }
  @Input() set nowPlaying(value: NowPlaying) {
    if (this?.id === value?.blobId) {
      this._nowPlaying.blobId = value.blobId;
      this.updateConfig(value.config);
      if(this.mainVideo && this.mainVideo.nativeElement) {
        this.playVideo(value.config.muted);
      }
    }
  }
  
  config: VideoConfig = this.nowPlaying.config;
  originalMuteState: boolean | null = null;
  isLoaded: boolean = false;
  timer: any;
  isPlaying = false;
  isBuffering = false;
  isSpeedOptionsOpen = false;
  isFullScreen = false;
  isNativeFullScreen = true;
  isControlsVisible = true;
  isProgressbarVisible = false;
  currentTime = '00:00';
  progressTime = '00:00';
  duration = '00:00';
  remainingTime = '00:00';
  progressOffset = 0;
  isSeeking = false;
  progressBarLeftOffset = 0;
  progressBarWidth = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.startObserver();
    this.renderUI(this.config);
  }

  ngOnChanges(changes: SimpleChanges) {
    
    if (changes?.nowPlaying) {
      this.renderUI(changes?.nowPlaying?.currentValue?.config);
    }
  }

  onVideoLoadedMetadata() {
    this.isLoaded = true;
  }

  renderUI(config: VideoConfig) {
    if (this.mainVideo && this.mainVideo.nativeElement) {
      this.mainVideo.nativeElement.currentTime = config.currentTime;
      this.mainVideo.nativeElement.volume = config.volume;
    }

    if(this.volumeSlider && this.volumeSlider.nativeElement) {
      this.volumeSlider.nativeElement.value = config.volume;
    }
  }

  updateConfig(config: VideoConfig) {
    this.config = { ...this.config, ...config };
    this.nowPlaying.config = this.config
  }

  handleVideoClick(event: any) {
    this.pauseVideo();
    if(this.mainVideo.nativeElement) {
      this.clicked.emit({
        blobId: this.id,
        config: this.config,
      });
    }
  }

  startObserver() {
    if (!this.autoplay) {
      const video = this.mainVideo.nativeElement;

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.intersectionRatio === 1) {
              video.play();
            } else {
              video.pause();
            }
        });
      }, { threshold: 1 });

      observer.observe(video);
    }
  }

  getNowPlaying() {
    return {
      blobId: this.id,
      config: this.config,
    }
  }

  hideControls() {
    if (this.isPlaying) {
      this.timer = setTimeout(() => {
        this.isControlsVisible = false;
        this.isProgressbarVisible = false;
      }, 1500);
    }
  }

  resetCounter() {
    this.duration = this.formatTime(this.mainVideo.nativeElement.duration);
    this.remainingTime = this.duration;
  }

  onTimeUpdate(event: Event) {
    let { currentTime, duration } = event.target as HTMLVideoElement;
    this.updateConfig({ currentTime })
    let percent = (currentTime / duration) * 100;
    this.progressBarWidth = percent;
    if (
      typeof duration === 'number' &&
      Number.isFinite(duration) &&
      typeof currentTime === 'number' &&
      Number.isFinite(currentTime)
    ) {
      this.remainingTime = this.formatTime(duration - currentTime);
      this.currentTime = this.formatTime(currentTime);
    }
  }

  onPlay(event: any) {
    this.hideControls();
    if (this.isBuffering) return;
    this.isPlaying = true;
    this.play.emit(event);
    this.currentlyPlayingId.emit(this.id);
  }

  onPause() {
    this.isPlaying = false;
    this.isControlsVisible = true;
    this.isProgressbarVisible = true;
  }

  onVideoWaiting() {
    this.isBuffering = true;
  }

  onVideoCanPlay() {
    this.isBuffering = false;
  }

  onEnded(event: any) {
    this.resetCounter();
    this.restoreMuteState();
    this.isControlsVisible = true;
    this.isProgressbarVisible = true;
    this.ended.emit(event);
  }

  togglePlayPause(event: any) {
    event.stopPropagation();

    if(!this.isPlaying) {
      this.originalMuteState = this.config.muted;
      this.playVideo(false);
    } else {
      this.restoreMuteState();
      this.pauseVideo();
    }
  }

  playVideo(muted = true, loop = false) {
    this.updateConfig({ muted })
    this.loop = loop;

    this.mainVideo.nativeElement.play();
    if (this.isBuffering) return;
    this.isPlaying = true;
  }

  pauseVideo() {
    this.mainVideo.nativeElement.pause();
    this.isPlaying = false;
  }

  restoreMuteState() {
    if (this.originalMuteState !== null) {
      this.updateConfig({ muted: this.originalMuteState })
    }
  }

  formatTime(time: number): string {
    let seconds: string | number = Math.floor(time % 60);
    let minutes: string | number = Math.floor(time / 60) % 60;
    let hours: string | number = Math.floor(time / 3600);

    seconds = seconds < 10 ? `0${seconds}` : seconds;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    hours = hours < 10 ? `0${hours}` : hours;

    if (hours == 0) return `${minutes}:${seconds}`;

    return `${hours}:${minutes}:${seconds}`;
  }

  toggleVolume() {
    this.updateConfig({ muted: !this.config.muted})
    this.volumeSlider.nativeElement.value = this.config.volume;
  }

  onVolumeSliderInput(e: Event) {
    let volumeValue = parseFloat(parseFloat((e.target as HTMLInputElement).value).toFixed(2));
    this.updateConfig({ volume: volumeValue });
    this.mainVideo.nativeElement.volume = volumeValue;
  }

  // toggleSpeedOptions() {
  //   this.isSpeedOptionsOpen = !this.isSpeedOptionsOpen;
  // }

  // setSpeed(speed: number) {
  //   this.mainVideo.nativeElement.playbackRate = speed;
  //   this.speedOptionsList.forEach((option) => {
  //     if (option.speed == speed) {
  //       option.active = true;
  //     } else {
  //       option.active = false;
  //     }
  //   });
  // }

  onPipBtnClick() {
    this.mainVideo.nativeElement.requestPictureInPicture();
  }

  // onSkipBackwardClick() {
  //   this.mainVideo.nativeElement.currentTime -= 5;
  // }

  // onSkipForwardClick() {
  //   this.mainVideo.nativeElement.currentTime += 5;
  // }

  isAppleMobileDeviceOrSafariOnMac() {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Detects iOS devices (iPhone, iPad, iPod)
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    } 
    // Detects macOS devices (MacBook, iMac, etc.) and checks if the browser is Safari and Not Chrome
    else if (/Mac/.test(userAgent) && /Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;

    if(this.isAppleMobileDeviceOrSafariOnMac()) {
      this.isNativeFullScreen = false;
    } else {
      this.isNativeFullScreen = true;

      if (isPlatformBrowser(this.platformId)) { 
        if (document.fullscreenElement) {
          return document.exitFullscreen();
        }
      }
      
      this.videoPlayerContainer.nativeElement.requestFullscreen();
    }
  }

  getVideoProgressOffset(event: MouseEvent | TouchEvent) {
    if (isPlatformBrowser(this.platformId)) { 
      if ('touches' in event) {
        const node = event.target as HTMLElement;
        const rect = node.getBoundingClientRect();
        const offsetX: number = event.touches[0].clientX - window.pageXOffset - rect.left;
        return offsetX;
      } else {
        return event.offsetX;
      }
    }
  }

  getVideoProgressBarTimeandOffset(videoProgressOffset: number, timelineWidth: number) {
    const currentTimeInVideo = Math.floor(
      (videoProgressOffset / timelineWidth) * this.mainVideo.nativeElement.duration
    );
    const progressOffset =
      videoProgressOffset < 20
        ? 20
        : videoProgressOffset > timelineWidth - 20
        ? timelineWidth - 20
        : videoProgressOffset;
    const progressTime = this.formatTime(currentTimeInVideo);
    return { progressTime, progressOffset };
  }

  seekVideo(videoProgressOffset: number) {
    let timelineWidth = this.videoTimeline.nativeElement.clientWidth;
    let newCurrentTime = (videoProgressOffset / timelineWidth) * this.mainVideo.nativeElement.duration;
    if (typeof newCurrentTime === 'number' && Number.isFinite(newCurrentTime)) {
      this.mainVideo.nativeElement.currentTime = newCurrentTime;
    }
    this.currentTime = this.formatTime(this.mainVideo.nativeElement.currentTime);
  }

  updateVideoProgressTimeandOffset(event: any) {
    let timelineWidth = this.videoTimeline.nativeElement.clientWidth;
    this.progressOffset = this.getVideoProgressOffset(event);
    const { progressTime, progressOffset } = this.getVideoProgressBarTimeandOffset(
      this.progressOffset,
      timelineWidth
    );
    this.progressBarLeftOffset = progressOffset;
    this.progressTime = progressTime;
  }

  onTimelineMouseDown(event: any) {
    event.stopPropagation();
    this.isSeeking = true;
  }

  onControlsWrapperClick(event: any) {
    event.stopPropagation();
  }

  onTimelineClick(event: any) {
    event.stopPropagation();
    this.progressOffset = this.getVideoProgressOffset(event);
    this.seekVideo(this.progressOffset);
  }

  onTimelineMouseMove(event: any) {
    event.stopPropagation();
    this.updateVideoProgressTimeandOffset(event);
  }

  onContainerMouseMove(event: any) {
    event.stopPropagation();
    this.isControlsVisible = true;
    this.isProgressbarVisible = true;
    clearTimeout(this.timer);
    this.hideControls();

    if (this.isSeeking) {
      this.progressOffset = this.getVideoProgressOffset(event);
      this.seekVideo(this.progressOffset);
      this.updateVideoProgressTimeandOffset(event);
    }
  }

  onContainerMouseUp(event: any) {
    event.stopPropagation();
    this.isSeeking = false;
  }


  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      if (document.fullscreenElement === null) {
        this.isFullScreen = false;
        this.isNativeFullScreen = true;
      }
    }
  }

  // Keyboard Events
  // @HostListener('window:keydown.space', ['$event'])
  // onSpacebar(event: KeyboardEvent) {
  //   if (document.activeElement === this.mainVideo.nativeElement) {
  //     event.preventDefault();
  //     if (this.mainVideo.nativeElement.paused) {
  //       this.mainVideo.nativeElement.play();
  //     } else {
  //       this.mainVideo.nativeElement.pause();
  //     }
  //   }
  // }
}
