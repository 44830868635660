import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Message } from '../core/models';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private subject = new Subject<any>()
  private message = new Subject<Message>()
 
  @Output() messageEvent = new EventEmitter<Message>();
  @Output() messageWidgetEvent = new EventEmitter<Message>();
  @Output() newMessageWidgetEvent = new EventEmitter<Message>();

  constructor() { }

  onclickEvent() {
    this.subject.next()
  }

  getOnclick(): Observable<any> {
    return this.subject.asObservable()
  }

  messaging() {
    this.message.next()
  }

  click(): Observable<Message> {
    return this.message.asObservable()
  }

}
