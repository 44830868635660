import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlobCarouselComponent } from './blob-carousel/blob-carousel.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { VideoPlayerModalComponent } from './video-player-modal/video-player-modal.component';
import { MdbFiveModule } from '../mdb-five/mdb-five.module';
import { VideoPlayerComponent } from './video-player/video-player.component';

const declarations = [
  FileUploadComponent, 
  BlobCarouselComponent, 
  GalleryComponent, 
  LightboxComponent,
  VideoPlayerComponent,
  VideoPlayerModalComponent
];

@NgModule({
  declarations: [
    ...declarations,
  ],
  imports: [
    CommonModule,
    MdbFiveModule
  ],
  exports: [
    ...declarations
  ]
})
export class MediaModule { }
