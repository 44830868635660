import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { HttpContext, HttpHeaders, HttpRequest } from '@angular/common/http';
import { SKIP_OPTION } from './interceptor.service';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public get session() { return this.storageService.getCurrentSession(); }

  public get internetSpeed() { return this.networkService.getCachedInternetSpeed(); }

  constructor(private storageService: StorageService, private networkService: NetworkService) { }

  public get accessToken(): string { return this.session && this.session.token ? (`${this.session.token.token_type} ${this.session.token.access_token}`) : ''; }

  public get refreshToken(): string { return this.session && this.session.token ? (this.session.token.refresh_token) : ''; }

  public get sessionId(): string { return this.session && this.session.id ? (this.session.id) : ''; }


  public get_signalr_qs() { // create query string for signalr requests
    return {
      'Access-Token': this.accessToken.replace('bearer ', ''),
      'Current-Position': this.storageService.getCurrentPosition()
    };
  }

  public setAdditionalHeaders(request: HttpRequest<any>): HttpHeaders {
    
    const additionalHeaderValues = { 'Authorization': this.accessToken };

    if (this.internetSpeed) additionalHeaderValues['speed'] = this.internetSpeed;

    for (const [key, value] of Object.entries(additionalHeaderValues)) {
      if (!request.headers.has(key)) {
        request = this.setNewHeader(request, key, value);
      }
    }

    //TODO - undo temporary changes
    if (!request.url.includes("upload")) {
      switch (request.method) {
        case 'POST':
        case 'PUT':
        case 'PATCH':
          request = this.setNewHeader(request, 'Content-Type', 'application/json');
          break;
      }
    }
  
    return request.headers;
  }

  private setNewHeader(request: HttpRequest<any>, key: string, value: any) {
    return request.clone({
      headers: request.headers.set(key, String(value)),
    });
  }

  // public skipAuthAndHeaders(headers?: any): any {
  //   const skip = JSON.stringify({ authentication: true, headers: true } as SkipIntercept);
  //   return !headers ? { skip } : Object.assign({}, headers, { skip });
  // }

  public skipAuthAndHeaders(context: HttpContext = new HttpContext()): any {
    return context.set(SKIP_OPTION.AUTHENTICATION, true).set(SKIP_OPTION.HEADERS, true);
  }
}