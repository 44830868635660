import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Constants } from '@src/app/core/config';
import { connectionType, messageDeleteOptions, MessageUploadOptions, RestrictionSettings } from '@src/app/core/Constants';
import { BlobData, BlobEmit, buttonList, Diagnostic, IGenericObject, Message, ResponseMessage, User, UserInputMessage } from '@src/app/core/models/model';
import { ComplaintComponent } from '@src/app/modules/core/complain/complaint.component';
import { MessageFileUploadComponent } from '@src/app/modules/feature/message/message-file-upload/message-file-upload.component';
import { MessageService } from '@src/app/modules/feature/message/message/message.service';
import { PrivateUserComponent } from '@src/app/modules/feature/user/private-user/private-user.component';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { FileUploadComponent } from '@src/app/modules/general/media/file-upload/file-upload.component';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { EmojiControlService } from '@src/app/services/emoji-control.service';
import { MessagingService } from '@src/app/services/messaging.service';
import { SignalrService } from '@src/app/services/signalr.service';
import { StorageService } from '@src/app/services/storage.service';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-message-chat-box',
  templateUrl: './message-chat-box.component.html',
  styleUrls: ['./message-chat-box.component.scss']
})
export class MessageChatBoxComponent implements OnInit, OnDestroy {
  @ViewChild('chatScrollElement') chatScrollElement: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('emojiBox') emojiBox: ElementRef;
  currentUserID: string = this.userService.getCurrentUserId();
  thread = { id: 'thread_id' };
  recepient = { id: 'recepient_id' };
  user: User;
  hide = false;
  userId = '';
  message: Message = {};
  isEmojiPickerVisible: boolean = false;
  emojiStyle: IGenericObject = {};
  messagesBody = '';
  clickEvent: Subscription;
  defaultClass = true;
  userMessages = [];
  timeoutDuration = 100;
  isAllowSub = true;
  recepientMessages: ResponseMessage[] = [];
  searchRecepient = '';
  messageCollections = [];
  recepient_id = { id: 'messageRecepient_id' };
  readonly category: string = Constants.Post;
  blobs = [];
  subject$ = new Subject<void>()
  hasblob = false;
  multiImage: boolean = false;
  deleteOptions = messageDeleteOptions;
  messageWidget = "widget";
  recepientID = '';
  recepientMessage: UserInputMessage = {};
  connectionType = connectionType;

  @Input() threadMessages: any[]
  @Input() name: string;
  @Input() imageUrl: string;
  @Input() threadTracker: string;
  @Input() isBlocked: boolean = false;
  @Input() recipientUser: User;
  @Input() blobCollection: BlobData[] = [];
  @Input() isOpenToTheRight: boolean;
  @Input() showCurrentlyTyping = false;

  groupButtonOption = {
    report: 'Report',
  }

  listOptions: buttonList[] = [
    { title: this.groupButtonOption.report, icon: 'mdi mdi-flag-variant-outline' },
  ];

  lastSeen: Date;

  @ViewChild(FileUploadComponent)
  private fileUploadComponent: FileUploadComponent;
  modalRef: MdbModalRef<MessageFileUploadComponent>;

  constructor(private storageService: StorageService, private messageService: MessageService, private userService: UserService,
              public signalrService: SignalrService, private messagingService: MessagingService, private _ngZone: NgZone, 
              private modalService: MdbModalService, private emojiControlService: EmojiControlService, private diagnostic: DiagnosticService) {
    this.clearMessageTextArea()
    this.send()
    this.receiveMessage()
    this.senderActionListener()
  }

  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
    this.showCurrentlyTyping = false;
  }

  ngOnInit(): void {
    this.getMessages();
    this.user = this.storageService.getCurrentUser();
    this.lastSeen = new Date(this.recipientUser.lastSeen);
  }

   // Handle Emoji Toggle and Position
   toggleEmoji(event: MouseEvent) {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
    const {
      spaceRemainderToTheBottom,
      emojiBoxHeight,
      viewportWidth
    } = this.emojiControlService.getEmojiButtonCoordinates(event);

    let top = '';
    let bottom = '';

    if(spaceRemainderToTheBottom > (emojiBoxHeight + 20)) {
      top = '30px';
      bottom = 'unset';
    } else {
      top = 'unset';
      bottom = viewportWidth <= 768 ? '96px': '113px';
    }
    
    if(this.isEmojiPickerVisible) {
      this.emojiStyle = {
        position: 'fixed',
        top: top,
        bottom: bottom,
        left: 'unset',
        right: 'unset'
      }
    } else {
      this.emojiStyle = {};
    }
  }

  //---------------------send message functions------------------//
  onKeydown(event) {
    event.preventDefault();
  }

  sendMessage() {
    if (!this.messagesBody || this.messagesBody == "\n" || !this.messagesBody.trim()) {
      if (this.blobs.length < 1) { return }
    }
    let threadId = JSON.parse(this.storageService.get(this.thread.id))
    let recepientId = JSON.parse(this.storageService.get(this.recepient.id))
    this.send_message(recepientId, threadId)
  }

  send_message(recepientId, threadId) {
    const message: Message = {
      recipientID: recepientId,
      threadID: threadId,
      body: this.messagesBody,
      isRead: false,
      senderID: this.currentUserID,
      createdOn: new Date(),
      isRetracted: false,
      recipientDeleted: false,
      senderDeleted: false,
      blobs: this.blobs
    }

    if (message.recipientID) {
      this.threadTracker = message.threadID
      this.messageService.send(message).subscribe((mess) => {
        if(mess){
          this.sendChatMessage(mess);
          this.updateRecepientList(mess);
        }
      });
      this.messagesBody = '';
      this.blobs = [];
    }
  }

  //-------signlR observable---------//
  send() {
    this.signalrService.message.subscribe(res => {
      this._ngZone.run(() => {
        this.sendChatMessage(res)
        this.updateRecepientList(res)
        this.updateRecepientListAfterDelete(res)
      })
    })
  }

  //this sends message to chat box real time
  sendChatMessage(mess: Message) {
    this.showCurrentlyTyping = false;
    const message = this.threadMessages.find((c) => c.id == mess.id);
    let index = this.threadMessages.indexOf(message);
    if(mess.isRetracted){ this.threadMessages[index].isRetracted = true }
    else if(mess.isRetracted == false){ this.threadMessages[index].isRetracted = false }
    else{ this.threadMessages.push(mess); }
    //send message to chat widget
    this.signalrService.chatWidgetMessages.next(this.threadMessages)
    const recepientId = JSON.parse(this.storageService.get(this.recepient_id.id)) //this is used to persist and track the receiver of this message
    if (recepientId) {
      if (recepientId == mess.recipientID) {
        //update message page messages
        this.signalrService.messageSource.next(this.threadMessages)
      }
    }
  }

  //this updates the user message list on both the widget and the message page real time
  updateRecepientList(mess: Message) {
    this.updateOnlineStatus();
    if(mess.isRetracted == null){
      if (mess.blobs.length > 0) { this.hasblob = true } else { this.hasblob = false; }
      let resmsg;
      this.userService.getUserRecepient(mess.recipientID).subscribe(user => {
        let res = this.recepientMessages.filter(e => e.message.threadID == mess.threadID)
        if (res.length < 1) {
          this.updateNewMessage(mess, user)
        } else {
          this.updateWithExistingMessage(mess, resmsg)
        }
        this.scrollToLastMessage();
      })
    }
  }

  updateNewMessage(mess: Message, user: User) {
    //if message list is empty for this thread, create a response message class
    //then push class into the exist feed list
    let responseBlock = <ResponseMessage>{ message: mess, user: user, hasBlob: this.hasblob };
    this.recepientMessages.unshift(responseBlock)
    this.signalrService.chatWidgetMessageList.next(this.recepientMessages)
    this.signalrService.messageThreadSource.next((this.recepientMessages))
  }

  updateWithExistingMessage(mess: Message, resmsg) {
    for (let i = 0; i < this.recepientMessages.length; i++) {
      if (this.recepientMessages[i].message.threadID === mess.threadID) {
        this.recepientMessages[i].message = mess
        this.recepientMessages[i].hasBlob = this.hasblob
        resmsg = this.recepientMessages[i]
      }
    }
    //to move the message to the top of the list, first splice it from the array
    //then push it back into the the array list
    const index = this.recepientMessages.indexOf(resmsg);
    if (index !== -1) {
      this.recepientMessages.splice(index, 1)
      this.recepientMessages.unshift(resmsg)
      this.signalrService.chatWidgetMessageList.next(this.recepientMessages)
      this.signalrService.messageThreadSource.next((this.recepientMessages))
    }
  }

  //---------retrieve user message list------//
  getMessages() {
    this.messageService.getMessageCollectionsAsync(this.searchRecepient)
      .subscribe((res: any) => {
        this.recepientMessages = []
        this.recepientMessages = res;
        this.scrollToLastMessage();
      });
  }

  //-----------------mark message as read------------------//
  eventTrackerToUpdateMessages() {
    this.messageService.eventTrackerToUpdateMessages(this.threadMessages, this.currentUserID)
  }

  scrollToLastMessage() {
    this.chatScrollElement.nativeElement.scrollIntoView();
  }

  deleteMessage(message: Message, option: string, i: number){
    this.messageService.deleteMessage(message, this.threadMessages, this.recepientMessages, this.currentUserID, option, i, this.deleteOptions.fromwidget)
  }

  undoDeletedMessage(message: Message, i: number){
    if( message.senderDeleted){ message.senderDeleted = false, this.threadMessages[i].senderDeleted = false  }
    else if(message.recipientDeleted){ message.recipientDeleted = false, this.threadMessages[i].recipientDeleted = false  }
    else if(message.isRetracted){ message.isRetracted = false,  this.threadMessages[i].isRetracted = false}
    this.updateMessageRecepientUserListAfterUndo(message)
    this.messageService.updateMessage(message).subscribe({
      next: ()=>{},
      error: (error)=>{  this.diagnostic.displayMessage(<Diagnostic>error); }
    })
   }

   updateRecepientListAfterDelete(mess: Message){
    this.messageService.updateRecepientListAfterDelete(mess, this.recepientMessages, this.threadMessages)
    this.updateMessageRecepientUserListAfterUndo(mess)
  }

  updateMessageRecepientUserListAfterUndo(mess: Message){
    if(!mess.isRetracted){
      this.userService.getUserRecepient(mess.recipientID).subscribe((user) => {
      this.messageService.updateRecepientListAfterUndo(mess, this.recepientMessages, this.threadMessages, user)  
      })
    }
  }

  //----------------helper functions-----------------//
  clearMessageTextArea() {
    this.clickEvent = this.messagingService.getOnclick().subscribe(() => {
      this.messagesBody = ''
    })
  }

  close() {
    this.messagingService.onclickEvent()
  }

  public addEmoji(event) {
    this.messagesBody = `${this.messagesBody}${event.emoji.native}`;
  }

  getUserImage(userImage: string): object {
    return this.userService.getUserImage(userImage);
  }

  upload() {
    this.fileUploadComponent.launch_uploader({ multiple: true, uploadCountLimit: RestrictionSettings.PostFileUploadCountLimit });
  }

  addFile(file: BlobEmit) {
    this.blobCollection.push(file.blob)
    if (file.blobComplete) { this.messageFileModal(this.blobCollection); }
  }

  messageFileModal(file: BlobData[]) {
    this.modalRef = this.modalService.open(MessageFileUploadComponent, {
      backdrop: true,
      keyboard: true,
      ignoreBackdropClick: true,
      modalClass: 'modal-md',
      containerClass: '',
      data: {
        postFiles: file,
        typeOption: MessageUploadOptions.fromMessageWidget
      }
    });

    this.modalRef.onClose.subscribe(() => {
      this.blobCollection = []
    });
  }

  receiveMessage() {
    this.messagingService.messageWidgetEvent.pipe(takeUntil(this.subject$)).subscribe(message => {
      this.blobs = message.blobs
      this.messagesBody = message.body
      this.sendMessage()
    })
  }

  updateOnlineStatus() {
    if (this.recepientMessages.length > 0) {
      const users = this.recepientMessages.map((u) => u.user);
      this.userService.updateOnlineStatuses(users).subscribe((res) => {
        this.recepientMessages.forEach((u) => {
          const updatedUser = res.find((x) => x.id == u.user.id);
          if (updatedUser) u.user = updatedUser;
        });
      });
    }
  }

  senderInputAction(){
    this.recepientID = JSON.parse(this.storageService.get(this.recepient.id))
    if(this.threadTracker){ this.messageService.senderInputAction(this.recepientID, this.threadTracker) }
   }

   senderActionListener(){
    this.signalrService.senderIsTyping$.subscribe(res=>{
      if(res && Object.keys(res).length > 0){
        if(res.threadID){
          let timer = 10000
          this.recepientMessage = res
          this.showCurrentlyTyping = true;
          setTimeout(() =>{
            this.showCurrentlyTyping = false;
          }, timer);
        }
      }
     })
   }

   viewPrivateUser() {
    this.modalRef = this.modalService.open(PrivateUserComponent, {
      modalClass: 'modal-dialog-centered',
      data: {
        user: this.recipientUser,
      }
    })
  }

  openComplaintModal(action: string, user: User) {
    this.modalRef = this.modalService.open(ComplaintComponent, {
      modalClass: 'modal-dialog-centered',
      data: {
        subject_id: user.id,
        category: 'user',
      }
    })
  }
}
