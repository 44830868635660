import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightKeywords'
})
export class HighlightKeywordsPipe implements PipeTransform {

  constructor() {}

  transform(data: {}, keywords: string, props: string[]) {

    for(let property of props) {
      const body = data[property];

      if (!body || !keywords) {
        break;
      }
  
      const searchTerms: string[] = keywords.split(' ').filter((item, index, self) => {
        return item.length > 1 && self.indexOf(item) === index;
      });
  
      let bodyCopy = body;
  
      searchTerms.forEach(term => {
        const regex = new RegExp(`(?!<[^>]*)(\\b${term}\\b|${term})(?![^<]*>)`, 'gi');
        bodyCopy = bodyCopy.replace(regex, `<span class="highlighted">$&</span>`);
      });
  
      data[property] = bodyCopy;
    };
    
    return data;
  }
}
