import { Component, OnInit, Input, Output, EventEmitter, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { pagination } from '@src/app/core/Constants';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { VideoPlayerModalComponent } from '../video-player-modal/video-player-modal.component';
import { StorageService } from '@src/app/services/storage.service';
import { BlobUrls, Constants } from '@src/app/core/config';
import { BlobData, Post } from '@src/app/core/models/model';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  readonly category: string = Constants.User;

  @Input() parent_category: string;
  @Input() blobs: BlobData[] = [];
  @Input() userId: string;
  @Input() mode: string = 'view';
  @Input() enableLightbox: boolean;
  @Output() emitter = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>()

  posts: Post[] = [];
  post: Post;
  showCarouselBtns: boolean = false;

  downloadPath: string = BlobUrls.Download + '/';
  downloadHold: string;
  page = pagination.page;
  size = pagination.size;

  blobStore: BlobData[] = [];

  selectedImage: BlobData;

  videoPlayerRef: MdbModalRef<VideoPlayerModalComponent>;

  set muted(value: boolean) { this.storageSvc.set('mute-preference', value) };
  get muted(): boolean { return (this.storageSvc.get('mute-preference') || 'true') == 'true' }; // mute by default if no value set

  public constructor(
    private modalService: MdbModalService, private storageSvc: StorageService, 
    private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
  }

  delete_file(blob: BlobData, confirmed: boolean = false): void {

    if (confirmed) {
      blob.isDeleted = true;
      //this.blobs.splice(index, 1);
    }

    else { this.emitter.emit({ action: 'confirm-delete', value: blob }); }
  }

  isDeleted(blob: BlobData): boolean { return blob.isDeleted == true; }

  blobSelected(blob: BlobData) {
    if (!this.enableLightbox) {
      this.selected.emit(blob);
    }

    else {
      switch (blob.blobCategory) {
        case 'Images':
          this.selectedImage = blob;
          break;

        case 'Videos':
          this.showVideo(blob)
          break;
      }
    }

  }

  showVideo(blob: BlobData) {
    this.videoPlayerRef = this.modalService.open(VideoPlayerModalComponent, {
      modalClass: 'modal-transform modal-xl modal-full overflow-hidden',
      data: {
        blob: blob
      }
    })
  }


  getURL(blob: BlobData) {
    return encodeURI(BlobUrls.Download + '/' + blob.url);
  }

  getVideos() {
    if (isPlatformBrowser(this.platformId)) {
      return this.el.nativeElement.querySelectorAll('video');
    };

    return []; // Return an empty array or appropriate fallback for SSR
  }

  onEnded(event) {
    for (let i = 0; i < this.getVideos().length; i++) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (this.getVideos()[i + 1]) {
            if (entry.intersectionRatio === 1 && this.getVideos()[i + 1] != event) {
              this.getVideos()[i + 1].play();
              this.onPlay(this.getVideos()[i + 1]);
            }
            // Else start afresh
          } else {
            this.getVideos()[0].play();
            this.onPlay(this.getVideos()[0]);
          }
        });
      }, {
        threshold: 1
      });
      observer.observe(this.getVideos()[i]);
    }
  }

  onPlay(event) {
    for (let i = 0; i < this.getVideos().length; i++) {
      if (event != this.getVideos()[i]) {
        this.getVideos()[i].pause();
      }
    }
  }

  startObserver() {
    const videos = this.getVideos();

    videos.forEach(video => {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio === 1) {
            video.play();
            video.muted = this.muted; // apply saved preference
          } else {
            this.muted = video.muted; // remember mute preference
            video.pause();
          }
        });
      }, {
        threshold: 1
      });
      observer.observe(video);
    });
  }
}