import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls, BlobUrls, HttpParameters } from '@src/app/core/config';
import { LocationRadiusLimit } from '@src/app/core/Constants';
import {
  User,
  Attendee,
  EventInvitation,
  Category,
  Geoboundary,
  Save,
  SavedEntity,
  BlobData,
  Event,
  UserEventSummary,
  MessageData,
  SharedEntity,
} from '@src/app/core/models/model';
import { UtilitiesService } from '@src/app/services/utilities.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { Guid } from 'guid-typescript';
import { ToasterService } from '@src/app/services/toaster.service';
import { GenericModalService } from '../../core/generic-modal-dialog/generic-modal/generic-modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly eventUrl = ApiUrls.EventUrl;
  private readonly blobUrl = BlobUrls;
  readonly currentUserID: string = this.userService.getCurrentUserId();

  data = <MessageData>{
    title: '',
    message: '',
  };
  
  constructor(private utilitiesService: UtilitiesService, private http: HttpClient, 
              private userService: UserService,  private toaster: ToasterService, 
              private genericModalService: GenericModalService, private route: Router,) {}

  getEvents(query: string = '', page?: number, size?: number, orderBy: string = ''): Observable<Event[]> {
    const params = new HttpParams().set('query', query).set('page', page).set('size', size).set('orderBy', orderBy);
    return this.http.get<Event[]>(this.eventUrl, { params });
  }

  getAllUserEvents(userId: string, query: string = '', orderBy: string = '') {
    const params = new HttpParams().set('query', query).set('orderBy', orderBy);
    return this.http.get<UserEventSummary>(`${this.eventUrl}/all-user-events/${userId}`, { params });
  }

  getAllGroupEvents(groupId: string, query: string = '', orderBy: string = '') {
    const params = new HttpParams().set('query', query).set('orderBy', orderBy);
    return this.http.get<UserEventSummary>(`${this.eventUrl}/group/all-group-events/${groupId}`, { params });
  }

  getUserEvents(
    userID: string,
    searchEntry: string = '',
    page?: number,
    size?: number,
    filterBy: string = '',
    orderBy: string = ''
  ): Observable<Event[]> {
    const params = new HttpParams()
      .set('query', searchEntry)
      .set('page', page)
      .set('size', size)
      .set('filterBy', filterBy)
      .set('orderBy', orderBy);
    return this.http.get<Event[]>(`${this.eventUrl}/${userID}`, { params });
  }

  getSuggestedAttendees(eventId: string, page?: number, size?: number, query?: string): Observable<User[]> {
    let params = new HttpParams().set('page', page).set('size', size).set('query', query);
    return this.http.get<User[]>(`${this.eventUrl}/suggested-attendees/${eventId}`, { params });
  }

  getAttendeeList(eventId: string, page?: number, size?: number, query?: string): Observable<Attendee[]> {
    let params = new HttpParams().set('page', page).set('size', size).set('query', query);
    return this.http.get<Attendee[]>(`${this.eventUrl}/attendee-list/${eventId}`, { params });
  }

  getEventsGroup( 
    groupId: string, 
    searchEntry: string = '',
    page?: number,
    size?: number,
    filterBy: string = '',
    orderBy: string = ''): Observable<Event[]> {
    const params = new HttpParams()
    .set('query', searchEntry)
    .set('page', page)
    .set('size', size)
    .set('filterBy', filterBy)
    .set('orderBy', orderBy);
    return this.http.get<Event[]>(`${this.eventUrl}/group/${groupId}`, { params });
  }

  getEvent(id, limit): Observable<Event> {
    return this.http.get<Event>(`${this.eventUrl}/get-event-by-id/${id}/${limit}`);
  }

  //addEvent(_event: Event, groupId: string = '', categories: string[]): Observable<Event> {
  addEvent(_event: Event, groupId: string = ''): Observable<any> {    
    if (groupId && groupId != Guid.EMPTY) {
      const params = new HttpParams().set('groupId', groupId);
      //.set('categories', categories)
      return this.http.post<Event>(`${this.eventUrl}/groupEvent`, _event, { params });
    } else {
      return this.http.post<Event>(`${this.eventUrl}`, _event);
    }
  }

  editEvent(eventId: string, event_edited: Event): Observable<any> {
    return this.http.put<Event>(`${this.eventUrl}/${eventId}`, event_edited);
  }

  editEventBanner(event_edited: Event): Observable<Event> {
    return this.http.put<Event>(`${this.eventUrl}/banner`, event_edited);
  }

  cancelEvent(eventId: string, event_canceled: boolean): Observable<boolean> {
    return this.http.put<boolean>(`${this.eventUrl}/cancel/${eventId}/${event_canceled}`, null);
  }

  deleteEvent(eventId: string, event_deleted: boolean): Observable<any> {
    return this.http.delete<any>(`${this.eventUrl}/${eventId}/${event_deleted}`);
  }

  attendEvent(attendee: Attendee): Observable<Event> {
    return this.http.post<Event>(`${this.eventUrl}/join`, attendee);
  }

  leaveEvent(eventId: string) {
    return this.http.delete<any>(`${this.eventUrl}/leave/${eventId}`);
  }

  // invitationResponse(inviteResponse: InvitationResponse): Observable<boolean>{
  //   return this.http.patch<boolean>(`${this.eventUrl}/InvitationResponse`,inviteResponse);
  // }

  inviteResponse(invite: EventInvitation, action?: string): Observable<any> {
    if (!action) {
      action = null;
    }
    return this.http.patch<boolean>(`${this.eventUrl}/response/${action}`, invite);
  }

  getCategories(searchParam: any): Observable<Category[]> {
    const params = new HttpParams().set('query', searchParam);
    return this.http.get<any[]>(`${this.eventUrl}/categories/`, { params });
  }

  includeCategory(_category: Category): Observable<Category> {
    return this.http.post<Category>(`${this.eventUrl}/categoryInclude`, _category);
  }

  parseBoundary(boundary: Geoboundary): Geoboundary {
    if (boundary && boundary.pref == 'radius') {
      boundary.polygon = null;
    }

    if (boundary && boundary.pref == 'geofence') {
      let polygon = [];
      boundary.polygon.forEach((path) =>
        polygon.push({
          lat: typeof path.lat == 'function' ? path.lat() : path.lat,
          lng: typeof path.lng == 'function' ? path.lng() : path.lng,
        })
      );
      boundary.polygon = polygon;
      boundary.radius = null;
    }

    delete boundary['pref'];
    delete boundary['unit'];

    return boundary;
  }

  getFeaturedEvents() {
    return of();
    // // return this.http.get('/test')
  }

  eventInviteUser(invitation: EventInvitation): Observable<string> {
    return this.http.post<string>(`${this.eventUrl}/invite`, invitation);
  }

  addOrRemoveOrganizer(attendee: Attendee): Observable<Attendee> {
    return this.http.put<Attendee>(`${this.eventUrl}/rsvp-toggle`, attendee);
  }

  removeAttendee(eventId: string, userId: string) {
    return this.http.delete<any>(`${this.eventUrl}/remove-attendee/${eventId}/${userId}`);
  }

  removeAttendeeDeleteStrip(eventId: string, userId: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.eventUrl}/attendee-delete-strip/${eventId}/${userId}`, null);
  }

  copyShareLink(event: Event) {
    this.utilitiesService.copyLinkToClipboard('event/' + event.id);
  }

  returnShareLink(eventId: string): string {
    return this.utilitiesService.buildCopyUrl('event/' + eventId);
  }

  saveEvent(_save: Save): Observable<boolean> {
    return this.http.post<boolean>(`${this.eventUrl}/${'save'}`, _save);
  }

  getUserSavedEvent(page, size, query: string = ''): Observable<SavedEntity[]> {
    let params = HttpParameters.params({ page, size, query });
    return this.http.get<SavedEntity[]>(`${this.eventUrl}/${'retrieve-saved-event'}`, { params });
  }

  cloneBlobByMetadata(blobMeta: BlobData[]) {
    return this.http.post<BlobData[]>(`${this.eventUrl}/clone-metadata`, blobMeta).pipe(
      map((response) => {
        return response;
      })
    );
  }

  save_toggle_text(event: Event): string {
    return event.saved ? 'Unsave' : 'Save';
  }

  getEventSuggestionsByLocation(eventId, location, page, size): Observable<User[]> {
    let radius = LocationRadiusLimit.locationRadiusLimit;
    let params = HttpParameters.params({ radius, page, size });

    return this.http.get<User[]>(`${this.eventUrl}/${'invitation-suggestions'}/${eventId}/${location}`, { params });
  }

  searchEntries(eventId, query: string = '', page, size, location: string = ''): Observable<User[]> {
    let params = HttpParameters.params({ location, page, size, query });
    return this.http.get<User[]>(`${this.eventUrl}/get-users-by-keyword/${eventId}`, { params });
  }

  deleteEventAsync(eventID, isDeleted){
    this.deleteEvent(eventID, isDeleted).subscribe(
      (res) => {
        switch (res.statusCode) {
          case 401:
            this.responseModal(res.response);
            break;
          case 400:
            this.responseModal(res.response);
            break;
          case 200:
            this.toaster.info('This event has been permanently deleted!');
            this.route.navigate(['/event']);
            break;
          default:
            break;
        }
      },
      (error) => {
        this.toaster.error('Unable to perform delete action, try again later.');
      }
    );
  }

  responseModal(message: string) {
    this.data.title = 'Delete Event';
    this.data.message = message
    this.genericModalService.open(this.data).subscribe((res) => {});
  }

  shareEventEntity(sharedEntity: SharedEntity): Observable<boolean> {
    return this.http.post<boolean>(`${this.eventUrl}/send-invitation`, sharedEntity);
  }
}
