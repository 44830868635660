// import { buttonList } from './../../core/models/model';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { connectionType } from "@src/app/core/Constants";
import { buttonList, MessageData, User } from "src/app/core/models";
import { Constants } from "src/app/core/config";
import { ComplaintComponent } from "../../core/complain/complaint.component";
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";
import { UtilitiesService } from "@src/app/services/utilities.service";
import { Router } from "@angular/router";
import { SignalrService } from "@src/app/services/signalr.service";
import { RestrictionsService } from "@src/app/services/restrictions.service";
import { UserService } from "../../feature/user/user.service";
import { BlockedUserComponent } from "../../feature/user/blocked-user/blocked-user.component";
import { ToasterService } from "@src/app/services/toaster.service";
import { GenericModalService } from "../../core/generic-modal-dialog/generic-modal/generic-modal.service";

@Component({
  selector: 'app-user-interaction-control',
  templateUrl: './user-interaction-control.component.html',
  styleUrls: ['./user-interaction-control.component.scss']
})
export class UserInteractionControlComponent implements OnInit {

  modalRef: MdbModalRef<ComplaintComponent> | null = null;

  connectionType = connectionType;
  // @Input() user: User = <User>{ notifications: [], messages: [], savedLocations: [], id: ''};
  @Input() showButtonActionListItem: boolean = true;
  @Input() user: User = {};
  @Input() listOptions: buttonList[];
  @Input() showDropdown: boolean = true;
  @Output() blocked = new EventEmitter<any>();
  @Output() button_action = new EventEmitter<string>();
  isMuted: boolean = false;
  isBlocked: boolean = false;
  readonly currentUserID: string = this.userService.getCurrentUserId();
  
  data = <MessageData>{
    title: '',
    message: '',
  };


  userPinned: string;

  constructor(private utilitiesService: UtilitiesService,
    private genericModalService: GenericModalService, private modalService: MdbModalService, private toast: ToasterService,
    private userService: UserService, private router: Router, private signalrService: SignalrService, private restrictionService: RestrictionsService) {
    this.signalrService.interactionUpdate.subscribe(res => {
      if (res && this.user) {
        if (res.relationship === connectionType.connected && this.user.id === res.recepientId) {
          this.user.pinStatus.relationship = connectionType.connected
        }
      }
    })
  }

  ngOnInit() { }

  buttonClick(action) {
    this.button_action.emit(action);
  }

  messageUser() {
    this.router.navigate(['/messages'], { queryParams: { 'ref': this.user.id } });
  }

  viewProfile() {
    this.router.navigate(['/user/' + this.user.username]);
  }

  pin_prompt(user: User): string {
    return this.userService.pin_prompt(user);
  }

  isConnected(user: User): boolean {
    return this.userService.isConnected(user);
  }

  pin(user: User, action: any) {
    this.userService.pin(user.id, action).subscribe((status) => {
      switch (status.statusCode) {        
        case 403:              
         this.blockedModal(action);
          break;
        case 406:
          this.openGenericModal();
          break;
        case 200:           
          user.pinStatus = status?.response;
          this.user = user;
          break;       
      } 
    });
  }

  openGenericModal() {
    (this.data.title = ''),
      (this.data.message = 'The action you are trying to perform is no longer valid because this request has been accepted'),
      (this.data.showOption = false);

    this.genericModalService.open(this.data).subscribe((res) => {
      if (res) {
      }
    });
  }

  isPinned(user: User): boolean {
    return this.userService.isPinned(user);
  }

  isPinning(user: User): boolean {
    return this.userService.isPinning(user);
  }

  isUnrelated(user: User): boolean {
    return this.userService.isUnrelated(user);
  }

  // public openComplaintModal() {
  //   this.modalRef = this.modalService.open(ComplaintComponent, {
  //     modalClass: 'modal-dialog-centered',
  //     data: {
  //       subject_id: this.user.id,
  //       category: 'user',
  //     }
  //   })
  // }

  restriction(restrictionType: string) {
    this.restrictionService.restriction(this.user?.id, restrictionType).subscribe((response) => {
      this.user.restriction = response;

      let action = '';

      switch (restrictionType) {
        case 'block':
        case 'unblock':
          action = `${restrictionType}ed`;
          this.blocked.emit(response);
          break;
        case 'mute':
        case 'unmute':
          action = `${restrictionType}d`;
          break;
      }

      if (action) this.toast.info(`${this.user.username || 'This user'} has been ${action}.`);
    });
  }

  copyShareLink() {
    this.utilitiesService.copyLinkToClipboard('user/' + this.user.username);
  }

  blockedModal(action) {
    this.modalRef = this.modalService.open(BlockedUserComponent, {
      modalClass: 'modal-dialog-centered',
      data: {
        user: this.user,
        action: action
      }
    })
  }
}