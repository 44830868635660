<app-alert></app-alert>

<div class="dashboard">
  <header class="dashboard-header">
    <div
      class="dashboard-header-container d-flex align-items-center"
      [ngClass]="{ 'justify-content-between px-2 px-sm-3': !largeScreen }"
    >
      <a [routerLink]="['/home']" role="button" [ngClass]="{ 'corchat-header-left': largeScreen }">
        <img src="../../../assets/images/corchat-logo.png" alt="Corchat Logo" class="corchat-logo" />
      </a>

      <ng-container *ngIf="largeScreen; then desktopScreen; else mobileScreen"></ng-container>

      <ng-template #desktopScreen>
        <div class="corchat-search-box">
          <div class="form-group has-search" [hidden]="!showSearch()">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              #searchInput
              class="form-control"
              (click)="search()"
              placeholder="Search"
              autocomplete="off"
              id="search"
            />
          </div>
        </div>

        <section class="corchat-header-right">
          <div class="corchat-user-profile" *ngIf="isLoggedIn()">
            <span [routerLink]="['/user/', user.username]" class="corchat-user-name text-lowercase">
              {{ user.username }}
            </span>
            <span class="border-line me-3"></span>
            <span (click)="toggleDropdown(); $event.stopPropagation()" style="cursor: pointer">
              <span class="crop" [appUserImage]="user" [clickable]="false" id="webview-profile-toggle"></span>
            </span>
          </div>
        </section>
      </ng-template>

      <ng-template #mobileScreen>
        <span class="mdi mdi-magnify" role="button" (click)="search()" routerLinkActive="active"></span>
        <span class="mdi mdi-map-outline" role="button" (click)="toggleLocationModal()"></span>
        <span role="button" [routerLink]="['/messages']" #messages="routerLinkActive" routerLinkActive="active">
          <span class="mdi mdi-message-text-outline" [ngClass]="{ active: messages.isActive }"></span>
          <span class="badge rounded-pill badge-notification bg-primary" *ngIf="messageCount > 0">{{
            messageCount
          }}</span>
        </span>
        <span
          role="button"
          [routerLink]="['/notifications']"
          #notifications="routerLinkActive"
          routerLinkActive="active"
          (click)="viewAllNotification()"
        >
          <span class="mdi mdi-bell-outline" [ngClass]="{ active: notifications.isActive }"></span>
          <span class="badge rounded-pill badge-notification bg-primary" *ngIf="notificationCount > 0">{{
            notificationCount
          }}</span>
        </span>

        <div class="d-flex align-items-center" *ngIf="isLoggedIn()">
          <span [routerLink]="['/user/', user.username]" class="corchat-user-name text-lowercase d-none d-sm-block">
            {{ user.username }}
          </span>

          <span
            class="crop"
            [appUserImage]="user"
            [clickable]="false"
            role="button"
            (click)="toggleDropdown(); $event.stopPropagation()"
            id="mobileview-profile-toggle"
          ></span>
        </div>

        <ul
          *ngIf="!dropdown_num"
          class="dropdown-menu dropdown-menu-sm animated slideInRight"
          aria-labelledby="dropdownMenuButton"
          (click)="hideDropdown()"
        >
          <li class="dropdown-item" id="mobileview-account-item" (click)="navigateToCentralAccount()">
            <span class="mdi mdi-emoticon-outline animated--drpdwn--icon"></span>
            <span class="animated--drpdwn--text">Seech Account</span>
          </li>
          <hr class="dropdown-divider" />
          <li [routerLink]="['/user/', user.username]" [queryParams]="{ tab: postTab }" class="dropdown-item"
            id="mobileview-profile-item">
            <span class="mdi mdi-account-outline animated--drpdwn--icon"></span>
            <span class="animated--drpdwn--text">Profile</span>
          </li>
          <li [routerLink]="['/user/', user.username]" [queryParams]="{ tab: locationTab }" class="dropdown-item"
            id="mobileview-location-item">
            <span class="mdi mdi-map-outline animated--drpdwn--icon"></span>
            <span class="animated--drpdwn--text">Locations</span>
          </li>
          <hr class="dropdown-divider" />
          <li [routerLink]="['/messages']" class="dropdown-item" id="mobileview-messages-item">
            <span class="mdi mdi-message-text-outline animated--drpdwn--icon"></span>
            <span class="corchat-number-indicators msg-count" *ngIf="messageCount > 0">{{ messageCount }}</span>
            <span class="animated--drpdwn--text">Messages</span>
          </li>
          <li [routerLink]="['/notifications']" class="dropdown-item" (click)="viewAllNotification()"
            id="mobileview-notifications-item">
            <span class="mdi mdi-bell-outline animated--drpdwn--icon"></span>
            <span class="corchat-number-indicators notif-count" *ngIf="notificationCount > 0">{{
              notificationCount
            }}</span>
            <span class="animated--drpdwn--text">Notifications</span>
          </li>
          <li [routerLink]="['/connections']" class="dropdown-item" id="mobileview-connections-item">
            <span class="mdi mdi-contacts-outline animated--drpdwn--icon"></span>
            <span class="animated--drpdwn--text">Connections</span>
          </li>
          <li [routerLink]="['/pending-requests']" class="dropdown-item" id="mobileview-requests-item">
            <span class="mdi mdi-plus-box-multiple-outline animated--drpdwn--icon"></span>
            <span class="corchat-number-indicators req-count" *ngIf="pendingRequestCount > 0">{{
              pendingRequestCount
            }}</span>
            <span class="animated--drpdwn--text">Requests</span>
          </li>
          <li [routerLink]="['/user/saved']" class="dropdown-item" id="mobileview-saved-item">
            <span class="material-icons-outlined mat-icon animated--drpdwn--icon">bookmark_border</span>
            <span class="animated--drpdwn--text">Saved</span>
          </li>
          <hr class="dropdown-divider" />
          <li [routerLink]="['/settings']" class="dropdown-item" id="mobileview-settings-item">
            <span class="mdi mdi-cog-outline animated--drpdwn--icon"></span>
            <span class="animated--drpdwn--text">Settings</span>
          </li>
          <li routerLink="/login" [queryParams]="{ action: 'logout' }" class="dropdown-item" id="mobileview-logout-item">
            <span class="material-symbols-outlined animated--drpdwn--icon">logout</span>
            <span class="animated--drpdwn--text">Logout</span>
          </li>
        </ul>
      </ng-template>
    </div>
    <app-http-progress-indicator></app-http-progress-indicator>
  </header>

  <div class="dashboard-body" [ngClass]="{ 'hide-right-sidebar': hideRightSection, 'position-relative': mobileScreen }">
    <aside class="dashboard-sidebar dashboard-sidebar-left" *ngIf="largeScreen">
      <nav class="dashboard-nav dashboard-nav-first">
        <ul class="corchat-sidenav-list">
          <li class="corchat-sidenav-list-item">
            <a routerLink="home" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-home-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-home-outline"></span>
              </span>
              <span class="corchat-sidenav-link-text">Home</span>
            </a>
          </li>
          <li class="corchat-sidenav-list-item">
            <a routerLink="people" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-people-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-account-multiple-outline"></span>
              </span>
              <span class="corchat-sidenav-link-text">People</span>
            </a>
          </li>
          <li class="corchat-sidenav-list-item">
            <a routerLink="/group/list" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-groups-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-account-group-outline"></span>
              </span>
              <span class="corchat-sidenav-link-text">Groups</span>
            </a>
          </li>
          <li class="corchat-sidenav-list-item">
            <a routerLink="event/list" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-events-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-calendar-text-outline"></span>
              </span>
              <span class="corchat-sidenav-link-text">Events</span>
            </a>
          </li>
          <li class="corchat-sidenav-list-item" *ngIf="showTrend">
            <a routerLink="trend/list" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-trends-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="material-icons-outlined" style="margin-top: 4px">whatshot</span>
              </span>
              <span class="corchat-sidenav-link-text">Trends</span>
            </a>
          </li>
        </ul>
      </nav>
      <nav class="dashboard-nav dashboard-nav-second">
        <ul class="corchat-sidenav-list">
          <li class="corchat-sidenav-list-item">
            <a routerLink="messages" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-messages-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-message-text-outline"></span>
                <span class="corchat-number-indicators" *ngIf="messageCount > 0">{{ messageCount }}</span>
              </span>
              <span class="corchat-sidenav-link-text">Messages</span>
            </a>
          </li>
          <li class="corchat-sidenav-list-item">
            <a routerLink="notifications" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-notifications-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-bell-outline"></span>
                <span class="corchat-number-indicators" *ngIf="notificationCount > 0">{{ notificationCount }}</span>
              </span>
              <span class="corchat-sidenav-link-text">Notifications</span>
            </a>
          </li>
          <li class="corchat-sidenav-list-item">
            <a routerLink="pending-requests" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-requests-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-plus-box-multiple-outline"></span>
                <span class="corchat-number-indicators" *ngIf="pendingRequestCount > 0">{{ pendingRequestCount }}</span>
              </span>
              <span class="corchat-sidenav-link-text">Requests</span>
            </a>
          </li>
          <li class="corchat-sidenav-list-item">
            <a routerLink="/connections" routerLinkActive="active-route" class="corchat-sidenav-link" id="webview-connections-sidenav">
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-contacts-outline"></span>
              </span>
              <span class="corchat-sidenav-link-text">Connections</span>
            </a>
          </li>
          <li class="corchat-sidenav-list-item">
            <a
              [routerLink]="['/user/', user.username]"
              [queryParams]="{ tab: locationTab }"
              routerLinkActive="active-route"
              class="corchat-sidenav-link"
              id="webview-locations-sidenav"
            >
              <span class="corchat-sidenav-link-vector-container">
                <span class="mdi mdi-map-outline"></span>
              </span>
              <span class="corchat-sidenav-link-text">Locations</span>
            </a>
          </li>
        </ul>
      </nav>
      <button class="dashboard-create-post" (click)="triggerPostModal()">
        <span class="material-symbols-outlined fs-6 text-white me-2"> add_circle </span>
        <span class="new-post-text text-capitalize text-white">Create New Post</span>
      </button>
    </aside>

    <!-- Mobile Location Widget Wrapper -->
    <app-saved-locations-modal *ngIf="isLocationModalOpen && !largeScreen"  (close)="closeLocationModal()"></app-saved-locations-modal>

    <main
      class="dashboard-content"
      [ngClass]="{ 'messages-screen': hideRightSection, 'overflow-hidden': isLocationModalOpen }"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onComplete()"
      [scrollWindow]="false"
    >
      <div class="dashboard-content-inner" [ngClass]="{ 'overflow-hidden': hideRightSection }">
        <router-outlet></router-outlet>
      </div>
    </main>

    <div class="dd-menu" *ngIf="hideRightSection && !mediumScreen">
      <ul
        *ngIf="!dropdown_num"
        class="dropdown-menu animated slideInRight"
        aria-labelledby="dropdownMenuButton"
        (click)="hideDropdown()"
      >
        <li class="dropdown-item" (click)="navigateToCentralAccount()">
          <span class="mdi mdi-emoticon-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text" >Seech Account</span>
        </li>
        <hr class="dropdown-divider" />
        <li [routerLink]="['/user/', user.username]" [queryParams]="{ tab: postTab }" class="dropdown-item"  id="webview-profile-item">
          <span class="mdi mdi-account-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Profile</span>
        </li>
        <li [routerLink]="['/user/', user.username]" [queryParams]="{ tab: locationTab }" class="dropdown-item"  id="webview-locations-item">
          <span class="mdi mdi-map-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Locations</span>
        </li>
        <hr class="dropdown-divider" />
        <li [routerLink]="['/messages']" class="dropdown-item"  id="webview-messages-item">
          <span class="mdi mdi-message-text-outline animated--drpdwn--icon"></span>
          <span class="corchat-number-indicators msg-count" *ngIf="messageCount > 0">{{ messageCount }}</span>
          <span class="animated--drpdwn--text">Messages</span>
        </li>
        <li [routerLink]="['/notifications']" class="dropdown-item"  id="webview-notifications-item">
          <span class="mdi mdi-bell-outline animated--drpdwn--icon"></span>
          <span class="corchat-number-indicators notif-count" *ngIf="notificationCount > 0">{{
            notificationCount
          }}</span>
          <span class="animated--drpdwn--text">Notifications</span>
        </li>
        <li [routerLink]="['/connections']" class="dropdown-item"  id="webview-connections-item">
          <span class="mdi mdi-contacts-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Connections</span>
        </li>
        <li [routerLink]="['/pending-requests']" class="dropdown-item"  id="webview-requests-item">
          <span class="mdi mdi-plus-box-multiple-outline animated--drpdwn--icon"></span>
          <span class="corchat-number-indicators req-count" *ngIf="pendingRequestCount > 0">{{
            pendingRequestCount
          }}</span>
          <span class="animated--drpdwn--text">Requests</span>
        </li>
        <li [routerLink]="['/user/saved']" class="dropdown-item"  id="webview-saved-item">
          <span class="material-icons-outlined mat-icon animated--drpdwn--icon">bookmark_border</span>
          <span class="animated--drpdwn--text">Saved</span>
        </li>
        <hr class="dropdown-divider" />
        <li [routerLink]="['/settings']" class="dropdown-item"  id="webview-settings-item">
          <span class="mdi mdi-cog-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Settings</span>
        </li>
        <li routerLink="/login" [queryParams]="{ action: 'logout' }" class="dropdown-item"  id="webview-logout-item">
          <span class="material-symbols-outlined animated--drpdwn--icon">logout</span>
          <span class="animated--drpdwn--text">Logout</span>
        </li>
      </ul>
    </div>

    <aside class="dashboard-sidebar dashboard-sidebar-right" *ngIf="!hideRightSection && largeScreen">
      <ul
        *ngIf="!dropdown_num"
        class="dropdown-menu animated slideInRight"
        aria-labelledby="dropdownMenuButton"
        (click)="hideDropdown()"
      >
        <li class="dropdown-item" (click)="navigateToCentralAccount()">
          <span class="mdi mdi-emoticon-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Seech Account</span>
        </li>
        <hr class="dropdown-divider"/>
        <li [routerLink]="['/user/', user.username]" [queryParams]="{ tab: postTab }" class="dropdown-item"
          id="webview-profile-item">
          <span class="mdi mdi-account-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Profile</span>
        </li>
        <li [routerLink]="['/user/', user.username]" [queryParams]="{ tab: locationTab }" class="dropdown-item"
          id="webview-locations-item">
          <span class="mdi mdi-map-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Locations</span>
        </li>
        <hr class="dropdown-divider" />
        <li [routerLink]="['/messages']" class="dropdown-item" id="webview-messages-item">
          <span class="mdi mdi-message-text-outline animated--drpdwn--icon"></span>
          <span class="corchat-number-indicators msg-count" *ngIf="messageCount > 0">{{ messageCount }}</span>
          <span class="animated--drpdwn--text">Messages</span>
        </li>
        <li [routerLink]="['/notifications']" class="dropdown-item" (click)="viewAllNotification()"
          id="webview-notifications-item">
          <span class="mdi mdi-bell-outline animated--drpdwn--icon"></span>
          <span class="corchat-number-indicators notif-count" *ngIf="notificationCount > 0">{{
            notificationCount
          }}</span>
          <span class="animated--drpdwn--text">Notifications</span>
        </li>
        <li [routerLink]="['/connections']" class="dropdown-item" id="webview-connections-item">
          <span class="mdi mdi-contacts-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Connections</span>
        </li>
        <li [routerLink]="['/pending-requests']" class="dropdown-item" id="webview-requests-item">
          <span class="mdi mdi-plus-box-multiple-outline animated--drpdwn--icon"></span>
          <span class="corchat-number-indicators req-count" *ngIf="pendingRequestCount > 0">{{
            pendingRequestCount
          }}</span>
          <span class="animated--drpdwn--text">Requests </span>
        </li>
        <li [routerLink]="['/user/saved']" class="dropdown-item" id="webview-saved-item">
          <span class="material-icons-outlined mat-icon animated--drpdwn--icon">bookmark_border</span>
          <span class="animated--drpdwn--text">Saved</span>
        </li>

        <hr class="dropdown-divider" />

        <li [routerLink]="['/settings']" class="dropdown-item" id="webview-settings-item">
          <span class="mdi mdi-cog-outline animated--drpdwn--icon"></span>
          <span class="animated--drpdwn--text">Settings</span>
        </li>
        <li routerLink="/login" [queryParams]="{ action: 'logout' }" class="dropdown-item" id="webview-logout-item">
          <span class="material-symbols-outlined animated--drpdwn--icon">logout</span>
          <span class="animated--drpdwn--text">Logout</span>
        </li>
      </ul>

      <section class="dashboard-widget dashboard-widget-location">
        <div class="corchat-right-sidenav-collapse">
          <div class="corchat-right-sidenav-collapse-item">
            <app-saved-locations-card (resync_location_update)="resyncByLocation()"></app-saved-locations-card>
          </div>
          <!-- close collapse-item -->
        </div>
        <!-- close collapse -->
      </section>

      <section class="dashboard-widget dashboard-widget-trending">
        <div class="pb-2 d-flex align-items-center">
          <span class="material-icons-outlined trend-fire-icon" *ngIf="showTrend">whatshot</span>

          <span class="heading-title-trending" *ngIf="showTrend">Trending</span>

          <span class="mdi mdi-account-group-outline" *ngIf="showSuggestedPins"></span>
          <span class="heading-title-trending" *ngIf="showSuggestedPins">Suggestions</span>
          <span class="heading-title-trending mt-2 widget-switch" *ngIf="showSwitch" (click)="widgetSwitch()">
            <span class="material-icons-outlined" style="cursor: pointer"> 360 </span>
          </span>
        </div>

        <div class="dashboard-widget-trending--suggestions" *ngIf="showSuggestedPins">
          <div class="row animated flipInY">
            <div class="col">
              <app-widget-pinned-users [users]="users"></app-widget-pinned-users>
            </div>
          </div>
        </div>
      </section>

      <article class="dashboard-meta">
         <a href="https://seech.com" target="_blank" class="dashboard-meta-link">&copy; Seech, {{ year }}</a>
        <span class="px-1"> | </span>
        <a class="dashboard-meta-link" role="button" (click)="triggerFeedbackModal()">Feedback</a>
        <span class="px-1"> | </span>
        <a [routerLink]="['support', 'faq']"class="dashboard-meta-link">FAQ</a>
        <span class="px-1"> | </span>
        <a [routerLink]="['support', 'legal']"class="dashboard-meta-link">Legal</a>
      </article>

      <!-- Message Chat Popup -->
      <app-message-chat-popup
        *ngIf="toggleMessage"
        (closeMessagePopupEmitter)="toggleMessage = false"
      ></app-message-chat-popup>

      <!-- New Message Box -->
      <app-new-message-box *ngIf="toggleNewMessageBox"></app-new-message-box>

      <div class="dashboard-chat-trigger-wrapper">
        <div class="profile-photo">
          <span class="crop-msg" [appUserImage]="user"></span>
        </div>

        <button type="button" class="dashboard-chat-trigger-btn" (click)="messageBoxToggle()">Messages</button>

        <div class="dashboard-chat-trigger-icons d-flex align-items-center me-2">
          <button type="button" (click)="showNew()" class="dashboard-chat-trigger-icons-btn">
            <i class="mdi mdi-email-plus-outline fs-6 me-1 text-white" aria-hidden="true"></i>
          </button>
          <button type="button" (click)="messageBoxToggle()" class="dashboard-chat-trigger-icons-btn">
            <i
              class="fa fs-6 text-white"
              [ngClass]="{ 'fa-chevron-up': !toggleMessage, 'fa-chevron-down': toggleMessage }"
            ></i>
          </button>
        </div>
      </div>
    </aside>
  </div>

  <!-- Mobile Navigation -->
  <nav class="dashboard-mobile-nav" *ngIf="mediumScreen" [ngClass]="{ 'messages-screen': hideRightSection }">
    <mdb-sidenav-layout>
      <!-- Bottom Tabs for mobile/tablets -->
      <div class="d-flex align-items-center justify-content-evenly">
        <span role="button" [routerLink]="['/home']" #home="routerLinkActive" routerLinkActive="active-route">
          <span [ngClass]="{ 'active-route': home.isActive }" class="mdi mdi-home-outline fs-1 menu-font"></span>
          <h6 [ngClass]="{ 'active-route': home.isActive }" class="menu-font">Home</h6>
        </span>
        <span role="button" [routerLink]="['/people']" #people="routerLinkActive" routerLinkActive="active-route">
          <span
            [ngClass]="{ 'active-route': people.isActive }"
            class="mdi mdi-account-multiple-outline fs-1 menu-font"
          ></span>
          <h6 [ngClass]="{ 'active-route': people.isActive }" class="menu-font">People</h6>
        </span>
        <span role="button" [routerLink]="['/group/list']" #group="routerLinkActive" routerLinkActive="active-route">
          <span
            [ngClass]="{ 'active-route': group.isActive }"
            class="mdi mdi-account-group-outline fs-1 menu-font"
          ></span>
          <h6 [ngClass]="{ 'active-route': group.isActive }" class="menu-font">Groups</h6>
        </span>
        <span role="button" [routerLink]="['/event/list']" #event="routerLinkActive" routerLinkActive="active-route">
          <span
            [ngClass]="{ 'active-route': event.isActive }"
            class="mdi mdi-calendar-text-outline fs-1 menu-font"
          ></span>
          <h6 [ngClass]="{ 'active-route': event.isActive }" class="menu-font">Events</h6>
        </span>
        <mdb-sidenav-content #sidenavContent>
          <span role="button" #sidenavToggle (click)="toggleMenu()">
            <span class="mdi mdi-menu fs-1 menu-font"></span>
            <h6>Menu</h6>
          </span>
        </mdb-sidenav-content>
      </div>

      <!-- Sidemenu for mobile/tablets -->
      <mdb-sidenav #sidenav="mdbSidenav" [accordion]="true" (sidenavHidden)="menuClosed(event)">
        <div class="d-flex flex-column position-fixed top-0 left-0 w-100" [ngStyle]="{ 'height': windowHeight + 'px'}">
          <ul class="sidenav-menu">
            <mdb-sidenav-item>
              <a class="sidenav-link mb-3" [routerLink]="['/home']" (click)="sidenav.hide()">
                <img src="../../../assets/images/corchat-logo.png" alt="Corchat Logo" class="corchat-logo" />
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                class="sidenav-link"
                [routerLink]="['/home']"
                #home="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                id="mobileview-home-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': home.isActive }"
                  class="mdi mdi-home-outline fs-1 me-3 menu-font"
                ></span>
                <span [ngClass]="{ 'active-route': home.isActive }" class="fs-6 menu-font">Home</span>
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                class="sidenav-link"
                [routerLink]="['/people']"
                #people="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                id="mobileview-people-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': people.isActive }"
                  class="mdi mdi-account-multiple-outline fs-1 me-3 menu-font"
                ></span>
                <span [ngClass]="{ 'active-route': people.isActive }" class="fs-6 menu-font">People</span>
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                class="sidenav-link"
                [routerLink]="['/group/list']"
                #group="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                id="mobileview-groups-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': group.isActive }"
                  class="mdi mdi-account-group-outline fs-1 me-3 menu-font"
                ></span>
                <span [ngClass]="{ 'active-route': group.isActive }" class="fs-6 menu-font">Groups</span>
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                class="sidenav-link"
                [routerLink]="['/event/list']"
                #event="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                id="mobileview-events-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': event.isActive }"
                  class="mdi mdi-calendar-text-outline fs-1 me-3 menu-font"
                ></span>
                <span [ngClass]="{ 'active-route': event.isActive }" class="fs-6 menu-font">Events</span>
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                *ngIf="trendIsActive"
                class="sidenav-link"
                [routerLink]="['/trends']"
                #trend="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                id="mobileview-trends-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': trend.isActive }"
                  class="material-icons-outlined d-flex align-items-center fs-1 menu-font me-3"
                  >whatshot</span
                >
                <span [ngClass]="{ 'active-route': trend.isActive }" class="fs-6 menu-font">Trends</span>
              </a>
            </mdb-sidenav-item>

            <hr class="side-menu-divider" />

            <mdb-sidenav-item>
              <a
                class="sidenav-link mt-4"
                [routerLink]="['/messages']"
                #messages="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                id="mobileview-messages-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': messages.isActive }"
                  class="mdi mdi-message-text-outline fs-1 me-3 menu-font"
                ></span>
                <span class="corchat-number-indicators" *ngIf="messageCount > 0">{{ messageCount }}</span>
                <span [ngClass]="{ 'active-route': messages.isActive }" class="fs-6 menu-font">Messages</span>
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                class="sidenav-link"
                [routerLink]="['/notifications']"
                #notifications="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                (click)="viewAllNotification()"
                id="mobileview-notifications-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': notifications.isActive }"
                  class="mdi mdi-bell-outline fs-1 me-3 menu-font"
                ></span>
                <span class="corchat-number-indicators side-notif-count" *ngIf="notificationCount > 0">{{
                  notificationCount
                }}</span>
                <span [ngClass]="{ 'active-route': notifications.isActive }" class="fs-6 menu-font">Notifications</span>
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                class="sidenav-link"
                [routerLink]="['/pending-requests']"
                #pendingRequests="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                id="mobileview-requests-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': pendingRequests.isActive }"
                  class="mdi mdi-plus-box-multiple-outline fs-1 me-3 menu-font"
                ></span>
                <span class="corchat-number-indicators side-notif-count" *ngIf="pendingRequestCount > 0">{{
                  pendingRequestCount
                }}</span>
                <span [ngClass]="{ 'active-route': pendingRequests.isActive }" class="fs-6 menu-font">Requests</span>
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                class="sidenav-link"
                [routerLink]="['/connections']"
                #connections="routerLinkActive"
                routerLinkActive="active-route"
                (click)="sidenav.hide()"
                id="mobileview-connections-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': connections.isActive }"
                  class="mdi mdi-contacts-outline fs-1 me-3 menu-font"
                ></span>
                <span [ngClass]="{ 'active-route': connections.isActive }" class="fs-6 menu-font">Connections</span>
              </a>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a
                class="sidenav-link"
                #locations="routerLinkActive"
                routerLinkActive="active-route"
                [routerLink]="['/user/', user.username]"
                [queryParams]="{ tab: locationTab }"
                (click)="sidenav.hide()"
                id="mobileview-locations-sidenav"
              >
                <span
                  [ngClass]="{ 'active-route': locations.isActive }"
                  class="mdi mdi-map-outline fs-1 me-3 menu-font"
                ></span>
                <span [ngClass]="{ 'active-route': locations.isActive }" class="fs-6 menu-font">Locations</span>
              </a>
            </mdb-sidenav-item>
          </ul>
          <section class="mt-auto">
            <button class="dashboard-create-post rounded-0" (click)="triggerPostModal(); sidenav.hide()">
              <span class="material-symbols-outlined fs-6 text-white me-2"> add_circle </span>
              <span class="new-post-text text-capitalize text-white">Create New Post</span>
            </button>
          </section>
        </div>
      </mdb-sidenav>
    </mdb-sidenav-layout>
  </nav>
</div>
