import { Directive, ElementRef, Input } from '@angular/core';
import { GroupService } from '../modules/feature/groups/group.service';

@Directive({
  selector: '[appGroupImage]'
})
export class GroupImageDirective {

  private groupImage: string;

  @Input() set appGroupImage(value: string) {
    this.groupImage = this.groupService.getGroupImageString(value);
    (this.el.nativeElement as HTMLElement).style.backgroundImage = `${this.groupImage}`;
  };

  constructor(private el: ElementRef, private groupService: GroupService) {

  }

}
