import { FeatureflagService } from '@src/app/services/featureflag.service';
import { MainMasterService } from './main-master.service';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, HostListener, HostBinding, OnDestroy, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { User, Geotag, SavedLocation, Diagnostic, Trend, SearchParam } from '../../core/models';
import { ProfileTabs, ModalTracks } from '../../core/config';
import { SessionService } from '../../services/session.service';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { SignalrService } from '../../services/signalr.service';
import { Subscription } from 'rxjs';
import { DiagnosticService } from '../../services/diagnostic.service';
import { Types, featureFlagsName, pagination } from '@src/app/core/Constants';
import { MessagingService } from '@src/app/services/messaging.service';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';
import { ScreenSize } from './../../core/Constants';
import { SidenavComponent } from 'ng-uikit-pro-standard';
import { environment } from './../../../environments/environment';
import { SavedLocationsCardComponent } from './saved-locations-card/saved-locations-card.component';
// import { SavedLocationsModalComponent } from './saved-locations-modal/saved-locations-modal.component';
import { NotificationsService } from '@src/app/modules/feature/notification/notifications.service';
import { ModalPostComponent } from '@src/app/modules/general/post-builder/modal-post/modal-post.component';
import { TrendService } from '@src/app/modules/feature/trends/trend.service';
import { SavedLocationService } from '@src/app/modules/feature/user/saved-location/saved-location.service';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { GeolocationService } from '@src/app/modules/general/geolocation/geolocation/geolocation.service';
import { MessageService } from '@src/app/modules/feature/message/message/message.service';
import { UtilitiesService } from '@src/app/services/utilities.service';
import { SearchQueryBuilderComponent } from '@src/app/modules/feature/search/search-query-builder/search-query-builder.component';
import { isPlatformBrowser } from '@angular/common';
import { FeedbackModalComponent } from '@src/app/modules/feature/support/feedback-modal/feedback-modal.component';

@Component({
  selector: 'app-main-master',
  templateUrl: './main-master.component.html',
  styleUrls: ['./main-master.component.scss'],
})
export class MainMasterComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('style.height.px') windowHeight: number;
  private resizeListener: () => void;
  private _loggedIn: boolean;
  private _savedLocation: boolean;
  public year = new Date().getFullYear();
  public triggerPost = true;
  isLocationModalOpen: boolean = false;

  get savedLocation(): boolean {
    return this._savedLocation;
  }

  set savedLocation(value: boolean) {
    this._savedLocation = value;
  }

  get loggedIn(): boolean {
    return this._loggedIn;
  }

  set loggedIn(value: boolean) {
    this._loggedIn = value;
  }

  hideRightSection = false;
  animationState = false;
  users: User[] = [];
  user: User = {};
  id = '';
  current_location: Geotag = {};
  notificationCount: number = 0;
  pendingRequestCount: number = 0;
  messageCount: number = 0;
  savedLocations: SavedLocation[] = [];
  get savedLocationCount(): number {
    return this.savedLocations.length;
  }
  searchEntry: string = '';
  @ViewChild('trendCollapse') trendCollapse: any;
  toggleMessage = false;
  toggleNewMessageBox = false;
  clickEvent: Subscription;
  dropdown_num = true;
  trends: any[] = [];
  trendTab = 1;
  trendWidgetList = 5;
  show = true;
  trendIsActive: boolean;
  showTrend: boolean = false;
  showSuggestedPins: boolean = true;
  showSwitch: boolean = false;
  connectionType = { pinned: 'pinned' };
  tab = { category: 'post' };
  messageRouteFormatter = { message: 'messages', pathNumber: 1 };
  modalRef: MdbModalRef<ModalPostComponent> | MdbModalRef<SavedLocationsCardComponent> | MdbModalRef<SearchQueryBuilderComponent> = null;
  isProd = environment.production;

  // fontOptions = [
  //   { value: "Raleway", label: "Raleway" },
  //   { value: "Fira Code", label: "Fira Code" },
  //   { value: "Google Sans", label: "Google Sans" },
  //   { value: "Proxima Nova", label: "Proxima Nova" },
  //   { value: "DM Sans", label: "DM Sans" },
  //   { value: "Outfit", label: "Outfit" },
  //   { value: "Overpass", label: "Overpass" },
  //   { value: "Source Sans Pro", label: "Source Sans Pro" },
  // ];

  // defaultFallbackFont = `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji !important"`;

  query = '';
  headerStatus = false;
  locationTab: string = ProfileTabs.Location.toLowerCase();
  postTab: string = ProfileTabs.Post;
  params: SearchParam = {};
  enableRefresh: boolean;
  page = pagination.page;
  size = pagination.size;
  menuOptions = {
    home: 'home',
    people: 'people',
    groups: 'groups',
    events: 'events',
    trends: 'trends',
    messages: 'messages',
    notification: 'notify',
    connections: 'connect',
    locations: 'location',
    saved: 'saved',
  };

  @ViewChild('sidenav', { static: false }) sidenav: SidenavComponent;
  @ViewChild('searchInput') searchInput;

  screenSize = ScreenSize;
  mediumScreen = false;
  smallScreen = false;
  largeScreen = false;
  readonly breakpoint$ = this.breakpointObserver
    .observe([
      `(min-width: ${this.screenSize.xxl})`,
      `(min-width: ${this.screenSize.xl})`,
      `(min-width: ${this.screenSize.lg})`,
      `(min-width: ${this.screenSize.md})`,
      `(min-width: ${this.screenSize.sm})`,
    ])
    .pipe(distinctUntilChanged());

  @HostListener('document:click', ['$event']) onDocumentClick() {
    if (this.dropdown_num == false) {
      this.hideDropdown();
    }
  }

  constructor(
    private sessionService: SessionService,
    private trendService: TrendService,
    private storageService: StorageService,
    private router: Router,
    private notificationService: NotificationsService,
    private signalrService: SignalrService,
    private cdr: ChangeDetectorRef,
    private geolocationService: GeolocationService,
    private userService: UserService,
    private messageService: MessagingService,
    private diagnostic: DiagnosticService,
    private mainMasterService: MainMasterService,
    private mainMessageService: MessageService,
    private savedLocationService: SavedLocationService,
    private modalService: MdbModalService,
    private breakpointObserver: BreakpointObserver,
    private featureflagService: FeatureflagService,
    private utilitiesService: UtilitiesService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) this.windowHeight = window.innerHeight;
    this.breakpoint$.subscribe(() => this.breakPointChanged());

    this.storageService.data.subscribe((data) => {
      this.update(data);
    });

    this.sessionService.connect();
    this.removeNewMessageBox();

    // this.hideRightNavEvent()
    this.mainMasterService.hideRightWidgets$.subscribe((hide) => {
      this.hideRightSection = hide;
      this.cdr.detectChanges();
    });

    this.resetNotification()
    this.signalrService.retrieveNotification().subscribe((res) => {
      if (res?.id) {
        this.notificationCount++;
        this.cdr.detectChanges();
      }
    });

    this.signalrService.retreiveMessages().subscribe((res) => {
      if (res?.id) {
        if (!res.isRead) {
          //this.messageCount++;
          this.newMessageNotificationCount();
        } else {
          this.messageCount = 0;
          //this.messageCount--;
        }
      }
    });
  }

  ngOnInit() {
    if (this.isLoggedIn()) {
      this.getCurrentUser();
    }

    this.current_location = this.storageService.getCurrentGeo();
    this.savedLocations = this.geolocationService.GetSavedLocations();
    this.reloadLocations(); // reload from DB to ensure accurate sync

    // //message route control
    // const route  = String(this.router.routerState.snapshot.url).split('/');
    // if(route[this.messageRouteFormatter.pathNumber] === this.messageRouteFormatter.message){
    //     this.hideRightSection  = true;
    // }

    this.featureflagService.getFeatureFlag(featureFlagsName.trends).subscribe((active) => {
      this.trendIsActive = active;
      if (this.trendIsActive) this.getTrends();
    });

    if (isPlatformBrowser(this.platformId)) {
      this.resizeListener = this.utilitiesService.debounce(() => {
        this.windowHeight = window.innerHeight;
      }, 1000);
  
      window.addEventListener('resize', this.resizeListener);
    }
  }

  ngAfterViewInit(): void {
    this.NotificationCount();
    this.NotificationRequestCount();
    this.newMessageNotificationCount();
    this.cdr.detectChanges();
    // this.trendCollapse.show();
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) window.removeEventListener('resize', this.resizeListener);
  }

  isLoggedIn() {
    return this.sessionService.isValidLogin();
  }

  breakPointChanged() {
    if (this.breakpointObserver.isMatched(`(min-width: ${this.screenSize.xl})`)) {
      this.mediumScreen = false;
      this.largeScreen = true;
    } else if (this.breakpointObserver.isMatched(`(min-width: ${this.screenSize.lg})`)) {
      this.mediumScreen = false;
      this.largeScreen = true;
    } else if (this.breakpointObserver.isMatched(`(min-width: ${this.screenSize.md})`)) {
      this.mediumScreen = true;
      this.largeScreen = false;
    } else {
      this.mediumScreen = true;
      this.largeScreen = false;
    }
  } 

  triggerFeedbackModal() {
    const modalRef = this.modalService.open(FeedbackModalComponent, {
      modalClass: 'modal-md',
      // ignoreBackdropClick: true
    });

    modalRef.onClose.subscribe((result) => {
      // this.mainMasterService.updatePostStatus(result.text);
    });
  }

  triggerPostModal() {
    this.modalRef = this.modalService.open(ModalPostComponent, {
      modalClass: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        modalState: 'postFormModal',
        textInputType: ModalTracks.TextArea,
        triggerPost: true,
      },
    });

    this.modalRef.onClose.subscribe((result) => {
      this.mainMasterService.updatePostStatus(result.text);
    });
  }

  // handleFontChange(event) {
  //   let font = `"${event.target.value}", ${this.defaultFallbackFont}`;
  //   document.documentElement.style.setProperty("--font-family", font);
  // }

  onComplete() {
    this.mainMasterService.updateScrollPage(true);
  }

  getUserImage(userImage: string): object {
    return this.userService.getUserImage(userImage);
  }

  toggleMenu() {
    this.sidenav.toggle();
    this.headerStatus = true;
  }

  menuClosed() {
    this.headerStatus = false;
  }

  // public onInput(value: string){
  //     this.searchEntry = value;
  // }

  /*search() {
        if (this.searchEntry.trim()) { 
            this.router.navigate(['/search'], { queryParams: { query: this.searchEntry.trim() } });
        }
    }*/

  NotificationCount() {
    this.notificationService.getNotificationCount().subscribe((response) => {
      if (response > 0) {
        this.notificationCount = response;
      }
      return this.notificationCount;
    });
  }

  NotificationRequestCount() {
    this.notificationService.getNotificationRequestCount().subscribe((response) => {
      if (response > 0) {
        this.pendingRequestCount = response;
      }
      return this.pendingRequestCount;
    });
  }

  newMessageNotificationCount() {
    this.mainMessageService.messageNotificationCount().subscribe((result) => {
      this.messageCount = result;
    });
  }

  update(data) {
    switch (data.key) {
      case this.storageService.key.currentUser:
        this.user = JSON.parse(data.value) as User;
        this.savedLocations = this.user.savedLocations;
        break;
      case this.storageService.key.currentGeo:
        this.current_location = JSON.parse(data.value) as Geotag;
        break;
    }
  }

  //ngDoCheck() {
  //    this.isLoggedIn = this.sessionService.isValid();
  //}

  showSearch(): boolean {
    return this.router.isActive('/search', false) ? false : true;
  }

  getCurrentUser() {
    this.user = this.storageService.getCurrentUser();
    this.id = this.user.id;
  }

  search() {
    if(this.searchInput) {
      this.searchInput.nativeElement.value = "";
    }

    this.modalRef = this.modalService.open(SearchQueryBuilderComponent, {
      backdrop: true,
      modalClass: 'modal-md',
    });
  }


  SetDefault(currentSL: SavedLocation) {
    let user = this.storageService.getCurrentUser(); // get user object from local storge
    currentSL.isDefault = true;
    this.savedLocationService.update(currentSL).subscribe(
      (resp) => {
        if (resp) {
          for (let sl of this.user.savedLocations) {
            if (this.IsSame(sl, resp)) {
              sl.isDefault = true;
            } else {
              sl.isDefault = false;
            }
          }
          user.savedLocations = this.user.savedLocations;
          user.address = resp.address;
          this.savedLocationService.sendAllSavedLocations(user.savedLocations);
          this.storageService.setCurrentUser(user); //reset the user object in local storage reactively
          //this.refreshButton.emit(true); //Alert parent of location activity;
        }
      },
      (error) => this.diagnostic.displayMessage(<Diagnostic>error)
    );
  }

  SetActive(currentSL: SavedLocation) {
    currentSL.isActive = !currentSL.isActive;
    this.updateSavedLocationAPI(currentSL);
    this.enableRefresh = true;
  }

  updateSavedLocationAPI(sl: SavedLocation) {
    this.userService.updateSavedLocation(sl).subscribe(
      (resp: SavedLocation) => {
        this.user.savedLocations.forEach((x) => {
          if (x.id == resp.id) {
            x.isActive = resp.isActive;
          }
        });
        this.storageService.setCurrentUser(this.user); //reset the user object in local storage reactively
        this.savedLocationService.sendSavedLocation(resp, 'update');
      },
      (error) => this.diagnostic.displayMessage(<Diagnostic>error)
    );
  }

  IsSame(firstSL: SavedLocation, secondSL: SavedLocation): boolean {
    if (firstSL.id == secondSL.id && JSON.stringify(firstSL.CreatedOn) == JSON.stringify(secondSL.CreatedOn)) {
      return true;
    }
    return false;
  }

  getTrends() {
    this.trendService
      .gettrends() //replace with original service
      .subscribe();
  }

  widgetSwitch() {
    if (this.showSuggestedPins && this.trendIsActive) {
      this.showSuggestedPins = !this.showSuggestedPins;
      this.showTrend = true;
      this.showSwitch = true;
    } else if (this.showTrend) {
      this.showTrend = !this.showTrend;
      this.showSuggestedPins = true;
    }
  }

  gotoTrendDetails(trend: Trend) {
    this.buildURL(trend.name);
  }

  buildURL(query: string) {
    this.params.location = this.params.location || this.userService.get('coords');
    this.params.radius = this.params.radius || this.userService.get('radius');

    this.router.navigate(['/search'], {
      queryParams: {
        query: query.trim(),
        category: this.tab.category,
        location: this.params.location,
        radius: this.params.radius,
      },
    });
  }

  group; // TODO : JJ needs to look into this
  editGroup() {
    // TODO : JJ needs to look into this
  }

  toggleDropdown() {
    this.dropdown_num = !this.dropdown_num;
  }

  hideDropdown() {
    this.dropdown_num = true;
  }

  messageBoxToggle() {
    this.toggleMessage = !this.toggleMessage;
  }

  showNew() {
    this.messageService.onclickEvent();
    this.toggleNewMessageBox = true;
  }

  removeNewMessageBox() {
    this.clickEvent = this.messageService.getOnclick().subscribe(() => {
      this.toggleNewMessageBox = false;
    });
  }

  reloadLocations() {
    this.savedLocationService.getSavedLocations(this.page, this.size).subscribe(
      (sl) => {
        this.user.savedLocations = sl;
        this.storageService.setCurrentUser(this.user);
        this.enableRefresh = false;
      },
      (error) => this.diagnostic.displayMessage(<Diagnostic>error)
    );
  }

  navigateToCentralAccount() {
    window.open(environment.accountWebURL, '_blank');
  }

  toggleLocationModal() {
    this.isLocationModalOpen = !this.isLocationModalOpen;
    // this.modalRef = this.modalService.open(SavedLocationsModalComponent, {
    //   modalClass: 'modal-fullscreen modal-dialog-scrollable',
    // });
  }

  closeLocationModal() {
    this.isLocationModalOpen = false;
  }

  resyncByLocation() {
    this.mainMasterService.InitiateSync();
  }

  resetNotification(){
    this.notificationService.resetNotificationCount()
    .subscribe((response) => {
      switch(response){
        case Types.ViewPageNotification:
          this.notificationCount = 0
          break;
        case Types.RequestNotification:
          this.pendingRequestCount = 0;
          break;
      }
    });
  }
}
