import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild, ChangeDetectorRef, ElementRef, QueryList, ViewChildren, Inject, PLATFORM_ID } from '@angular/core';
import {
    trigger,
    state,
    style,
    transition,
    animate
} from '@angular/animations';
import { MdbCarouselComponent } from 'mdb-angular-ui-kit/carousel';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { VideoPlayerModalComponent } from '../video-player-modal/video-player-modal.component';
import { Views } from '@src/app/core/Constants';
import { BlobData, NowPlaying } from '@src/app/core/models/model';
import { BlobUrls } from '@src/app/core/config';
import { MessageService } from '@src/app/modules/feature/message/message/message.service';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-blob-carousel',
    templateUrl: './blob-carousel.component.html',
    styleUrls: ['./blob-carousel.component.scss'],
    animations: [
        trigger('slider', [
            state('next', style({
                marginLeft: '*' /*'calc(* - 720px)'*/
            })),
            state('previous', style({
                marginLeft: 'calc(* + 720px)'
            })),
            //transition('previous => next', [animate('2s')]),
            //transition('next => previous', [animate('2s')])
            transition('* => *', [animate('2s')])
            //transition('* => *', [style({ marginLeft: '*' }),
            //    animate(2000, style({ marginLeft: 0 }))])
        ])
    ],
})
export class BlobCarouselComponent implements OnInit {

    @ViewChild('carousel') carousel!: MdbCarouselComponent;
    @ViewChild('mainVideoRef') mainVideoRef: ElementRef;
    @ViewChildren(VideoPlayerComponent) videoPlayers: QueryList<VideoPlayerComponent>;

    @Input() multiImage: boolean;
    @Input() parent_category: string;
    @Input() blobsModals: BlobData[];
    @Input() upload_edit_mode: boolean = false;
    @Input() requirePrimary: boolean;
    @Input() disableNavigation: boolean;
    @Input() previewLimit = 6;
    @Input() blobs: BlobData[] = [];
    @Input() openInFullscreen: boolean;
    @Input() isSuspendedView: boolean;
    @Input() addSpacingToTop: boolean;
    @Input() addSpacingToBottom: boolean;
    @Input() innerRadius: boolean;
    @Output() currentBlobWithIndex = new EventEmitter<any>();
    @Input() showFullVisibleMediaWithOverlay: boolean;
    @Input() showFullVisibleMediaWithoutOverlay: boolean;
    @Input() showFullVisibleMediaInNonMultiImageLightBox: boolean;
    @Input() isFullyOccupyParentContainerHeight: boolean;
    @Input() videoView: string = 'cover';

    @Input() nowPlaying: NowPlaying;


    modalRef: MdbModalRef<VideoPlayerModalComponent>;
    get activeBlobs() { return this.blobs?.filter(x => !x.isDeleted) }
    //primary_blob: string;
    //primary_blob_warning: string = "Please set a primary / default Blob";
    carousel_state;

    slideInterval: number = 3000;
    interval;
    slideDuration: string = '2000ms';
    blobWidth: number = 720;
    current_slide_position: string = '0px';
    currentMargin: number = 0

    carousel_started: boolean;
    start_stop_btn_text: string = 'play';

    internet: number;
    percentDone: number;
    startTime: any;
    endTime: any;
    currTime: any;
    prevTime: any;
    speed: number = 0;
    bytesReceived: number = 0;
    oldbytes: number = 0;
    unit: string = "Mbps";
    views = Views;
    currentBlobId: string;

    @Output() blobClick = new EventEmitter<any>();

    public constructor(
        private modalService: MdbModalService, 
        private cdr: ChangeDetectorRef, 
        private el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object) { }

    ngOnInit() {
        this.start();

        if (this.blobsModals) {
            this.blobsModals.forEach(blob => {
                this.blobs.unshift(blob);
            });

            //this.blobs.push(this.blobsModals[0])
        }  
    }

    getURL(blob: BlobData) {        
        return encodeURI(BlobUrls.Download + '/' + blob.url);
    }

    getVideoPlayerById(videoId: string): VideoPlayerComponent | undefined {
        return this.videoPlayers.find(player => player.id === videoId);
    }

    getNowPlaying() {
        return this.getVideoPlayerById(this.currentBlobId)?.getNowPlaying();
    }

    onVideoClick(nowPlaying: NowPlaying, func: Function, args: any[]) {
        if (args.length === 2) {
            func.apply(this, args.concat(nowPlaying));
        } else {
            func.apply(this, args.concat(undefined, nowPlaying));
        }
    }

    onBlobClick(blobIndex: number, blob?: BlobData, nowPlaying?: NowPlaying) {
        if (this.disableNavigation) return;

        const currentView = this.views.media;
        
        if (typeof blob !== 'undefined') {
            const blobAutoplay = blob.autoplay;
            this.blobClick.emit({ blobIndex, blobAutoplay, currentView, nowPlaying });
            blob.autoplay = false;
            this.blobs.forEach((b) => { b.autoplay = false; })
            return;
        }

        this.blobClick.emit({ blobIndex, currentView, nowPlaying });
        let blobMessage = {blobs: this.activeBlobs, index: blobIndex}
        this.currentBlobWithIndex.emit(blobMessage)
    }
      
    showVideo(blob: BlobData) {
        this.modalRef = this.modalService.open(VideoPlayerModalComponent, {
            modalClass: 'modal-transform modal-xl modal-full overflow-hidden',
            data: {
                blob: blob
            }
        })
    }

    getVideos() {
        if (isPlatformBrowser(this.platformId)) {
            return this.el.nativeElement.querySelectorAll('video');
        }

        return []; // Return an empty array or appropriate fallback for SSR
    }

    onPlay(event) {
        // for (let i = 0; i < this.getVideos().length; i++) {
        //     if (event != this.getVideos()[i]) {
        //         this.getVideos()[i].pause();
        //     }
        // }
    }

    onEnded(event) {
        // for (let i = 0; i < this.getVideos().length; i++) {
        //     const observer = new IntersectionObserver((entries, observer) => {
        //         entries.forEach(entry => {
        //             if (this.getVideos()[i + 1]) {
        //                 if (entry.intersectionRatio === 1 && this.getVideos()[i + 1] != event) {
        //                     this.getVideos()[i + 1].play();
        //                     this.onPlay(this.getVideos()[i + 1]);
        //                 }
        //                 // Else start afresh
        //             } else {
        //                 this.getVideos()[0].play();
        //                 this.onPlay(this.getVideos()[0]);
        //             }
        //         });
        //     }, { threshold: 1 });

        //     observer.observe(this.getVideos()[i]);
        // }
    }

    start() {
        this.interval = setInterval(() => this.get_next_slide(), this.slideInterval);
        this.carousel_started = true;
        this.start_stop_btn_text = 'stop';
    }

    stop() {
        clearInterval(this.interval);
        this.carousel_started = false;
        this.start_stop_btn_text = 'play';
    }

    toggle_start_stop() {
        if (this.carousel_started) {
            this.stop();
        }
        else {
            this.start();
        }
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (this.carousel_started) {
            this.stop();
        }
    }
    @HostListener('mouseleave') onMouseLeave() {
        if (!this.carousel_started) {
            this.start();
        }
    }

    get_next_slide() {

        if (!!this.blobs && this.blobs.length > 0) {
            if (this.currentMargin == (-this.blobWidth * (this.blobs.length - 1))) {
                this.currentMargin = 0;
            }

            else {
                this.currentMargin -= this.blobWidth;
            }

            this.current_slide_position = this.currentMargin + 'px';
        }
    }

    get_previous_slide() {
        //this.carousel_state = "previous";
        this.currentMargin += this.blobWidth;
        this.current_slide_position = this.currentMargin + 'px';
    }

    public set_primary(blob: BlobData): void {
        if (this.disableNavigation) return;

        for (let img of this.blobs) {
            if (img.isPrimary) {
                img.isPrimary = false;
            }
        }

        blob.isPrimary = true;
        //this.primary_blob = blob.url;
    }

    //no_primary_blob(): boolean {

    //    let primaryBlobNotSet: boolean = true;

    //    for (let blob of this.blobs) {

    //        if (blob.isPrimary) {
    //            //this.primary_blob_warning = "";
    //            primaryBlobNotSet = false;
    //            break;
    //        }
    //    }

    //    primaryBlobNotSet = !this.upload_edit_mode ? false : primaryBlobNotSet;

    //    return primaryBlobNotSet;
    //}

    public deleteSlide(blob: BlobData): void {
        if (this.disableNavigation) return;

        blob.isDeleted = true;

        this.carousel?.next();

        this.cdr.detectChanges();
    }

    public restore_slide(blob: BlobData): void {
        if (this.disableNavigation) return;

        blob.isDeleted = false;
    }

    getNewBlobs(): BlobData[] {
        let newBlobs: BlobData[] = [];

        for (let blob of this.blobs) {
            //blob.content = null;

            if (blob.isDeleted != true) {
                newBlobs.push(blob);
            }
        }

        return newBlobs;
    }

    getEditedBlobs(new_blobs: BlobData[], old_blobs: BlobData[]): BlobData[] {
        let editedBlobs: BlobData[] = [];
        for (let new_blob of new_blobs) {
            if (new_blob.newUpload) {
                if (new_blob.isDeleted != true) {
                    editedBlobs.push(new_blob);
                }
            }
            else if (new_blob.isDeleted) {
                //let deletedBlob: BlobData = <BlobData>{ id: new_blob.id, isDeleted: new_blob.isDeleted }
                let deletedBlob: BlobData = <BlobData>{ id: new_blob.id, isDeleted: new_blob.isDeleted, blobCategory: new_blob.blobCategory, modifiedName: new_blob.modifiedName }
                //alert("Deleted : " + JSON.stringify(deletedBlob));
                editedBlobs.push(deletedBlob);
            }
            else {
                editedBlobs = new_blobs;
            }

            //else {
            //    for (let old_blob of old_blobs) {
            //        if ((new_blob.blob_ID == old_blob.blob_ID) && (new_blob.isPrimary != old_blob.isPrimary)) {
            //            let primaryBlob: BlobData = <BlobData>{ file_guid: new_blob.blob_ID, isPrimary: new_blob.isPrimary }
            //            //alert("Primary : " + JSON.stringify(primaryBlob));
            //            editedBlobs.push(primaryBlob);
            //        }
            //    }
            //}
        }
        return editedBlobs;
    }

    hasBlob(): boolean {

        if (this.blobs.length > 0) {
            for (let blob of this.blobs) {
                if (blob.isDeleted != true) {
                    return true;
                }
            }

            return false;
        }

        else {
            return false;
        }
    }
}