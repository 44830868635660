import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileTabs } from '@src/app/core/config';
import { pagination, savedLocationLimit } from '@src/app/core/Constants';
import { User, Geotag, SavedLocation, Diagnostic, MessageData } from '@src/app/core/models/model';
import { GenericModalService } from '@src/app/modules/core/generic-modal-dialog/generic-modal/generic-modal.service';
import { SavedLocationService } from '@src/app/modules/feature/user/saved-location/saved-location.service';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { GeolocationService } from '@src/app/modules/general/geolocation/geolocation/geolocation.service';
import { ModalPostComponent } from '@src/app/modules/general/post-builder/modal-post/modal-post.component';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { StorageService } from '@src/app/services/storage.service';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-saved-locations-card',
  templateUrl: './saved-locations-card.component.html',
  styleUrls: ['./saved-locations-card.component.scss']
})
export class SavedLocationsCardComponent implements OnInit {
  users: User[] = [];
  user: User = {};
  current_location: Geotag = {};
  locationTab: string = ProfileTabs.Location.toLowerCase();
  page = pagination.page;
  size = pagination.size;
  isLoggedIn: boolean;
  savedLocations: SavedLocation[] = [];
  savedLocation: SavedLocation = {};
  allSavedLocations: SavedLocation[] = [];
  modalRef: MdbModalRef<ModalPostComponent>;
  savedLocationForm;
  get savedLocationCount(): number { return this.savedLocations?.length };
  enableRefresh: boolean;
  geotag: Geotag;
  @Output() resync_location_update = new EventEmitter<any>();
  readonly currentUsername: string = this.userService.getCurrentUserName();

  constructor(private userService: UserService,
    private geolocationService: GeolocationService,
    private storageService: StorageService,
    private savedLocationService: SavedLocationService,
    private diagnostic: DiagnosticService,
    private genericModalService: GenericModalService,
  ) {
    this.storageService.data.subscribe(data => { this.update(data); });
    this.user = this.storageService.getCurrentUser();

    this.savedLocationService.locationSyncTrigger().subscribe(() => this.reloadLocations());

  }

  ngOnInit(): void {
    this.current_location = this.storageService.getCurrentGeo();
    this.savedLocations = this.geolocationService.GetSavedLocations();
    this.reloadLocations();
  }

  reloadLocations() {
    this.resync_location_update.emit();
    this.savedLocationService.getSavedLocations(this.page, this.size)
      .subscribe(
        sl => {
          this.user.savedLocations = sl;
          this.storageService.setCurrentUser(this.user);
          this.enableRefresh = false;
        },
        error => this.diagnostic.displayMessage(<Diagnostic>error));
  }

  SetDefault(currentSL: SavedLocation) {
    let user = this.storageService.getCurrentUser(); // get user object from local storge
    currentSL.isDefault = true;
    this.savedLocationService.update(currentSL).subscribe((resp) => {
      if (resp) {
        for (let sl of this.user.savedLocations) {
          if (this.IsSame(sl, resp)) {
            sl.isDefault = true;
          }
          else {
            sl.isDefault = false;
          }
        }
        user.savedLocations = this.user.savedLocations
        user.address = resp.address;
        this.savedLocationService.sendAllSavedLocations(user.savedLocations);
        this.storageService.setCurrentUser(user); //reset the user object in local storage reactively 
        this.enableRefresh = true;
      }
    },
      error => this.diagnostic.displayMessage(<Diagnostic>error));
  }

  SetActive(currentSL: SavedLocation) {
    currentSL.isActive = !currentSL.isActive;
    this.updateSavedLocationAPI(currentSL);
    this.enableRefresh = true;
  }

  updateSavedLocationAPI(sl: SavedLocation) {
    this.userService.updateSavedLocation(sl).subscribe((resp: SavedLocation) => {
      this.user.savedLocations.forEach((x) => {
        if (x.id == resp.id) { x.isActive = resp.isActive }
      });
      this.storageService.setCurrentUser(this.user); //reset the user object in local storage reactively
      this.savedLocationService.sendSavedLocation(resp, "update");

    },
      error => this.diagnostic.displayMessage(<Diagnostic>error));
  }

  IsSame(firstSL: SavedLocation, secondSL: SavedLocation): boolean {
    if ((firstSL.id == secondSL.id) && (JSON.stringify(firstSL.CreatedOn) == JSON.stringify(secondSL.CreatedOn))) {
      return true;
    }
    return false;
  }

  update(data) {
    switch (data.key) {
      case this.storageService.key.currentUser:
        this.user = JSON.parse(data.value) as User;
        this.savedLocations = this.user.savedLocations;
        break;
      case this.storageService.key.currentGeo:
        this.current_location = JSON.parse(data.value) as Geotag;
        break;
    }
  }

  limitData = <MessageData>{
    title: "",
    message: "",
  };

  createLocation() {
    this.limitData.title = "Saved Location Limit Alert";
    this.limitData.message = "Maximum number (" + savedLocationLimit + ") of locations exceeded. Please edit or delete an existing location.";
    this.limitData.showOption = false;
    this.limitData.infoIcon = false;
    const locationList = this.storageService.getCurrentUser();
    if (locationList.savedLocations.length >= savedLocationLimit) {
      this.savedLocationService.limitAlert(this.limitData);
      return false;
    } else {
      this.savedLocationService.openLocationModal()
    }
  }

}
