import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ViewChild, Input, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { Geotag, Geoboundary } from '@src/app/core/models/model';
import { GeolocationService } from '@src/app/modules/general/geolocation/geolocation/geolocation.service';

declare var google: any;

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent implements OnInit {
  @ViewChild('geolocation_map') geolocation_map;

  @Input() locationDetails: Geotag = {};
  @Input() boundaryDetails: Geoboundary = null;
  @Input() isFullyOccupyParentContainerHeight: boolean = false;

  map;
  marker;
  boundary_image;
  shape_options = this.geolocationService.shape_options;
  constructor(private geolocationService: GeolocationService, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.displayMap();
    }
  }

  displayMap() {
    let coords = this.locationDetails.coordinates.split(",");
    this.map = new google.maps.Map(this.el.nativeElement.querySelector('#geolocation_map'), {
      zoom: 12,
      center: new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1])),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      navigationControlOptions: { style: google.maps.NavigationControlStyle.SMALL }
    });

    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1])),
      draggable: false,
    });

    if (this.boundaryDetails) {
      if (this.boundaryDetails.pref == 'radius' && this.boundaryDetails.radius) {
        this.shape_options.Normal['center'] = this.geolocationService.createLatLng(this.locationDetails.coordinates);
        this.shape_options.Normal['radius'] = this.boundaryDetails.radius;
        this.boundary_image = new google.maps.Circle(this.shape_options.Normal);

      }

      if (this.boundaryDetails.pref == 'geofence' && this.boundaryDetails.polygon) {
        this.boundaryDetails.polygon.forEach((p) => {
          p.lat = Number(p.lat);
          p.lng = Number(p.lng);
        })
        this.shape_options.Normal['paths'] = this.boundaryDetails.polygon;
        this.boundary_image = new google.maps.Polygon(this.shape_options.Normal);
      }
      this.boundary_image.setMap(this.map);
      this.marker.setMap(this.map);
    }

    this.marker.setMap(this.map);
  }

}
