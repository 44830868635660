<section class="invitation-snippets">
  <app-loading-indicator *ngIf="loading"></app-loading-indicator>
  <div
    class="invitation-snippets-row shadow-card"
    *ngFor="let notification of eventRequests; let even = even; let odd = odd;" trackById
    [ngClass]="{ 'list-odd': odd, 'list-even': even }"
  >
    <div class="invitation-user-image-box">
      <div
        (click)="viewNotification(notification.id)"
        [routerLink]="['/user/', notification.user.username]"
        class="invitation-user-image"
        [styleDeclaration]="styleDeclaration"
        [appUserImage]="notification.user"
      ></div>
    </div>

    <div class="w-100" (click)="viewNotification(notification.id)">
      <div class="d-flex text-btn-wrapper justify-content-between align-items-center">
        <span class="invite" [routerLink]="['/event/', notification.localNotificationdata.id]" role="button">
          <span class="invite-sender text-lowercase"> {{ notification.user.username }} </span>
          <span class="invite-text"> invited you to attend </span>
          <a class="d-inline-flex"
            ><span class="invite-body d-inline-block text-truncate">
              {{ notification.localNotificationdata.body + ' event' }}</span
            ></a
          >
        </span>

        <span class="">
          <div
            *ngIf="notification.localNotificationdata.invitation?.accepted == null; else eventAction"
            class="d-flex mt-1 gap-2"
          >
            <button
              [id]="'eventReject' + notification.id"
              [disabled]="notification.isDisabled"
              class="btn btn-sm btn-outline-primary"
              (click)="eventReject(notification)"
            >
              <span class="mdi mdi-close-circle-outline ms-n2 me-2"></span>
              <span class="me-n1">Ignore</span>
            </button>
            <button
              [id]="'eventAccept' + notification.id"
              [disabled]="notification.isDisabled"
              class="btn btn-sm btn-primary"
              (click)="eventAccept(notification)"
            >
              <span class="mdi mdi-calendar-check-outline ms-n1 me-2"></span>
              <span class="me-n1">Attend</span>
            </button>
          </div>
          <ng-template #eventAction>
            <div class="dropdown" mdbDropdown #dropdown>
              <button
                [className]="
                  notification.localNotificationdata.invitation.accepted
                    ? 'btn btn-sm btn-outline-primary'
                    : 'btn btn-sm btn-outline-primary'
                "
                aria-expanded="false"
                id="dropdownMenuButton"
                mdbDropdownToggle
                [disabled]="notification.localNotificationdata.invitation.accepted || notification.isDisabled"
              >
                <span
                  *ngIf="!notification.localNotificationdata.invitation.accepted"
                  class="mdi mdi-close-circle-outline ms-n2 me-2"
                ></span>
                <span class="me-1">{{
                  notification.localNotificationdata.invitation.accepted ? 'Attending' : 'Ignored'
                }}</span>
                <i
                  *ngIf="!notification.localNotificationdata.invitation.accepted"
                  class="mdi mdi-chevron-down me-n3 dropdown-toggle"
                ></i>
              </button>
              <ul mdbDropdownMenu class="dropdown-menu dropdown-list" aria-labelledby="dropdownMenuButton">
                <li (click)="dropdown.hide()">
                  <a
                    role="button"
                    class="dropdown-item"
                    (click)="responseOption(notification, notification.localNotificationdata.invitation.accepted)"
                  >
                    <span class="mdi mdi-calendar-check-outline dropdown-list-icon"></span>
                    <span class="dropdown-list-text">Attend</span>
                  </a>
                </li>
              </ul>
            </div>
          </ng-template>
        </span>
      </div>
    </div>
  </div>
</section>
