import { StorageService } from '@src/app/services/storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiUrls } from '../core/config';
import { FeatureFlag } from '../core/models';
import { featureFlags } from '../core/Constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureflagService {

  private readonly featureFlagUrl = ApiUrls.FeatureFlagUrl;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getFeatureFlags(): Observable<FeatureFlag[]> {
    // return this.http.get<FeatureFlag[]>(`${this.featureFlagUrl}`);
    return of([]); // temporarily kill feature flags
  }

  getFeatureFlag(featureName: string): Observable<boolean> {
    // return this.http.get<boolean>(`${this.featureFlagUrl}/${featureName}`);
    return of(false); // temporarily kill feature flags
  }

  isActive(flagName: string): boolean {
    // let response = JSON.parse(this.storageService.get(featureFlags));
    // let flag = <FeatureFlag>response?.find(c => c.name == flagName);
    // return flag ? flag.isEnabled : false;

    return false; // temporarily kill feature flags
  }

}
