import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Complaint, Diagnostic, Violation } from '@src/app/core/models/model';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { ToasterService } from '@src/app/services/toaster.service';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ModalPostComponent } from '../../general/post-builder/modal-post/modal-post.component';
import { AlertService } from '../alert/alert.service';
import { ComplaintService } from './complaint.service';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss'],
  providers: [ComplaintService]
})
export class ComplaintComponent implements OnInit {

  maxlength: number = 200;
  @Input() subject_id: string;
  @Input() category: string;
  @Output() completed = new EventEmitter<boolean>();

  complaint = <Complaint>{};
  violation = <Violation>{};
  violations: Violation[] = [];
  showDetails: boolean = false;
  complaintForm: FormGroup = new FormGroup({
    violation: new FormControl(true),
    details: new FormControl(''),
    isAnonymous: new FormControl(true)
  });

  constructor(private complaintService: ComplaintService, private router: Router,
              private diagnostic: DiagnosticService, private toasterService: ToasterService,
              public modalRef: MdbModalRef<ModalPostComponent>
              ) {
                this.complaintForm.controls['violation'].valueChanges.subscribe((v: Violation) => {
                  this.violation = v;
                  if (this.isOther(this.violation.value)) {
                    this.showDetails = true;
                  }
                  else {
                    this.showDetails = false;
                  }
                });
              }

  ngOnInit(): void {
    this.getViolations();
    // this.feedback.category = "post";
    // this.feedback.subjectID = this.subject_id;
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      this.modalRef.close()
    });
  }

  getViolations() {
    this.complaintService.getViolations()
      .subscribe(
        v => {
          this.violations = v;
        },
        error => {
          this.diagnostic.displayMessage(<Diagnostic>error);
        }
      );
  }

  isEnabled(): boolean {
    return !this.violation.id;
  }

  submit() {
    this.complaint.violationID = this.violation.id;
    this.complaint.isAnonymous = this.complaintForm.controls['isAnonymous'].value == true;
    this.complaint.details = this.complaintForm.controls['details'].value;
    this.complaint.subjectID = this.subject_id;
    this.complaint.category = this.category;
    if (this.isValid(this.complaint)) {
      this.complaintService.submit(this.complaint).subscribe(response => {
        this.toasterService.success("Complaint submitted, thank you.");
        this.modalRef.close();
      });
    }
  }

  isValid(complaint: Complaint): boolean {
    let isvalid = !!complaint && !!complaint.violationID;
    if (this.otherReason()) {
      return isvalid && !!this.complaint.details;
    }
    return isvalid;
  }

  otherReason(): boolean {
    return !!this.violation && this.isOther(this.violation.value);
  }

  isOther(selectedViolation: string): boolean {
    return selectedViolation.toLowerCase() === "other";
  }
}