<div class="trending-wrapper" *ngIf="getFeatureFlagTrends()">
  <div class="row px-0 mx-0">
    <div class="col-md-12 px-0 bg-white rounded vh-100">
      <h5 class="trending-heading text-uppercase mt-4">
        <span class="corchat-sidenav-link-vector-container">
          <span class="mdi mdi-fire"></span>
        </span>
        <span> Trending</span>
      </h5>
      <div *ngIf="trends.length > 0">
        <div class="list-group message-list" *ngFor="let trend of trends">
          <a href="javascript:void()" class="list-group-item list-group-item-action" aria-current="true">
            <div class="d-flex w-100 mt-3" (click)="gotoTrendDetails(trend)">
              <ul class="trending-list mb-1">
                <li class="trending-list-item">
                  <span class="trending-title">{{trend.name}}</span>
                </li>
                <span class="trending-count">{{trend.count}} Engagements</span>
                <li class="li-dot">
                  <span class="trend-location">{{trend.location}}</span>
                </li>
              </ul>
            </div>
            <div class="ms-auto">
              <div mdbDropdown class="dropdown">
                <i (click)="showReportBtn()" aria-expanded="false" id="dropdownMenu2" type="button" mdbDropdownToggle
                  class="mdi mdi-dots-horizontal fs-6 me-1 trending-icon dropdown-toggle" aria-hidden="true">
                </i>
                <ul mdbDropdownMenu class="dropdown-menu report-btn" *ngIf="openReportBtn"
                  aria-labelledby="dropdownMenu2">
                  <li (click)="openModal(trend.id)" class="dropdown-item small-ft" role="button">
                    <span class="mdi mdi-flag-variant-outline me-2 report-flag"></span>
                    <span class="report-text"> Report </span>
                  </li>
                </ul>
              </div>
            </div>
          </a>
        </div>
      </div>


      <div class="list-group message-list" *ngIf="trends.length < 1">
        <a href="javascript:void()" class="list-group-item list-group-item-action" aria-current="true">
          <div class="d-flex w-100 mt-3">
            <ul class="trending-list mb-1">
              <li class="trending-list-item">
                <span class="trending-title">No trend available at this time</span>
              </li>
            </ul>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>