import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count'
})
export class CountPipe implements PipeTransform {

  transform(value: number, description?: string, plural?: string): unknown {
    const formattedValue = this.formatNumber(value);
    return `${formattedValue} ${value > 1 && description ? (plural || description + 's') : (description || '')}`;
  }

  private formatNumber(num: number): string {
    if (num >= 1_000_000_000) { return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'; }
    if (num >= 1_000_000) { return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'; }
    if (num >= 1_000) { return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K'; }
    if (num == 0) { return num.toString() }

    return num ? num.toString() : '';
  }

}
