import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { connectionActions } from '@src/app/core/Constants';
import { User } from '@src/app/core/models/model';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { UserService } from '../user.service';
import { ToasterService } from '@src/app/services/toaster.service';

@Component({
  selector: 'app-private-user',
  templateUrl: './private-user.component.html',
  styleUrls: ['./private-user.component.scss']
})
export class PrivateUserComponent implements OnInit {
  user = <User>{ blobs: [] };
  isRequested: string;

  styleDeclaration = <CSSStyleDeclaration>{
    textAlign: 'center',
    paddingTop: '7px',
    fontSize: 'x-large',
    color: 'white',
  }
  constructor(public modalRef: MdbModalRef<PrivateUserComponent>, private router: Router, public userService: UserService, private cdr: ChangeDetectorRef, private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.isRequested = this.user.pinStatus.relationship;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();

    this.router.events.subscribe(() => {
      this.modalRef.close()
    });
  }

  sendRequest(partnerId) {
    this.userService.profileRequest(partnerId, connectionActions.pin).subscribe(res => {
      if (res) {
        debugger;
        switch (res.statusCode) {        
          case 403:
            this.modalRef.close();
            this.toasterService.warning('Cannot send friend request. User has blocked you.');
            break;

          case 200:           
              this.isRequested = res.response.relationship;
              this.user.pinStatus.relationship = res.response.relationship;
              this.modalRef.close();
              break;       
        } 
      }
    })
  }

}
