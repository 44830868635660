import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], params: string): any[] {
    if (!items || !params) return items;
    params = params.toLowerCase();
    return items.filter((data) => this.matchValue(data, params));
  }
  matchValue(data, value) {
    return Object.keys(data).map((key) => {
      return new RegExp(value, 'gi').test(data[key]);
    }).some(result => result);
  }

}
