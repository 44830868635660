import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { BlobUrls } from '@src/app/core/config';
import { BlobData } from '@src/app/core/models/model';
import { MdbLightboxComponent, MdbLightboxItemDirective } from 'mdb-angular-ui-kit/lightbox';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit {
  @ViewChild('lightbox') lightbox: MdbLightboxComponent;
  @ViewChild('mdbLightboxItem') selectedElement: MdbLightboxItemDirective;
  
  @Input() private previewLimit = 0;
  @Input() blobs: BlobData[] = [];
  @Input() modalOnly: boolean = false;
  @Output() closed = new EventEmitter();
  private $selectedItem: BlobData;
  get selectedItem() { return this.$selectedItem; }

  @Input() set selectedItem(data: BlobData) {
    if(data?.id) {
      this.$selectedItem = data;
      this.cdr.detectChanges();
      this.launch(this.selectedElement);
    };
  }

  get activeBlobs() { return this.blobs?.filter(x => !x.isDeleted) }
  
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  getPreviewLimit() {
    return this.previewLimit || this.activeBlobs.length;
  }

  getURL(blob: BlobData) {
    return encodeURI(BlobUrls.Download + '/' + blob.url);
  }

  isSelected(blob: BlobData) {
    return this.selectedItem?.modifiedName ? (this.selectedItem.modifiedName == blob.modifiedName) : false;
  }

  launch(selectedElement: MdbLightboxItemDirective) {
    setTimeout(() => {
      this.lightbox.open(selectedElement);
    }, 0);
  }

  lightboxClosed() {
    this.closed.emit();
  }
}
