import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { ApiUrls, HttpParameters } from '@src/app/core/config';
import { UserService } from '@src/app/modules/feature/user/user.service';
import { CommentLike, Comment, Reply, ReplyLike } from '@src/app/core/models/model';
import { SKIP_OPTION } from '@src/app/services/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private commentUrl = ApiUrls.CommentUrl;
  private commentsUrl = ApiUrls.CommentsUrl;
  private replyUrl = ApiUrls.ReplyUrl;
  private repliesUrl = ApiUrls.RepliesUrl;
  readonly currentUserID: string = this.userService.getCurrentUserId();
  readonly coords: string = this.userService.get('coords');
  readonly radius: number = this.userService.get('radius');

  constructor(private http: HttpClient, private userService: UserService) { }


  getComments(postID: string) {
    return this.http.get<Comment[]>(`${this.commentsUrl}/${postID}`);
  }

  getCommentByID(commentID: string, replyID: string) {
    return this.http.get<any>(`${this.commentUrl}/post/${commentID}/${replyID}`);
  }

  getRepliesByCommentID(commentID: string, replyID: string, page: number) {
    if (!replyID) {
      replyID = Guid.EMPTY;
    }
    let params = HttpParameters.params({ page });
    return this.http.get<Comment>(`${this.commentUrl}/comment-with-replies/${commentID}/${replyID}`, { params });
  }

  getMoreComments(postID: string, startIndex: string, page: number) {
    if (!startIndex) {
      startIndex = Guid.EMPTY;
    }
    let params = HttpParameters.params({ page });
    return this.http.get<Comment[]>(`${this.commentUrl}/post/${postID}/${startIndex}`, { params });
  }

  addComment(_comment: Comment) {
    return this.http.post<Comment>(`${this.commentUrl}`, _comment);
  }

  editComment(_comment: Comment) {
    return this.http.put<Comment>(`${this.commentUrl}/${_comment.id}`, JSON.stringify(_comment.body));
  }

  deleteComment(_commentId: string, isDeleted: boolean) {
    const params = new HttpParams().set('isDeleted', isDeleted);
    return this.http.delete<string>(`${this.commentUrl}/${_commentId}`, {params});
  }

  likeComment(_like: CommentLike): Observable<boolean> {
    return this.http.post<boolean>(`${this.commentUrl}/like`, _like, { context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)});
  }

  // like(_comment: Comment) {
  //     if (!_comment.liked) { _comment.liked = false; }
  //     let new_like = <CommentLike>{ commentID: _comment.id, userID: this.currentUserID, liked: !_comment.liked };
  //     this.likeComment(new_like)
  //         .subscribe(like_status => { _comment.liked = like_status;
  //                                     if (_comment.liked) {
  //                                         _comment.totalLikes++;
  //                                     }

  //                                     else { _comment.totalLikes--; }
  //                                   });
  // }

  //====================================== Replies =======================================================================

  addReply(_reply: Reply) {
    return this.http.post<Reply>(`${this.replyUrl}`, _reply);
  }

  editReply(_reply: Reply) {
    return this.http.put<Reply>(`${this.replyUrl}/${_reply.id}`, JSON.stringify(_reply.body));
  }

  deleteReply(_replyId: string,isDeleted: boolean) {
    const params = new HttpParams().set('isDeleted', isDeleted);
    return this.http.delete<string>(`${this.replyUrl}/${_replyId}`, { params });
  }

  getMoreReplies(commentID: string, startIndex: string, size: number) {
    if (!startIndex) {
      startIndex = Guid.EMPTY;
    }
    let params = HttpParameters.params({ startIndex, size });
    return this.http.get<Reply[]>(`${this.repliesUrl}/${commentID}`, { params });
  }

  likeReply(_like: ReplyLike): Observable<boolean> {
    return this.http.post<boolean>(`${this.replyUrl}/${'like'}`, _like, { context: new HttpContext().set(SKIP_OPTION.PROGRESS, true)});
  }

}
