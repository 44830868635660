import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map, find, first, switchMap } from "rxjs/operators";
import { pipe } from "rxjs";
import { ApiUrls, HttpParameters } from "@src/app/core/config";
import { Diagnostic, Geotag, SavedLocation } from "@src/app/core/models/model";
import { UserService } from "../user.service";
import { FormBuilder } from "@angular/forms";
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";
import { StorageService } from "@src/app/services/storage.service";
import { ModalPostComponent } from "@src/app/modules/general/post-builder/modal-post/modal-post.component";
import { DiagnosticService } from "@src/app/services/diagnostic.service";
import { GeolocationService } from "@src/app/modules/general/geolocation/geolocation/geolocation.service";
import { GenericModalService } from "@src/app/modules/core/generic-modal-dialog/generic-modal/generic-modal.service";

@Injectable({
  providedIn: "root",
})
export class SavedLocationService {
  readonly coords: string = this.userService.get("coords");

  private savedLocationUrl = ApiUrls.SavedLocationUrl;

  private allSavedLocations$ = new Subject<SavedLocation[]>();
  private savedLocation$ = new Subject<any>();
  private refresh = new Subject<any>();
  private locationSyncTrigger$ = new Subject<any>();
  private currentLocationTag: Geotag;
  modalRef: MdbModalRef<ModalPostComponent>;
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: MdbModalService,
    private storageService: StorageService,
    private geolocationService: GeolocationService,
    private diagnostic: DiagnosticService,
    private genericModalService: GenericModalService,
  ) { }

  ngOnInit(): void { }

  DefaultLocation(savedLocations: SavedLocation[]) {
    for (let location of savedLocations) {
      if (location.isDefault) {
        return location;
      }
    }
  }

  getDefaultSavedLocation(page?: number, size?: number) {
    return this.getSavedLocations(page, size).pipe(
      map((response) => {
        return this.DefaultLocation(response);
      })
    );
  }

  getSavedLocations(page?: number, size?: number): Observable<SavedLocation[]> {
    let params = HttpParameters.params({ page, size });
    return this.http.get<SavedLocation[]>(this.savedLocationUrl, { params });
  }

  addSavedLocation(savedLocation: SavedLocation): Observable<SavedLocation> {
    return this.http.post<SavedLocation>(
      `${this.savedLocationUrl}`,
      savedLocation
    );
  }

  update(savedLocation: SavedLocation): Observable<SavedLocation> {
    return this.http.patch(`${this.savedLocationUrl}`, savedLocation);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.savedLocationUrl}/${id}`);
  }

  sendAllSavedLocations(message: SavedLocation[]) {
    this.allSavedLocations$.next(message);
  }

  sendSavedLocation(message: SavedLocation, type: string) {
    this.savedLocation$.next({ data: message, type });
  }

  sendRefresh(userId: string) {
    this.refresh.next({ data: userId });
  }

  getAllSavedLocations(): Observable<SavedLocation[]> {
    return this.allSavedLocations$.asObservable();
  }

  getSavedLocation(): Observable<any> {
    return this.savedLocation$.asObservable();
  }

  getRefresh(): Observable<any> {
    return this.refresh.asObservable();
  }

  triggerLocationSync() {
    this.locationSyncTrigger$.next();
  }

  locationSyncTrigger() {
    return this.locationSyncTrigger$.asObservable();
  }

  clearMessage() {
    this.savedLocation$.next();
    this.allSavedLocations$.next();
    this.refresh.next();
  }

  openLocationModal() {
    let user = this.storageService.getCurrentUser();
    if (!user) {
      return;
    }
    const location_type = "coordinates";
    let modalRef;
    this.geolocationService.GetCurrentPosition().subscribe((coordinate) => {
      this.geolocationService
        .GetGeocode(coordinate, location_type, true)
        .subscribe((geodata) => {
          if (geodata.address) {
            const geotag: Geotag = {
              type: "current-location",
              coordinates: coordinate,
              address: geodata.address,
            };
            this.currentLocationTag = geotag;
            modalRef = this.modalService.open(ModalPostComponent, {
              backdrop: true,
              keyboard: true,
              ignoreBackdropClick: true,
              modalClass: "modal-md",
              containerClass: "",
              data: {
                geotag: geotag,
                modalState: "locationGeoTagModal",
                textInputType: "locationCreate",
              },
            });

            modalRef.onClose.subscribe((geotag: Geotag) => {
              const savedLocationForm = this.formBuilder.group({
                userID: user.id,
                coordinates: geotag.coordinates,
                address: geotag.address,
                description: geotag.description,
                isActive: true,
                isDefault: false,
                createdOn: new Date(),
              });
              geotag.address &&
                this.addSavedLocation(savedLocationForm.value).subscribe(
                  (resp) => {
                    if (resp) {
                      user.savedLocations.unshift(resp);
                      this.storageService.setCurrentUser(user);
                      this.sendSavedLocation(resp, "save");
                    }
                  },
                  (error) => {
                    this.diagnostic.displayMessage(<Diagnostic>error);
                  }
                );
            });
          }
        });
    });
    return modalRef;
  }

  limitAlert(message) {
    this.genericModalService.open(message).subscribe(() => { });
  }
}
