import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TimeAgoPipe as ExternalTimeAgo } from 'time-ago-pipe';

@Pipe({
  name: 'timeAgo'
})


export class TimeAgoPipe extends ExternalTimeAgo implements PipeTransform {

  transform(value: any) {
    return this.getDuration(value);
  }

  getDuration(date: any): string {
    return super.transform(date.toString());

    // if (!moment(date).isValid()) { return 'Invalid Date'; }

    // const duration = new Date().getTime() - new Date(date).getTime();

    // if (duration < 1000 * 60) {
    //   return `${Math.round(duration / 1000)} seconds ago`;
    // } else if (duration < 1000 * 60 * 60) {
    //   const min = Math.round(duration / (1000 * 60));
    //   return `${min} minute${min > 1 ? 's' : ''} ago`;
    // } else if (duration < 1000 * 60 * 60 * 24) {
    //   const hr = Math.round(duration / (1000 * 60 * 60));
    //   return `${hr} hour${hr > 1 ? 's' : ''} ago`;
    // } else {
    //   const day = Math.round(duration / (1000 * 60 * 60 * 24));
    //   return `${day} day${day > 1 ? 's' : ''} ago`;
    // }
  }

}
