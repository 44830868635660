<div class="modal-header">
  <h5 class="modal-title d-inline-block text-truncate" id="exampleModalLabel">{{text}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <app-location-map [locationDetails]="data"></app-location-map>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modalRef.close()">
      Close
    </button>
    <button type="button" class="btn btn-primary">Save changes</button>
  </div> -->