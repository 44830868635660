<quill-editor #editor class="content-editor" trackChanges="all" [placeholder]="placeHolder" [readOnly]="readOnly"
    [styles]="{'height':height, 'min-height':minHeight, 'max-height': maxHeight, 'overflow-y': 'auto', 'display': 'grid' }" (onEditorChanged)="onEditorChanged($event)"
    (onEditorCreated)="onEditorCreated($event)" (onBlur)="onBlur($event)" (onFocus)="onFocus($event)"
    (onContentChanged)="onContentChanged($event)" [modules]="modules" [(ngModel)]="body"
    [ngModelOptions]="{standalone: true}"
    id="{{elementId}}"
    >
</quill-editor>

<div [ngClass]="{'position-relative': hideEmojiTrigger}">
    <button *ngIf="!hideEmojiTrigger" (click)="toggleEmoji($event)" [disabled]="readOnly" [ngClass]="{'disabled': emojiPickerDisabled}" role="button" class="emoji-box">
        😊
    </button>
    <app-emoji-control *ngIf="emojiPickerVisible" [emojiStyle]="isCustomEmojiBoxPosition ? isObjectTruthyAndNotEmpty(customEmojiBoxStyle) ? customEmojiBoxStyle : emojiStyle : {}" (closeEmoji)="emojiPickerVisible = false" (addEmoji)="addEmoji($event)"></app-emoji-control>
</div>