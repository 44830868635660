import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { SavedLocation, SearchParam } from "@src/app/core/models/model";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GeolocationService } from "@src/app/modules/general/geolocation/geolocation/geolocation.service";
import { FilterType, SearchCharacterLimit, radiusOptions } from "../../../../core/Constants";
import { UtilitiesService } from '@src/app/services/utilities.service';

@Component({
  selector: "app-search-query-builder",
  templateUrl: "./search-query-builder.component.html",
  styleUrls: ["./search-query-builder.component.scss"],
})
export class SearchQueryBuilderComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;
  searchCharacterLimit = SearchCharacterLimit.SearchTextbox;
  checkInLocation = false;
  
  filterType = {
    name: "No filter",
    value: FilterType.NoFilter,
  };

  filterTypes = [
    {
      name: "No filter",
      value: FilterType.NoFilter,
    },
    {
      name: "Past Hour",
      value: FilterType.PastHour,
    },
    {
      name: "Past 24 Hours",
      value: FilterType.Past24Hours,
    },
    {
      name: "Past Week",
      value: FilterType.PastWeek,
    },
    {
      name: "Past Month",
      value: FilterType.PastMonth,
    },
    {
      name: "Custom",
      value: FilterType.Custom,
    },
    {
      name: "Location",
      value: FilterType.Location,
    },
  ];

  params: SearchParam = {};

  radiusOptions = [
    { name: "One Mile", value: radiusOptions.OneMile },
    { name: "Five Miles", value: radiusOptions.FiveMiles },
    { name: "Twenty Miles", value: radiusOptions.TwentyMiles },
    { name: "Fifty Miles", value: radiusOptions.FiftyMiles },
    { name: "Hundred Miles", value: radiusOptions.HundredMiles },
  ];

  savedLocations: SavedLocation[] = [];
  savedLocation: SavedLocation;
  searchForm: FormGroup;
  selectedLocation: string;
  selectedAddress: string;
  showRadius = false;

  constructor(
    private geolocationService: GeolocationService,
    private router: Router,
    private utilitiesService: UtilitiesService,
    public modalRef: MdbModalRef<SearchQueryBuilderComponent>,
    private fb: FormBuilder,
  ) {
    this.searchForm = this.fb.group({
      radius: new FormControl(""),
      filterType: new FormControl(""),
      query: new FormControl(""),
      location: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.getUserSavedLocations();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    }, 500);
  }

  getUserSavedLocations() {
    this.savedLocations = this.geolocationService.GetSavedLocations();
  }

  filterAction(type: number, savedLocation?: SavedLocation) {
    this.checkInLocation = false;
    this.filterType = this.filterTypes.find((filter) => filter.value === type);

    if (FilterType.Location) {
      this.savedLocationFilter(savedLocation);
    }
  }

  locationFilter(savedLocation?: SavedLocation) {
    this.savedLocation = savedLocation;
    this.showRadius = true;
    this.savedLocationFilter(savedLocation);
    this.selectedAddress = savedLocation?.address;
    this.selectedLocation = savedLocation.coordinates;
    this.searchForm.controls["location"].setValue(this.selectedLocation);
  }

  savedLocationFilter(savedLocation: SavedLocation) {
    if (savedLocation) {
      this.checkInLocation = true;
      this.params.location = savedLocation.coordinates;
    } else {
      this.checkInLocation = false;
    }
  }

  removeAddress() {
    this.showRadius = false;
    this.selectedAddress = "";
  }

  radiusFilter(radius: number) {
    this.params.radius = radius;
  }

  getInitialSearchResult(formValue) {
    const resultantQuery = {
      query: formValue.query,
      location: formValue.location,
      radius: formValue.radius,
      filterType: formValue.filterType,
    };

    // this.router.navigate(['/search'], { queryParams: { search: JSON.stringify(resultantQuery) } });
    this.router.navigate(["/search"], { queryParams: resultantQuery });
    this.modalRef.close();
  }

  characterSearchLimit() {
    const formValue = this.searchForm.value;
    
    if (formValue.query.length > this.searchCharacterLimit) {
      this.utilitiesService.showCharacterLimitToast();
      return;
    }else{
      this.getInitialSearchResult(formValue);
    }
  }

}
