<section class="row px-0 mx-0 bg-white h-100 flex-nowrap flex-column">
  <div class="my-3 header">
    <span class="mdi mdi-plus-box-multiple-outline header-icon"></span>
    <span class="ms-1 header-text">Pending Requests</span>
  </div>
  <div class="mb-3">
    <div class="d-flex flex-column position-relative">
      <div class="input-group has-search">
        <input
          type="search"
          class="form-control rounded-connect"
          placeholder="Search"
          aria-label="Search"
          [formControl]="searchTerm"
          (keyup.enter)="searchPendingRequest($event.target.value)"
          [maxlength]=searchCharacterLimit
          aria-describedby="search-addon"
        />
      </div>
      <span class="fa fa-search form-control-search align-self-end" aria-hidden="true"></span>
    </div>
  </div>
  <!-- Nav Tabs -->
  <div class="flex-1">
    <mdb-tabs [fill]="true" #tabs (activeTabChange)="onGetActiveTab($event)" class="d-flex flex-column h-100">
      <!--Panel 1-->
      <mdb-tab>
        <ng-template mdbTabTitle>
          <span id="pending-req-all">All</span>
        </ng-template>

        <ng-template mdbTabContent>
          <div *ngIf="loading" class="request-content-wrapper d-flex align-items-center justify-content-center">
            <app-loading-indicator></app-loading-indicator>
          </div>
          <div class="request-content-wrapper d-flex flex-column" [ngClass]="{'align-items-center justify-content-center': eventRequest?.count < 1 && groupRequest?.count < 1 && profileRequests?.count < 1}" *ngIf="!loading">
            <app-content-placeholder
              *ngIf="eventRequest?.count < 1 && groupRequest?.count < 1 && profileRequests?.count < 1"
              [placeholderText]="placeholderText"
            >
            </app-content-placeholder>
            <div class="col-md-12" *ngIf="profileRequests?.count > 0">
              <div class="d-flex align-items-center justify-content-between py-1">
                <span class="section-title">Connections</span>
                <span class="h-rule"></span>
                <span class="see-all" (click)="navigateUser(1)" role="button"
                  >See All ({{ profileRequests?.count }})</span
                >
              </div>
              <app-connections [notifications]="profileRequests?.records" [profileSummary]="false"></app-connections>
              <br />
            </div>
            <div class="col-md-12" *ngIf="eventRequest?.count > 0">
              <div class="d-flex align-items-center justify-content-between py-1">
                <span class="section-title">Events</span>
                <span class="h-rule"></span>
                <span class="see-all" (click)="navigateUser(2)" role="button">See All ({{ eventRequest?.count }})</span>
              </div>
              <event-request [eventRequests]="eventRequest?.records" [eventSummary]="false"> </event-request>
              <br />
            </div>
            <div class="col-md-12" *ngIf="groupRequest?.count > 0">
              <div class="d-flex align-items-center justify-content-between py-1">
                <span class="section-title">Groups</span>
                <span class="h-rule"></span>
                <span class="see-all" (click)="navigateUser(3)" role="button">See All ({{ groupRequest?.count }})</span>
              </div>
              <group-request [groupRequests]="groupRequest?.records" [groupSummary]="false"> </group-request>
            </div>
          </div>
        </ng-template>
      </mdb-tab>
      <mdb-tab>
        <ng-template mdbTabTitle>
          <span id="pending-req-connections">Connections</span>
        </ng-template>

        <ng-template mdbTabContent>
          <div class="request-content-wrapper" [ngClass]="{'justify-content-center align-items-center': profileRequests?.count < 1}">
            <app-content-placeholder *ngIf="profileRequests?.count < 1" [placeholderText]="emptyConnections">
            </app-content-placeholder>
            <div class="w-100 h-100 d-flex flex-column" [ngClass]="{'justify-content-center align-items-center': loadingConnections}" *ngIf="profileRequests?.count > 0">
              <app-loading-indicator *ngIf="loadingConnections"></app-loading-indicator>
              <!-- <app-connections [profileSummary]=true></app-connections> -->
              <app-connections *ngIf="!loadingConnections" [notifications]="notifications"> </app-connections>
            </div>
          </div>
        </ng-template>
      </mdb-tab>
      <!--Panel 2-->
      <mdb-tab>
        <ng-template mdbTabTitle>
          <span id="pending-req-events">Events</span>
        </ng-template>

        <ng-template mdbTabContent>
          <div class="request-content-wrapper" [ngClass]="{'justify-content-center align-items-center': eventRequest?.count < 1}">
            <app-content-placeholder *ngIf="eventRequest?.count < 1" [placeholderText]="emptyEvents">
            </app-content-placeholder>
            <div class="w-100 h-100 d-flex flex-column" [ngClass]="{'justify-content-center align-items-center': loadingEvents}" *ngIf="eventRequest?.count > 0">
              <app-loading-indicator *ngIf="loadingEvents"></app-loading-indicator>
              <event-request *ngIf="!loadingEvents" [eventRequests]="eventRequests"> </event-request>
            </div>
          </div>
        </ng-template>
      </mdb-tab>
      <!--Panel 3-->
      <mdb-tab>
        <ng-template mdbTabTitle>
          <span id="pending-req-groups">Groups</span>
        </ng-template>

        <ng-template mdbTabContent>
          <div class="request-content-wrapper" [ngClass]="{'justify-content-center align-items-center': groupRequest?.count < 1}">
            <app-content-placeholder *ngIf="groupRequest?.count < 1" [placeholderText]="emptyGroups">
            </app-content-placeholder>
            <div class="w-100 h-100 d-flex flex-column" [ngClass]="{'justify-content-center align-items-center': loadingGroups}" *ngIf="groupRequest?.count > 0">
              <app-loading-indicator *ngIf="loadingGroups"></app-loading-indicator>
              <group-request *ngIf="!loadingGroups" [groupRequests]="groupRequests"> </group-request>
            </div>
          </div>
        </ng-template>
      </mdb-tab>
    </mdb-tabs>
  </div>
</section>
