import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GeoTagMessageData } from '../../core/models/model';

@Injectable({
  providedIn: 'root'
})
export class PostMessageService {

  private subject = new Subject<any>();

  sendPost(message: GeoTagMessageData) {
    this.subject.next({ data: message });
  }

  getPost(): Observable<any> {
    return this.subject.asObservable();
  }


  clearMessage() {
    this.subject.next();
  }

  constructor() { }
}
