<div>
  <div class="modal-header">
    <h3 class="modal-header-title">Message Upload(s)</h3>
    <button type="button" class="btn-close p-1 m-0" aria-label="Close" (click)="modalRef.close()"></button>
  </div>
  <div class="modal-body pt-0">
    <div class="form-group basic-textarea">
      <textarea
        class="form-control pl-2 mb-0 text-area"
        rows="3"
        placeholder="Write a message....."
        autocomplete="off"
        [(ngModel)]="messageBody"
      >
      </textarea>
    </div>
    <app-file-upload [parent_category]="category" (file_emitter)="addFile($event)"></app-file-upload>
    <div [ngClass]="{'mt-2': hasBlob()}">
      <app-blob-carousel
      [multiImage]="multiImage"
      [parent_category]="category"
      [blobsModals]="postFiles"
      [showFullVisibleMediaWithOverlay]="showFullVisibleMediaWithOverlay"
      [showFullVisibleMediaInNonMultiImageLightBox]="showFullVisibleMediaInNonMultiImageLightBox"
      [upload_edit_mode]="true"
      [innerRadius]="true"
    ></app-blob-carousel>
    </div>
  </div>
  <app-emoji-control
    class="emoji-mart"
    *ngIf="isEmojiPickerVisible"
    (closeEmoji)="isEmojiPickerVisible = false"
    (addEmoji)="addEmoji($event)"
    [emojiStyle]="emojiStyle"
    title="Choose your emoji"
  ></app-emoji-control>

  <div class="px-3 mb-3">
    <button class="btn btn-post-edit-gallary" (click)="upload()">
      <span class="btn-post-edit-icon">
        <span class="mdi mdi-camera-outline"></span>
      </span>
      <span class="btn-post-edit-text text-capitalize">Gallery</span>
    </button>
    <button
      (click)="toggleEmoji($event)"
      class="btn btn-post-edit-emoticon"
    >
      <span class="btn-post-edit-icon">
        <span class="mdi mdi-emoticon-happy-outline"></span>
      </span>
      <span class="btn-post-edit-text text-capitalize">Emoticon</span>
    </button>
    <!-- <span class="mdi mdi-dots-horizontal" role="button"></span> -->
    <button (click)="sendMessage()" type="button" class="btn btn-post-edit-send ms-auto">
      <span class="btn-post-edit-icon">
        <span class="mdi mdi-send"></span>
      </span>
      <span class="btn-post-edit-text text-capitalize text-white">Send</span>
    </button>
  </div>
</div>

<!-- <MessageComponent [hidden]="true"></MessageComponent> -->
