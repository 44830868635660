<div class="modal-header">
  <div class="members-header">
    <span class="mdi mdi-share-variant-outline fs-6 me-1"></span>
    <span class="members-text">Share</span>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>

<div class="row mt-3 search-wrapper">
  <div class="col-12 d-flex flex-column">
    <div class="input-group has-search">
      <input type="search" class="form-control rounded-connect" placeholder="Search" aria-label="Search"
        aria-describedby="search-addon" (input)="search()" [(ngModel)]="query" />
    </div>
    <span class="fa fa-search form-control-search align-self-end" aria-hidden="true"></span>
  </div>
</div>

<div class="modal-body">
  <app-loading-indicator *ngIf="loading"></app-loading-indicator>
  <div class="text-center" *ngIf="users?.length < 1 && !loading">
    <p>No users available</p>
  </div>
  <ng-container *ngIf="!loading">
    <div *ngFor="let user of users" class="user-list-wrapper">
      <div class="user-list-image">
        <span class="crop" role="button" [appUserImage]="user" [clickable]="false"></span>
        <div class="user-list-data">
          <!-- <span class="user-list-data-name">{{user?.name}}</span> -->
          <div class="d-flex flex-wrap align-items-center">
            <span class="user-list-data-name text-capitalize me-2">{{ user?.name }}</span>
            <span class="user-list-data-username text-lowercase">@{{ user?.username }}</span>
          </div>
          <div class="d-inline-flex"><span class="user-list-data-address d-inline-block text-truncate" role="button">{{user?.address}}</span></div>
        </div>
      </div>
      <input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" (click)="selectedUser(user)"
        aria-label="..." />
    </div>
  </ng-container>
</div>

<hr class="my-0">

<div *ngIf="!disableSendButton" class="div-input">
  <!-- <textarea class="form-control mb-1 d-flex justify-content-center share-text-area" 
    rows="3"
    type="text"
    placeholder="Write a message...">
    </textarea> -->
  <input type="text" class="form-control share-text-area" placeholder="Write a message..." spellcheck="true"
    [(ngModel)]="secondaryMessage" autocomplete="off">
</div>


<div class="d-flex p-1 justify-content-end me-2">
  <button type="button" class="btn btn-primary" (click)="sendMessage()" [disabled]="disableSendButton">
    <span>SEND</span>
  </button>
</div>