<div class="row mt-3 w-100 mx-auto" *ngIf="loading; else loaded">
    <span class="mx-auto"><small>Loading suggestions ...</small></span>
</div>

<ng-template #loaded>
    <div class="row mt-3 w-100 mx-auto" *ngIf="users && users.length < 1;else showUsers">
        <span class="mx-auto"><small>No suggested pins or connections found.</small></span>
    </div>

    <ng-template #showUsers>
        <div class="d-flex users mt-2 px-2" *ngFor="let user of users">
            <div class="d-flex">
                <span class="crop" role="button" [styleDeclaration]="styleDeclaration" [appUserImage]="user"></span>
            </div>

            <div class="d-flex flex-column">
                <div class="mt-2">
                    <h5 class="text-lowercase">{{user.username}}</h5>
                    <small class="d-inline-block text-truncate my-1">{{user.address}}</small>
                </div>

                <div class="d-flex gap-2 mt-n2">
                    <button type="button" [disabled]="user.pinInProgress" (click)="pin(user, user?.pinStatus?.action)"
                        class="btn btn-primary btn-sm user-btn">
                        <span class="mdi mdi-pin-outline user-btn-icon"></span>
                        <span class="user-btn-text"
                            [ngClass]="{'pin-text': user?.pinStatus?.action === 'Pin'}">{{user?.pinStatus?.action}}</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary btn-sm user-btn"
                        (click)="dismiss(user.id)">
                        <span class="mdi mdi-cancel user-btn-icon"></span>
                        <span class="user-btn-text">Dismiss</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- <ng-template #showUsers>
        <div class="d-flex users mt-3" *ngFor="let user of users"> 
            <div class="d-inline-block">
                <span class="crop" [styleDeclaration]="styleDeclaration" [appUserImage]="user"></span>
            </div>
    
            <div class="btn-group ms-auto">
                <button type="button" [disabled]="user.pinInProgress" (click)="pin(user, user?.pinStatus?.action)" class="btn btn-toggle text-white d-flex small-btn align-items-center text-capitalize">
                    <span class="fs-6"> 
                        <span class="mdi mdi-pin-outline me-1"></span>
                    </span>
                    <span class="text-capitalize me-1">{{user?.pinStatus?.action}}</span>
                </button>
            </div>
        </div>
    </ng-template> -->
</ng-template>