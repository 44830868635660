import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pinPrompt, pagination, EntityType, connectionType } from '@src/app/core/Constants';
import { User, Diagnostic, BroadcastMessage } from '@src/app/core/models/model';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { ToasterService } from '@src/app/services/toaster.service';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MessageService } from '../../feature/message/message/message.service';
import { PeopleService } from '../../feature/people/people.service';
import { UserAddressModalComponent } from '../../feature/user/user-address-modal/user-address-modal.component';


@Component({
  selector: 'app-share-entity-component',
  templateUrl: './share-entity-component.component.html',
  styleUrls: ['./share-entity-component.component.scss']
})
export class ShareEntityComponentComponent implements OnInit {

  param: string = "";
  connectionType = connectionType;
  pinPrompt = pinPrompt;
  pinnedUsers: User[] = [];
  pinningUsers: User[] = [];
  users: User[] = [];
  connectedUsers: User[] = [];
  page = pagination.page;
  size = pagination.size;
  query: string = "";
  isSelected = false;
  selectedList = [];
  entityUrl: string;
  disableSendButton = true;
  entityType: string;
  secondaryMessage: string = "";
  loading = false;

  constructor(
    public modalRef: MdbModalRef<ShareEntityComponentComponent>,
    private router: Router,
    private toasterService: ToasterService,
    private messageService: MessageService,
    private modalService: MdbModalService,
    private peopleService: PeopleService,
    private diagnostic: DiagnosticService) { }

  ngOnInit(): void {
    this.getUsers();
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      this.modalRef.close()
    });
  }

  // getUsers() {
  //   this.userService.getUsers(this.query, this.page, this.size)
  //       .subscribe(
  //           u =>  { 
  //               this.users = u;
  //             },
  //           error => this.diagnostic.displayMessage(<Diagnostic>error));
  //   }

  getUsers() {
    this.loading = true;
    this.peopleService.getPeople(this.query, this.page, this.size)
      .subscribe(
        u => {
          this.loading = false;
          if (u && u.length > 0) {
            if (this.users.length > 0 && this.query) {
              this.users = u;
            }
            else if (this.users.length > 0) {
              const users = [...this.users, ...u];
              this.users = [
                ...new Map(users.map((item) => [item["id"], item])).values(),
              ];
            } else {
              this.users = u;
            }
          } else {
            this.page = 0;
            // this.noUser = true;
          }
        },
        error => this.diagnostic.displayMessage(<Diagnostic>error));
  }

  search() {
    this.query = this.query.trim();
    this.getUsers();
  }


  getUserProfile(user: User) {
    this.router.navigate(['/user/', user.username]);
    this.modalRef.close();
  }

  displayUserAddress(user: User) {
    this.modalRef = this.modalService.open(UserAddressModalComponent, {
      modalClass: 'modal-dialog-centered',
      data: {
        data: user
      }
    })
  }

  disableButton() {
    if (this.selectedList.length > 0) {
      this.disableSendButton = false
    } else {
      this.disableSendButton = true
    }
  }

  selectedUser(user: User) {
    let usr = this.selectedList.filter(e => e === user.id)
    if (usr.length < 1) {
      this.selectedList.push(user.id)
      this.disableButton()
    } else {
      const index: number = this.selectedList.indexOf(user.id);
      if (index !== -1) {
        this.selectedList.splice(index, 1);
        this.disableButton()
      }
    }
  }

  sendMessage() {
    if (this.entityType == EntityType.Post || this.entityType == EntityType.Event) {
      this.sendPostToMessage()
    }
  }

  sendPostToMessage() {
    let broadCast: BroadcastMessage = {
      recipientID: this.selectedList,
      body: this.entityUrl,
      createdOn: new Date,
      secondaryBody: this.secondaryMessage
    }
    this.messageService.sendBroadCast(broadCast).subscribe((res) => {
      this.modalRef.close()
      res ? this.toasterService.success('Message has been sent') : this.toasterService.error('Message not sent');
    })
  }

}
