<div class="vh-100">
  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="lightbox-video" *ngIf="blob">
      <div class="video-container">
        <!-- <video width="100%" height="100%" controls playsinline muted>
          <source [attr.src]="getURL(blob)" type="video/mp4" />
          Your browser does not support the video tag.
        </video> -->
        <app-video-player [src]="getURL(blob)" [view]="'contain'"></app-video-player>
      </div>
    </div>
  </div>
</div>