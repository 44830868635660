<div mdbDropdown class="dropdown" #dropdown>
  <button
    class="btn btn-outline-primary btn-sm"
    id="{{ id }}"
    mdbDropdownToggle
    aria-expanded="false"
  >
    <span *ngIf="dropDownData?.defaultValue" class="veh">
      <!-- show only first six characters -->
      {{ dropDownData?.defaultValue?.substring(0, 10) }}
      {{ dropDownData.defaultValue.length > 10 ? "..." : "" }}
    </span>

    <span *ngIf="defaultValue" class="veh">
      <!-- show only first six characters -->
      {{ defaultValue?.substring(0, 10) }}
      {{ defaultValue.length > 10 ? "..." : "" }}
    </span>

    <i class="mdi mdi-chevron-down dropdown-toggle"></i>
  </button>

  <!-- <ul
    *ngIf="dropDownData?.optionValues"
      mdbDropdownMenu
      class="dropdown-menu"
      aria-labelledby="dropdownMenu2"
    >
        <li *ngFor="let option of dropDownData.optionValues; index as i">
            <a
            class="dropdown-item"
            type="button"
            (click)="dropDownItemClicked(option, i);
            dropDownData.defaultValue = option.text
            "
            [ngClass]="{'cchat-active': option.value === dropDownData.defaultValue ? dropDownData.defaultValue : ''}"
            >
            {{option.text}}
            </a>
        </li>
    </ul> -->

  <ul
    *ngIf="dropDownData?.optionValues"
    mdbDropdownMenu
    class="dropdown-menu dropdown-list"
    aria-labelledby="dropdownMenuButton"
  >
    <span *ngFor="let option of dropDownData.optionValues; index as i">
      <li (click)="dropdown.hide()">
        <a
          role="button"
          (click)="
            dropDownItemClicked(option, i);
            dropDownData.defaultValue = option.text
          "
          class="dropdown-item"
        >
          <span class="dropdown-list-text"> {{ option.text }} </span>
        </a>
      </li>
    </span>
  </ul>

  <!-- <ul
    *ngIf="optionValues"
      mdbDropdownMenu
      class="dropdown-menu"
      aria-labelledby="dropdownMenu2"
    >
        <li *ngFor="let option of optionValues; index as i">
            <a
            class="dropdown-item"
            type="button"
            (click)="
            valueChanged(helperValues,i,option);
            defaultValue = option.description;
            "
            [ngClass]="{'cchat-active': option.description === defaultValue}"
            >
            {{option.description}}
        </a>
        </li>
    </ul> -->

  <ul
    *ngIf="optionValues"
    mdbDropdownMenu
    class="dropdown-menu dropdown-list"
    aria-labelledby="dropdownMenuButton"
  >
    <span *ngFor="let option of optionValues; index as i">
      <li (click)="dropdown.hide()">
        <a
          role="button"
          (click)="
            valueChanged(helperValues, i, option);
            defaultValue = option.description
          "
          class="dropdown-item"
        >
          <span class="dropdown-list-text"> {{ option.description }} </span>
        </a>
      </li>
    </span>
  </ul>

  <!-- <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenu2">
      <li><button class="dropdown-item" type="button">Action</button></li>
      <li><button class="dropdown-item" type="button">Another action</button></li>
      <li>
        <button class="dropdown-item" type="button">Something else here</button>
      </li>
    </ul> -->
</div>
