import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanActivateGuard } from "../guards/can-activate.guard";
import { MainMasterComponent } from "../layouts/main-master/main-master.component";
import { featureFlagsName } from "../core/Constants";
import { NotificationsComponent } from "../modules/feature/notification/notifications/notifications.component";
import { PendingRequestComponent } from "../modules/feature/pending-requests/pending-request/pending-request.component";
import { TrendListingComponent } from "../modules/feature/trends/trend-listing/trend-listing.component";
import { CanLoadGuard } from "../guards/can-load.guard";

const protected_routes: Routes = [
  // {
  //     path: 'authenticate',
  //     component: AuthenticateComponent,
  //     data: { anonymous: true }
  // },

  {
    path: "search",
    loadChildren: () =>
      import("../modules/feature/search/search.module").then(
        (x) => x.SearchModule
      ),
  },

  {
    path: "home",
    loadChildren: () =>
      import("../modules/feature/home/home.module").then((x) => x.HomeModule),
  },

  {
    path: "people",
    loadChildren: () =>
      import("../modules/feature/people/people.module").then(
        (x) => x.PeopleModule
      ),
  },

  {
    path: "event",
    loadChildren: () =>
      import("../modules/feature/events/event.module").then(
        (x) => x.EventModule
      ),
  },

  {
    path: "group",
    loadChildren: () =>
      import("../modules/feature/groups/group.module").then(
        (x) => x.GroupModule
      ),
  },

  {
    path: "post",
    loadChildren: () =>
      import("../modules/feature/post/post.module").then((x) => x.PostModule),
  },

  {
    path: "settings",
    loadChildren: () =>
      import("../modules/feature/settings/settings.module").then(
        (x) => x.SettingsModule
      ),
  },

  {
    path: "trends",
    component: TrendListingComponent,
    loadChildren: () =>
      import("../modules/feature/trends/trend.module").then(
        (x) => x.TrendModule
      ),
    data: { flag: featureFlagsName.trends },
  },

  {
    path: "user",
    loadChildren: () =>
      import("../modules/feature/user/user.module").then((x) => x.UserModule),
  },

  {
    path: "messages",
    loadChildren: () =>
      import("../modules/feature/message/message.module").then(
        (x) => x.MessageModule
      ),
  },

  {
    path: "notifications",
    component: NotificationsComponent,
    loadChildren: () =>
      import("../modules/feature/notification/notification.module").then(
        (x) => x.NotificationModule
      ),
  },

  {
    path: "pending-requests",
    component: PendingRequestComponent,
    loadChildren: () =>
      import(
        "../modules/feature/pending-requests/pending-requests.module"
      ).then((x) => x.PendingRequestModule),
  },

  {
    path: "connections",
    loadChildren: () =>
      import("../modules/feature/connection/connection.module").then(
        (x) => x.ConnectionModule
      ),
  },

  {
    path: "support",
    loadChildren: () =>
      import("../modules/feature/support/support.module").then((x) => x.SupportModule),
  },

  // { path: '', redirectTo: '/home', pathMatch: 'full' }, // default empty route
];

const routes: Routes = [
  {
    path: "",
    component: MainMasterComponent,
    canLoad: [CanLoadGuard],
    canActivateChild: [CanActivateGuard],
    data: { title: "Protected Views" },
    children: protected_routes,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProtectedRoutingModule {}
