import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EmojiControlService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  getEmojiButtonCoordinates(event: MouseEvent) {
    event.stopPropagation();

    let emojiBoxWidth = 290;
    let emojiBoxHeight = 350;
    let viewportTop = 0;
    let viewportLeft = 0;
    let viewportHeight = 0;
    let viewportWidth = 0;
    let buttonTop = 0;
    let buttonLeft = 0;
    let spaceRemainderToTheBottom = 0;

    if (isPlatformBrowser(this.platformId)) {
      const button = event.target as Element;
      const rect = button.getBoundingClientRect();
      viewportTop = window.pageYOffset || document.documentElement.scrollTop;
      viewportLeft = window.pageXOffset || document.documentElement.scrollLeft;
      viewportHeight = window.innerHeight;
      viewportWidth = window.innerWidth;
      buttonTop = rect.top - viewportTop;
      buttonLeft = rect.left - viewportLeft;
      spaceRemainderToTheBottom = viewportWidth <= 992 ? (viewportHeight - 80) - buttonTop : viewportHeight - buttonTop;
    }

    return {
      emojiBoxWidth,
      emojiBoxHeight,
      viewportHeight,
      viewportWidth,
      buttonTop,
      buttonLeft,
      spaceRemainderToTheBottom
    };
  }
}
