<div
  id="blobs"
  [ngClass]="{ 'mt-3': addSpacingToTop, 'mb-3 ': addSpacingToBottom, 'radius-md overflow-hidden': innerRadius }"
  *ngIf="activeBlobs"
>
  <div
    id="single-blob"
    *ngIf="activeBlobs.length == 1 && !openInFullscreen"
    [ngClass]="{ 'h-100': isFullyOccupyParentContainerHeight }"
  >
    <ul class="blob-single" [ngClass]="{ 'h-100': isFullyOccupyParentContainerHeight }">
      <!-- Media Slide/Container -->
      <li
        class="slide"
        [ngClass]="{
          'slide-for-visible-full-media': showFullVisibleMediaWithOverlay,
          'h-100': isFullyOccupyParentContainerHeight
        }"
      >
        <!-- Single Image -->
        <img
          class="single-image position-relative"
          [ngClass]="{
            'single-full-visible-image-with-overlay': showFullVisibleMediaWithOverlay,
            'single-full-visible-image-without-overlay': showFullVisibleMediaWithoutOverlay,
            'h-100': isFullyOccupyParentContainerHeight
          }"
          *ngIf="activeBlobs[0].blobCategory == 'Images'"
          [attr.src]="getURL(activeBlobs[0])"
          (click)="onBlobClick(0)"
          width="360"
          height="200"
          alt=""
          [id]="activeBlobs[0].id"
        />

        <!-- Video Container & Video -->
        <div
          class="overflow-hidden video-container w-100"
          *ngIf="activeBlobs[0].blobCategory == 'Videos'"
          [ngClass]="{ 'h-100': isFullyOccupyParentContainerHeight }"
        >
          <app-video-player
            [id]="activeBlobs[0].id"
            [src]="getURL(activeBlobs[0])"
            [view]="videoView"
            [autoplay]="true"
            [nowPlaying]="nowPlaying"
            (play)="onPlay($event.target)"
            (currentlyPlayingId)="currentBlobId = $event"
            (clicked)="onVideoClick($event, onBlobClick.bind(this), [0])"
            (ended)="onEnded($event.target)"
          >
          </app-video-player>
        </div>

        <!-- Media Controls -->
        <span
          *ngIf="upload_edit_mode"
          class="single-file"
          [ngClass]="{ 'single-file-for-visible-full-image': showFullVisibleMediaWithOverlay }"
        >
          <span
            (click)="deleteSlide(activeBlobs[0])"
            *ngIf="activeBlobs[0].isDeleted == null ? true : !activeBlobs[0].isDeleted"
            role="button"
            class="mdi mdi-delete-outline fs-5 p-2 mx-1 text-white img-overlay"
          ></span>
          <span
            (click)="restore_slide(activeBlobs[0])"
            *ngIf="activeBlobs[0].isDeleted == null ? false : activeBlobs[0].isDeleted"
            role="button"
            class="mdi mdi-restore fs-5 p-2 mx-1 text-white img-overlay"
          ></span>
        </span>
      </li>
    </ul>
  </div>

  <mdb-carousel
    #carousel
    *ngIf="activeBlobs.length > 1 && multiImage == true"
    [controls]="true"
    [animation]="'fade'"
    [interval]="false"
    class="d-block w-100"
    id="postpop"
    [ngClass]="{ 'h-100': isFullyOccupyParentContainerHeight }"
  >
    <!-- Carousel Item -->
    <mdb-carousel-item
      class="carousel-item"
      [ngClass]="{
        'carousel-item-for-visible-full-media': showFullVisibleMediaWithOverlay,
        'h-100': isFullyOccupyParentContainerHeight
      }"
      *ngFor="let blob of activeBlobs; index as i;" trackById
      [id]="blob.id"
    >
      <!-- Image -->
      <img
        *ngIf="blob.blobCategory == 'Images'"
        [attr.src]="getURL(blob)"
        [img]="getURL(blob)"
        class="carousel-image position-relative"
        alt="image.alt"
        [ngClass]="{
          'carousel-image-for-visible-full-image': showFullVisibleMediaWithOverlay,
          'h-100': isFullyOccupyParentContainerHeight
        }"
      />

      <div
        *ngIf="blob.blobCategory == 'Videos'"
        [id]="blob.id"
        class="carousel-video position-relative overflow-hidden"
        [ngClass]="{
          'carousel-video-for-visible-full-video': showFullVisibleMediaWithOverlay,
          'h-100': isFullyOccupyParentContainerHeight
        }"
      >
        <app-video-player
          [id]="blob.id"
          [src]="getURL(blob)"
          [view]="videoView"
          [nowPlaying]="nowPlaying"
          (play)="onPlay($event.target)"
          (clicked)="onVideoClick($event, onBlobClick.bind(this), [i])"
          (ended)="onEnded($event.target)"
          (currentlyPlayingId)="currentBlobId = $event"
        >
        </app-video-player>
      </div>

      <!-- Media Controls -->
      <div
        *ngIf="upload_edit_mode"
        class="action-btns"
        [ngClass]="{ 'action-btns-for-visible-full-image': showFullVisibleMediaWithOverlay }"
      >
        <span *ngIf="requirePrimary && blob.blobCategory == 'Images'">
          <span
            class=""
            role="button"
            (click)="set_primary(blob)"
            *ngIf="blob.isDeleted == null ? true : !blob.isDeleted"
          >
            <span
              class="material-symbols-outlined primary-icon"
              [ngClass]="blob.isPrimary ? 'img-overlay-primary' : 'primary'"
            >
              star
            </span>
          </span>
        </span>
        <span
          (click)="deleteSlide(blob)"
          *ngIf="blob.isDeleted == null ? true : !blob.isDeleted"
          role="button"
          class="material-symbols-outlined delete-icon img-overlay"
          >delete</span
        >
        <span
          (click)="restore_slide(blob)"
          *ngIf="blob.isDeleted == null ? false : blob.isDeleted"
          role="button"
          class="mdi mdi-restore fs-5 p-2 mx-1 text-white img-overlay"
        ></span>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>

  <div class="lightbox" *ngIf="activeBlobs.length > 1 && multiImage == false">
    <div class="w-100 d-flex img-container">
      <!-- Lightbox Media Container -->
      <div
        class="img-spacing"
        *ngFor="let blob of activeBlobs | slice : 0 : previewLimit; index as i; let last = last"
        [ngClass]="{ 'me-0': last, 'img-spacing-for-full-visible-image': showFullVisibleMediaInNonMultiImageLightBox }"
      >
        <!-- Lightbox Image -->
        <img
          *ngIf="blob.blobCategory == 'Images'"
          [attr.src]="getURL(blob)"
          [img]="getURL(blob)"
          alt="{{ blob.altText }}"
          class="flex-item position-relative"
          role="button"
          [ngStyle]="{ opacity: i === previewLimit - 1 && activeBlobs.length > previewLimit ? '0.5' : '' }"
          (click)="onBlobClick(i)"
          [ngClass]="{ 'flex-item-img-for-full-visible-image': showFullVisibleMediaInNonMultiImageLightBox }"
        />

        <!-- Lightbox Video Container -->
        <div *ngIf="blob.blobCategory == 'Videos'">
          <div
            [id]="blob.id"
            class="flex-item position-relative overflow-hidden"
            role="button"
            [ngStyle]="{ opacity: i === previewLimit - 1 && blobs.length > previewLimit ? '0.5' : '' }"
            [ngClass]="{ 'flex-item-video-for-full-visible-video': showFullVisibleMediaInNonMultiImageLightBox }"
          >
            <app-video-player
              [id]="blob.id"
              [src]="getURL(blob)"
              [view]="'cover'"
              [nowPlaying]="nowPlaying"
              (play)="onPlay($event.target)"
              (clicked)="onVideoClick($event, onBlobClick.bind(this), [i, blob])"
              (currentlyPlayingId)="currentBlobId = $event"
              (ended)="onEnded($event.target)"
            >
            </app-video-player>
          </div>
        </div>

        <!-- Lightbox media controls -->
        <span *ngIf="upload_edit_mode" class="img-count">
          <span
            (click)="deleteSlide(blob)"
            *ngIf="blob.isDeleted == null ? true : !blob.isDeleted"
            role="button"
            class="mdi mdi-delete-outline fs-5 p-2 mx-1 text-white img-overlay"
          ></span>
          <span
            (click)="restore_slide(blob)"
            *ngIf="blob.isDeleted == null ? false : blob.isDeleted"
            role="button"
            class="mdi mdi-restore fs-5 p-2 mx-1 text-white img-overlay"
          ></span>
        </span>

        <span
          class="img-count flex-item text-white"
          *ngIf="i === previewLimit - 1 && activeBlobs.length > previewLimit"
          [ngClass]="{ 'img-count-for-full-visible-image': showFullVisibleMediaInNonMultiImageLightBox }"
          >+{{ activeBlobs.length - previewLimit }}</span
        >
      </div>
    </div>
  </div>

  <!-- Light box media section for group post item -->

  <mdb-lightbox class="lightbox" *ngIf="openInFullscreen" [zoomLevel]="5">
    <!-- Single image/video -->

    <div id="single-blob" *ngIf="activeBlobs.length == 1">
      <ul class="blob-single">
        <!-- Lightbox media container -->
        <li class="slide" [ngClass]="{ 'slide-img-only': activeBlobs[0].blobCategory == 'Images' }">
          <img
            class="single-image position-relative"
            mdbLightboxItem
            *ngIf="activeBlobs[0].blobCategory == 'Images'"
            [attr.src]="getURL(activeBlobs[0])"
            alt="{{ activeBlobs[0].altText }}"
            [id]="activeBlobs[0].id"
            [ngClass]="{ 'single-full-visible-image-without-overlay': showFullVisibleMediaWithoutOverlay }"
          />

          <!-- Lightbox Video Container -->
          <div class="video-container" *ngIf="activeBlobs[0].blobCategory == 'Videos'">
            <div
              [id]="activeBlobs[0].id"
              class="overflow-hidden single-video position-relative group-video"
              [ngClass]="{ 'full-visible-video-without-overlay': showFullVisibleMediaWithoutOverlay }"
            >
              <app-video-player
                [id]="activeBlobs[0].id"
                [src]="getURL(activeBlobs[0])"
                [nowPlaying]="nowPlaying"
                [autoplay]="true"
                (play)="onPlay($event.target)"
                (clicked)="onVideoClick($event, showVideo.bind(this), [activeBlobs[0]])"
                (ended)="onEnded($event.target)"
                (currentlyPlayingId)="currentBlobId = $event"
              >
              </app-video-player>
            </div>
          </div>

          <!-- Lightbox media control -->
          <span *ngIf="upload_edit_mode" class="single-file">
            <span
              (click)="deleteSlide(activeBlobs[0])"
              role="button"
              class="material-symbols-outlined fs-5 p-2 mx-1 text-white img-overlay"
              >delete</span
            >
          </span>
        </li>
      </ul>
    </div>

    <!-- Multiple images/videos -->
    <div class="w-100 d-flex img-container" *ngIf="activeBlobs.length > 1">
      <!-- Multiple Image container -->
      <div class="img-spacing" *ngFor="let blob of activeBlobs | slice : 0 : previewLimit">
        <!-- Multiple image -->
        <img
          mdbLightboxItem
          *ngIf="blob.blobCategory == 'Images'"
          [attr.src]="getURL(blob)"
          [img]="getURL(blob)"
          alt="{{ blob.altText }}"
          class="w-100 flex-item position-relative"
          [ngStyle]="{ opacity: i === previewLimit - 1 && activeBlobs.length > previewLimit ? '0.5' : '' }"
        />

        <div
          [id]="blob.id"
          *ngIf="blob.blobCategory == 'Videos'"
          class="flex-item position-relative group-video overflow-hidden"
          [ngStyle]="{ opacity: i === previewLimit - 1 && blobs.length > previewLimit ? '0.5' : '' }"
        >
          <app-video-player
            [id]="blob.id"
            [src]="getURL(blob)"
            [nowPlaying]="nowPlaying"
            (play)="onPlay($event.target)"
            (clicked)="onVideoClick($event, showVideo.bind(this), [blob])"
            (ended)="onEnded($event.target)"
            (currentlyPlayingId)="currentBlobId = $event"
          >
          </app-video-player>
        </div>
        <!-- Multiple media control -->
        <span *ngIf="upload_edit_mode" class="position-relative" [ngClass]="{ 'delete-icon-adjust': openInFullscreen }">
          <span
            (click)="deleteSlide(blob)"
            role="button"
            class="material-symbols-outlined fs-5 p-2 mx-1 text-white img-overlay">delete</span>
        </span>
      </div>
    </div>
  </mdb-lightbox>
</div>

<!-- </div> -->
