<app-file-upload [parent_category]="category" (file_emitter)="addFile($event)"></app-file-upload>

<div class="chat-dialog" [ngClass]="{'open-to-the-right' : isOpenToTheRight}">
    <div class="chat-dialog-header">
      <div class="chat-dialog-chat-info">
        <div class="chat-dialog-avatar">
          <div class="chat-dialog-avatar-image" [appUserImage]="recipientUser"></div>
          <div class="chat-dialog-avatar-status" [appOnlineStatus]="recipientUser" [dynamic]="true"></div>
        </div>
        <div class="chat-dialog-user">
          <h5 class="chat-dialog-user-name">{{ name }} &nbsp;(@{{recipientUser.username}})</h5>
          <p class="chat-dialog-user-status">
            <ng-container>
              {{ lastSeen | timeAgo }}
            </ng-container>
          </p>
        </div>
      </div>

      <button class="chat-dialog-close" (click)="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  
    <div class="chat-dialog-body">
      <div class="chat-dialog-body-inner">
        <ng-container *ngIf="recipientUser.canMessage; else noMessage">
          <div *ngFor="let msg of signalrService.chatMessages$ | async; let i = index">
            <!-- Receiver -->
            <div
              class="d-flex"
              [ngClass]="{ 'justify-content-end': msg.senderID == currentUserID }"
              *ngIf="msg.threadID == threadTracker">
              <ng-container *ngIf="msg.body != null">
                <div
                  class="chat-dialog-box"
                  [ngClass]="{ receiver: msg.senderID != currentUserID, sender: msg.senderID == currentUserID }"
                >
                  <ng-container *ngIf="msg.isRetracted != true; else retractedResMessage">
                    <ng-container *ngIf="(!msg.senderDeleted && msg.senderID == currentUserID) || (!msg.recipientDeleted && msg.senderID != currentUserID); else retractedResMessage">
                      <span class="small-font date-text">
                        {{ msg.createdOn | date : 'longDate' }}, {{ msg.createdOn | date : 'shortTime' }}
                      </span>&nbsp;&nbsp;
                      <span class="dropdown ms-auto option-dropdown" mdbDropdown #dropdown>
                        <span mdbDropdownToggle aria-expanded="false" id="dropdownMenuButton">
                          <span class="material-icons-outlined control-icon d-flex" style="float: right; line-height: 1.7; cursor: pointer;">
                              more_horiz
                          </span>
                        </span>
                        <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li (click)="dropdown.hide()">
                                <a role="button" (click)="deleteMessage(msg, deleteOptions.delete, i)" class="dropdown-item d-flex align-items-center">
                                    <span class="author-text"> Delete for me</span> </a>
                            </li>
                            <li (click)="dropdown.hide()" *ngIf="msg.senderID == currentUserID">
                                <a role="button" (click)="deleteMessage(msg, deleteOptions.retract, i)" class="dropdown-item">
                                    <span class="author-text"> Delete for everyone</span> </a>
                            </li>
                        </ul>
                      </span>
                      <div class="chat-dialog-box-blob" *ngIf="msg.blobs.length >= 1">
                        <app-blob-carousel
                          [multiImage]="multiImage"
                          [parent_category]="category"
                          [blobs]="msg.blobs"
                          [showFullVisibleMediaWithoutOverlay]="true"
                        ></app-blob-carousel>
                      </div>
                      <div class="chat-dialog-box-content" *ngIf="msg.body">
                        <pre class="chat-dialog-box-content-pre">{{ msg.body.trim() }}</pre>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <ng-template #retractedResMessage>
              <span class="deleted"><i>This message has been deleted</i></span> 
              <span class="undo-display" *ngIf="msg.senderDeleted || msg.recipientDeleted || (msg.senderID == currentUserID && msg.isRetracted == true)"
              (click)="undoDeletedMessage(msg, i)">
                <a>Undo</a></span>
            </ng-template>
          </div>
        </ng-container>
        <div class="chat-dialog-box-scroll-marker" #chatScrollElement></div>
      </div>

      <ng-template  #noMessage>
        <div class="corchat-post-wrapper private-acc-wrapper">
          <ng-container *ngIf="recipientUser.canMessage == false">
            <span class="material-symbols-outlined private-acc-icon"> security </span>
            <span *ngIf="recipientUser?.pinStatus?.relationship == ''" class="private-acc-text">
              You are not allowed to message user at this time 
              <span (click)="viewPrivateUser()" class="req-access"> connect </span> with user. 
            </span>
            <span *ngIf="recipientUser?.pinStatus?.relationship == connectionType.pinned" class="private-acc-text">
              <span class="private-acc-text"> You have requested to connect with this user </span>
            </span>
            <span *ngIf="recipientUser?.pinStatus?.relationship == connectionType.pinning" class="private-acc-text">
              This user already requested to connect with you.
              <app-user-interaction-control [user]="recipientUser" [userId]="userId" class="mt-2" [listOptions]="listOptions"
              (button_action)="openComplaintModal($event, recipientUser)"></app-user-interaction-control> 
            </span>
          </ng-container>
          <ng-container *ngIf="recipientUser.canMessage == null">
            <span class="material-symbols-outlined private-acc-icon"> security </span>
            <span class="private-acc-text"> You are not allowed to message this user at this time.</span>
          </ng-container>
        </div>
      </ng-template>
    </div>

    <ng-container *ngIf="showCurrentlyTyping">
      <span class="p-1 message-currently-typing" *ngIf="currentUserID == recepientMessage.recepientID && threadTracker == recepientMessage.threadID"> {{recepientMessage.recepientName}} is typing...</span>
    </ng-container>

  <div class="chat-dialog-footer" *ngIf="recipientUser.canMessage">
    <app-emoji-control
      *ngIf="isEmojiPickerVisible"
      (closeEmoji)="isEmojiPickerVisible = false"
      (addEmoji)="addEmoji($event)"
      [emojiStyle]="emojiStyle"
    ></app-emoji-control>
    
    <textarea
      type="text"
      class="form-control chat-dialog-textarea"
      placeholder="Type a message..."
      (keyup.enter)="sendMessage()"
      (keydown.enter)="onKeydown($event)"
      (click)="eventTrackerToUpdateMessages()"
      [(ngModel)]="messagesBody"
      autofocus
      placeholder="{{ !isBlocked ? 'Write a message ...' : 'Unblock ' + name + ' to send a message' }}"
      #new_msg
      [disabled]="isBlocked"
      (input)="eventTrackerToUpdateMessages(); senderInputAction()"
    ></textarea>

    <div class="chat-dialog-controls">
      <button class="btn btn-action btn-action--gallery" (click)="upload()" [disabled]="isBlocked">
        <span class="btn-action-icon">
          <span class="mdi mdi-camera-outline"></span>
        </span>
        <span class="btn-action-text">Gallery</span>
      </button>
      <button class="btn btn-action btn-action--emotion" [disabled]="isBlocked" (click)="toggleEmoji($event)">
        <span class="btn-action-icon">
          <i class="far fa-grin icon"></i>
        </span>
        <span class="btn-action-text">Emoticons</span>
      </button>

      <button class="btn btn-action btn-action--send ms-auto" (click)="sendMessage()"
        [disabled]="messagesBody == '' || isBlocked">
        <span class="btn-action-icon">
          <span class="mdi mdi-send text-white"></span>
        </span>
        <span class="btn-action-text text-white">Send</span>
      </button>
    </div>
  </div>
</div>