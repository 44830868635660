import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownData} from '@src/app/core/models/model';

@Component({
  selector: 'app-c-chat-dropdown',
  templateUrl: './c-chat-dropdown.component.html',
  styleUrls: ['./c-chat-dropdown.component.scss']
})
export class CChatDropdownComponent implements OnInit {
 
@Input() dropDownData: DropdownData;
@Input() optionValues: any;
@Input() defaultValue: string;
@Output() onDropDownItemClick: EventEmitter<any> = new EventEmitter<any>();
@Output() onDropDownOptionValuesClick: EventEmitter<any> = new EventEmitter<any>();
@Output() selectedHelperChange: EventEmitter<any> = new EventEmitter<any>();
@Input() helperValues: any = {};
@Input() id: string;


// send the dropdown item clicked to the parent component
dropDownItemClicked(item: any, indx) {
  // console.log("dropDownItemClicked", item, indx)
    item.indexSelected =  indx
  
  this.onDropDownItemClick.emit(item);
}

dropDownOptionValuesClicked(item: any, i:number,) {
  this.onDropDownOptionValuesClick.emit(item);
}

valueChanged(item: any, i:number, mainItem: any) {
  item.indexSelected =  i;
  item.selectedValue = mainItem.value;
  // console.log(item)
  this.selectedHelperChange.emit(item);
}

// how parent component can use the dropdown item clicked
// onDropDownItemClick(item: DropdownData) {
//   console.log(item);
// }

// how to use the component in the parent component if you aew using dropdownData
// <app-c-chat-dropdown [dropDownData]="dropDownData" (onDropDownItemClick)="onDropDownItemClick($event)"></app-c-chat-dropdown>\
//  If you are using option values:
  constructor() { 
  }

  ngOnInit(): void {
    // console.log("dropDownData", this.dropDownData);
    // console.log("optionValues", this.optionValues);
    // console.log("defaultValue", this.defaultValue);
    // console.log("helperValues", this.helperValues);

    // console.log("dropDownData", this.dropDownData);

  }

}
