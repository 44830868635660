import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainMasterService {
  private makePost: Subject<string> = new Subject();
  private scrollPost: Subject<boolean> = new Subject();
  private scrollEnd: Subject<string> = new Subject();
  private modalClose: Subject<boolean> = new Subject();
  private resyncByLocation: Subject<boolean> = new Subject();
  hideRightWidgets$ = new Subject<boolean>();


  constructor() { }

  public getCloseStatus(): Observable<boolean> {
    return this.modalClose.asObservable();
  }

  public updateCloseStatus(modalStatus: boolean) {
    this.modalClose.next(modalStatus);
  }

  public getPostStatus(): Observable<string> {
    return this.makePost.asObservable();
  }

  public updatePostStatus(postStatus: string) {
    this.makePost.next(postStatus);
  }

  public getScrollPage(): Observable<boolean> {
    return this.scrollPost.asObservable();
  }

  public updateScrollPage(postScroll: boolean) {
    this.scrollPost.next(postScroll);
  }

  public getScrollEnd(): Observable<any> {
    return this.scrollEnd.asObservable();
  }

  public updateScrollEnd(postScroll: any) {
    this.scrollEnd.next(postScroll);
  }

  public updateSync(): Observable<any> {
    return this.resyncByLocation.asObservable();
  }

  public InitiateSync() {
    this.resyncByLocation.next();
  }

  hideRightWidgets(hide: boolean) {
    this.hideRightWidgets$.next(hide);
  }


}
