
import { Pipe, PipeTransform, Renderer2, RendererFactory2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'hrefRouterlink'
})
export class HrefRouterlinkPipe implements PipeTransform {

  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2, private sanitizer: DomSanitizer) { 
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  transform(html: string) {
    const container = this.renderer.createElement('div');

    container.innerHTML = html;

    const root = container.getRootNode() as HTMLParagraphElement;

    if (root) {

      const anchors = root.getElementsByTagName('a') as HTMLCollectionOf<HTMLAnchorElement>;

      if (anchors?.length) {

        Array.from(anchors).forEach(a => {

          const href = a.getAttribute('href');

          if (href) {

            a.setAttribute('routerLink', href);

            a.removeAttribute('href');
          }
        });
      }
    }

    return this.sanitizer.bypassSecurityTrustHtml(container.innerHTML);

  }

}
