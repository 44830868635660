import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { EmbedComponent } from './embed/embed.component';
import { ComplaintComponent } from './complain/complaint.component';
import { GenericModalComponent } from './generic-modal-dialog/generic-modal/generic-modal.component';
import { ShareEntityComponentComponent } from './share-entity-component/share-entity-component.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@src/app/directives/directives.module';

const declarations = [
  AlertComponent,
  EmbedComponent,
  ComplaintComponent,
  GenericModalComponent,
  ShareEntityComponentComponent
]

@NgModule({
  declarations: [
    ...declarations
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    DirectivesModule
  ],
  exports: [
    ...declarations
  ]
})
export class CoreModule { }
