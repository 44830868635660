import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, ROUTES } from '@angular/router';
import { AuthService } from './services/auth.service';

function routesFactory(authService: AuthService) {
  return [
    {
      path: '',
      loadChildren: () => {
        return authService.isAuthenticated() ? 
                            import('./protected/protected-routing.module').then(m => m.ProtectedRoutingModule) : 
                            import('./public/public-routing.module').then(m => m.PublicRoutingModule);
      }
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '/home', pathMatch: 'full' }, // route not found
  ]
}

@NgModule({
  imports: [RouterModule.forRoot([], { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', scrollPositionRestoration: "enabled", initialNavigation: 'enabled' })],
  providers: [{
    provide: ROUTES,
    useFactory: routesFactory,
    multi: true,
    deps: [AuthService]
  }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
