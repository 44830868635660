import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@src/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { ProtectedRoutingModule } from './protected/protected-routing.module';
import { ProtectedModule } from './protected/protected.module';
import { PublicRoutingModule } from './public/public-routing.module';
import { PublicModule } from './public/public.module';
import { AuthService } from './services/auth.service';
import { AnalyticsService } from './services/analytics.service';
import { CoreConfigService } from './services/core-config.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,

    // ... !authenticated ? [
      PublicRoutingModule,
      PublicModule,
    // ] : [
      ProtectedRoutingModule,
      ProtectedModule,
    // ],

    AppRoutingModule
  ],
  // providers: [{
  //   provide: APP_INITIALIZER,
  //   useFactory: authenticated,
  //   multi: true,
  //   deps: [AuthService]
  // }],
  providers: [
    {
      provide: CoreConfigService,
      useValue: AnalyticsService
    }],
  bootstrap: [AppComponent],
})
export class AppModule { }