import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UtilitiesService } from '@src/app/services/utilities.service';

@Component({
    selector: 'app-authenticate',
    templateUrl: './authenticate.component.html',
    styleUrls: ['./authenticate.component.scss']
})

export class AuthenticateComponent implements OnDestroy {

    returnUrl: string;
    auth_web_url: string = environment.authWebURL;
    iframe_src: string = this.auth_web_url + '/authenticate';
    stopListening: Function;

    constructor(private route: ActivatedRoute, private router: Router, private renderer: Renderer2, private utilitiesService: UtilitiesService) {

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
        this.stopListening = this.renderer.listen('window', 'message', this.handleTokenResponse.bind(this));
    }

    handleTokenResponse(e: Event) {
        const message = e as MessageEvent;
        let data = JSON.stringify(message.data).replace(/"/g, "");

        if (message.origin === this.auth_web_url && data.split('=')[0] == 'authenticated') {
            let success = data.split('=')[1] == 'true';
            this.handle_redirect(success);
        }
    }

    handle_redirect(success: boolean) {
        if (success) {
            history.pushState({}, "", this.returnUrl); // set referrer
            this.router.navigate([this.returnUrl], { replaceUrl: true });
        }

        else { window.location.href = `${this.auth_web_url}/login?redirect=${encodeURIComponent(this.utilitiesService.getOrigin() + this.returnUrl)}&action=continue`; }
    }

    ngOnDestroy() {
        this.stopListening();
    }
}

