import { Pipe, PipeTransform } from '@angular/core';
import { BlobUrls } from '../core/config';

@Pipe({
  name: 'download'
})

export class DownloadPipe implements PipeTransform {
  downloadPath: string = BlobUrls.Download + '/';

  transform(url: string, defaultUrl: string): string {
    if (url) {
      return this.downloadPath + url;
    } else {
      return defaultUrl;
    }
  }

}
