<div *ngIf="user?.id !== currentUserID">
  <div
    class="user-interaction"
    [ngClass]="{
      connected: isConnected(user),
      connect: !isConnected(user),
      pin: isUnrelated(user),
      pinned: isPinned(user)
    }"
  >
    <button
      type="button"
      (click)="pin(user, user?.pinStatus?.action)"
      class="btn"
      id="{{ user?.id }}"
      [ngClass]="{
        'btn-primary text-white': isPinning(user) || isUnrelated(user),
        'btn-outline-primary in-action-dropdown text-blue':
          isConnected(user) || isPinned(user)
      }"
      [disabled]="
        user?.pinStatus?.relationship === connectionType.connected ||
        user?.pinStatus?.relationship === connectionType.pinned
      "
    >
      <span
        *ngIf="user?.pinStatus?.relationship === connectionType.pinning"
        class="mdi mdi-account-plus-outline msg-tag"
      ></span>
      <span
        *ngIf="user?.pinStatus?.relationship === connectionType.connected"
        class="mdi mdi-account-multiple-outline msg-tag"
      ></span>
      <span
        *ngIf="
          user?.pinStatus?.relationship === connectionType.pinned ||
          user?.pinStatus?.relationship === ''
        "
        class="mdi mdi-pin-outline pin-tag"
      ></span>

      <span
        *ngIf="
          user?.pinStatus?.relationship === connectionType.pinning ||
          user?.pinStatus?.relationship === connectionType.connected
        "
        class="msg-text"
        [ngClass]="{ 'no-text-spacing': !showDropdown }"
        >
        {{ isPinning(user) ? "connect" : "connected" }}
      </span>

      <span
        *ngIf="
          user?.pinStatus?.relationship === connectionType.pinned ||
          user?.pinStatus?.relationship === ''
        "
        [ngClass]="{ 'ms-1': isUnrelated(user), 'me-3': isUnrelated(user), 'no-text-spacing': !showDropdown }"
        class="pin-text text-blue"
        >
        {{ isPinned(user) ? "pinned" : "pin" }}
      </span>
    </button>

    <span
      mdbDropdown
      #dropdown
      class="dropdown"
      *ngIf="showDropdown"
      [ngClass]="
        user?.pinStatus?.relationship === connectionType.pinning ||
        user?.pinStatus?.relationship === connectionType.connected
          ? 'btn-drp2'
          : 'btn-drp'
      "
    >
      <i
        aria-expanded="false"
        id="dropdownMenuButton"
        type="button"
        mdbDropdownToggle
        class="mdi mdi-chevron-down edit-profile-dropdown dropdown-toggle"
        [ngClass]="{
          'text-white': isPinning(user) || isUnrelated(user),
          'in-action-dropdown text-blue': isConnected(user) || isPinned(user)
        }"
      >
      </i>

      <ul
        mdbDropdownMenu
        class="dropdown-menu p-1"
        aria-labelledby="dropdownMenuButton"
        [ngClass]="{
          openDropdownBtn2: isUnrelated(user) || isPinned(user),
          openDropdownBtn: isPinning(user) || isConnected(user)
        }"
      >
        <li (click)="dropdown.hide()" *ngIf="showButtonActionListItem">
          <a
            role="button"
            (click)="messageUser(user)"
            class="dropdown-item small-ft"
          >
            <span class="mdi mdi-message-outline dropdown-list-icon"></span>
            <span class="dropdown-list-text">Message</span>
          </a>
        </li>
        <li (click)="dropdown.hide()">
          <a
            role="button"
            (click)="copyShareLink()"
            class="dropdown-item small-ft"
          >
            <span class="mdi mdi-content-copy dropdown-list-icon"></span>
            <span class="dropdown-list-text"> Copy URL </span>
          </a>
        </li>
        <li (click)="dropdown.hide()" *ngIf="showButtonActionListItem">
          <a
            role="button"
            (click)="restriction(user?.restriction?.isMuted ? 'unmute' : 'mute')"
            class="dropdown-item small-ft"
          >
            <span
              class="dropdown-list-icon"
              [ngClass]="{
                'mdi mdi-volume-high': user?.restriction?.isMuted,
                'mdi mdi-volume-off': !user?.restriction?.isMuted
              }"
            >
            </span>
            <span class="dropdown-list-text">
              {{ user?.restriction?.isMuted ? "Unmute" : "Mute" }}
            </span>
          </a>
        </li>
        <li (click)="dropdown.hide()" *ngIf="showButtonActionListItem">
          <a
            role="button"
            (click)="restriction(user?.restriction?.isBlocked ? 'unblock' : 'block')"
            class="dropdown-item small-ft"
          >
            <span class="mdi mdi-cancel dropdown-list-icon"></span>
            <span class="dropdown-list-text">
              {{ user?.restriction?.isBlocked ? "Unblock" : "Block" }}
            </span>
          </a>
        </li>
        <li
          (click)="dropdown.hide()"
          *ngFor="let listOption of listOptions; index as i"
        >
          <a
            role="button"
            (click)="buttonClick(listOption.title)"
            class="dropdown-item small-ft"
          >
            <span class="{{ listOption.icon }} dropdown-list-icon"></span>
            <span class="dropdown-list-text"> {{ listOption.title }} </span>
          </a>
        </li>
        <li
          (click)="dropdown.hide()"
          *ngIf="
            isConnected(user) &&
            (user?.pinStatus?.relationship === connectionType.pinning ||
              user?.pinStatus?.relationship === connectionType.connected)
          "
        >
          <a
            role="button"
            (click)="pin(user, user?.pinStatus?.action)"
            class="dropdown-item small-ft"
          >
            <span class="material-icons-outlined dropdown-list-icon mat-icon">
              no_accounts
            </span>
            <span class="dropdown-list-text"> Disconnect </span>
          </a>
        </li>
        <li
          (click)="dropdown.hide()"
          *ngIf="
            isPinned(user) &&
            (user?.pinStatus?.relationship === connectionType.pinned ||
              user?.pinStatus?.relationship === '')
          "
        >
          <a
            role="button"
            (click)="pin(user, user?.pinStatus?.action)"
            class="dropdown-item small-ft"
          >
            <span class="mdi mdi-pin-off-outline dropdown-list-icon"></span>
            <span class="dropdown-list-text">
              {{ user?.pinStatus?.action }}
            </span>
          </a>
        </li>
      </ul>
    </span>
  </div>
</div>
