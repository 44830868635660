import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountPipe } from './count.pipe';
import { DownloadPipe } from './download.pipe';
import { HrefRouterlinkPipe } from './href-routerlink.pipe';
import { LimitPipe } from './limit.pipe';
import { NotificationLimitPipe } from './notification-limit.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SearchPipe } from './search.pipe';
import { StripTagsPipe } from './strip-tags.pipe';
import { TimeAheadPipe } from './time-ahead.pipe';
import { TimeDiffPipe } from './time-diff.pipe';
import { TruncateHtmlPipe } from './truncate-html.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { HighlightKeywordsPipe } from './highlight-keywords.pipe';

const pipes = [
  TimeAgoPipe,
  TimeDiffPipe,
  TimeAheadPipe,
  StripTagsPipe,
  LimitPipe,
  SearchPipe,
  DownloadPipe,
  NotificationLimitPipe,
  CountPipe,
  SafeHtmlPipe,
  HrefRouterlinkPipe,
  TruncateHtmlPipe,
  SafeUrlPipe,
  HighlightKeywordsPipe
];

@NgModule({
  declarations: [...pipes, HighlightKeywordsPipe],
  providers: [TimeAgoPipe, TimeAheadPipe, StripTagsPipe],
  imports: [
    CommonModule
  ],
  exports: [...pipes]
})
export class PipesModule { }
