import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SignalrService } from '@src/app/services/signalr.service';
import { DiagnosticService } from '@src/app/services/diagnostic.service';
import { pagination, notificationType, invitationStatus } from '@src/app/core/Constants';
import { NotificationMessage, Diagnostic, Invitation, EventInvitation } from '@src/app/core/models/model';
import { EventService } from '../../events/event.service';
import { NotificationsService } from '../../notification/notifications.service';
import { ToasterService } from '@src/app/services/toaster.service';

@Component({
  selector: 'event-request',
  templateUrl: './event-request.component.html',
  styleUrls: ['./event-request.component.scss'],
})
export class EventRequestComponent implements OnInit {
  styleDeclaration = <CSSStyleDeclaration>{
    textAlign: 'center',
    paddingTop: '3px',
    fontSize: 'x-large',
    color: 'white',
  };

  @Input() eventRequests: NotificationMessage[];
  @Input() eventSummary?: boolean;

  page = pagination.page;
  size = pagination.size;
  loading: boolean = false;

  constructor(
    private signalrService: SignalrService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationsService,
    private eventService: EventService,
    private diagnostic: DiagnosticService,
    private toaster: ToasterService,
  ) {
    this.signalrService.retrieveNotification().subscribe((msg: NotificationMessage) => {
      if (msg?.id && msg?.notificationType == notificationType.event) {
        this.eventRequests = [...new Set([msg, ...this.eventRequests])];
        this.cdr.detectChanges();
      }
    });

    this.search_events();
  }

  ngOnInit() {
    // if(this.eventSummary){
    //   this.getInvitationsNotifications(notificationType.event, this.page, this.size);
    // }
  }

  search_events() {
    this.notificationService.eventSearchResponse.subscribe((query) => {
      if (query) {
        this.getInvitationsNotifications(notificationType.event, this.page, this.size, query);
      }
    });
  }

  getInvitationsNotifications(invitationType: string, pageNumber: number, size: number, query: string = '') {
    //this.loading = true;
    this.notificationService.getInvitationByType(invitationType, pageNumber, size, query).subscribe(
      (response) => {
        // this.loading = false;
        if (response.length > 0) {
          if (this.eventRequests?.length > 0 && query) {
            this.eventRequests = [];
            this.eventRequests = [...new Set([...response, ...this.eventRequests])];
          } else {
            this.eventRequests = response;
          }
        }
        // console.log('inv ==>', this.eventsData);
      },
      (error) => {
        // this.loading = false;
      }
    );
  }

  viewNotification(notificationId: string) {
    this.notificationService.viewNotification(notificationId).subscribe((response) => {
      if (response) {
        //this.cdr.detectChanges();
      }
    });
  }

 

  eventAccept(notification: NotificationMessage) {
    notification.isDisabled = true;
    let invitation = this.invitation(notification.localNotificationdata.invitation);
    this.eventService.inviteResponse(invitation, invitationStatus.accepted).subscribe(
      (resp) => {
        switch (resp.statusCode) 
          {    
            case 400:   
              this.toaster.error(resp.message);                                              
            break; 
            case 404: 
            case 417:   
              this.removeInvitation(notification)
              this.toaster.error(resp.message);                                              
              break;
            case 200:           
              this.eventRequests.find((c) => c.localNotificationdata.id == notification.localNotificationdata.id).localNotificationdata.invitation.accepted = resp.isAccepted;
              notification.isDisabled = false;
              this.cdr.detectChanges();
              break;       
          }    
      },
      (error) => {
        notification.isDisabled = false;
        this.diagnostic.displayMessage(<Diagnostic>error);
      }
    );
    return notification;
  }

  eventReject(notification: NotificationMessage) {
    notification.isDisabled = true;
    let invitation = this.invitation(notification.localNotificationdata.invitation);
    this.eventService.inviteResponse(invitation, invitationStatus.declined).subscribe(
      (resp) => {
        switch (resp.statusCode) 
        {        
          case 400:   
            this.toaster.error(resp.message);                                              
            break; 
          case 404: 
          case 417:   
            this.removeInvitation(notification)
            this.toaster.error(resp.message);                                              
            break;
          case 200:           
            this.eventRequests.find((c) => c.localNotificationdata.id == notification.localNotificationdata.id).localNotificationdata.invitation.accepted = resp;
            notification.isDisabled = false;
            this.cdr.detectChanges();
            break;       
        }    
      },
      (error) => {
        notification.isDisabled = false;
        this.diagnostic.displayMessage(<Diagnostic>error);
      }
    );
    return notification;
  }

  invitation(invitation: Invitation): EventInvitation {
    let invite = <EventInvitation>{
      id: invitation.id,
      eventId: invitation.activityId,
      userId: invitation.userId,
      invitedOn: invitation.invitedOn,
      invitedBy: invitation.invitedBy,
      accepted: invitation.accepted,
      respondedOn: invitation.respondedOn,
    };
    return invite;
  }

  responseOption(notification, response) {
    if (response) {
      this.eventReject(notification);
    } else {
      this.eventAccept(notification);
    }
  }

  removeInvitation(notification: NotificationMessage) {
    let index = this.eventRequests.findIndex((d) =>  d.localNotificationdata.id == notification.localNotificationdata.id);
    this.eventRequests.splice(index, 1);            
  }
}
