import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { LayoutModule } from '@angular/cdk/layout';
import { WavesModule, BadgeModule, ToastModule } from 'ng-uikit-pro-standard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { CoreModule } from '../modules/core/core.module';
import { QuickChatModule } from '../modules/general/quick-chat/quick-chat.module';
import { SharedModule } from '../modules/shared/shared.module';
import { HttpProgressIndicatorComponent } from '../layouts/main-master/http-progress-indicator/http-progress-indicator.component';
import { MainMasterComponent } from '../layouts/main-master/main-master.component';
import { SavedLocationsCardComponent } from '../layouts/main-master/saved-locations-card/saved-locations-card.component';
import { SavedLocationsModalComponent } from '../layouts/main-master/saved-locations-modal/saved-locations-modal.component';
import { WidgetPinnedUsersComponent } from '../layouts/main-master/widget-pinned-users/widget-pinned-users.component';
import { MdbNotificationService } from 'mdb-angular-ui-kit/notification';
// import { NgxImageCompressService } from 'ngx-image-compress';
import { GeomodalComponent } from '../modules/general/geolocation/geo-modal/geo-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AuthenticateComponent,

    MainMasterComponent,
    HttpProgressIndicatorComponent,
    WidgetPinnedUsersComponent,
    SavedLocationsCardComponent,
    SavedLocationsModalComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule, // OR => ProtectedRoutingModule,

    PerfectScrollbarModule,
    LayoutModule,
    InfiniteScrollModule,
   
    CoreModule,
    QuickChatModule,
    SharedModule,
    WavesModule,
    BadgeModule,
    ToastModule.forRoot({
      opacity: 1,
      timeOut: 2000,
      positionClass: 'md-toast-bottom-center',
      preventDuplicates: true,
      newestOnTop: true,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MdbNotificationService,
    // NgxImageCompressService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: { suppressScrollX: true } }
  ],
  entryComponents: [GeomodalComponent],
})
export class ProtectedModule { }
