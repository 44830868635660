import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'timeAhead'
})
export class TimeAheadPipe implements PipeTransform {

  transform(value: any) {
    return this.getDuration(value);
  }

  getDuration(date: any) {

    if (moment(new Date()).diff(date) > 0) { return 'now'; }

    const duration = new Date(date).getTime() - new Date().getTime();

    if (duration < 1000 * 60) {
      return `${Math.round(duration / 1000)} seconds ahead`;
    } else if (duration < 1000 * 60 * 60) {
      const min = Math.round(duration / (1000 * 60));
      return `${min} minute${min > 1 ? 's' : ''} ahead`;
    } else if (duration < 1000 * 60 * 60 * 24) {
      const hr = Math.round(duration / (1000 * 60 * 60));
      return `${hr} hour${hr > 1 ? 's' : ''} ahead`;
    } else {
      const day = Math.round(duration / (1000 * 60 * 60 * 24));
      return `${day} day${day > 1 ? 's' : ''} ahead`;
    }
  }

}
