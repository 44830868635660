<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
    </div>

    <div class="modal-body">
        <div class="body-content">
            <span class="wrapper-text">This action will automatically pin <span class="username">{{user?.name}}</span>,
                are you sure you want to proceed?</span>
        </div>
        <div class="align-self-center d-flex">
            <button class="btn btn-outline-primary cancel-btn" (click)="modalRef.close()">
                <span>Cancel</span>
            </button>
            <button (click)="sendRequest(user.id)" class="btn btn-primary">
                <span class="">Request</span>
            </button>
        </div>
    </div>
</div>