import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageChatBoxComponent } from './message-chat-box/message-chat-box.component';
import { MessageChatPopupComponent } from './message-chat-popup/message-chat-popup.component';
import { NewMessageBoxComponent } from './new-message-box/new-message-box.component';
import { MediaModule } from '../media/media.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@src/app/directives/directives.module';
import { PipesModule } from '@src/app/pipes/pipes.module';
import { SmartInputOutputModule } from '../smart-input-output/smart-input-output.module';
import { SharedModule } from '../../shared/shared.module';
import { MdbFiveModule } from '../mdb-five/mdb-five.module';

const declarations = [
  MessageChatPopupComponent,
  MessageChatBoxComponent,
  NewMessageBoxComponent,
]

@NgModule({
  declarations: [
    ...declarations
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    MediaModule,
    SmartInputOutputModule,
    MdbFiveModule,
  ],
  exports: [
    ...declarations,
  ]
})
export class QuickChatModule { }
