import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SocialTag } from '../core/Constants';

@Directive({
  selector: '[appMentionRoute]'
})
export class MentionRouteDirective {

  constructor(private router: Router) { }

  @HostListener('click', ['$event.target']) onClick($event) {
    if ($event.getAttribute('social-tag')) {
      const data = $event.getAttribute('social-tag');
      
      if (data == SocialTag.Mention.Name) {
        const category = $event.getAttribute('category') || 'user';
        const goRoute = $event.getAttribute('routerlink') || $event.getAttribute('routerLink');
        if (goRoute) this.router.navigate([category, goRoute]);
      }

      else if (data == SocialTag.HashTag.Name) {

        const goRoute = $event.getAttribute('routerlink') || $event.getAttribute('routerLink');
        if (goRoute) {
          const encodedRoute = encodeURIComponent(`#${goRoute}`);
          this.router.navigateByUrl(`search?query=${encodedRoute}`);
        }
      }

    }

  }

}
