import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TrendService } from '../trend.service';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ComplaintComponent } from '../../../core/complain/complaint.component'
import { featureFlagsName } from '@src/app/core/Constants';
import { FeatureflagService } from '@src/app/services/featureflag.service';
import { SearchParam, Trend } from '@src/app/core/models/model';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-trend-listing',
  templateUrl: './trend-listing.component.html',
  styleUrls: ['./trend-listing.component.scss']
})
export class TrendListingComponent implements OnInit {

  modalRef: MdbModalRef<ComplaintComponent> | null = null;

  openReportBtn: boolean = false;

  trends: any[] = [];
  loading: boolean = true;
  query: string = '';
  savedLocationCount: number = 5;
  trendTab: number = 1;
  trendKeyword = '';
  private trend_search_term = new Subject<string>();
  params: SearchParam = {};
  tab = { category: "post" }
  trendFeatureFlag: boolean;

  constructor(private modalService: MdbModalService,
    private trendService: TrendService,
    private cdr: ChangeDetectorRef,
    private featureflagService: FeatureflagService,
    private userService: UserService,
    private route: Router) { }

  ngOnInit() {
    this.trendService.search(this.trend_search_term)
      .subscribe(res => {
        this.trends = res;
      });
    this.getTrends();
  }

  gotoTrendDetails(trend: Trend) {
    this.buildURL(trend.name);
  }

  buildURL(query: string) {
    this.params.location = this.params.location || this.userService.get('coords');
    this.params.radius = this.params.radius || this.userService.get('radius');

    this.route.navigate(['/search'], { queryParams: { query: query.trim(), category: this.tab.category, location: this.params.location, radius: this.params.radius } });
  }


  getTrends() {
    this.trendService.gettrends() //replace with original service
      .subscribe();
  }

  search_trends() {
    this.query = this.query.trim();
    if (this.query === '') {
      this.getTrends();
    }
    this.trend_search_term.next(this.query);
  }

  private detectChanges() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  complete() {
    this.loading = false;
    this.detectChanges();
  }

  public openModal(trendId) {
    this.modalRef = this.modalService.open(ComplaintComponent, {
      modalClass: 'modal-dialog-centered',
      data: {
        subject_id: trendId,
        category: 'trends',
      }
    })
  }

  public showReportBtn() {
    this.openReportBtn = !this.openReportBtn
  }


  getFeatureFlagTrends(): boolean {
    return this.featureflagService.isActive(featureFlagsName.trends);

  }
}
